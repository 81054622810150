<template>
  <nav v-if="pages > 0" class="pagination-wrapper">
    <button class="arrow-btn" :disabled="isFirstPage" @click="shiftPage(-1)">
      <ChevronLeft :size="24" />
    </button>
    <ul class="numbers-wrapper">
      <li
        v-for="number in numbers[numbersIndex]"
        :key="number"
        role="button"
        class="number-item"
        @click="changePage(number)"
      >
        <span class="page-number" :class="{ active: this.page === number }">{{ number }}</span>
      </li>
    </ul>
    <button class="arrow-btn" :disabled="isLastNumber" @click="shiftPage(1)">
      <ChevronRight :size="24" />
    </button>
  </nav>
</template>

<script>
import ChevronRight from 'vue-material-design-icons/ChevronRight';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft';

export default {
  name: 'CruisePagination',
  emits: ['update:page'],
  components: { ChevronLeft, ChevronRight },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      numbersIndex: 0,
    };
  },
  computed: {
    numbers() {
      const numbers = [];
      const groups = Math.ceil(this.pages / this.pageSize);

      for (let group = 0; group < groups; group++) {
        const numberGroup = [];
        const startNum = this.pageSize * group + 1;
        const endNum = Math.min((group + 1) * this.pageSize, this.pages);

        for (let num = startNum; num <= endNum; num++) {
          numberGroup.push(num);
        }
        numbers.push(numberGroup);
      }
      return numbers;
    },
    isFirstPage() {
      return this.numbersIndex === 0;
    },
    isLastNumber() {
      return this.numbersIndex === this.numbers.length - 1;
    },
  },
  methods: {
    updatePage(page) {
      this.$emit('update:page', page);
    },
    changePage(page) {
      this.updatePage(page);
    },
    shiftPage(shift) {
      this.numbersIndex += shift;
      const targetIndex = shift < 0 ? this.pageSize - 1 : 0;
      this.updatePage(this.numbers[this.numbersIndex][targetIndex]);
    },
  },
};
</script>

<style scoped>
.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.numbers-wrapper {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.number-item {
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page-number {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: var(--Grey-grey-600);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-number:hover {
  background: #f5f4f4;
}

.page-number.active {
  color: var(--White);
  background: var(--Blue-blue-500);
  font-weight: 700;
}

.arrow-btn {
  color: var(--Grey-grey-900);
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
}

.arrow-btn:focus,
.arrow-btn:active {
  border-radius: 5px;
  border: 1px solid var(--Blue-blue-500);
}

.arrow-btn:disabled {
  color: var(--Grey-grey-300);
  border: none;
  cursor: default;
}
</style>
