import api from '@/services/api';

const API_URL = '/api/reservation';

class ReservationService {
  /** Departure List */
  async getDeparturePortList(params) {
    try {
      const response = await api.get(`${API_URL}/getDepartureportDropBoxList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Osaka Voyage Schedule */
  async getOsakaVoyageSchedule(params) {
    try {
      const response = await api.get(`${API_URL}/getOsakaVoyageSchedule`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** OneNight Voyage Schedule */
  async getOneNightVoyageSchedule(date) {
    try {
      const response = await api.get(`${API_URL}/getOneNightVoyageSchedule`, { params: { date } });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Tsushima Express Schedule */
  async getAvailableSchedule(params) {
    try {
      const response = await api.get(`${API_URL}/getAvailableSchedule`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMyTrip(params) {
    try {
      const response = await api.get(`${API_URL}/getListNew`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMyTripDetail(params) {
    try {
      const response = await api.get(`${API_URL}/getDetailNew`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new ReservationService();
