<template>
  <ReCruiseModal :showModal="showModal" @update:showModal="updateShowModal" :title="$t('password.change')" autoHeight>
    <template #content>
      <div class="input-wrapper">
        <CruiseInputField :label="$t('password.newPassword')" :helperText="$t('validation.password')" required>
          <ReCruiseInput type="password" :placeholder="$t('login.inputPassword')" v-model="password" />
        </CruiseInputField>
        <CruiseInputField :label="$t('password.confirmNewPassword')" required>
          <ReCruiseInput type="password" :placeholder="$t('password.reInputPassword')" v-model="confirmPassword" />
        </CruiseInputField>
      </div>
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">
        {{ $t('actions.cancel') }}
      </ReCruiseButton>
      <ReCruiseButton class="confirm-btn" @click="updatePassword">{{ $t('actions.save') }}</ReCruiseButton>
    </template>
  </ReCruiseModal>
</template>

<script>
import UserService from '@/services/user';
import { validators } from '@/utils/validator';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';

export default {
  name: 'UpdatePasswordModal',
  components: { ReCruiseModal, ReCruiseButton, CruiseInputField, ReCruiseInput },
  emits: ['update:showModal'],
  props: {
    showModal: Boolean,
    userId: String,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
    };
  },
  watch: {
    showModal(value) {
      if (!value) {
        this.password = '';
        this.confirmPassword = '';
      }
    },
  },
  computed: {
    mobileClass() {
      return window.innerWidth < 768 ? 'mobile' : '';
    },
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },
    passwordValidation() {
      if (!this.password) {
        this.$alert({ content: this.$t('messages.enterPassword') });
        return;
      }

      if (!validators.isValidPassword(this.password)) {
        this.$alert({ content: this.$t('validation.alertPassword') });
        return;
      }

      if (!this.confirmPassword) {
        this.$alert({ content: this.$t('messages.checkPassword') });
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.$alert({ content: this.$t('messages.passwordMismatch') });
        return;
      }

      return true;
    },
    async updatePassword() {
      if (!this.passwordValidation()) {
        return;
      }

      try {
        const result = await this.$confirm({
          title: this.$t('password.change'),
          content: this.$t('password.changePasswordConfirm'),
        });
        if (result) {
          const params = { password: this.password, userId: this.userId };
          const response = await UserService.updatePassword(params);
          this.$alert({ content: response.message });
        }
        this.closeModal();
      } catch (error) {
        this.password = '';
        this.confirmPassword = '';

        this.$alert({ content: error });
      }
    },
  },
};
</script>

<style scoped>
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

@media screen and (min-width: 768px) {
  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }
}
</style>
