import api from '@/services/api';

const API_URL = '/api/payment';

class PaymentService {
  async registerTrade(params) {
    try {
      const response = await api.post(`${API_URL}/postRegisterRequest`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async requestPayment(params) {
    try {
      const response = await api.post(`${API_URL}/postRequest`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async requestEncInfo(query) {
    try {
      const response = await api.get(`${API_URL}/getPaymentData`, { params: query });

      return response;
    } catch (error) {}
  }

  async cancelReservation(params) {
    try {
      const response = await api.post(`${API_URL}/cancel`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getBankCodeList() {
    try {
      const response = await api.get(`${API_URL}/bankCode`);

      return response;
    } catch (error) {}
  }
}

export default new PaymentService();
