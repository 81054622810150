<template>
  <header class="header-container" :class="headerClasses" @mouseenter="handleEnter" @mouseleave="handleLeave">
    <div class="header-wrapper">
      <nav class="sub-nav container-default-renewal" v-if="isNavigation">
        <router-link to="/support/qna" class="sub-link">{{ $t('menu.qna') }}</router-link>
        <LocaleSelect :active="isActive" />
        <span v-if="user" class="sub-link" @click="logout">{{ $t('user.logout') }}</span>
        <router-link v-else to="/register" class="sub-link">{{ $t('user.signUp') }}</router-link>
      </nav>

      <nav class="nav-bar container-default-renewal" :class="showNavClass">
        <div class="menu-logo-wrapper" :class="{ active: isNavigation }">
          <h1 class="logo-wrapper">
            <router-link to="/" class="flex-center">
              <img class="nav-logo" :src="logoSrc" alt="PanStar Cruise" />
            </router-link>
          </h1>

          <ul v-if="isNavigation" class="gnb-menu">
            <li
              v-for="(item, idx) in globalNav"
              class="menu-item"
              :class="activeClass(idx)"
              :ref="`link${idx}`"
              :key="`link${idx}`"
              @mouseleave="leaveMenu($event, idx)"
            >
              <router-link v-if="!item.blocked" class="menu-link" :to="item?.path">
                <span class="name-text">{{ item.name }}</span>
              </router-link>

              <div v-else class="menu-link">
                <div class="link-item flex-between" @mouseenter="enterMenu(idx)">
                  <span class="name-text" :class="activeClass(idx)">{{ item.name }}</span>

                  <span class="arrow-icon" :class="[{ hover: isActive }, activeClass(idx)]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g clip-path="url(#clip0_1799_15185)">
                        <path
                          d="M7.41 8.59009L12 13.1701L16.59 8.59009L18 10.0001L12 16.0001L6 10.0001L7.41 8.59009Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1799_15185">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>

                <div class="sub-menu-wrapper" :class="activeClass(idx)">
                  <ul class="sub-menu-contents">
                    <li
                      v-for="subMenu in item.children"
                      class="sub-menu"
                      :class="{ active: !subMenu.blocked }"
                      :key="subMenu.name"
                    >
                      <RouterLink v-if="!subMenu.blocked" class="sub-title active" :to="subMenu.path">
                        {{ subMenu.name }}
                      </RouterLink>
                      <span v-else class="sub-title">
                        {{ subMenu.name }}
                      </span>

                      <ul class="menu-child-wrapper">
                        <li v-for="child in subMenu.children">
                          <RouterLink :to="child.path" class="child-title">{{ child.name }} </RouterLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>

          <div class="login-wrapper" v-if="!user && isBooking">
            <ReCruiseButton class="login-btn" theme="outline" @click="goLoginPage">
              {{ $t('user.login') }}
            </ReCruiseButton>
          </div>
        </div>

        <MainUserNav v-if="isNavigation" :active="isActive" />

        <button v-if="isNavigation" class="menu-btn flex-center" @click="clickMenu">
          <img class="menu-icon" :src="navSrc" alt="" />
        </button>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MenuIcon from 'vue-material-design-icons/Menu';
import LocaleSelect from '@/layouts/LocaleSelect.vue';
import MainUserNav from '@/components/user/MainUserNav.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'MainHeader',
  emits: ['clickMenu'],
  components: { MenuIcon, LocaleSelect, MainUserNav, ReCruiseButton },
  props: {
    active: Boolean,
    isNavigation: {
      type: Boolean,
      default: true,
    },
    isMainPage: Boolean,
    currentScroll: Number,
  },
  data() {
    return {
      currentIdx: null,
      isHover: false,
    };
  },
  computed: {
    ...mapGetters('common', ['globalNav']),
    ...mapState('auth', ['user']),

    path() {
      return this.$route.path;
    },
    logoSrc() {
      const logoFileName = 'panstar_full_logo';
      return !(this.currentScroll || !this.isMainPage || this.isHover)
        ? require(`@/assets/images/logo/${logoFileName}_w.svg`)
        : require(`@/assets/images/logo/${logoFileName}.svg`);
    },
    navSrc() {
      return require(`@/assets/images/icon/nav_menu_icon${
        this.currentScroll || !this.isMainPage || this.isHover ? '' : '_w'
      }.svg`);
    },
    headerClasses() {
      const classes = [];

      // When there is a scroll position or is not on the main page
      if (this.currentScroll || !this.isMainPage || this.isHover) {
        classes.push('white-bg');
      }

      // When navigation is not activated
      if (!this.isNavigation) {
        classes.push('blue-bg');
      }

      return [...classes, this.showNavClass];
    },
    showNavClass() {
      return this.isNavigation ? 'show-nav' : '';
    },
    isActive() {
      if (this.isMainPage) {
        return this.currentScroll > 0 || this.isHover;
      }
      return this.currentScroll > 0 || !this.isMainPage || this.isHover;
    },
    isBooking() {
      return this.$route.meta.isBooking;
    },
  },
  watch: {
    path() {
      this.currentIdx = null;
    },
  },
  methods: {
    clickMenu() {
      this.$emit('clickMenu');
    },
    handleEnter() {
      this.isHover = true;
    },
    handleLeave() {
      this.isHover = false;
    },
    enterMenu(idx) {
      this.currentIdx = idx;
    },
    leaveMenu(e, idx) {
      if (this.$refs[`link${idx}`].some(el => el.contains(e.relatedTarget))) return;

      this.currentIdx = null;
    },
    activeClass(index) {
      return this.currentIdx === index ? 'active' : '';
    },
    async logout() {
      const result = await this.$confirm({ content: this.$t('messages.logoutConfirm') });
      if (!result) return;

      try {
        await this.$store.dispatch('auth/logout');
        this.$router.push('/');
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
    async goLoginPage() {
      if (!this.user) {
        this.$router.push({ name: 'login-form', query: { redirect: this.$route.fullPath } });
        return;
      }
    },
  },
};
</script>

<style scoped>
.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent;
  color: var(--White);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  z-index: var(--zLayoutNavigation);
}

.sub-nav {
  display: none;
}

.header-container:hover {
  color: var(--Black);
  background: var(--White);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
}

.header-container.white-bg {
  color: var(--Black);
  background: var(--White);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
}

.header-container.blue-bg {
  background: var(--Grey-grey-50);
  box-shadow: none;
}

.header-wrapper {
  width: 100%;
  height: 100%;
}

.nav-bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-wrapper,
.nav-logo {
  width: 200px;
}

.menu-logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.menu-btn {
  width: 21px;
  height: 14px;
}

.gnb-menu {
  display: none;
  height: 100%;
}

.menu-item {
  width: auto;
  height: 100%;
}

.menu-item.active {
  color: var(--Blue-blue-500);
  border-bottom: 2px solid var(--Blue-blue-500, #446ceb);
  cursor: default;
}

.menu-link {
  display: flex;
  align-items: center;
  padding: 0 24px;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 500;
  line-height: 100%;
}

.link-item {
  gap: 10px;
}

.name-text {
  height: 24px;
}

.name-text.active {
  color: var(--Blue-blue-500);
}

.arrow-icon {
  color: var(--White);
  transition: transform 0.3s ease;
}

.header-container:hover .arrow-icon,
.arrow-icon.hover {
  color: var(--Grey-grey-900, #333);
}

.arrow-icon.active {
  color: var(--Blue-blue-500) !important;
  transform: rotate(180deg);
}

.sub-menu-wrapper {
  display: none;
  width: 100%;
  position: absolute;
  top: 108px;
  left: 0;
  background: var(--White);
  min-height: 285px;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.sub-menu-wrapper.active {
  display: flex;
  justify-content: center;
}

.sub-menu-contents {
  width: 1352px;
  text-align: left;
  display: flex;
  padding: 24px 0;
}

.sub-menu {
  width: 234px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
}

.sub-title {
  color: var(--Grey-grey-900, #333);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.sub-title.active:hover {
  cursor: pointer;
  color: var(--Blue-blue-500, #446ceb);
}

.child-title {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.9px;
  height: 25px;
  color: var(--Grey-grey-900, #333);
}

.child-title:hover {
  cursor: pointer;
  color: var(--Blue-blue-500, #446ceb);
  border-bottom: 1px solid var(--Blue-blue-500, #446ceb);
}

.menu-child-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.login-btn {
  width: 68px;
  height: 33px;
  padding: 7px 0;
  color: var(--Blue-blue-500, #446ceb);
  font-size: 16px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .header-container {
    flex-direction: column;
    justify-content: flex-start;
    min-height: 80px;
  }

  .header-container.show-nav {
    gap: 4px;
    height: 109px;
    padding-top: 20px;
  }

  .header-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1384px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .sub-nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .nav-bar.show-nav {
    height: 53px;
    align-items: flex-start;
  }

  .sub-link {
    cursor: pointer;
    padding: 7px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logo-wrapper,
  .nav-logo {
    width: 220px;
  }

  .menu-logo-wrapper {
    gap: 90px;
    align-items: center;
  }

  .menu-logo-wrapper.active {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .menu-btn {
    display: none;
  }

  .menu-icon {
    display: block;
    width: 30.75px;
    height: 20.5px;
  }

  .gnb-menu {
    display: flex;
    height: 100%;
    text-align: right;
    padding-top: 4px;
  }

  .login-btn {
    width: 100px;
  }

  .name-text {
    line-height: 120%;
  }
}
</style>
