import makeUid from '@/utils/makeUid';

export const sharedComputedProps = {
  computed: {
    attrs() {
      const { class: className, ...rest } = this.$attrs;
      return rest;
    },
    safeId() {
      if (this.$attrs.id) {
        return this.$attrs.id;
      }
      return makeUid();
    },
  },
};
