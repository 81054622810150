<template>
  <ReCruiseModal :showModal="showModal" @update:showModal="updateShowModal" :title="title">
    <template #content>
      <TourScheduleItem
        :tour="tour"
        v-model:selectedSchedule="selectedSchedule"
        v-model:selectedGrade="selectedGrade"
      />
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">
        {{ $t('actions.close') }}
      </ReCruiseButton>
      <ReCruiseButton class="confirm-btn" :disabled="!selectedSchedule" @click="goToBooking">
        {{ $t('tourProduct.selectJourney') }}
      </ReCruiseButton>
    </template>
  </ReCruiseModal>
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { getLocalText } from '@/utils/convert';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import TourScheduleItem from '@/components/tour/TourScheduleItem.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'TourScheduleModal',
  components: { ReCruiseModal, TourScheduleItem, ReCruiseButton },
  emits: ['update:showModal'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    title: String,
    tour: Object,
  },
  data() {
    return {
      selectedGrade: null,
      selectedSchedule: null,
    };
  },
  watch: {
    tour: {
      deep: true,
      immediate: true,
      handler() {
        this.selectedGrade = this.tour.minimumPrices[0];
        this.selectedSchedule = null;
      },
    },
  },
  computed: {
    mobileClass() {
      return window.innerWidth < 768 ? 'mobile' : '';
    },
  },
  methods: {
    ...mapMutations('booking', ['updateSearchBookingInfo']),
    getLocalText,

    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.selectedGrade = this.tour?.minimumPrices[0];
      this.selectedSchedule = null;
      this.updateShowModal(false);
    },
    goToBooking() {
      const { code } = this.tour.packageTour.route;
      const { leaveVoyageNumber, returnVoyageNumber, leaveVoyageInformation, returnVoyageInformation } =
        this.selectedSchedule;
      const bookingType = { OSC: 'cruise', ONC: 'cruise', TSE: 'fastShip' };
      const category = { OSC: 'osaka', ONC: 'oneNight', TSE: 'fastShip' };
      const path = { OSC: 'booking/cruise/tour', ONC: 'booking/cruise/tour', TSE: 'booking/fast-ship/tour' };
      const isRound = this.tour.packageTour.isRound;

      this.updateSearchBookingInfo({
        portState: {
          from: {
            departure: {
              portName: this.getLocalText(leaveVoyageInformation.departurePort, 'name'),
              portCode: leaveVoyageInformation.departurePort.code,
            },
            arrival: {
              portName: this.getLocalText(leaveVoyageInformation.arrivePort, 'name'),
              portCode: leaveVoyageInformation.arrivePort.code,
            },
          },
          to: {
            departure: isRound
              ? {
                  portName: this.getLocalText(returnVoyageInformation.departurePort, 'name'),
                  portCode: returnVoyageInformation.departurePort.code,
                }
              : null,
            arrival: isRound
              ? {
                  portName: this.getLocalText(returnVoyageInformation.arrivePort, 'name'),
                  portCode: returnVoyageInformation.arrivePort.code,
                }
              : null,
          },
        },
        packageTourId: this.tour.packageTour.id,
        packagePrice: this.tour.packageTour.packagePrice,
        saleCodeAdult: this.tour.packageTour.saleCodeAdult,
        saleCodeChild: this.tour.packageTour.saleCodeChild,
        saleCodeInfant: this.tour.packageTour.saleCodeInfant,
        leaveVoyageNo: leaveVoyageNumber,
        returnVoyageNo: returnVoyageNumber,
        bookingName: this.getLocalText(this.tour.packageTour, 'name'),
        bookingType: bookingType[code],
        routeCode: code,
        category: category[code],
        schedule: {
          from: this.selectedSchedule.leaveVoyageInformation,
          to: this.selectedSchedule?.returnVoyageInformation,
        },
        departure: {
          portCode: this.selectedSchedule.leaveVoyageInformation.departurePort.code,
          portName: this.getLocalText(this.selectedSchedule.leaveVoyageInformation.departurePort, 'name'),
          highlight: code === 'ONC',
        },
        arrival: this.selectedSchedule?.returnVoyageInformation
          ? {
              portCode: this.selectedSchedule.returnVoyageInformation.departurePort.code,
              portName: this.getLocalText(this.selectedSchedule.returnVoyageInformation.departurePort, 'name'),
            }
          : {
              portCode: this.selectedSchedule.leaveVoyageInformation.arrivePort.code,
              portName: this.getLocalText(this.selectedSchedule.leaveVoyageInformation.arrivePort, 'name'),
            },
        isRoundTrip: this.tour.packageTour.isRound,
        dateRange: {
          from: dayjs(this.selectedSchedule.leaveVoyageInformation.pickupDt),
          to: this.selectedSchedule?.returnVoyageInformation
            ? dayjs(this.selectedSchedule?.returnVoyageInformation?.pickupDt)
            : null,
        },
        passengerTypeCountList: [],
        availableGrade: this.selectedSchedule.packageTourGrades.find(
          item => item.grade.gradeCode === this.selectedGrade.gradeCode,
        ),
      });

      let agreementPath;
      if (bookingType[code] === 'cruise') {
        agreementPath = '/agreement/osaka';
      } else if (bookingType[code] === 'fastShip') {
        agreementPath = '/agreement/daemado';
      }

      this.$router.push({ path: agreementPath, query: { redirect: `/${path[code]}` } });
    },
  },
};
</script>

<style scoped>
.modal-content-container {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  margin-bottom: 52px;
}

.content-wrapper {
  padding: 24px 16px 55px;
}

.modal-btns {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

@media screen and (min-width: 768px) {
  :deep(.modal-wrapper) {
    width: 30%;
    min-width: 600px;
    height: 74%;
  }

  :deep(.modal-contents) {
    overflow: hidden;
  }

  .modal-content-container {
    margin-bottom: 82px;
  }

  .content-wrapper {
    padding: 16px;
    width: 100%;
    padding-bottom: 0;
  }

  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }
}
</style>
