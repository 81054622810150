<template>
  <div class="not-found-wrapper">
    <h1>404 Not Found</h1>
    <p>{{ $t('messages.notFound') }}</p>
    <CruiseButton theme="q" @click="clickHome">{{ $t('actions.goHome') }}</CruiseButton>
  </div>
</template>

<script>
import CruiseButton from '@/components/button/CruiseButton.vue';

export default {
  name: 'NotFound',
  components: { CruiseButton },
  methods: {
    clickHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.not-found-wrapper {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
</style>
