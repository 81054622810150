<template>
  <div class="user-info-container">
    <div class="banner flex-center">
      <div class="contents-wrapper welcome-message">
        {{ $t('user.welcome', { userName: user?.name }) }}
      </div>
    </div>

    <div class="user-info-wrapper">
      <div class="edit-wrapper">
        <div class="sub-title">{{ $t('user.info') }}</div>
        <div class="info-field-wrapper">
          <div class="info-field">
            <div class="info-label">{{ $t('labels.userId') }}</div>
            <div>{{ userInfo.userId }}</div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('labels.password') }}</div>
            <div>
              <CruiseButton theme="q" @click="togglePasswordModal">{{ $t('password.change') }}</CruiseButton>
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('labels.name') }}<span class="required-star">*</span></div>
            <div class="input-width number-wrapper">
              <CruiseInput v-model="userInfo.name" :placeholder="$t('labels.inputName')" />
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('profile.dateOfBirth') }}<span class="required-star">*</span></div>
            <div class="input-width number-wrapper">
              <CruiseInput v-model="userInfo.birthDay" :placeholder="$t('validation.numberOnlBirth')" maxLength="8" />
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('profile.gender') }}<span class="required-star">*</span></div>
            <div class="input-field">
              <CruiseRadio v-model="userInfo.sex" :label="$t('profile.male')" value="M" name="sex" />
              <CruiseRadio v-model="userInfo.sex" :label="$t('profile.female')" value="F" name="sex" />
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('labels.phoneNumber') }}<span class="required-star">*</span></div>
            <div class="input-width number-wrapper">
              <!-- <CruiseSelect
                class="number-selector"
                :placeholder="$t('profile.countryCode')"
                v-model="countryNo"
                :options="countryNoList"
              /> -->

              <PhoneNumberAuth
                v-if="$i18n.locale === 'ko'"
                v-model:isVerified="isVerified"
                v-model:userPhoneNumber="userInfo.mobile"
              />
              <CruiseInput
                v-else
                class="input-width"
                :placeholder="$t('messages.phoneNumber')"
                v-model="userInfo.mobile"
                type="number"
              />
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('profile.email') }}<span class="required-star">*</span></div>

            <div class="input-width number-wrapper">
              <div v-if="$i18n.locale === 'ko'" class="input-width email-wrapper">
                <div class="email-field">
                  <CruiseInput
                    type="emailAddress"
                    class="input-width"
                    :placeholder="$t('profile.inputEmail')"
                    v-model="emailId"
                  />
                  <span>@</span>
                  <CruiseInput
                    type="emailDomain"
                    class="input-width"
                    :placeholder="$t('profile.inputSelf')"
                    v-model="emailDomain"
                    :readonly="emailReadonly"
                  />
                </div>
                <CruiseSelect class="email-selector" v-model="emailTemplate" :options="emailList" />
              </div>

              <EmailAuth
                v-else
                class="number-wrapper"
                v-model:isVerified="isVerified"
                v-model:userEmail="emailId"
                v-model:userEmailDomain="emailDomain"
              />
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('profile.receiveEmail') }}</div>
            <div class="input-width agree-wrapper">
              <div class="input-field">
                <CruiseRadio
                  v-model="userInfo.emailReceive"
                  :label="$t('agreement.agree')"
                  :value="true"
                  name="email"
                />
                <CruiseRadio
                  v-model="userInfo.emailReceive"
                  :label="$t('agreement.disagree')"
                  :value="false"
                  name="email"
                />
              </div>
              <div>
                {{ $t('messages.emailSubscriptionNote') }}
              </div>
            </div>
          </div>

          <div class="info-field">
            <div class="info-label">{{ $t('labels.smsAppPush') }}</div>
            <div class="input-width agree-wrapper">
              <div class="input-field">
                <CruiseRadio v-model="userInfo.smsReceive" :label="$t('agreement.agree')" :value="true" name="sms" />
                <CruiseRadio
                  v-model="userInfo.smsReceive"
                  :label="$t('agreement.disagree')"
                  :value="false"
                  name="sms"
                />
              </div>
              <div>{{ $t('messages.importantNoticeSubscription') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-wrapper">
        <TextButton theme="danger" @click="toggleDeleteAccountModal">{{ $t('withdrawal.label') }}</TextButton>

        <CruiseButton class="save-btn" :theme="saveBtnClass" :disabled="!isSave" @click="updateUserInfo">
          {{ $t('actions.save') }}
        </CruiseButton>
      </div>
    </div>

    <UpdatePasswordModal v-model:showModal="isShowPasswordModal" :userId="userInfo.userId" />
    <DeleteAccountModal v-model:showModal="isShowDeleteAccountModal" :userId="userInfo.userId" />
  </div>
</template>

<script>
import UserService from '@/services/user';
import { mapState } from 'vuex';
import { validators } from '@/utils/validator';

import TextButton from '@/components/button/TextButton.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import UpdatePasswordModal from '@/components/user/UpdatePasswordModal.vue';
import DeleteAccountModal from '@/components/user/DeleteAccountModal.vue';
import PhoneNumberAuth from '@/components/common/PhoneNumberAuth.vue';
import EmailAuth from '@/components/login/EmailAuth.vue';

export default {
  name: 'UserInfoView',
  components: {
    TextButton,
    CruiseButton,
    CruiseRadio,
    CruiseSelect,
    CruiseInput,
    UpdatePasswordModal,
    DeleteAccountModal,
    PhoneNumberAuth,
    EmailAuth,
  },
  data() {
    return {
      countryNo: '',
      countryNoList: [],

      userInfo: {},
      originUserInfo: {},

      isVerified: false,

      emailId: '',
      emailDomain: '',
      emailReadonly: false,
      emailTemplate: this.$t('profile.inputSelf'),
      emailList: [this.$t('profile.inputSelf'), 'naver.com', 'gmail.com', 'daum.net', 'hotmail.com'],

      isShowPasswordModal: false,
      isShowDeleteAccountModal: false,
    };
  },
  watch: {
    emailTemplate(value) {
      const isDirect = value === this.$t('profile.inputSelf');
      this.emailReadonly = !isDirect;
      this.emailDomain = isDirect ? '' : value;
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    convertedEmail() {
      return `${this.emailId}@${this.emailDomain}`;
    },
    isSave() {
      const fieldsToCompare = ['name', 'birthDay', 'sex', 'mobile', 'email', 'emailReceive', 'smsReceive'];

      return fieldsToCompare.some(field => {
        if (field === 'email') {
          return this.originUserInfo[field] !== this.convertedEmail;
        }
        return this.originUserInfo[field] !== this.userInfo[field];
      });
    },
    saveBtnClass() {
      return this.isSave ? 'p' : 's';
    },
  },
  methods: {
    init() {
      this.getUserInfo();
      this.setEmailAddress();
      this.setPhoneNumber();
    },
    async getUserInfo() {
      try {
        const params = { userId: this.user.userId };
        const { data } = await UserService.getUserInfo(params);
        this.userInfo = data;
        this.originUserInfo = { ...data };
      } catch (error) {
        this.$alert({ content: error });
        this.isVerified = false;
      }
    },
    togglePasswordModal() {
      this.isShowPasswordModal = true;
    },
    async updateUserInfo() {
      if (!this.userInfo.mobile) {
        this.$alert({ content: this.$t('messages.enterPhoneNumber') });
        return;
      }

      const isPhoneNumberChanged = this.originUserInfo.mobile !== this.userInfo.mobile;
      if (this.$i18n.locale === 'ko' && isPhoneNumberChanged && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completePhoneVerification') });
        return;
      }

      if (!this.emailId || !this.emailDomain) {
        this.$alert({ content: this.$t('messages.enterEmail') });
        return;
      }

      if (!validators.isValidEmail(this.convertedEmail)) {
        this.$alert({ content: this.$t('messages.checkEmail') });
        return;
      }

      const isEmailChanged = this.originUserInfo.email !== this.convertedEmail;
      if (this.$i18n.locale !== 'ko' && isEmailChanged && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completeEmailVerification') });
        return;
      }

      try {
        const result = await this.$confirm({
          title: this.$t('labels.profileUpdate'),
          content: this.$t('messages.confirmProfileUpdate'),
        });

        if (result) {
          const params = { ...this.userInfo, email: this.convertedEmail };
          const response = await UserService.updateUserInfo(params);

          this.$alert({ content: response.message }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    setEmailAddress() {
      this.emailId = this.user.email.split('@')[0];
      this.emailDomain = this.user.email.split('@')[1];
    },
    setPhoneNumber() {
      this.userInfo.mobile = this.user.mobile;
    },
    toggleDeleteAccountModal() {
      this.isShowDeleteAccountModal = true;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.user-info-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 160px;
  background: #f7f7f7;
}

.welcome-message {
  color: var(--Black);
  font-size: 24px;
  font-weight: 700;
}

.contents-wrapper {
  width: 100%;
  max-width: 1228px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.user-info-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 68px;
  gap: 20px;
}

.sub-title {
  font-size: 14px;
  font-weight: 700;
}

.edit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-field-wrapper {
  border-top: var(--default--border);
}

.info-field {
  font-size: 12px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 17px;
  border-bottom: var(--default--border);
}

.info-label {
  width: 90px;
  word-break: break-all;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-width {
  flex: 1 0;
}

.number-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.number-selector {
  flex: 0;
  min-width: 80px;
}

:deep(.number-wrapper .label-wrapper),
:deep(.number-wrapper .label-wrapper) {
  display: none;
}

.agree-wrapper {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.email-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.email-field {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
}

.email-selector {
  width: 100%;
}

.required-star {
  color: var(--Orange-orange-500);
}

:deep(.save-btn) {
  width: 155px;
}

:deep(.auth-request-btn) {
  width: auto;
  padding: 5px;
}

@media screen and (min-width: 768px) {
  .banner {
    height: 200px;
  }

  .user-info-wrapper {
    max-width: 800px;
  }

  .sub-title {
    font-size: 18px;
  }
  .info-field {
    padding: 20px;
    gap: 100px;
  }

  .info-label {
    width: 100px;
    word-break: keep-all;
  }

  .number-selector {
    min-width: 120px;
  }

  .agree-wrapper {
    gap: 20px;
  }

  .email-wrapper {
    align-items: center;
    flex-direction: row;
  }

  .email-field {
    width: 75%;
  }

  .email-selector {
    width: 25%;
  }

  :deep(.input-width) {
    max-width: 100% !important;
  }

  :deep(.email-field) {
    width: calc(75% + 10px);
  }
}

@media (max-width: 767px) {
  :deep(.auth-request-btn) {
    min-width: auto;
  }
}
</style>
