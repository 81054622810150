<template>
  <li class="tour-product-item-wrapper">
    <div class="tour-product-item">
      <div class="tour-product-image">
        <img :src="getLocalText(tour.packageTour, 'coverImageUrl')" alt="tour-product-image" />
      </div>

      <div class="tour-product-info-wrapper">
        <div class="tour-product-info">
          <div class="tour-product-info-header">
            <div class="header-sub">
              <div class="header-sub-gray" v-if="term">{{ term }}</div>
              <div class="header-sub-point" v-if="dateFormat">
                {{ $t('tourProduct.nextDepartureDate') }}: {{ dateFormat }}
              </div>
            </div>
            <div class="header-title">{{ getLocalText(tour.packageTour, 'name') }}</div>
          </div>

          <div class="tour-product-info-desc">
            <div class="tour-product-desc">{{ getLocalText(tour.packageTour, 'promotionText') }}</div>
            <div class="tour-product-option">
              <div class="tour-direction">{{ roundTag }}</div>
              <div class="tour-route" v-if="routeTag">{{ routeTag }}</div>
              <div class="tour-etc" v-if="etcTag">{{ etcTag }}</div>
            </div>
          </div>
        </div>

        <div class="tour-more-wrapper">
          <div class="width-full flex-between" ref="more">
            <TextButton class="more-btn" @click="toggleDetail"> {{ $t('actions.viewMore') }} + </TextButton>
            <div class="tour-price-per">
              <span class="tour-per">{{ $t('labels.perPerson') }}</span>
              <div class="tour-price">
                <span>
                  {{
                    convertCommaNumber(
                      tour.packageTour.packagePrice +
                        tour.minimumPrices.reduce((lowest, room) => {
                          return room.price < lowest.price ? room : lowest;
                        }).price,
                    )
                  }}
                </span>
                <span class="price-text"
                  >{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                  }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}~</span
                >
              </div>
            </div>
          </div>
          <ReCruiseButton class="schedule-btn" @click="toggleSchedule">
            <span v-if="$i18n.locale === 'en'">
              View {{ tour.packageTour.packageTourSchedule.length }}
              {{ tour.packageTour.packageTourSchedule.length > 1 ? 'schedules' : 'schedule' }}
            </span>
            <span v-else>
              {{
                $t('tourProduct.viewSchedules', {
                  count: tour.packageTour.packageTourSchedule.length,
                  s: $i18n.locale === 'en' ? 's' : '',
                })
              }}
            </span>
          </ReCruiseButton>
        </div>
      </div>
    </div>

    <TourMoreInfoModal
      v-model:showModal="isOpenDetail"
      :modalTitle="$t('labels.product') + $t('actions.viewMore')"
      :tour="tour"
    />
  </li>
</template>

<script>
import dayjs from 'dayjs';
import { convertCommaNumber, getLocalText } from '@/utils/convert';

import TextButton from '@/components/button/TextButton.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import TourMoreInfoModal from '@/components/tour/TourMoreInfoModal.vue';

export default {
  name: 'TourProductItem',
  emits: ['onClick'],
  components: { TextButton, ReCruiseButton, TourMoreInfoModal },
  props: {
    tour: Object,
  },
  data() {
    return {
      isOpenDetail: false,
    };
  },
  computed: {
    term() {
      const { isRound, term } = this.tour.packageTour;
      const nightText = term - 1 === 1 ? 'Night' : 'Nights';
      const termText =
        this.$i18n.locale === 'en'
          ? `${term - 1} ${nightText} ${term} Days`
          : `${term - 1}${this.$t('tourProduct.night')} ${term}${this.$t('tourProduct.day')}`;

      return isRound ? (term > 1 ? termText : this.$t('tourProduct.dayTrip')) : '';
    },
    dateFormat() {
      const leaveDate = this.tour.packageTour.packageTourSchedule[0]?.leaveDate || '';
      return leaveDate ? dayjs(leaveDate).format('YY.MM.DD') : '';
    },
    roundTag() {
      return this.tour.packageTour.isRound ? this.$t('labels.roundTrip') : this.$t('labels.oneWay');
    },
    routeTag() {
      return this.getLocalText(this.tour.packageTour, 'tagRoute');
    },
    etcTag() {
      return this.getLocalText(this.tour.packageTour, 'tagEtc');
    },
  },
  methods: {
    convertCommaNumber,
    getLocalText,

    toggleDetail() {
      this.isOpenDetail = !this.isOpenDetail;
    },
    toggleSchedule() {
      this.$emit('onClick', this.tour);
    },
    handleClickOutside(e) {
      if (!this.$refs.more.contains(e.target)) {
        this.isOpenDetail = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.tour-product-item-wrapper {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.tour-product-item-wrapper:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.tour-product-item {
  display: flex;
  flex-direction: column;
}

.tour-product-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.tour-product-info-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tour-product-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 0;
}

.tour-product-info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.header-sub {
  display: flex;
  align-items: center;
  gap: 4px;
}

.header-sub-gray {
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.7px;
}

.header-sub-point {
  display: flex;
  padding: 2.645px 7.936px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--Orange-orange-300, #faa542);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.header-title {
  font-size: 20px;
  font-weight: 700;
}

.tour-product-info-desc {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-product-desc {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.tour-product-option {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.tour-direction {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 14.799px;
  border-radius: 30px;
  border: 1px solid var(--Blue-blue-500);
  background: var(--White);
  color: var(--Blue-blue-500);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.tour-route {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--Blue-blue-500);
  background: var(--White);
  color: var(--Blue-blue-500);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.tour-etc {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--Grey-grey-700);
  background: var(--White);
  color: var(--Grey-grey-700);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.tour-more-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
}

.width-full {
  width: 100%;
}

.tour-price-per {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  line-height: 17px;
}

.tour-per {
  font-size: 12px;
  font-weight: 400;
}

.tour-price {
  font-family: Arial;
  font-size: 24px;
  font-weight: 700;
}

.price-text {
  font-size: 17px;
}

.schedule-btn {
  width: 100%;
}

.action-btn {
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .tour-product-item-wrapper {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .tour-product-item {
    flex-direction: row;
    gap: 24px;
  }

  .tour-product-image {
    max-width: 300px;
  }

  .tour-product-info-wrapper {
    position: relative;
  }

  .tour-product-info {
    padding: 0;
    gap: 24px;
  }

  .tour-more-wrapper {
    flex-direction: row;
    align-items: flex-end;
  }

  .tour-price-per {
    position: absolute;
    top: 0;
    right: 0;
  }

  .schedule-btn {
    max-width: 160px;
    height: 50px;
  }
}
</style>
