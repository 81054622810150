<template>
  <div class="info-wrapper">
    <div class="info-content">
      <BreadcrumbNavigation class="crumb-margin" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import BreadcrumbNavigation from '@/components/common/BreadcrumbNavigation.vue';

export default {
  name: 'InformationView',
  components: { BreadcrumbNavigation },
};
</script>

<style scoped>
.info-wrapper {
  width: 100%;
  padding-top: 24px;
}

.info-content {
  margin: 0 auto;
  width: 100%;
  max-width: 1384px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.crumb-margin {
  margin-left: 16px;
}
</style>
