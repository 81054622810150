<template>
  <div class="page-title-wrapper">
    <h1 class="page-title">{{ $t('menu.groupReservationQna') }}</h1>
    <p class="page-desc">{{ $t('pageDescription.qna') }}</p>
  </div>

  <div class="qna-user-info">
    <div class="header-title-wrapper flex-between">
      <div class="header-title-text">{{ $t('labels.personalInfo') }}</div>
      <div class="required-text"><span class="required-star">*</span>{{ $t('messages.requiredField') }}</div>
    </div>

    <CruiseInputField :label="$t('labels.name')" required>
      <div class="field-wrapper">
        <ReCruiseInput class="input-width" :placeholder="$t('labels.inputName')" v-model="qnaInfo.name" />
      </div>
    </CruiseInputField>

    <CruiseInputField :label="$t('labels.phoneNumber')" required>
      <div class="field-wrapper">
        <ReCruiseInput
          class="input-width"
          :placeholder="$t('messages.phoneNumber')"
          v-model="qnaInfo.mobile"
          type="number"
        />
      </div>
    </CruiseInputField>

    <CruiseInputField :label="$t('profile.email')" required>
      <div class="email-wrapper">
        <div class="email-field">
          <ReCruiseInput
            type="emailAddress"
            class="email-left"
            v-model="qnaInfo.email"
            :placeholder="$t('profile.inputEmail')"
          />
          <span>@</span>
          <ReCruiseInput
            type="emailDomain"
            class="email-right"
            v-model="emailDomain"
            :readonly="emailReadonly"
            :placeholder="$t('profile.inputSelf')"
          />
        </div>
        <div class="field-wrapper email-template">
          <div ref="emailDomain" class="mobile-width">
            <ReCruiseSelect
              class="select-field"
              v-model="emailTemplate"
              v-model:isOpen="isOpenEmailDomainList"
              :options="emailList"
              :placeholder="$t('profile.inputSelf')"
            />
          </div>
        </div>
      </div>
    </CruiseInputField>
  </div>

  <div class="qna-detail-info">
    <div class="header-title-wrapper flex-between">
      <div class="header-title-text">{{ $t('labels.inquiry') }}</div>
    </div>

    <CruiseInputField :label="$t('labels.selectRoute')" required>
      <div class="field-wrapper">
        <div ref="category" class="mobile-width">
          <ReCruiseSelect
            class="select-field select-width"
            :placeholder="$t('messages.selectInquiryRoute')"
            :options="routeList"
            v-model="qnaInfo.route"
            v-model:isOpen="isOpenCategoryList"
          >
            <template #option="{ item }">
              {{ item.name }}
            </template>
          </ReCruiseSelect>
        </div>
      </div>
    </CruiseInputField>

    <CruiseInputField :label="$t('labels.inquiryContent')">
      <div class="textarea-field-wrapper">
        <CruiseTextArea
          class="content-textarea"
          v-model="qnaInfo.content"
          :placeholder="$t('messages.enterContent')"
          :maxlength="1000"
        >
        </CruiseTextArea>
      </div>
    </CruiseInputField>

    <CruiseInputField :label="$t('labels.agreeToPersonalInfo')" required>
      <div class="field-wrapper">
        <ReCruiseCheckbox :label="$t('messages.checkAgreeToPersonalInfo')" v-model="agreeToPrivacyPolicy" />
      </div>
    </CruiseInputField>
  </div>

  <div class="btn-wrapper">
    <ReCruiseButton class="bottom-btn prev" theme="outline" @click="cancel">{{ $t('actions.cancel') }}</ReCruiseButton>
    <ReCruiseButton class="bottom-btn" @click="submitQna" :disabled="!canSubmit">{{ $t('labels.ask') }}</ReCruiseButton>
  </div>
</template>

<script>
import { getLocalText, convertCommaNumber } from '@/utils/convert';
import { regExp, validators } from '@/utils/validator';

import QnaService from '@/services/qna';
import RouteService from '@/services/route';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';
import ReCruiseSelect from '@/components/renewal/common/ReCruiseSelect.vue';
import CruiseTextArea from '@/components/common/CruiseTextArea.vue';
import ReCruiseCheckbox from '@/components/renewal/common/ReCruiseCheckbox.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'GroupBookingQna',
  components: {
    CruiseInputField,
    ReCruiseInput,
    ReCruiseSelect,
    CruiseTextArea,
    ReCruiseCheckbox,
    ReCruiseButton,
  },
  data() {
    return {
      qnaInfo: {
        name: '',
        email: '',
        mobile: '',
        category: { code: 'Group Reservation' },
        content: '',
        route: null,
      },

      routeList: [],

      emailDomain: '',
      emailReadonly: false,
      emailTemplate: this.$t('profile.inputSelf'),
      emailList: [this.$t('profile.inputSelf'), 'naver.com', 'gmail.com', 'daum.net', 'hotmail.com'],

      agreeToPrivacyPolicy: true,

      isOpenEmailDomainList: false,
      isOpenCategoryList: false,

      regExp,
    };
  },
  watch: {
    emailTemplate(value) {
      const isDirect = value === this.$t('profile.inputSelf');
      this.emailReadonly = !isDirect;
      this.emailDomain = isDirect ? '' : value;
    },
  },
  computed: {
    convertedEmail() {
      return `${this.qnaInfo.email}@${this.emailDomain}`;
    },
    canSubmit() {
      return (
        this.qnaInfo.name &&
        validators.isValidEmail(this.convertedEmail) &&
        this.qnaInfo.mobile &&
        this.qnaInfo.route &&
        this.agreeToPrivacyPolicy
      );
    },
  },
  methods: {
    getLocalText,
    convertCommaNumber,

    async init() {
      const { data } = await RouteService.getRouteList();
      this.routeList = data;
    },
    async submitQna() {
      try {
        const result = await this.$confirm({ content: this.$t('messages.sendInquiry') });
        if (result) {
          this.qnaInfo = {
            ...this.qnaInfo,
            email: this.convertedEmail,
          };

          await QnaService.submitQna(this.qnaInfo);

          this.$alert({
            content: this.$t('messages.inquirySuccessWithNote'),
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        this.$alert({ content: this.$t('messages.inquiryError') });
      }
    },
    async cancel() {
      const result = await this.$confirm({ content: this.$t('messages.cancelInquiry') });

      if (result) {
        window.location.reload();
      } else {
        return;
      }
    },
    handleClickOutside(event) {
      if (this.$refs.emailDomain && !this.$refs.emailDomain.contains(event.target)) {
        this.isOpenEmailDomainList = false;
      }

      if (this.$refs.category && !this.$refs.category.contains(event.target)) {
        this.isOpenCategoryList = false;
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  word-break: keep-all;
  margin-bottom: 10px;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.page-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.qna-user-info,
.qna-detail-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.qna-user-info {
  margin-bottom: 26px;
}

.qna-detail-info {
  margin-bottom: 36px;
}

:deep(.input-label) {
  font-size: 16px;
  font-weight: 600;
  /* line-height: 18px; */
}

.header-title-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 150%;
}

.required-text {
  color: var(--Grey-grey-700);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.required-star {
  font-size: 12px;
  color: var(--Orange-orange-500);
  margin-right: 2px;
}

.select-width {
  width: 100%;
}

.field-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 0;
  height: 80px;
}

.email-field {
  display: flex;
  align-items: center;
  gap: 8px;
}

.email-left {
  width: 150px;
  flex-shrink: 0;
}

.email-right {
  flex: 1 0;
}

.select-field {
  width: 100%;
  flex-shrink: 0;
}

.textarea-field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
}

:deep(.cruise-textarea) {
  height: 210px;
}

.btn-wrapper {
  display: flex;
  gap: 16px;
}

.bottom-btn {
  flex: 1 0;
}

.mobile-width {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 34px;
  }

  .page-desc {
    font-size: 20px;
  }

  .required-star {
    font-size: 14px;
    margin-right: 3px;
  }

  .input-width {
    max-width: 366px;
  }

  .field-wrapper {
    height: 80px;
    background: var(--White);
    padding-left: 30px;
  }

  .header-title-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--Grey-grey-900);
    height: 66px;
  }

  .header-title-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 30px;
  }

  .required-text {
    font-size: 16px;
  }

  .email-selector {
    max-width: 120px;
  }

  .qna-user-info,
  .qna-detail-info {
    display: flex;
    gap: 0;
    flex-direction: column;
    margin-bottom: 0;
  }

  .input-field-wrapper {
    flex-direction: row;
    align-items: center;
    height: 80px;
    height: 100% !important;
    border-bottom: 1px solid var(--Grey-grey-200);
    background: var(--Grey-grey-50);
  }

  .textarea-field-wrapper {
    display: block;
    width: 100%;
    padding: 20px 20px 20px 30px;
    background: var(--White);
  }

  :deep(.cruise-textarea) {
    height: 391px;
  }

  :deep(.label-wrapper) {
    display: flex;
    width: 212px;
    height: 100%;
    padding: 0 15px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    flex-shrink: 0;
  }

  .email-wrapper {
    flex-direction: row;
    background: var(--White);
    padding-left: 30px;
  }

  :deep(.select-field .options-wrapper) {
    top: 42px;
  }

  .email-left,
  .email-right,
  .select-field,
  .select-width {
    width: 224px;
    height: 40px;
  }

  .email-template {
    padding-left: 0;
  }

  .btn-wrapper {
    justify-content: flex-end;
  }

  .bottom-btn {
    width: 160px !important;
    flex: none;
  }

  .mobile-width {
    width: auto;
  }
}
</style>
