<template>
  <div v-if="isLoading || forceLoading" class="loading-wrapper">
    <div class="spinner">
      <div v-for="n in 8" :key="n" class="dot" :style="getDotStyle(n)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CruiseLoading',
  computed: {
    isLoading() {
      return this.$store.state.common.isLoading;
    },
    forceLoading() {
      return this.$store.state.common.forceLoading;
    },
  },
  methods: {
    getDotStyle(index) {
      const angle = (360 / 8) * index - 90;
      return {
        transform: `rotate(${angle}deg) translate(20px)`,
        animationDelay: `${-0.8 + index * 0.1}s`,
      };
    },
  },
};
</script>

<style scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: var(--zLayoutLoading);
  /* background: rgba(236, 241, 244, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
}

.spinner {
  position: relative;
  width: 60px;
  height: 60px;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fe8823;
  animation: fade 1.2s infinite ease-in-out both;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
}

@keyframes fade {
  0%,
  39%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}
</style>
