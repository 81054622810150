<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('vehicleGuide.basic.title') }}</div>
      <div class="txt_det">{{ $t('vehicleGuide.basic.intro') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('vehicleGuide.basic.available.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.basic.available.content.info1') }}</li>
                <li>{{ $t('vehicleGuide.basic.available.content.info2') }}</li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.basic.unavailable.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.basic.unavailable.content.info1') }}</li>
                <li>{{ $t('vehicleGuide.basic.unavailable.content.info2') }}</li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.basic.cautionAndForbidden.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('vehicleGuide.basic.cautionAndForbidden.content.info1.part1') }}
                  <span class="highlight">{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info1.part2') }}</span
                  >{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info1.part3') }}
                </li>
                <li>
                  {{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part1')
                  }}<span class="highlight">{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part2') }}</span
                  >{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part3')
                  }}<span class="highlight">{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part4') }}</span
                  >{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part5') }}
                  <span class="highlight">{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part6') }}</span
                  >{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info2.part7') }}
                </li>
                <li>
                  {{ $t('vehicleGuide.basic.cautionAndForbidden.content.info3.part1')
                  }}<span class="highlight">{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info3.part2') }}</span
                  >{{ $t('vehicleGuide.basic.cautionAndForbidden.content.info3.part3') }}
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.basic.availablePeriod.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('vehicleGuide.basic.availablePeriod.content.info1.part1')
                  }}<span class="highlight">{{ $t('vehicleGuide.basic.availablePeriod.content.info1.part2') }}</span
                  >{{ $t('vehicleGuide.basic.availablePeriod.content.info1.part3') }}
                </li>
                <li>
                  {{ $t('vehicleGuide.basic.availablePeriod.content.info2.part1')
                  }}<span class="highlight">{{ $t('vehicleGuide.basic.availablePeriod.content.info2.part2') }}</span
                  >{{ $t('vehicleGuide.basic.availablePeriod.content.info2.part3') }}
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Title -->
    <div class="area_title">
      <div class="txt_title space_area">{{ $t('vehicleGuide.feeGuide.title') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li class="init">
            <div class="txt_detail">
              <div class="wrap_table">
                <table class="detail_table">
                  <colgroup>
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{{ $t('vehicleGuide.feeGuide.table.header.category') }}</th>
                      <th>{{ $t('vehicleGuide.feeGuide.table.header.automobile') }}</th>
                      <th>{{ $t('vehicleGuide.feeGuide.table.header.motorCycle') }}</th>
                      <th>{{ $t('vehicleGuide.feeGuide.table.header.notice') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row1.cabin') }}</td>
                      <td>800,000</td>
                      <td>640,000</td>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row1.notice') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row2.cabin') }}</td>
                      <td>850,000</td>
                      <td>690,000</td>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row2.notice') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row3.cabin') }}</td>
                      <td>1,050,000</td>
                      <td>890,000</td>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row3.cabin') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.table.body.row4.regulation') }}</td>
                      <td colspan="4">
                        <div class="left_side center">
                          {{ $t('vehicleGuide.feeGuide.table.body.row4.regulationDetail') }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="txt_unit">{{ $t('vehicleGuide.feeGuide.unit') }}</div>
              </div>
              <ul class="check_info">
                <li>{{ $t('vehicleGuide.feeGuide.explaination.info1') }}</li>
                <li>{{ $t('vehicleGuide.feeGuide.explaination.info2') }}</li>
              </ul>
            </div>

            <div class="txt_detail">
              <div class="wrap_table">
                <table class="detail_table">
                  <colgroup>
                    <col width="40%" />
                    <col width="60%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{{ $t('vehicleGuide.feeGuide.detailTable.table.header.item') }}</th>
                      <th>{{ $t('vehicleGuide.feeGuide.detailTable.table.header.detail') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.detailTable.table.body.row1.title') }}</td>
                      <td>
                        <div class="left_side">
                          {{ $t('vehicleGuide.feeGuide.detailTable.table.body.row1.content') }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('vehicleGuide.feeGuide.detailTable.table.body.row2.title') }}</td>
                      <td>
                        <div class="left_side">
                          {{ $t('vehicleGuide.feeGuide.detailTable.table.body.row2.content') }}
                        </div>
                        <br /><br />
                        <div class="left_side highlight">
                          {{ $t('vehicleGuide.feeGuide.detailTable.table.body.row2.annotation') }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('vehicleGuide.documentGuide.title') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('vehicleGuide.documentGuide.myVehicle.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('vehicleGuide.documentGuide.myVehicle.content.part1') }}
                  <span class="highlight">{{ $t('vehicleGuide.documentGuide.myVehicle.content.part2') }}</span> /
                  <span class="highlight">{{ $t('vehicleGuide.documentGuide.myVehicle.content.part3') }}</span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.documentGuide.myMotorCycle.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('vehicleGuide.documentGuide.myMotorCycle.content.part1') }}
                  <span class="highlight">{{ $t('vehicleGuide.documentGuide.myMotorCycle.content.part2') }}</span> /
                  <span class="highlight">{{ $t('vehicleGuide.documentGuide.myMotorCycle.content.part3') }}</span>
                </li>
              </ul>
            </div>
          </li>
          <!-- <li>
            <div class="label_title">{{ $t('vehicleGuide.documentGuide.familyVehicle.title') }}</div>
            <div class="txt_detail">
              <div class="label_detail">{{ $t('vehicleGuide.documentGuide.familyVehicle.guide') }}</div>

              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.documentGuide.familyVehicle.content') }}</li>
              </ul>
            </div>
          </li> -->
          <li>
            <div class="label_title">{{ $t('vehicleGuide.documentGuide.caution.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.documentGuide.caution.content.info1', { email: 'cruise@panstar.kr' }) }}</li>
                <li>
                  <div class="highlight">
                    {{ $t('vehicleGuide.documentGuide.caution.content.info2.part1') }}
                  </div>
                  {{ $t('vehicleGuide.documentGuide.caution.content.info2.part2') }}
                </li>
                <li>{{ $t('vehicleGuide.documentGuide.caution.content.info3') }}</li>
                <li>{{ $t('vehicleGuide.documentGuide.caution.content.info4') }}</li>
                <li>{{ $t('vehicleGuide.documentGuide.caution.content.info5') }}</li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('vehicleGuide.importDayGuide.title') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title space_init">
              {{ $t('vehicleGuide.importDayGuide.item1.part1')
              }}<span class="highlight">{{ $t('vehicleGuide.importDayGuide.item1.part2') }}</span
              >{{ $t('vehicleGuide.importDayGuide.item1.part3') }}
              <span class="highlight">{{ $t('vehicleGuide.importDayGuide.item1.part4') }}</span
              >{{ $t('vehicleGuide.importDayGuide.item1.part5') }}
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.importDayGuide.item2.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('vehicleGuide.importDayGuide.item2.content.item1.part1') }}
                  <span class="highlight">
                    {{ $t('vehicleGuide.importDayGuide.item2.content.item1.part2') }}
                  </span>
                </li>
                <li>
                  <span class="highlight">{{ $t('vehicleGuide.importDayGuide.item2.content.item2.part1') }}</span
                  >{{ $t('vehicleGuide.importDayGuide.item2.content.item2.part2')
                  }}<span class="highlight">{{ $t('vehicleGuide.importDayGuide.item2.content.item2.part3') }}</span
                  >{{ $t('vehicleGuide.importDayGuide.item2.content.item2.part4') }}
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.importDayGuide.item3.title') }}</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  <span class="highlight">{{ $t('vehicleGuide.importDayGuide.item3.content.part1') }}</span>
                  <div class="list_sub">{{ $t('vehicleGuide.importDayGuide.item3.content.part2') }}</div>
                  <!-- <div class="list_sub">{{ $t('vehicleGuide.importDayGuide.item3.content.part3') }}</div> -->
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.importDayGuide.item4.title') }}​</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.importDayGuide.item4.content.item1') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item4.content.item2') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item4.content.item3') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item4.content.item4') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item4.content.item5') }}</li>
              </ul>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('vehicleGuide.importDayGuide.item5.title') }}​</div>
            <div class="txt_detail">
              <ul class="list_dot">
                <li>{{ $t('vehicleGuide.importDayGuide.item5.content.item1') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item5.content.item2') }}</li>
                <li>{{ $t('vehicleGuide.importDayGuide.item5.content.item3') }}</li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('vehicleGuide.importDayProcedure.title') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">
              <span class="number_circle">1</span>{{ $t('vehicleGuide.importDayProcedure.item1.title') }}
            </div>
            <div class="txt_detail">
              <div class="label_detail space_left">{{ $t('vehicleGuide.importDayProcedure.item1.content') }}</div>
            </div>
          </li>
          <li>
            <div class="label_title">
              <span class="number_circle">2</span>{{ $t('vehicleGuide.importDayProcedure.item2.title') }}
            </div>
            <div class="txt_detail">
              <div class="label_detail space_left">{{ $t('vehicleGuide.importDayProcedure.item2.content') }}</div>
            </div>
          </li>
          <li>
            <div class="label_title">
              <span class="number_circle">3</span>{{ $t('vehicleGuide.importDayProcedure.item3.title') }}
            </div>
            <div class="txt_detail">
              <div class="label_detail space_left">{{ $t('vehicleGuide.importDayProcedure.item3.content') }}</div>
            </div>
          </li>
          <li>
            <div class="label_title">
              <span class="number_circle">4</span>{{ $t('vehicleGuide.importDayProcedure.item4.title') }}
            </div>
            <div class="txt_detail">
              <div class="label_detail space_left">{{ $t('vehicleGuide.importDayProcedure.item4.content') }}</div>
            </div>
          </li>
          <li>
            <div class="label_title">
              <span class="number_circle">5</span>{{ $t('vehicleGuide.importDayProcedure.item5.title') }}
            </div>
            <div class="txt_detail">
              <div class="label_detail space_left">{{ $t('vehicleGuide.importDayProcedure.item5.content') }}</div>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('vehicleGuide.directionGuide.title') }}</div>
      <div class="txt_det">{{ $t('vehicleGuide.directionGuide.intro') }}</div>
    </div>
    <!-- //Title -->
    <div class="area_contents">
      <ol class="map_detail">
        <li>
          <div class="map_image">
            <img src="@/assets/images/information/product/vehicle_osaka01.png" alt="Parking area image" />
          </div>
          <div class="map_info">
            <div class="number_circle">1</div>
            <div class="label_title">{{ $t('vehicleGuide.directionGuide.procedure.step1.title') }}</div>
            <div class="txt_detail">
              {{ $t('vehicleGuide.directionGuide.procedure.step1.detail.part1')
              }}<span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step1.detail.part2') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step1.detail.part3') }}
            </div>
          </div>
        </li>
        <li>
          <div class="map_image">
            <img src="@/assets/images/information/product/vehicle_osaka02.png" alt="Parking Area image" />
          </div>
          <div class="map_info">
            <div class="number_circle">2</div>
            <div class="label_title">{{ $t('vehicleGuide.directionGuide.procedure.step2.title') }}</div>
            <div class="txt_detail">
              {{ $t('vehicleGuide.directionGuide.procedure.step2.detail.part1')
              }}<span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step2.detail.part2') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step2.detail.part3') }}
            </div>
          </div>
        </li>
        <li>
          <div class="map_image">
            <img src="@/assets/images/information/product/vehicle_osaka03.png" alt="Parking Area image" />
          </div>
          <div class="map_info">
            <div class="number_circle">3</div>
            <div class="label_title">{{ $t('vehicleGuide.directionGuide.procedure.step3.title') }}</div>
            <div class="txt_detail">
              <span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step3.detail.part1') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step3.detail.part2') }}
              <span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step3.detail.part3') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step3.detail.part4') }}
            </div>
          </div>
        </li>
        <li>
          <div class="map_image">
            <img src="@/assets/images/information/product/vehicle_osaka04.png" alt="Parking Area image" />
          </div>
          <div class="map_info">
            <div class="number_circle">4</div>
            <div class="label_title">{{ $t('vehicleGuide.directionGuide.procedure.step4.title') }}</div>
            <div class="txt_detail">
              <span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step4.detail.part1') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step4.detail.part2') }}
              <span class="highlight">{{ $t('vehicleGuide.directionGuide.procedure.step4.detail.part3') }}</span
              >{{ $t('vehicleGuide.directionGuide.procedure.step4.detail.part4') }}
            </div>
          </div>
        </li>
        <li>
          <div class="map_image">
            <img src="@/assets/images/information/product/vehicle_osaka05.png" alt="Parking Area Image" />
          </div>
          <div class="map_info">
            <div class="number_circle">5</div>
            <div class="label_title">{{ $t('vehicleGuide.directionGuide.procedure.step5.title') }}</div>
            <div class="txt_detail">{{ $t('vehicleGuide.directionGuide.procedure.step5.detail') }}</div>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OsakaVehicle',
  components: {},
  data() {
    return {
      example: '',
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail > li {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--Grey-grey-100);
}
.view_detail .area_detail > li:last-child {
  border-bottom: none;
}
.view_detail .area_detail .init {
  padding-bottom: 0;
  border-bottom: none;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 28px;
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: pre-wrap;
}
.view_detail .area_detail .txt_unit {
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 20px;
  font-weight: 500;
  color: #686d72;
  line-height: 28px;
}
.view_detail .area_detail .txt_detail {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 48px;
}
.detail_table tr .left_side {
  display: inline-flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  white-space: pre;
}
.detail_table tr .left_side.center {
  justify-content: center;
}
.label_title .highlight {
  font-weight: 600;
  display: contents;
}
.highlight {
  color: #f18d00;
}
.label_title.space_init {
  margin-bottom: 0 !important;
}
.number_circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #304da7;
  color: var(--White);
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
}
.space_left {
  margin-left: 48px;
}
@media (max-width: 767px) {
  .view_detail .area_detail > li {
    padding-bottom: 24px;
  }
  .view_detail .area_detail > li + li {
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 24px;
  }
  .view_detail .area_detail .txt_unit {
    top: -12px;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .number_circle {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .space_left {
    margin-left: 0;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 150%;
  color: #505458;
  white-space: pre-wrap;
  word-break: auto-phrase;
}
.view_detail .area_detail .txt_detail .label_detail .txt_sub {
  color: var(--Grey-grey-700);
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.4px;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  color: #686d72;
  white-space: pre-line;
  word-break: auto-phrase;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
@media (max-width: 767px) {
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    margin-bottom: 24px;
    font-size: 22px;
  }
  .view_detail .area_detail .txt_detail {
    font-size: 16px;
    line-height: 20px;
  }
  .view_detail .area_detail .txt_detail .label_detail {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 26px;
  }
  .view_detail .area_detail .txt_detail .label_detail .txt_sub {
    font-size: 16px;
  }
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_dot li .list_sub {
    font-size: 16px;
  }
}
/********************************************************
   component table
********************************************************/
.wrap_table {
  overflow: auto;
}

/********************************************************
  Detail Table
********************************************************/
.detail_table {
  width: 100%;
}
.wrap_table + .check_info {
  margin-top: 16px;
  margin-bottom: 12px;
}
.check_info li {
  padding-left: 33px;
  line-height: 32px;
  position: relative;
  color: #686d72;
  font-size: 20px;
  font-weight: 500;
}
.check_info li:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
  margin-right: 12px;
  width: 21px;
  height: 29px;
  background: url('@/assets/images/information/icon/icon_check.svg') no-repeat;
  content: '';
}

@media (max-width: 767px) {
  .check_info li {
    padding-left: 29px;
    font-size: 16px;
    line-height: 26px;
  }
  .check_info li + li {
    margin-top: 16px;
  }
  .check_info li:before {
    top: 5px;
    width: 17px;
    height: 24px;
    background-size: 100%;
  }
}

.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th .txt_sub {
  color: var(--Grey-grey-700);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}
.detail_table thead tr:first-child th:last-of-type {
  border-right: none;
}
.detail_table thead tr:last-child th:last-of-type {
  border-right: none;
}
.detail_table thead tr:last-child th.border {
  border-right: 1px solid #dddfe2;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  white-space: nowrap;
}
.detail_table tbody tr th.bg_th.txt_center.large {
  height: 140px;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tbody tr th.bg_th .txt_sub {
  font-size: 18px;
  font-weight: normal;
}
.detail_table tr td {
  white-space: nowrap;
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table tr td:first-child {
  border-left: none;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table tr td,
  .detail_table thead tr th {
    font-size: 16px;
    white-space: nowrap;
  }
  .detail_table tbody tr td span,
  .detail_table tbody tr td div {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr th.bg_th .txt_sub {
    font-size: 14px;
  }
  .detail_table tr th.txt_center {
    text-align: center;
  }
  .detail_table tbody tr th.bg_th.txt_center.large {
    height: auto;
  }
}
.map_detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 80px 0;
}
.map_detail li:nth-child(4) {
  margin-left: auto;
  margin-right: 36px;
}
.map_detail li:nth-child(5) {
  margin-right: auto;
}
.map_detail .map_image {
  width: 427px;
}
.map_detail .map_info {
  width: 427px;
  padding: 32px 0 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map_detail .map_info .label_title {
  padding: 20px 0 16px;
  color: #304da7;
  font-size: 28px;
  font-weight: 600;
}
.map_detail .map_info .txt_detail {
  color: #505458;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  white-space: pre-line;
}
@media (max-width: 767px) {
  .map_detail {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0;
  }
  .map_detail .map_image,
  .map_detail .map_info {
    width: 100%;
  }
  .map_detail .map_info .label_title {
    padding: 18px 0 14px;
    color: #304da7;
    font-size: 22px;
    font-weight: 600;
  }
  .map_detail .map_info .txt_detail {
    font-size: 18px;
    line-height: 26px;
  }
  .map_detail li:nth-child(4) {
    margin: 0;
  }
  .map_detail li:nth-child(5) {
    margin: 0;
  }
}
.area_title .txt_title.space_area {
  margin-bottom: 28px;
}
.area_contents {
  position: relative;
}
</style>
