<template>
  <div class="passenger-count-input flex-between" role="button" @click="togglePassengerCountLayer">
    <div class="passenger">
      {{ $t('labels.passengers') }}
      <p class="num" :class="disabledClass">{{ totalPassenger }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RePassengerCountInput',
  emits: ['onClick'],
  components: {},
  props: {
    totalPassenger: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    isShow: Boolean,
  },
  computed: {
    disabledClass() {
      return {
        disabled: this.disabled,
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    togglePassengerCountLayer() {
      this.$emit('onClick');
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.passenger-count-input {
  width: 100%;
  box-sizing: border-box;
  background: var(--White);
  height: 50px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100);
}

.passenger {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-left: 16px;
  justify-content: space-between;
  padding-right: 16px;
  color: var(--Grey-grey-900);
}

.num {
  float: right;
}

@media screen and (min-width: 768px) {
  .passenger-count-input {
    min-width: 80px;
  }
}
</style>
