<template>
  <div ref="select" class="select-wrapper" @click="toggleDropDown">
    <div class="select-item">
      <div class="text-ellipsis">
        <template v-if="isSelected">
          <template v-if="$slots.option">
            <slot name="option" :item="selectedItem"></slot>
          </template>
          <template v-else> {{ selectedItem }} </template>
        </template>
        <template v-else>{{ placeholder }}</template>
      </div>

      <span class="triangle-icon" :class="activeClass(isShow)">
        <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="Polygon 1" d="M3.5 4.5L0.468911 0.75L6.53109 0.75L3.5 4.5Z" fill="currentColor" />
        </svg>
      </span>
    </div>

    <!-- drop down -->
    <div class="options-wrapper" v-if="isShow" @click.stop>
      <div v-for="(item, index) in options" :key="index" class="option-item text-ellipsis" @click="selectItem(item)">
        <template v-if="$slots.option">
          <slot name="option" :item="item" :index="index"></slot>
        </template>
        <template v-else>{{ item }}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CruiseDropDown',
  emits: ['update:modelValue', 'onChange'],
  props: {
    modelValue: {
      type: [Number, Object, String, Boolean],
      default: null,
    },
    options: Array,
    valueProperty: String,
    disabled: Boolean,
    placeholder: String,
  },
  data() {
    return {
      isShow: false,
      selectedItem: null,
    };
  },
  computed: {
    isSelected() {
      return this.modelValue !== null && this.modelValue !== undefined && this.modelValue !== '';
    },
  },
  watch: {
    options(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.selectedItem = null;
      }
    },
  },
  methods: {
    activeClass(value) {
      return { active: value };
    },
    toggleDropDown() {
      if (!this.disabled) {
        this.isShow = !this.isShow;
      }
    },
    selectItem(item) {
      this.selectedItem = item;

      const isModelObject = typeof this.modelValue === 'object';
      const value = isModelObject || !this.valueProperty ? item : item[this.valueProperty];

      this.$emit('update:modelValue', value);
      this.$emit('onChange', value, item);

      this.toggleDropDown();
    },
    handleClickOutside(event) {
      if (this.$refs.select && !this.$refs.select.contains(event.target)) {
        this.isShow = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.select-wrapper {
  height: 24px;
  position: relative;
}

.select-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3px;
  gap: 8px;
  cursor: pointer;
}

.options-wrapper {
  position: absolute;
  width: max-content;
  top: calc(100% + 3px);
  left: -10%;
  background: var(--White);
  z-index: var(--zContentsPopover);
  border-radius: 5px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
}

.option-item {
  height: 32px;
  padding: 8px 16px;
  cursor: pointer;
  white-space: nowrap;

  color: var(--Grey-grey-900, #333);
  font-size: 14px;
  font-weight: 400;
}

.option-item:hover {
  color: var(--Blue-blue-500);
  background: var(--Blue-blue-50);
}

.triangle-icon {
  transition: transform 0.5s ease, color 0.5s ease;
}

.triangle-icon.active {
  transform: rotate(180deg);
}
</style>
