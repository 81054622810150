<template>
  <button
    class="action-btn"
    :class="[btnClass, activeClass]"
    v-bind="attrs"
    :disabled="disabled"
    @focus="onFocus"
    @blur="onBlur"
    @keydown.enter.prevent
  >
    <slot></slot>
  </button>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';

export default {
  name: 'CruiseButton',
  mixins: [sharedComputedProps],
  props: {
    theme: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'outline', 'danger', 'normal', 'disabled'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isActive: Boolean,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    btnClass() {
      return [`btn-${this.theme}`];
    },
    activeClass() {
      return this.isActive ? 'active' : '';
    },
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<style scoped>
.action-btn {
  display: flex;
  width: 80px;
  height: 50px;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.btn-primary {
  color: var(--White);
  background: var(--Blue-blue-500);
}

.btn-primary:disabled {
  background: var(--Blue-blue-200);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active {
  background: var(--Blue-blue-600);
}

.btn-primary:disabled:hover {
  background: var(--Blue-blue-200);
  cursor: not-allowed;
}

.btn-outline {
  gap: 4px;
  color: var(--Blue-blue-500);
  border: 1px solid var(--Blue-blue-500);
  background: var(--White);
}

.btn-outline:disabled {
  color: var(--Blue-blue-200);
  border: 1px solid var(--Blue-blue-200);
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline.active {
  color: var(--Blue-blue-700);
  border: 1px solid var(--Blue-blue-700);
}

.btn-outline:disabled:hover {
  color: var(--Blue-blue-200);
  border: 1px solid var(--Blue-blue-200);
  cursor: not-allowed;
}

.btn-danger {
  color: var(--White);
  background: var(--Orange-orange-500);
}

.btn-danger:disabled {
  background: var(--Orange-orange-200);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active {
  background: var(--Orange-orange-600);
}

.btn-danger:disabled:hover {
  background: var(--Orange-orange-200);
  cursor: not-allowed;
}

.btn-normal {
  border: 1px solid var(--Grey-grey-100);
  color: var(--Grey-grey-900);
  font-size: 14px;
  font-weight: 600;
  background: var(--White);
}

.btn-normal:hover,
.btn-normal:focus,
.btn-normal.active {
  border: 1px solid var(--Blue-blue-500);
}

.btn-disabled {
  color: var(--White);
  font-size: 14px;
  font-weight: 600;
  background: var(--Grey-grey-200);
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .action-btn {
    width: 200px;
    height: 50px;
    padding: 10px;
    font-size: 18px;
  }
}
</style>
