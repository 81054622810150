<template>
  <div class="date-picker" ref="date" role="button" @click="toggleCalendar">
    <div class="date-input">
      <ReDateItem :range="range" :label="label.from" :date="dateRange.from" :disabled="disabled" />
      <template v-if="range">
        <span class="date-separator" :class="disabledClass">~</span>
        <ReDateItem :label="label.to" :date="dateRange.to" :disabled="disabled" />
      </template>
    </div>

    <div class="calendar-icon flex-center">
      <CalendarRangeIcon :size="19" />
    </div>
  </div>
</template>

<script>
import ReDateItem from '@/components/renewal/common/ReDateItem.vue';
import CalendarRangeIcon from 'vue-material-design-icons/CalendarRange';

export default {
  name: 'ReCruiseDatePicker',
  components: { ReDateItem, CalendarRangeIcon },
  emits: ['update:dateRange', 'onClick'],
  props: {
    label: Object,
    range: Boolean,
    dateRange: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabledClass() {
      return {
        'gray-color': this.disabled,
      };
    },
  },
  methods: {
    toggleCalendar() {
      this.$emit('onClick');
    },
    updateDateRange(dateRange) {
      this.$emit('update:dateRange', dateRange);
    },
  },
};
</script>

<style scoped>
.date-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  background: var(--White);
  padding: 15px 10px 15px 16px;
  color: var(--Grey-grey-900);
  border: 1px solid var(--Grey-grey-100);
  cursor: pointer;
}

.date-input {
  display: flex;
  height: 100%;
}

.date-separator {
  display: block;
  height: 20px;
  padding: 0 3px;
}

.calendar-icon {
  width: 24px;
  height: 24px;
}

.date-calendar {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  z-index: var(--zContentsPopover);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
}

.gray-color {
  color: var(--Grey-grey-200);
}

@media screen and (min-width: 768px) {
  .date-calendar {
    width: auto;
  }
}
</style>
