<template>
  <div class="cruise-calendar">
    <div class="calendar-wrapper">
      <div class="calendar-wrapper mobile">
        <div v-for="(no, i) in 12" class="current-calendar">
          <ReCalendarHeader :date="nextDate(i)" />
          <ReCalendarBody
            :date="nextDate(i)"
            :dateRange="dateRange"
            @update:dateRange="updateDateRange"
            :range="range"
            :allowedDate="allowedDate"
          />
        </div>
      </div>

      <div class="calendar-wrapper pc">
        <button class="month-navigator" @click="updateMonth(-1)">
          <img class="arrow-icon" src="@/assets/images/icon/arrow_left_navigation_icon.svg" alt="icon" />
        </button>

        <div class="calendar-inner">
          <div class="current-calendar">
            <ReCalendarHeader :date="now" />
            <ReCalendarBody
              :date="now"
              :dateRange="dateRange"
              @update:dateRange="updateDateRange"
              :range="range"
              :allowedDate="allowedDate"
            />
          </div>
          <div v-if="range || doubleCalendar" class="next-calendar">
            <ReCalendarHeader :date="nextDate(1)" />
            <ReCalendarBody
              :date="nextDate(1)"
              :dateRange="dateRange"
              @update:dateRange="updateDateRange"
              :range="range"
              :allowedDate="allowedDate"
            />
          </div>
        </div>

        <button class="month-navigator" @click="updateMonth(1)">
          <img class="arrow-icon right" src="@/assets/images/icon/arrow_left_navigation_icon.svg" alt="icon" />
        </button>
      </div>
    </div>

    <div class="calendar-footer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import ReCalendarHeader from '@/components/renewal/calendar/ReCalendarHeader.vue';
import ReCalendarBody from '@/components/renewal/calendar/ReCalendarBody.vue';

export default {
  name: 'CruiseCalendar',
  components: { ReCalendarHeader, ReCalendarBody },
  emits: ['update:dateRange'],
  props: {
    range: Boolean,
    allowedDate: Array,
    dateRange: {
      type: Object,
      required: true,
    },
    doubleCalendar: Boolean,
  },
  data() {
    return {
      now: dayjs(),
    };
  },
  computed: {
    nextDate() {
      return idx => {
        return this.now.add(idx, 'M');
      };
    },
  },
  methods: {
    updateDateRange(dateRange) {
      this.$emit('update:dateRange', dateRange);
    },
    updateMonth(step) {
      this.now = this.now.add(step, 'M');
    },
  },
};
</script>

<style scoped>
.cruise-calendar {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
}

.calendar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.calendar-wrapper.mobile {
  display: block;
}

.calendar-wrapper.pc {
  display: none;
}

.calendar-inner {
  width: 100%;
}

.current-calendar,
.next-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.calendar-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.month-navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Black);
  padding: 0 16px;
}

.arrow-icon {
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--White);
}

.arrow-icon:focus,
.arrow-icon:hover {
  border-radius: 5px;
  border: 1px solid var(--Blue-blue-700);
}

.arrow-icon.right {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .cruise-calendar {
    padding: 0;
  }

  .calendar-wrapper.mobile {
    display: none;
  }

  .calendar-wrapper.pc {
    display: flex;
  }

  .calendar-wrapper.pc {
    flex-direction: row;
  }

  .calendar-inner {
    display: flex;
    gap: 72px;
  }

  .current-calendar,
  .next-calendar {
    width: 100%;
    box-sizing: content-box;
  }
}
</style>
