<template>
  <div class="agreement-wrapper">
    <div class="agreement-header">
      <ReCruiseCheckbox
        class="label-title"
        v-model="isAgreeAll"
        :label="$t('agreement.agreeAll')"
        :iconSize="24"
        @onChange="checkAllTerms"
      />
      <div class="header-desc">{{ $t('agreement.osakaAgreements') }}</div>
    </div>

    <div v-for="term in termList" :key="term.id">
      <TermAgreementItem
        v-model="term.isAgreed"
        :label="term.title"
        :content="term.content"
        :required="term.isRequired"
      />
    </div>

    <div class="btn-wrapper">
      <ReCruiseButton class="prev" :class="mobileClass" theme="outline" @click="goBack">
        {{ $t('actions.prev') }}
      </ReCruiseButton>
      <ReCruiseButton class="next" @click="goToPage" :disabled="!isAgreeAll">{{ $t('actions.next') }}</ReCruiseButton>
    </div>
  </div>
</template>

<script>
import TermService from '@/services/term';

import ReCruiseCheckbox from '@/components/renewal/common/ReCruiseCheckbox.vue';
import TermAgreementItem from '@/components/term/TermAgreementItem.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'OsakaAgreement',
  components: { ReCruiseCheckbox, TermAgreementItem, ReCruiseButton },
  data() {
    return {
      isAgreeAll: false,
      iconSize: 24,

      termList: [],
    };
  },
  watch: {
    termList: {
      deep: true,

      handler() {
        this.checkIndividualAgreements();
      },
    },
  },
  computed: {
    mobileClass() {
      return window.innerWidth < 768 ? 'mobile' : '';
    },
  },
  methods: {
    checkAllTerms() {
      this.termList.forEach(term => {
        term.isAgreed = this.isAgreeAll;
      });
    },
    async getTermList() {
      try {
        const { data } = await TermService.getTermList({ category: 'osaka' });

        this.termList = data.map(term => ({
          ...term,
          isAgreed: false,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    checkIndividualAgreements() {
      const allAgreed = this.termList.every(term => term.isAgreed);
      if (this.isAgreeAll !== allAgreed) {
        this.isAgreeAll = allAgreed;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goToPage() {
      if (!this.isAgreeAll) {
        return;
      }

      const redirect = this.$route.query.redirect;
      if (redirect) {
        this.$router.push(redirect);
      }
    },
  },
  created() {
    this.getTermList();
  },
};
</script>

<style scoped>
.agreement-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.agreement-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.5px;
}

.header-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-break: break-all;
}

.btn-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}

.prev {
  display: none;
}

.prev.mobile {
  display: block;
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  border: none;
}

.prev.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

.prev,
.next {
  font-size: 18px;
  font-weight: 600;
  width: 50vw;
  height: 54px;
  border-radius: 0;
}

:deep(.checkmark) {
  width: 30px;
  height: 30px;
}

:deep(.check-icon) {
  font-size: 25px;
}

@media screen and (min-width: 768px) {
  .agreement-wrapper {
    gap: 60px;
  }

  .agreement-header {
    gap: 16px;
  }

  .label-title {
    font-size: 28px;
  }

  .header-desc {
    font-size: 20px;
  }

  .btn-wrapper {
    position: static;
    justify-content: center;
    gap: 16px;
  }

  .prev {
    display: block;
  }

  .prev.mobile {
    display: none;
  }

  .prev,
  .next {
    width: 160px;
    height: 50px;
    border-radius: 5px;
  }
}
</style>
