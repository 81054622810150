export function isFullWidth(text) {
  return /[^\u0020-\u007E]/.test(text);
}

function convertFullWidthCharToHalfWidth(char) {
  return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
}

function convertFullWidthSpaceToHalfWidth(char) {
  return char === '\u3000' ? ' ' : char;
}

function convertHalfWidthCharToFullWidth(char) {
  return String.fromCharCode(char.charCodeAt(0) + 0xfee0);
}

function convertHalfWidthSpaceToFullWidth(char) {
  return char === ' ' ? '\u3000' : char;
}

export function toHalfWidth(text) {
  return text
    .split('')
    .map(char => {
      if (/[\uFF01-\uFF5E]/.test(char) || /[\uFF10-\uFF19]/.test(char)) {
        return convertFullWidthCharToHalfWidth(char);
      }
      if (char === '\u3000') {
        return convertFullWidthSpaceToHalfWidth(char);
      }
      return char;
    })
    .join('');
}

export function toFullWidth(text) {
  return text
    .split('')
    .map(char => {
      if (/[!-~]/.test(char) || /[0-9]/.test(char)) {
        return convertHalfWidthCharToFullWidth(char);
      }
      if (char === ' ') {
        return convertHalfWidthSpaceToFullWidth(char);
      }
      return char;
    })
    .join('');
}
