<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('facilityGuide.cabinLocation') }}</div>
      <div class="txt_det">{{ $t('facilityGuide.dreamCabinInfo.intro') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <!-- Swiper -->
      <swiper
        class="tabs_slider"
        :modules="modules"
        :slidesPerView="'auto'"
        :space-between="12"
        navigation
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(tab, index) in tabs" :key="index">
          <a href="javascript:" :class="{ active: currentTab === index }" @click="currentTab = index">{{ $t(tab) }}</a>
        </swiper-slide>
      </swiper>
      <!-- //Swiper -->
      <div v-show="currentTab === 0">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="data_view">OCEAN VIEW</div>
              <div class="name_room">ROYAL SUITE</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.loyal.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.loyal.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.loyal.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.loyal.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream1" :key="index" :class="{ active: dreamRoom1 === index }">
                <a href="javascript:" @click="dreamRoom1 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content">
              <img src="@/assets/images/information/facility/dream_royal_room01.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_royal_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 1">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="data_view">OCEAN VIEW</div>
              <div class="name_room">DELUXE SUITE</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.deluxe.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.deluxe.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.deluxe.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.deluxe.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream2" :key="index" :class="{ active: dreamRoom2 === index }">
                <a href="javascript:" @click="dreamRoom2 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom2 === 0">
              <img src="@/assets/images/information/facility/dream_deluxe_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom2 === 1">
              <img src="@/assets/images/information/facility/dream_deluxe_room02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_deluxe_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
      <!-- <div v-show="currentTab === 2">
        <div class="view_room">
          <div class="info_room">
            <div class="info_room_content">
              <div class="data_view">OCEAN VIEW</div>
              <div class="name_room">JUNIOR SUITE</div>
              <div class="room_space">가족과 연인 등의 소그룹을 위한 고품격 공간</div>
              <ul class="list_info">
                <li>샤워실, 화장실, 생수 및 음료, 커피, 차 제공</li>
                <li>2인 기준 객실</li>
                <li>바다 전망</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <ul class="tab_menu">
              <li v-for="(tab, index) in dream3" :key="index" :class="{ active: dreamRoom3 === index }">
                <a href="javascript:" @click="dreamRoom3 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
          </div>
          <div class="tab_content">
            <div class="tab_content">
              <img :src="dream3[dreamRoom3].imageSrc" />
            </div>
          </div>
        </div>
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_junior_location.png" />
          </div>
        </div>
      </div> -->
      <div v-show="currentTab === 2">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="data_view">OCEAN VIEW</div>
              <div class="name_room">FAMILY ROOM</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.family.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.family.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.family.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.family.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream4" :key="index" :class="{ active: dreamRoom4 === index }">
                <a href="javascript:" @click="dreamRoom4 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom4 === 0">
              <img src="@/assets/images/information/facility/dream_family_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom4 === 1">
              <img src="@/assets/images/information/facility/dream_family_room02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->

        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_family_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
      <div v-show="currentTab === 3">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">STANDARD A</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.standardA.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardA.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardA.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardA.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream5" :key="index" :class="{ active: dreamRoom5 === index }">
                <a href="javascript:" @click="dreamRoom5 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom5 === 0">
              <img src="@/assets/images/information/facility/dream_standard_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom5 === 1">
              <img src="@/assets/images/information/facility/dream_standard_room02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_standard_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 4">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">STANDARD A COMBI</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.standardACombi.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardACombi.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardACombi.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardACombi.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream6" :key="index" :class="{ active: dreamRoom6 === index }">
                <a href="javascript:" @click="dreamRoom6 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom6 === 0">
              <img src="@/assets/images/information/facility/dream_combi_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom6 === 1">
              <img src="@/assets/images/information/facility/dream_combi_room02.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom6 === 2">
              <img src="@/assets/images/information/facility/dream_combi_room03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_combi_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
      <div v-show="currentTab === 5">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">STANDARD TWIN BED</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.stantardTwin.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.stantardTwin.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.stantardTwin.capacity') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.stantardTwin.view') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream7" :key="index" :class="{ active: dreamRoom7 === index }">
                <a href="javascript:" @click="dreamRoom7 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom7 === 0">
              <img src="@/assets/images/information/facility/dream_twin_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom7 === 1">
              <img src="@/assets/images/information/facility/dream_twin_room02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_twin_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
      <div v-show="currentTab === 6">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">STANDARD BUNKER BED</div>
              <div class="room_space">{{ $t('facilityGuide.dreamCabinInfo.standardBunk.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardBunk.amenities') }}</li>
                <li>{{ $t('facilityGuide.dreamCabinInfo.standardBunk.capacity') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in dream8" :key="index" :class="{ active: dreamRoom8 === index }">
                <a href="javascript:" @click="dreamRoom8 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->
          <div class="tab_content">
            <div class="tab_content" v-show="dreamRoom8 === 0">
              <img src="@/assets/images/information/facility/dream_bunker_room01.png" />
            </div>
            <div class="tab_content" v-show="dreamRoom8 === 1">
              <img src="@/assets/images/information/facility/dream_bunker_room02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_bunker_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/navigation';
import 'swiper/css';

export default {
  name: 'TabContainer',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        'facilityGuide.dreamCabinTabs.loyal',
        'facilityGuide.dreamCabinTabs.deluxe',
        // 'facilityGuide.dreamCabinTabs.junior',
        'facilityGuide.dreamCabinTabs.family',
        'facilityGuide.dreamCabinTabs.standardA',
        'facilityGuide.dreamCabinTabs.standardACombi',
        'facilityGuide.dreamCabinTabs.standardTwin',
        'facilityGuide.dreamCabinTabs.standardBunk',
      ],
      dreamRoom1: 0,
      dream1: [
        { imageSrc: require('@/assets/images/information/facility/dream_royal_room01.png') },
        // Add more tab data as needed
      ],
      dreamRoom2: 0,
      dream2: [
        { imageSrc: require('@/assets/images/information/facility/dream_deluxe_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_deluxe_room02.png') },
        // Add more tab data as needed
      ],
      // dreamRoom3: 0,
      // dream3: [
      //   { imageSrc: require('@/assets/images/information/facility/dream_junior_room01.png') },
      //   { imageSrc: require('@/assets/images/information/facility/dream_junior_room02.png') },
      //   { imageSrc: require('@/assets/images/information/facility/dream_junior_room03.png') },
      // ],
      dreamRoom4: 0,
      dream4: [
        { imageSrc: require('@/assets/images/information/facility/dream_family_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_family_room02.png') },
        // Add more tab data as needed
      ],
      dreamRoom5: 0,
      dream5: [
        { imageSrc: require('@/assets/images/information/facility/dream_standard_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_standard_room02.png') },
        // Add more tab data as needed
      ],
      dreamRoom6: 0,
      dream6: [
        { imageSrc: require('@/assets/images/information/facility/dream_combi_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_combi_room02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_combi_room03.png') },
        // Add more tab data as needed
      ],
      dreamRoom7: 0,
      dream7: [
        { imageSrc: require('@/assets/images/information/facility/dream_twin_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_twin_room02.png') },
        // Add more tab data as needed
      ],
      dreamRoom8: 0,
      dream8: [
        { imageSrc: require('@/assets/images/information/facility/dream_bunker_room01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_bunker_room02.png') },
        // Add more tab data as needed
      ],
    };
  },
  watch: {
    currentTab() {
      for (let i = 1; i <= this.tabs.length; i++) {
        const currentIndex = `dreamRoom${i}`;
        this[currentIndex] = 0;
      }
    },
  },
  methods: {
    showTabContent(imageSrc) {
      this.selectedTabImageSrc = imageSrc;
    },
  },
  setup() {
    const onSwiper = () => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.tabs_slider {
  padding: 6px 2px;
  margin: 0 -2px;
}
.tabs_slider .swiper-slide {
  width: auto !important;
  margin-right: 12px;
}
.tabs_slider .swiper-slide a {
  display: inline-block;
  padding: 0 28px;
  border-radius: 100px;
  border: 1px solid #446ceb;
  font-weight: 600;
  color: #446ceb;
  font-size: 20px;
  line-height: 54px;
  background-color: #fff;
}
.tabs_slider .swiper-slide a.active {
  background-color: #446ceb;
  color: #fff;
}
.tabs_slider :deep(.swiper-button-prev),
.tabs_slider :deep(.swiper-button-next) {
  width: 62px;
  height: 62px;
  top: 4px;
  margin: 0;
  background-color: #fff;
}
.tabs_slider :deep(.swiper-button-prev) {
  left: 0;
}
.tabs_slider :deep(.swiper-button-next) {
  right: 0;
}

.tabs_slider :deep(.swiper-button-prev:before),
.tabs_slider :deep(.swiper-button-next:before) {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  content: '';
  flex: none;
}
.tabs_slider :deep(.swiper-button-prev:before) {
  left: 2px;
}
.tabs_slider :deep(.swiper-button-next:before) {
  right: 2px;
}
.tabs_slider :deep(.swiper-button-prev.swiper-button-disabled),
.tabs_slider :deep(.swiper-button-next.swiper-button-disabled) {
  display: none;
}
.tabs_slider :deep(.swiper-wrapper) {
  height: 72px;
}
.tabs_slider :deep(.swiper-button-prev:after),
.tabs_slider :deep(.swiper-button-next:after) {
  color: #b6bbbf;
  font-weight: bold;
  font-size: 20px;
  z-index: 1;
}
.tabs_slider :deep(.swiper-wrapper) {
  height: auto;
  padding: 2px 2px;
  margin: 0 -2px;
}

.view_room {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 0 0;
}
.view_room .info_room {
  width: 470px;
  margin-right: 27px;
  flex: none;
}
.view_room .info_room .info_room_content {
  height: 273px;
}
.view_room .info_room .data_view {
  color: #9299a0;
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 6px;
}
.view_room .info_room .name_room {
  color: #304da7;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: -1px;
  margin-bottom: 24px;
}
.view_room .info_room .room_space {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #505458;
}
.view_room .info_room .list_info {
  margin: 27px 0 0 0;
}
.view_room .info_room .list_info li {
  color: #686d72;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: flex-start;
}
.view_room .info_room .list_info li:before {
  position: relative;
  top: 2px;
  margin: 13px;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #b6bbbf;
  content: '';
}

.view_room .tab_menu {
  margin: 27px 0 0 0;
  display: inline-flex;
  border-radius: 5px;
  max-width: 100%;
  overflow: auto;
  scrollbar-width: none;
}
.view_room .tab_menu li {
  flex: auto;
  height: 90px;
  opacity: 0.6;
  width: 100%;
  max-width: 140px;
  min-width: 140px;
}
.view_room .tab_menu li.active {
  opacity: 1;
}
.view_room .tab_menu li a img {
  width: 100%;
  height: 100%;
}
.view_room .tab_menu li a {
  display: block;
  width: 100%;
  height: 100%;
}
.view_room .tab_content {
  flex: auto;
  width: 100%;
  height: 390px;
  overflow: hidden;
  border-radius: 16px;
}
.view_room .tab_content img {
  object-fit: cover;
  object-position: 50% 35%;
}
.view_location {
  margin: 60px 0 0 0;
}
.view_location .local_image {
  overflow: auto;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
.view_location .label_location {
  margin-bottom: 24px;
  color: #505458;
  font-weight: 600;
  line-height: 24px;
  font-size: 22px;
}

@media (max-width: 767px) {
  .view_room .tab_content {
    height: 234px;
    border-radius: 5px;
  }
  .view_room {
    flex-direction: column;
    margin-top: 24px;
  }
  .view_room .info_room {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .view_room .info_room .info_room_content {
    height: auto;
  }
  .view_room .info_room .name_room {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .view_room .info_room .room_space {
    font-size: 18px;
    line-height: 24px;
  }
  .view_room .info_room .list_info {
    margin: 16px 0 0 0;
  }
  .view_room .info_room .list_info li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_room .info_room .list_info li:before {
    margin-top: 6px;
  }
  .view_room .info_room .list_info li + li {
    margin-top: 8px;
  }
  .view_room .tab_menu {
    margin-top: 24px;
  }
  .view_room .tab_menu li {
    height: 63px;
    max-width: 109px;
    min-width: 109px;
  }
  .view_location {
    margin: 20px 0 0 0;
  }
  .view_location .local_image img {
    width: auto;
    height: 190px;
  }
  .view_location .label_location {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .tabs_slider :deep(.swiper-button-next),
  .tabs_slider :deep(.swiper-button-prev) {
    display: none !important;
  }

  .tabs_slider .swiper-slide {
    margin-right: 8px;
  }
  .tabs_slider .swiper-slide a {
    font-size: 14px;
    padding: 0 12px;
    line-height: 41px;
  }
  .view_room .info_room .list_info .txt_info {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
