<template>
  <div class="sb_payment_success"></div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getItem } from '@/utils/localStorage';
import SbPaymentService from '@/services/sbpayment';

export default {
  name: 'SbPaymentSuccess',
  props: {},
  data() {
    return {};
  },
  computed: {
    searchBookingInfo() {
      return getItem('searchBookingInfo');
    },
    bookingType() {
      if (this.searchBookingInfo.bookingType === 'cruise') {
        return this.searchBookingInfo.bookingType;
      } else if (this.searchBookingInfo.bookingType === 'fastShip') {
        return 'fast-ship';
      }
    },
  },
  methods: {
    ...mapMutations('payment', ['setCompleteState']),

    async init() {
      await this.getSoftbankPayment();
      
      this.$router.push(`/booking/${this.bookingType}/complete`);
    },
    async getSoftbankPayment() {
      try {
        const params = {
          orderId: this.searchBookingInfo.orderId,
        };

        const { data } = await SbPaymentService.getSoftbankPayment(params);
        this.setCompleteState(data);
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
