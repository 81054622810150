import i18n from '@/languages/i18n';

const state = {
  loadingCount: 0,
  isLoading: false,
  forceLoading: false,
  originalGlobalNav: [
    {
      name: i18n.global.t('menu.tourProduct'),
      path: '/tour',
    },
    {
      name: i18n.global.t('menu.operationProduct'),
      path: '/product',
      blocked: true,
      children: [
        {
          name: i18n.global.t('osakaCruise'),
          path: '/product/osaka',
          blocked: true,
          children: [
            {
              name: i18n.global.t('menu.operationInfo'),
              path: '/product/osaka/info',
            },
            {
              name: i18n.global.t('menu.ratesAndDiscounts'),
              path: '/product/osaka/price',
            },
            {
              name: i18n.global.t('menu.checkInGuide'),
              path: '/product/osaka/procedure',
            },
            {
              name: i18n.global.t('menu.baggageGuide'),
              path: '/product/osaka/baggage',
            },
            {
              name: i18n.global.t('menu.vehicleGuide'),
              path: '/product/osaka/vehicle',
            },
          ],
        },
        {
          name: i18n.global.t('tsushimaExpressShip'),
          path: '/product/daemado',
          blocked: true,
          children: [
            {
              name: i18n.global.t('menu.operationInfo'),
              path: '/product/daemado/info',
            },
            {
              name: i18n.global.t('menu.ratesAndDiscounts'),
              path: '/product/daemado/price',
            },
            {
              name: i18n.global.t('menu.checkInGuide'),
              path: '/product/daemado/procedure',
            },
            {
              name: i18n.global.t('menu.baggageGuide'),
              path: '/product/daemado/baggage',
            },
          ],
        },
        {
          name: i18n.global.t('oneNightCruise'),
          path: '/product/one-night',
          blocked: true,
          children: [
            {
              name: i18n.global.t('menu.operationInfo'),
              path: '/product/one-night/info',
            },
            {
              name: i18n.global.t('menu.ratesAndDiscounts'),
              path: '/product/one-night/price',
            },
            {
              name: i18n.global.t('menu.checkInGuide'),
              path: '/product/one-night/procedure',
            },
          ],
        },
      ],
    },
    {
      name: i18n.global.t('menu.facilityGuide'),
      path: '/facility',
      blocked: true,
      children: [
        {
          name: i18n.global.t('panstarDreamShip'),
          path: '/facility/dream',
          blocked: true,
          children: [
            {
              name: i18n.global.t('menu.cabinGuide'),
              path: '/facility/dream/room',
            },
            {
              name: i18n.global.t('menu.onboardFacilityGuide'),
              path: '/facility/dream/info',
            },
          ],
        },
        {
          name: i18n.global.t('tsushimaExpress'),
          path: '/facility/daemado',
          blocked: true,
          children: [
            {
              name: i18n.global.t('menu.seatGuide'),
              path: '/facility/daemado/seat',
            },
            {
              name: i18n.global.t('menu.onboardFacilityGuide'),
              path: '/facility/daemado/info',
            },
          ],
        },
      ],
    },
    {
      name: i18n.global.t('menu.event'),
      path: '/event',
    },
    // {
    //   name: i18n.global.t('menu.groupQna'),
    //   path: '/support/qna/group-booking',
    // },
    {
      name: i18n.global.t('menu.support'),
      path: '/support',
      blocked: true,
      children: [
        {
          name: i18n.global.t('menu.notice'),
          path: '/support/notice',
          stopped: true,
        },
        {
          name: i18n.global.t('menu.qna'),
          path: '/support/qna',
        },
        {
          name: i18n.global.t('menu.faq'),
          path: '/support/faq',
        },
      ],
    },
  ],
};

const mutations = {
  increaseLoading(state) {
    state.loadingCount++;
    state.isLoading = true;
  },
  decreaseLoading(state) {
    if (state.loadingCount > 0) {
      state.loadingCount--;
    }

    if (state.loadingCount === 0) {
      state.isLoading = false;
    }
  },
  setForceLoading(state, value) {
    state.forceLoading = value;
  },
};

const actions = {};

const getters = {
  globalNav: state => {
    if (i18n.global.locale === 'ja') {
      return state.originalGlobalNav.map(item => {
        if (item.path === '/product') {
          return {
            ...item,
            children: item.children.filter(child => child.path !== '/product/one-night'),
          };
        }
        return item;
      });
    }
    return state.originalGlobalNav;
  },
};

const common = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default common;
