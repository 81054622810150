<template>
  <div class="terms-wrapper">
    <div class="terms-contents">
      <h3 class="sub-title">{{ $t('register.termAgree') }}</h3>
      <p class="title-description">{{ $t('term.agreeToTerms') }}</p>

      <div class="terms-box">
        <div class="terms-padding all-check">
          <CruiseCheckbox
            v-model="isAllCheck"
            shape="round"
            :label="$t('term.agreeToAllTerms')"
            @onChange="toggleSelectAll"
          />
        </div>
        <div class="checkbox-wrapper terms-padding">
          <div class="term-item-wrapper" v-for="(term, idx) in termList" :key="idx">
            <div class="term-item">
              <CruiseCheckbox :label="term.title" v-model="term.checked" @onChange="checkTerm($event, idx)" />
              <CruiseButton class="term-btn" theme="q" @click="toggleModal(term)">
                {{ $t('term.openTerm') }}
              </CruiseButton>
            </div>
            <div class="promotion-items" v-if="!term.isRequired">
              <CruiseCheckbox
                :label="$t(term.email.title)"
                v-model="term.email.checked"
                @onChange="checkSubOption($event, idx, 'email')"
              />
              <CruiseCheckbox
                :label="term.sms.title"
                v-model="term.sms.checked"
                @onChange="checkSubOption($event, idx, 'sms')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn-wrapper">
      <CruiseButton class="next-btn" @click="goNext">{{ $t('actions.next') }}</CruiseButton>
    </div>

    <ReCruiseModal v-model:showModal="isShowModal" :title="termTitle">
      <template #content>
        {{ termContent }}
        <div v-if="termImageUrl">
          <img class="term-image" :src="termImageUrl" alt="term image" />
        </div>
      </template>
    </ReCruiseModal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import TermService from '@/services/term';

import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';

export default {
  name: 'RegisterTerms',
  components: { CruiseCheckbox, CruiseButton, ReCruiseModal },
  data() {
    return {
      isAgree: false,
      isAllCheck: false,

      termList: [],

      termTitle: '',
      termContent: '',
      termImageUrl: '',

      isShowModal: false,
    };
  },
  computed: {
    allRequiredChecked() {
      return this.termList.filter(term => term.isRequired).every(term => term.checked);
    },
    promotionTerm() {
      return this.termList.filter(term => !term.isRequired)[0];
    },
  },
  watch: {
    termList: {
      handler: function (terms) {
        const allChecked = terms.every(term => term.checked);
        const allRequiredChecked = this.allRequiredChecked;

        this.isAllCheck = allChecked;
        this.isAgree = allRequiredChecked;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('auth', ['updatedRegisterUserInfo']),

    init() {},
    toggleSelectAll(e) {
      const checked = e.target.checked;

      this.termList.forEach(term => {
        term.checked = checked;
        if (!term.isRequired) {
          term.email.checked = checked;
          term.sms.checked = checked;
        }
      });
      this.isAllCheck = checked;
    },
    checkTerm(e, idx) {
      const checked = e.target.checked;
      const term = this.termList[idx];
      term.checked = checked;

      if (!term.isRequired) {
        term.email.checked = checked;
        term.sms.checked = checked;
      }

      this.updateSelectAllStatus();
    },
    checkSubOption(e, idx, type) {
      const checked = e.target.checked;
      const term = this.termList[idx];
      term[type].checked = checked;

      if (!term.email.checked || !term.sms.checked) {
        term.checked = false;
      } else {
        term.checked = true;
      }

      this.updateSelectAllStatus();
    },
    updateSelectAllStatus() {
      this.isAllCheck = this.termList.every(term => term.checked);
      this.isAgree = this.termList.filter(term => term.isRequired).every(term => term.checked);
    },
    async getTermList() {
      try {
        const { data } = await TermService.getTermList({ category: 'register' });

        const email = { title: 'profile.email', checked: false };
        const sms = { title: 'SMS', checked: false };
        data[data.length - 1].email = email;
        data[data.length - 1].sms = sms;

        this.termList = data.map(term => ({
          ...term,
          checked: false,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    goNext() {
      if (!this.isAgree) {
        this.$alert({ content: this.$t('term.agreeToRequiredTerms') });
        return;
      }

      this.updatedRegisterUserInfo({
        emailReceive: this.promotionTerm.email.checked,
        smsReceive: this.promotionTerm.sms.checked,
      });

      this.$router.push('details');
    },
    toggleModal(term) {
      this.termTitle = term.title;
      this.termContent = term.content;
      this.termImageUrl = term.imageUrl;

      this.isShowModal = !this.isShowModal;
    },
  },
  created() {
    this.getTermList();
  },
};
</script>

<style scoped>
.terms-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--Black);
}

.terms-contents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sub-title {
  font-size: 14px;
  font-weight: 700;
}

.title-description {
  font-size: 12px;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  border-top: var(--default--border);
}

.term-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.promotion-items {
  display: flex;
  gap: 10px;
  border: var(--default--border);
  padding: 10px;
}

.term-btn {
  width: auto;
  height: 20px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  padding: 4px 10px;
}

.terms-box {
  border: var(--default--border);
}

.terms-padding {
  padding: 16px 10px;
}

.promotion-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.promotion-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 25px;
}

.all-check {
  display: flex;
  align-items: center;
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
}

.next-btn {
  width: 155px;
}

.modal-wrapper {
  position: relative;
  padding-top: 60px;
}

.term-content {
  white-space: pre-wrap;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  overflow: auto;
}

.term-image-wrapper,
.term-image {
  width: 100%;
  height: 100%;
}

:deep(.modal-contents) {
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {
  .sub-title {
    font-size: 18px;
  }

  .terms-padding {
    padding: 20px;
  }

  .next-btn {
    width: 188px;
  }

  :deep(.modal-contents) {
    overflow: hidden;
  }
}
</style>
