<template>
  <div class="my-coupons-wrapper">
    <AddCoupon @onAddCoupon="getUserCouponList" />

    <div class="coupon-list-wrapper">
      <CouponItem v-for="item in myCouponList" :coupon="item.coupon" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CouponService from '@/services/coupon';
import AddCoupon from '@/components/coupon/AddCoupon.vue';
import CouponItem from '@/components/coupon/CouponItem.vue';

export default {
  name: 'MyCoupons',
  components: { AddCoupon, CouponItem },
  data() {
    return {
      myCouponList: [],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    async getUserCouponList() {
      try {
        const pcid = this.user.PCID;
        const { data } = await CouponService.postUserCouponList(pcid);
        this.myCouponList = data || [];
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
  },
  created() {
    this.getUserCouponList();
  },
};
</script>

<style scoped>
.my-coupons-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 600px;
}

.coupon-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (min-width: 768px) {
  .coupon-list-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
</style>
