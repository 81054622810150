import api from '@/services/api';

const API_URL = '/api/faq';

class FaqService {
  async getFaqList(params) {
    try {
      const response = await api.get(`${API_URL}/getList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getFaqCategoryList() {
    try {
      const response = await api.get(`${API_URL}/getCategoryList`);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getFaqDetail(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new FaqService();
