<template>
  <footer class="main-footer">
    <div class="footer-top">
      <div class="footer-wrapper container-default-renewal">
        <div class="footer-menu">
          <div v-if="$i18n.locale === 'ja'" class="menu-row" :class="$i18n.locale === 'ja' ? 'ja' : ''">
            <button @click="toggleModal('cruise')">{{ $t('footer.passengerTransport') }}</button>
            <button @click="toggleModal('privacy')">{{ $t('footer.privacyPolicy') }}</button>
          </div>
          <div v-if="$i18n.locale !== 'ja'" class="menu-row">
            <button @click="toggleModal('cruise')">{{ $t('footer.cruisePassengerTransport') }}</button>
            <button @click="toggleModal('fastShip')">{{ $t('footer.expressPassengerTransport') }}</button>
          </div>
          <div v-if="$i18n.locale !== 'ja'" class="menu-row">
            <button @click="toggleModal('site')">{{ $t('footer.siteTermsOfUse') }}</button>
            <button @click="toggleModal('privacy')">{{ $t('footer.privacyPolicy') }}</button>
          </div>
          <div v-if="$i18n.locale !== 'ja'" class="menu-row">
            <button @click="toggleModal('email')">{{ $t('footer.unsubscribeEmail') }}</button>
          </div>
        </div>

        <div class="seperator"><span></span></div>

        <div class="footer-etc">
          <div class="sns">
            <a href="https://pf.kakao.com/_MlMku" target="_blank">
              <img class="sns-icon" src="@/assets/images/icon/sns_kakao_icon.svg" alt="kakao talk" />
            </a>
            <a href="https://www.facebook.com/panstarline" target="_blank">
              <img class="sns-icon" src="@/assets/images/icon/sns_facebook_icon.svg" alt="facebook" />
            </a>
            <a href="https://www.youtube.com/watch?v=rhpBhFkvfew" target="_blank">
              <img class="sns-icon" src="@/assets/images/icon/sns_youtube_icon.svg" alt="youtube" />
            </a>
          </div>

          <div ref="family" class="family-site" @click="toggleFamilySiteMenu">
            <span>FAMILY SITE</span>
            <img class="triangle-icon" src="@/assets/images/icon/triangle_icon_w.svg" alt="icon" />
          </div>
          <div v-if="showFamilySite" class="family-menu">
            <a v-for="site in familySiteList" :href="site.url" target="_blank" class="family-item">{{ site.name }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="footer-wrapper container-default-renewal">
        <div class="footer-copyright">
          <div class="logo">
            <img class="logo-img" :src="logoSrc" alt="panstar cruise logo" />
          </div>
          <div class="copyright-wrapper">
            <div class="address">
              <div class="address-left">
                <dl>
                  <dd>
                    <span>
                      <b>{{ $t('footer.companyInfo.panstarLineDotCom') }}</b>
                      {{ $t('footer.companyInfo.panstarLineDotComAddress') }}
                    </span>
                  </dd>
                  <dd>
                    <span>{{ $t('footer.companyInfo.registrationNumbers') }} : 104-81-44895</span>
                    <span>{{ $t('footer.companyInfo.salesReportNumbers.panstarLineDotCom') }}</span>
                    <span>{{ $t('footer.companyInfo.representatives.panstarLineDotCom') }}</span>
                  </dd>
                </dl>

                <dl>
                  <dd>
                    <span>
                      <b>{{ $t('footer.companyInfo.panstarEnterprise') }}</b>
                      {{ $t('footer.companyInfo.panstarEnterpriseAddress') }}
                    </span>
                  </dd>
                  <dd>
                    <span>{{ $t('footer.companyInfo.registrationNumbers') }} : 139-81-23687</span>
                    <span>{{ $t('footer.companyInfo.salesReportNumbers.panstarEnterprise') }}</span>
                    <span>{{ $t('footer.companyInfo.representatives.panstarEnterprise') }}</span>
                  </dd>
                </dl>

                <dl>
                  <dd>
                    <span>
                      <b>{{ $t('footer.companyInfo.gimpoBranch') }}</b>
                      {{ $t('footer.companyInfo.gimpoBranchAddress') }}
                    </span>
                    <span>
                      <b>{{ $t('footer.companyInfo.seoulBranch') }}</b>
                      {{ $t('footer.companyInfo.seoulBranchAddress') }}
                    </span>
                  </dd>
                </dl>
              </div>

              <div class="address-right">
                <dl>
                  <dd>
                    <span>
                      <b>{{ $t('footer.contacts.passengerInquiry') }}</b>
                      &nbsp; Tel.1577-9996/9666 &nbsp; Fax. 051-469-6179
                    </span>
                    <span>
                      <b>{{ $t('footer.contacts.freightInquiry') }}</b>
                      &nbsp;Tel. 051-465-4500 &nbsp; Fax. 051-469-8208
                    </span>
                  </dd>
                  <dd>
                    <b>{{ $t('footer.contacts.partnershipInquiry') }}</b>
                    &nbsp;marketing@panstar.kr
                  </dd>
                </dl>

                <dl>
                  <dt>COPYRIGHT(c) 2024 PANSTAR ALL RIGHTS RESERVED</dt>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ReCruiseModal
      v-if="$i18n.locale !== 'ja'"
      v-model:showModal="isShowCruiseTermModal"
      :title="$t('footer.cruisePassengerTransport')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[0].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale === 'ja'"
      v-model:showModal="isShowCruiseTermModal"
      :title="$t('footer.passengerTransport')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[5].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale !== 'ja'"
      v-model:showModal="isShowFastShipTermModal"
      :title="$t('footer.expressPassengerTransport')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[1].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale === 'ko'"
      v-model:showModal="isShowSiteTermModal"
      :title="$t('footer.siteTermsOfUse')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[2].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale === 'en'"
      v-model:showModal="isShowSiteTermModal"
      :title="$t('footer.siteTermsOfUse')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[9].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale !== 'ja'"
      v-model:showModal="isShowPrivacyPolicyModal"
      :title="$t('footer.privacyPolicy')"
    >
      <template #content><div v-html="$sanitize(footerTermList[3].content)"></div></template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale === 'ja'"
      v-model:showModal="isShowPrivacyPolicyModal"
      :title="$t('footer.privacyPolicy')"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[6].content)"></div>
      </template>
    </ReCruiseModal>

    <ReCruiseModal
      v-if="$i18n.locale !== 'ja'"
      v-model:showModal="isShowEmailOptOutModal"
      :title="$t('footer.unsubscribeEmail')"
      class="email"
    >
      <template #content>
        <div v-html="$sanitize(footerTermList[4].content)"></div>
      </template>
    </ReCruiseModal>
  </footer>
</template>

<script>
import { familySiteList, footerTermList } from '@/data/footer';
import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';

export default {
  name: 'MainFooter',
  components: { ReCruiseModal },
  data() {
    return {
      familySiteList: familySiteList,
      showFamilySite: false,

      footerTermList: footerTermList,

      isShowCruiseTermModal: false,
      isShowFastShipTermModal: false,
      isShowSiteTermModal: false,
      isShowEmailOptOutModal: false,
      isShowPrivacyPolicyModal: false,
    };
  },
  computed: {
    logoSrc() {
      return require('@/assets/images/logo/panstar_full_logo.svg');
    },
  },
  methods: {
    toggleFamilySiteMenu() {
      this.showFamilySite = !this.showFamilySite;
    },
    toggleModal(type) {
      if (type === 'cruise') this.isShowCruiseTermModal = !this.isShowCruiseTermModal;
      if (type === 'fastShip') this.isShowFastShipTermModal = !this.isShowFastShipTermModal;
      if (type === 'site') this.isShowSiteTermModal = !this.isShowSiteTermModal;
      if (type === 'email') this.isShowEmailOptOutModal = !this.isShowEmailOptOutModal;
      if (type === 'privacy') this.isShowPrivacyPolicyModal = !this.isShowPrivacyPolicyModal;
    },
    handleClickOutside(e) {
      if (!this.$refs.family.contains(e.target)) {
        this.showFamilySite = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.main-footer {
  width: 100%;
  font-size: 12px;
  word-break: break-all;
}

.footer-top {
  width: 100%;
  color: var(--White);
  background: var(--Grey-grey-200);
}

.footer-menu {
  padding: 30px 0 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
}

.menu-row {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}

.menu-row:last-child {
  margin-bottom: 0;
}

.menu-row button {
  width: 50%;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid var(--White);
  word-break: keep-all;
}

.menu-row button:nth-child(2) {
  border-right: none;
}

.menu-row button:hover {
  font-weight: 700;
}

.menu-row.ja:first-child button:first-child {
  border-right: 1px solid var(--White);
}

.menu-row.ja:first-child button:last-child {
  border-right: none;
}

.menu-row:last-child button {
  border: none;
}

.seperator {
  width: 100%;
  height: 1px;
}

.seperator > span {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--Grey-grey-100);
}

.sns {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.sns-icon {
  width: 32px;
  height: 32px;
}

.family-site {
  height: 50px;
  padding: 17.5px 16px;
  background: var(--Grey-grey-400);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.triangle-icon {
  width: 7px;
  height: 6px;
}

.family-menu {
  display: block;
  background: #858b92;
  width: calc(100% + 32px);
  margin-left: -16px;
}

.family-item {
  display: block;
  padding: 10px 16px;
}

.family-item:hover {
  background: var(--Grey-grey-400);
}

.footer-copyright {
  width: 100%;
}

.logo {
  padding: 24px 0;
}

.logo-img {
  width: 200px;
  height: 22.269px;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

b {
  font-weight: 600;
}

dl {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.address-left {
  word-break: keep-all;
}

.address-left dl:nth-child(3) dd,
.address-right dl:nth-child(4) dd {
  gap: 8px;
}

.address-right dl:nth-child(5) {
  color: var(--Grey-grey-700);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
}

dd {
  display: flex;
  flex-wrap: wrap;
  color: var(--Grey-grey-700);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.4px;
}

dd > span {
  word-wrap: break-word;
  font-weight: 400;
}

:deep(.modal-contents) {
  font-size: 16px;
  line-height: 150%;
}

:deep(pre) {
  white-space: pre-line !important;
}

@media screen and (min-width: 768px) {
  .main-footer {
    min-width: 1384px;
    font-size: 14px;
    line-height: 16px;
  }

  .footer-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
  }

  .footer-top > div {
    display: flex;
  }

  .footer-menu {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .menu-row {
    width: fit-content;
    margin-bottom: 0;
  }

  .menu-row button {
    width: fit-content;
  }

  .menu-row:first-child button:first-child {
    padding-left: 0;
  }

  .menu-row button:nth-child(2) {
    border-right: 1px solid var(--White);
  }

  .seperator {
    display: none;
  }

  .footer-etc {
    position: relative;
    display: flex;
    height: 64px;
  }

  .sns {
    padding: 0;
    margin-right: 16px;
  }

  .family-site {
    width: 180px;
    height: 100%;
    margin-left: 0;
  }

  .family-menu {
    position: absolute;
    top: 64px;
    right: 0;
    width: 180px;
    margin-left: 0;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    padding: 0;
    margin-bottom: 24px;
  }

  .logo-img {
    width: 220px;
    height: 24.519px;
  }

  .footer-copyright {
    width: 100%;
    padding: 39px 0 42.48px;
  }

  .address {
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
  }

  .address-left dl:last-child {
    margin: 0;
  }

  .address-left dl dd:nth-child(2) > span {
    border-right: 1px solid var(--Grey-grey-700);
    padding-right: 8px;
  }

  .address-left dl dd:nth-child(2) > span:last-child {
    border-right: none;
  }

  .address-right dl {
    gap: 16px;
  }

  dl {
    width: 100% !important;
    gap: 8px;
    margin-bottom: 16px;
  }

  dd {
    gap: 8px;
    line-height: 100%;
  }

  :deep(.email) {
    height: 30%;
  }
}
</style>
