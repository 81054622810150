<template>
  <div class="voyage-item" :class="itemClass" @click="selectSchedule">
    <div class="voyage-schedule">
      <div class="departure-info">
        <div class="port-text">
          <span>
            {{ getLocalText(item.leaveVoyageInformation.departurePort, 'name') }}
          </span>
          <ArrowIcon class="flex-center" :size="16" />
          <span>
            {{ getLocalText(item.leaveVoyageInformation.arrivePort, 'name') }}
          </span>
        </div>
        <div class="date-text">
          {{ dateFormat(item.leaveDate) }} {{ item.leaveVoyageInformation.departureExpectTm }}
          {{ $t('tourProduct.departure') }}
        </div>
      </div>
      <div class="departure-info" v-if="item.returnVoyageInformation">
        <div class="port-text">
          <span>
            {{ getLocalText(item.returnVoyageInformation.departurePort, 'name') }}
          </span>
          <ArrowIcon class="flex-center" :size="14" />
          <span>
            {{ getLocalText(item.returnVoyageInformation.arrivePort, 'name') }}
          </span>
        </div>
        <div class="date-text">
          {{ dateFormat(item.returnDate) }} {{ item.returnVoyageInformation.departureExpectTm }}
          {{ $t('tourProduct.departure') }}
        </div>
      </div>
    </div>

    <div class="voyage-info">
      <div class="info">
        <span class="price-text">{{ $t('tourProduct.packageTotalPricePerAdult') }}</span>
        <div class="voyage-price">
          {{ convertCommaNumber(price + curGradeInfo?.leavePrice + (curGradeInfo?.returnPrice || 0))
          }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
          }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}
          <span class="voyage-count"> ({{ $t('tourProduct.remainCount', { count: curGradeInfo?.count }) }}) </span>
        </div>
      </div>
      <div class="check-icon">
        <img src="@/assets/images/icon/check_btn_icon.svg" alt="check icon" />
      </div>
      <div class="soldout">{{ $t('tourProduct.soldOut') }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { convertCommaNumber, getLocalText } from '@/utils/convert';
import { YYYY_MM_DD } from '@/const/const';

import ArrowIcon from 'vue-material-design-icons/ArrowRight';

export default {
  name: 'VoyageInfoItem',
  components: { ArrowIcon },
  emits: ['onClick'],
  props: {
    item: Object,
    selectedGrade: Object,
    active: Boolean,
    price: Number,
  },
  computed: {
    disabled() {
      return this.curGradeInfo?.count === 0;
    },
    itemClass() {
      return {
        active: this.active,
        disabled: this.disabled,
      };
    },
    curGradeInfo() {
      return this.item?.packageTourGrades.find(grade => grade.grade.gradeCode === this.selectedGrade?.gradeCode);
    },
  },
  methods: {
    convertCommaNumber,
    getLocalText,

    selectSchedule() {
      if (this.disabled) {
        return;
      }

      this.$emit('onClick');
    },
    dateFormat(date) {
      return dayjs(date).format(YYYY_MM_DD);
    },
  },
};
</script>

<style scoped>
.voyage-item {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  outline: 1px solid var(--Grey-grey-100);
  cursor: pointer;
}

.voyage-item:hover,
.voyage-item:focus {
  outline: 1px solid var(--Blue-blue-500);
}

.voyage-item.active {
  outline: 2px solid var(--Blue-blue-500);
}

.voyage-item.disabled {
  color: var(--White);
  background: var(--Grey-grey-200);
  cursor: not-allowed;
  outline: none;
}

.voyage-item .soldout {
  display: none;
}

.voyage-item.active .check-icon {
  display: block;
}

.voyage-item .check-icon,
.voyage-item.disabled .check-icon {
  display: none;
}

.voyage-item.disabled .date-text {
  color: rgba(255, 255, 255, 0.7);
}

.voyage-item.disabled .info {
  display: none;
}

.voyage-item.disabled .soldout {
  display: block;
  width: 100%;
  text-align: right;
  font-size: 18px;
  font-weight: 700;
}

.voyage-schedule {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.departure-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.port-text {
  display: flex;
  gap: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.date-text {
  color: var(--Grey-grey-700);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.voyage-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.price-text {
  color: var(--Blue-blue-300);
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.voyage-price {
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
}

.voyage-count {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .voyage-item {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
  }

  .voyage-schedule {
    width: 290px;
    border-bottom: none;
    padding-bottom: 0;
  }

  .info {
    align-items: flex-start;
    flex-direction: column;
  }

  .check-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .voyage-item.disabled .soldout {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
