<template>
  <div class="register-container">
    <div class="register-header" v-if="isShowProgress">
      <div class="title-wrapper">
        <h1 class="title-text">{{ $t('user.signUp') }}</h1>
        <p class="title-description">{{ $t('register.membershipMessage') }}</p>
      </div>

      <div class="navigation-wrapper">
        <template v-for="({ icon, path, label }, idx) in navigationList" :key="idx">
          <div class="nav-item" :class="navClass(path)">
            <component :is="icon" :size="24" />
            <div class="nav-label">{{ label }}</div>
          </div>
          <ChevronRightIcon v-if="idx < navigationList.length - 1" :size="20" />
        </template>
      </div>
    </div>

    <RouterView></RouterView>
  </div>
</template>

<script>
import FileSignIcon from 'vue-material-design-icons/FileSign';
import AccountIcon from 'vue-material-design-icons/AccountOutline';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircleOutline';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight';

export default {
  name: 'RegisterView',
  components: { FileSignIcon, AccountIcon, CheckCircleIcon, ChevronRightIcon },
  data() {
    return {
      navigationList: [
        { icon: 'FileSignIcon', path: 'terms', label: this.$t('register.termAgree') },
        { icon: 'AccountIcon', path: 'details', label: this.$t('register.inputUserInfo') },
        { icon: 'CheckCircleIcon', path: 'complete', label: this.$t('register.registerComplete') },
      ],
    };
  },
  computed: {
    isShowProgress() {
      return this.$route.name !== 'complete';
    },
  },
  methods: {
    navClass(path) {
      return {
        active: this.$route.name === path,
      };
    },
  },
};
</script>

<style scoped>
.register-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 16px;
}

.register-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--Black);
}

.title-text {
  font-size: 16px;
  font-weight: 700;
}

.title-description {
  font-size: 10px;
}

.navigation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 96px;
  height: 60px;
  opacity: 0.45;
  font-weight: 400;
  color: #969696;
  font-size: 12px;
}

.nav-item.active {
  color: var(--Black);
  font-weight: 700;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .register-container {
    width: 100%;
    max-width: 1060px;
    gap: 40px;
    padding: 20px;
  }

  .title-text {
    font-size: 28px;
  }

  .title-description {
    font-size: 12px;
  }

  .nav-item {
    gap: 10px;
    padding: 18px 20px;
    width: 188px;
  }

  .nav-label {
    font-size: 16px;
  }
}
</style>
