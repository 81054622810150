<template>
  <button class="action-btn" :class="btnClass" :disabled="disabled" @keydown.enter.prevent>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'CruiseButton',
  props: {
    theme: {
      type: String,
      default: 'p',
      validator: value => ['p', 's', 't', 'q'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      return [`btn-${this.theme}`];
    },
  },
};
</script>

<style scoped>
.action-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
}

.btn-p {
  color: var(--White);
  background: var(--Main, #4c65b6);
}

.btn-s {
  color: #969696;
  background: #dcdcdc;
}

.btn-t {
  border: var(--default--border);
  background: var(--White);
}

.btn-q {
  border: 0.5px solid #000;
  background: var(--White);
}

.action-btn:disabled:hover {
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .action-btn {
    width: 120px;
    font-size: 14px;
  }
}
</style>
