<template>
  <div class="popup-background flex-center" :class="dimmedClass">
    <div class="popup-wrap">
      <div class="popup-content">
        <div class="popup-title">{{ $t('labels.cancelFeeGuide') }}</div>

        <ul class="content-list">
          <li>
            {{ $t('cancelNotice.cruisePolicy.before3Days') }}
          </li>
          <li>
            {{ $t('cancelNotice.cruisePolicy.before2Days') }}
          </li>
          <li>
            {{ $t('cancelNotice.cruisePolicy.before1Days') }}
          </li>
          <li>
            {{ $t('cancelNotice.cruisePolicy.before2Hours') }}
          </li>
          <li>
            {{ $t('cancelNotice.cruisePolicy.after2Hours') }}
          </li>
          <li class="seperate-terms">
            <div class="term">
              <span class="popup">※</span>
              {{ $t('cancelNotice.feeAppliedToRoomFare') }}
            </div>
            <div class="term">
              <span class="popup">※</span>
              {{ $t('cancelNotice.standardTicketRefund') }}
            </div>
            <div class="term">
              <span class="popup">※</span>
              {{ $t('cancelNotice.promotionTicketPolicy') }}
            </div>
          </li>
        </ul>
      </div>

      <div class="button-wrap">
        <ReCruiseButton class="bottom-btn" @click="close">{{ $t('actions.confirm') }}</ReCruiseButton>
      </div>
    </div>
  </div>
</template>

<script>
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CheckBold from 'vue-material-design-icons/CheckBold';

export default {
  name: 'CancelNoticePopup',
  components: { ReCruiseButton, CheckBold },
  emits: ['close'],
  props: {
    dimmed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dimmedClass() {
      return { clearDim: !this.dimmed };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  z-index: var(--zLayoutLoading);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Backdrop-renewal);
  backdrop-filter: blur(4px);
  word-break: keep-all;
  padding: 0 20px;
}

.clearDim {
  background: transparent;
  backdrop-filter: none;
}

.popup-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* width: 310px; */
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.07), 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  overflow: auto;
  background: var(--White);
}

.popup-title {
  width: 100%;
  color: var(--Grey-grey-900, #333);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1.5px;
  white-space: pre-wrap;
}

.content-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.content-list li {
  font-size: 16px;
  font-weight: 400;
  word-break: auto-phrase;
  line-height: 150%;
}

.seperate-terms {
  margin-top: 10px;
}

.popup {
  font-size: 16px;
  font-weight: 700;
}

.popup-text {
  color: var(--Orange-orange-500);
  font-size: 16px;
  font-weight: 700;
}

.button-wrap {
  display: flex;
}

.bottom-btn {
  width: 100%;
  height: 55px;
  border-radius: 0;
}

@media screen and (min-width: 768px) {
  .popup-wrap {
    /* width: 357px; */
    width: 402px;
  }
}
</style>
