<template>
  <div class="notice-detail-wrap">
    <div class="page-title-wrapper">
      <h1 class="page-title">{{ $t('menu.notice') }}</h1>
    </div>

    <div class="notice-detail-inner">
      <div class="detail-header">
        <div class="detail-title">{{ noticeDetail[getLocalKey('title')] }}</div>
        <div class="detail-date">{{ noticeDetail.createdAt }}</div>
      </div>

      <div class="detail-content">
        <div class="content" v-html="$sanitize(this.noticeDetail[this.getLocalKey('content')])"></div>
      </div>
    </div>

    <ReCruiseButton class="button" @click="$router.push('/support/notice')">{{
      $t('actions.backToList')
    }}</ReCruiseButton>
  </div>
</template>

<script>
import { getLocalKey } from '@/utils/convert';

import NoticeService from '@/services/notice';

import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'NoticeDetail',
  components: { ReCruiseButton },
  data() {
    return {
      noticeDetail: {},
    };
  },
  computed: {
    noticeId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getLocalKey,

    async getNoticeDetail() {
      if (!this.noticeId) return;

      try {
        const { data } = await NoticeService.getNoticeDetail(this.noticeId);
        this.noticeDetail = data;
      } catch (error) {
        this.$alert({ content: error });
      }
    },
  },
  created() {
    this.getNoticeDetail();
  },
};
</script>

<style scoped>
.notice-detail-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.notice-detail-inner {
  width: 100%;
  border-top: 1.5px solid var(--Grey-grey-700);
  border-bottom: 1px solid var(--Grey-grey-200);
  margin-bottom: 10px;
}

.detail-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--Grey-grey-200);
  margin-top: 10px;
  padding-bottom: 10px;
}

.detail-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.detail-date {
  display: flex;
  align-items: center;
  height: 30px;
  color: var(--Grey-grey-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}

.detail-content {
  padding: 30px 0;
  font-size: 14px;
  font-weight: 400;
}

.button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .notice-detail-wrap {
    gap: 60px;
  }

  .page-title {
    font-size: 34px;
  }

  .notice-detail-inner {
    margin-bottom: 40px;
  }

  .detail-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 30px;
  }

  .detail-title {
    font-size: 24px;
  }

  .detail-date {
    font-size: 22px;
  }

  .detail-content {
    padding: 40px 0;
    font-size: 20px;
  }

  .button {
    width: 160px;
  }
}
</style>
