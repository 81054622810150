<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('menu.operationInfo') }}</div>
      <div class="txt_det">{{ $t('sailingInfo.daemadoTitle') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('sailingInfo.sailingRoute') }}</div>
            <div class="img_map">
              <img :src="imgSrc" alt="Route" />
            </div>

            <div class="wrap_table layout_web">
              <table class="detail_table">
                <colgroup>
                  <col width="140px" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('sailingInfo.courses.label') }}</th>
                    <th>
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_round"></em
                      >{{ $t('sailingInfo.courses.hitakatsu') }}
                    </th>
                    <th>
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_round"></em
                      >{{ $t('sailingInfo.courses.izuhara') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="bg_th">{{ $t('sailingInfo.duration') }}</th>
                    <td>{{ $t('sailingInfo.aboutTime', { n: 1, m: 10 }) }}</td>
                    <td>{{ $t('sailingInfo.aboutTime', { n: 2, m: 10 }) }} {{ $t('sailingInfo.viaHitakatsu') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="wrap_table layout_mobile">
              <table class="detail_table">
                <colgroup>
                  <col width="50%" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('sailingInfo.courses.label') }}</th>
                    <th class="">{{ $t('sailingInfo.duration') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="bg_th txt_center">
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_round"></em
                      >{{ $t('sailingInfo.courses.hitakatsu') }}
                    </th>
                    <td>{{ $t('sailingInfo.aboutTime', { n: 1, m: 10 }) }}</td>
                  </tr>

                  <tr>
                    <th class="bg_th txt_center">
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_round"></em
                      >{{ $t('sailingInfo.courses.izuhara') }}
                    </th>
                    <td>{{ $t('sailingInfo.aboutTime', { n: 2, m: 10 }) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <div class="label_title">{{ $t('sailingInfo.routeSchedule') }}</div>
            <!-- Tabs -->
            <ul class="component_tabs">
              <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }">
                <a href="javascript:" @click="currentTab = index">{{ $t(tab.text, tab.params) }}</a>
              </li>
            </ul>
            <!-- //Tabs -->
            <div class="wrap_tabs">
              <!-- Tab contents  -->
              <div class="tab_content" v-show="currentTab === 0">
                <div class="schedule-img">
                  <img src="@/assets/images/information/product/schedule_daemado_01.png" />
                  <img src="@/assets/images/information/product/schedule_daemado_02.png" />
                  <img src="@/assets/images/information/product/schedule_daemado_03.png" />
                </div>
              </div>
              <div class="tab_content" v-show="currentTab === 1">
                <div class="schedule-img">
                  <img src="@/assets/images/information/product/t1.png" />
                  <img src="@/assets/images/information/product/t2.png" />
                  <img src="@/assets/images/information/product/t3.png" />
                </div>
              </div>
              <!-- <div class="tab_content" v-show="currentTab === 0">
                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="17%" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.route') }}</th>
                        <th>{{ $t('labels.departure') }}<em class="arrow_go"></em>{{ $t('labels.arrival') }}</th>
                        <th v-for="day in daysOfWeek">{{ day }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center" rowspan="2">
                          {{ $t('sailingInfo.routes.busan') }}<br /><em class="arrow_go"></em><br />{{
                            $t('sailingInfo.courses.hitakatsu')
                          }}
                        </th>
                        <td>08:40 ~ 09:50</td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                      </tr>
                      <tr>
                        <td>12:40 ~ 13:50</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <th class="bg_th txt_center" rowspan="4">
                          {{ $t('sailingInfo.courses.hitakatsu') }}<br /><em class="arrow_go"></em><br />{{
                            $t('sailingInfo.routes.busan')
                          }}
                        </th>
                        <td>12:50 ~ 14:00</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                      </tr>
                      <tr>
                        <td>16:30 ~ 17:40</td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>16:40 ~ 17:50</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
              <!-- //Tab contents  -->
              <!-- Tab contents  -->
              <!-- <div class="tab_content" v-show="currentTab === 1">
                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="17%" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.route') }}</th>
                        <th>{{ $t('labels.departure') }}<em class="arrow_go"></em>{{ $t('labels.arrival') }}</th>
                        <th v-for="day in daysOfWeek">{{ day }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.routes.busan') }}<br /><em class="arrow_go"></em><br />{{
                            $t('sailingInfo.courses.izuhara')
                          }}
                        </th>
                        <td>08:40 ~ 11:40</td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <th class="bg_th txt_center" rowspan="4">
                          {{ $t('sailingInfo.courses.izuhara') }}<br /><em class="arrow_go"></em><br />{{
                            $t('sailingInfo.routes.busan')
                          }}
                        </th>
                        <td>15:30 ~ 17:40</td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td>
                          <span class="txt_round">O</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->

              <div class="view_detail">
                <ol class="area_detail">
                  <li>
                    <hr class="hr_line" />
                  </li>
                  <li>
                    <div class="label_title">{{ $t('sailingInfo.reservationRequirements') }}</div>
                    <!-- Note -->
                    <div class="view_note">
                      <!-- ui Note -->
                      <div class="ui_note">
                        <em class="img_note"></em>
                        <div class="txt_note">
                          <div class="label_note">{{ $t('sailingInfo.requiredTitle') }}</div>
                          {{ $t('sailingInfo.requiredInfo') }}
                        </div>
                      </div>
                      <!-- //ui Note -->
                      <!-- ui Note -->
                      <div class="ui_note">
                        <em class="img_note2"></em>

                        <div class="txt_note">
                          <div class="label_note">{{ $t('sailingInfo.optionalTitle') }}</div>
                          {{ $t('sailingInfo.daemadoOptionalInfo') }}
                        </div>
                      </div>
                      <!-- //ui Note -->
                    </div>
                    <!-- //Note -->
                    <div class="txt_detail">
                      <ul class="list_dot">
                        <li>
                          {{ $t('sailingInfo.daemadoDisclaimer') }}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </li>
          <li></li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DaemadoProduct',
  data() {
    return {
      currentTab: 0,
      tabs: [
        {
          text: 'sailingInfo.courses.monthPeriod',
          params: {
            year: '2024',
            from: this.$i18n.locale === 'en' ? 'October' : '10',
            to: this.$i18n.locale === 'en' ? 'December' : '12',
          },
        },
        {
          text: 'sailingInfo.courses.monthPeriod',
          params: {
            year: '2025',
            from: this.$i18n.locale === 'en' ? 'January' : '1',
            to: this.$i18n.locale === 'en' ? 'March' : '3',
          },
        },
      ],
    };
  },
  computed: {
    imgSrc() {
      const localeKey = this.$i18n.locale !== 'ko' ? `_${this.$i18n.locale}` : '';
      return require(`@/assets/images/information/product/map_daemado_fastship${localeKey}.jpg`);
    },
    daysOfWeek() {
      return JSON.parse(this.$t('sailingInfo.daysOfWeek').replace(/'/g, '"'));
    },
  },
};
</script>
<style scoped>
/********************************************************
   component tabs
********************************************************/
.component_tabs {
  margin-bottom: 60px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #446ceb;
}
.component_tabs li {
  flex: auto;
  width: 100%;
}
.component_tabs li + li {
  border-left: 1px solid #446ceb;
}
.component_tabs li a {
  display: block;
  line-height: 68px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #446ceb;
  font-weight: 600;
}
.component_tabs li.active a {
  background-color: #446ceb;
  color: #fff;
}
@media (max-width: 767px) {
  .component_tabs {
    margin-bottom: 24px;
  }
  .component_tabs li a {
    line-height: 48px;
    font-size: 16px;
  }
}

.layout_mobile {
  display: none;
}
.layout_web {
  display: block;
}
@media (max-width: 767px) {
  .layout_mobile {
    display: block;
  }
  .layout_web {
    display: none;
  }
}
/********************************************************
  Detail Table
********************************************************/
.wrap_table {
  overflow: auto;
}
.wrap_table + .label_title {
  margin-top: 60px;
}
.detail_table {
  white-space: nowrap;
  width: 100%;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
}
.detail_table tbody tr td .txt_sub {
  font-size: 18px;
  color: #686d72;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tr td {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table tr td .txt_round {
  color: #304da7;
}
.detail_table tr th .arrow_go {
  display: inline-block;
  margin: 0 12px;
  width: 14px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_go.svg') no-repeat;
}
.detail_table tr th .arrow_round {
  display: inline-block;
  margin: 0 12px;
  width: 27px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_round.svg') no-repeat;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}

@media (max-width: 767px) {
  .wrap_table + .label_title {
    margin-top: 48px;
  }
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr td .txt_sub {
    font-size: 14px;
  }

  .schedule-img {
    width: 100%;
    overflow: auto;
  }

  .schedule-img img {
    width: 800px;
  }
}
.tab_content + .view_detail {
  padding-top: 60px;
}
.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail li .img_map {
  margin-bottom: 60px;
  font-size: 0;
}
.view_detail .area_detail li .img_map img {
  width: 100%;
  border-radius: 10px;
}
.view_detail .area_detail > li .hr_line {
  border: none;
  border-top: 1px solid #dddfe2;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
@media (max-width: 767px) {
  .view_detail .area_detail li .img_map {
    margin-bottom: 24px;
  }
  .view_detail .area_detail li .img_map img {
    height: 160px;
    object-fit: cover;
    object-position: 55% 50%;
  }
  .view_detail .area_detail .hr_line {
    margin: -36px 0;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link {
    line-height: 40px;
    font-size: 18px;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link + .txt_link {
    margin-left: 20px;
  }
  .view_detail .area_detail > li + li {
    margin-top: 48px;
  }
}
.view_detail .area_detail .txt_detail .txt_strong {
  color: #505458;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .txt_strong {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 24px;
  }
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 16px;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 24px;
  color: #505458;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  color: #686d72;
  line-height: 28px;
  white-space: pre-wrap;
}
.view_detail .area_detail .txt_detail .list_dot li strong {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }
}

.view_note {
  display: flex;
  margin-bottom: 32px;
}
.view_note .ui_note {
  flex: auto;
  display: flex;
  align-items: center;
}
.view_note .img_note2,
.view_note .img_note {
  display: inline-block;
  flex: none;
  width: 200px;
  height: 200px;
  margin-right: 50px;
  background: url('@/assets/images/information/icon/img_monitor.svg') no-repeat;
}
.view_note .img_note2 {
  background: url('@/assets/images/information/icon/img_monitor2.svg') no-repeat;
}
.view_note .txt_note {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
}
.view_note .txt_note .label_note {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #505458;
  line-height: 31px;
}

@media (max-width: 767px) {
  .view_note {
    flex-direction: column;
  }
  .view_note .ui_note + .ui_note {
    margin-top: 16px;
  }
  .view_note .ui_note {
    flex-direction: column;
  }
  .view_note .txt_note {
    text-align: center;
  }
  .view_note .txt_note .label_note {
    font-size: 20px;
  }
  .view_note .txt_note {
    font-size: 16px;
    line-height: 24px;
  }
  .view_note .img_note2,
  .view_note .img_note {
    margin: 0 0 16px 0;
  }
}
</style>
