import api from '@/services/api';

const API_URL = '/api/coupon';

class CouponService {
  async postUserCouponByCouponCode(params) {
    try {
      const response = await api.post(`${API_URL}/postUserCouponByCouponCode`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async postUserCouponList(pcid) {
    try {
      const response = await api.post(`${API_URL}/postUserCouponList`, { pcid });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new CouponService();
