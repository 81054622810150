<template>
  <ReCruiseModal
    :showModal="showModal"
    @update:showModal="updateShowModal"
    :title="$t('withdrawal.label')"
  >
    <template #content>
      <div class="txt-area">
        <div class="sub-txt">
          {{ $t('withdrawal.privacyPolicy') }}
        </div>
        <div class="sub-txt gray-color">
          <div>{{ $t('withdrawal.after') }}</div>
          <ul v-if="$i18n.locale === 'ko'">
            <li>
              홈페이지 상에서 <span class="highlight">구매내역 조회</span>,
              <span class="highlight">예약조회가 불가능</span> 하므로 회원 탈퇴 전 확인하여 주시기 바랍니다.
            </li>
            <li>적립된 <span class="highlight">마일리지 및 쿠폰은 삭제</span>됩니다.</li>
            <li>여행상품 및 각종 소식/이벤트에 관한 이메일, SMS 서비스를 받으실 수 없습니다.</li>
            <li>재가입을 하셔도 <span class="highlight">이전의 예약 및 구매내역을 조회하실 수 없습니다.</span></li>
            <li>재가입은 탈퇴일로부터 <span class="highlight">3일 후에 가능</span>합니다.</li>
            <li>탈퇴한 아이디는 <span class="highlight">재사용할 수 없습니다.</span></li>
          </ul>
          <ul v-if="$i18n.locale === 'ko'">
            <li>
              홈페이지 상에서 <span class="highlight">구매내역 조회</span>,
              <span class="highlight">예약조회가 불가능</span> 하므로 회원 탈퇴 전 확인하여 주시기 바랍니다.
            </li>
            <li>적립된 <span class="highlight">마일리지 및 쿠폰은 삭제</span>됩니다.</li>
            <li>여행상품 및 각종 소식/이벤트에 관한 이메일, SMS 서비스를 받으실 수 없습니다.</li>
            <li>재가입을 하셔도 <span class="highlight">이전의 예약 및 구매내역을 조회하실 수 없습니다.</span></li>
            <li>재가입은 탈퇴일로부터 <span class="highlight">3일 후에 가능</span>합니다.</li>
            <li>탈퇴한 아이디는 <span class="highlight">재사용할 수 없습니다.</span></li>
          </ul>
          <ul v-if="$i18n.locale === 'ko'">
            <li>
              홈페이지 상에서 <span class="highlight">구매내역 조회</span>,
              <span class="highlight">예약조회가 불가능</span> 하므로 회원 탈퇴 전 확인하여 주시기 바랍니다.
            </li>
            <li>적립된 <span class="highlight">마일리지 및 쿠폰은 삭제</span>됩니다.</li>
            <li>여행상품 및 각종 소식/이벤트에 관한 이메일, SMS 서비스를 받으실 수 없습니다.</li>
            <li>재가입을 하셔도 <span class="highlight">이전의 예약 및 구매내역을 조회하실 수 없습니다.</span></li>
            <li>재가입은 탈퇴일로부터 <span class="highlight">3일 후에 가능</span>합니다.</li>
            <li>탈퇴한 아이디는 <span class="highlight">재사용할 수 없습니다.</span></li>
          </ul>

          <ul v-if="$i18n.locale === 'ja'">
            <li>
              ホームページ上で<span class="highlight">購入内訳の照会</span>,<span class="highlight"
                >予約照会が不可能</span
              >ですので、会員退会前にご確認ください。
            </li>
            <li>積み立てられた<span class="highlight">マイレージ及びクーポンは削除</span>になります。</li>
            <li>旅行商品及び各種ニュース/イベントに関するメール、SMSサービスを受けることができません。</li>
            <li>に再登録しても<span class="highlight">以前の予約及び購入履歴を照会することができません。</span></li>
            <li>の再登録は、退会日から<span class="highlight">3日後に可能</span>を行います。</li>
            <li>脱退したハンドルネームは<span class="highlight">再使用できません。</span></li>
          </ul>

          <ul v-if="$i18n.locale === 'en'">
            <li>
              You will not be able to <span class="highlight">view your purchase history or reservations</span> on the
              website, so please check these details before canceling your membership.
            </li>
            <li>Your <span class="highlight">mileage and coupons will be deleted.</span></li>
            <li>
              You will no longer receive emails or SMS services regarding travel products and various news/events.
            </li>
            <li>
              Even if you sign up again,
              <span class="highlight"
                >you will not be able to view your previous reservations and purchase history.</span
              >
            </li>
            <li>Signing up again is <span class="highlight">available 3 days after</span> the cancellation.</li>
            <li>Cancelled ID <span class="highlight">cannot be reused.</span></li>
          </ul>
        </div>
      </div>

      <div class="input-wrapper">
        <ReCruiseInput type="userId" :placeholder="$t('login.confirmId')" required v-model="inputUserId" />
        <ReCruiseInput type="password" :placeholder="$t('password.confirmPassword')" required v-model="inputPassword" />
      </div>

      <div class="sub-txt border-none">{{ $t('withdrawal.completeMessage1') }}</div>
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">
        {{ $t('actions.cancel') }}
      </ReCruiseButton>
      <ReCruiseButton class="confirm-btn" :theme="confirmButtonTheme" :disabled="!isInputValid" @click="deleteAccount">
        {{ $t('actions.withdrawal') }}
      </ReCruiseButton>
    </template>
  </ReCruiseModal>
</template>

<script>
import UserService from '@/services/user';
import { mapState } from 'vuex';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';

export default {
  name: 'DeleteAccountModal',
  components: { ReCruiseModal, ReCruiseButton, CruiseInputField, ReCruiseInput },
  emits: ['update:showModal'],
  props: {
    showModal: Boolean,
    userId: String,
  },
  data() {
    return {
      inputUserId: '',
      inputPassword: '',
    };
  },
  watch: {
    showModal(value) {
      if (!value) {
        this.inputUserId = '';
        this.inputPassword = '';
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    mobileClass() {
      return window.innerWidth < 768 ? 'mobile' : '';
    },
    isInputValid() {
      return this.inputUserId && this.inputPassword;
    },
    confirmButtonTheme() {
      return this.isInputValid ? 'primary' : 'disabled';
    },
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },
    accountValidation() {
      if (!this.inputUserId) {
        this.$alert({ content: this.$t('messages.enterUserId') });
        return;
      }

      if (!this.inputPassword) {
        this.$alert({ content: this.$t('messages.enterPassword') });
        return;
      }

      return true;
    },
    async deleteAccount() {
      if (!this.accountValidation()) {
        return;
      }

      try {
        const result = await this.$confirm({
          title: this.$t('withdrawal.label'),
          content: this.$t('withdrawal.completeMessage2'),
          confirmBtnText: this.$t('actions.withdrawal'),
        });

        if (result) {
          const params = { userId: this.inputUserId, password: this.inputPassword };
          const response = await UserService.deleteAccount(params);

          await this.$store.dispatch('auth/logout');
          this.$alert({ content: response.message });
          this.closeModal();
          this.$router.push('/');
        }
      } catch (error) {
        this.$alert({ content: error });
      }
    },
  },
};
</script>

<style scoped>
.sub-txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.28px;
  border-bottom: 1px solid var(--Grey-grey-100);
  padding-bottom: 20px;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.sub-txt.gray-color {
  color: var(--Grey-grey-600);
}

.highlight {
  color: var(--Orange-orange-300);
  font-weight: 700;
}

.border-none {
  border-bottom: 0;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 16px;
}

li {
  list-style: disc;
  line-height: 130%;
}

@media screen and (min-width: 768px) {
  .sub-txt {
    padding-bottom: 24px;
  }

  .border-none {
    padding-top: 25px;
  }

  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }
}
</style>
