const htmlEntities = {
  '&lt;': '<',
  '&gt;': '>',
  '&amp;': '&',
  '&quot;': '"',
  '&apos;': "'",
  '&#39;': "'",
  '&copy;': '©',
  '&reg;': '®',
  '&trade;': '™',
  '&euro;': '€',
  '&pound;': '£',
};

export function decodeHtmlEntities(text) {
  return text.replace(
    /&lt;|&gt;|&amp;|&quot;|&apos;|&#39;|&copy;|&reg;|&trade;|&euro;|&pound;/g,
    match => htmlEntities[match],
  );
}
