<template>
  <div v-if="mostRecentNotice" class="notice-wrapper" :class="[fixedClass]" @click="goToPageByPC(mostRecentNotice.id)">
    <div class="notice-inner container-default-renewal" v-if="mostRecentNotice">
      <div class="category notice">
        <span class="category-text notice">
          {{ getLocalText(mostRecentNotice, 'category') }}
        </span>
      </div>

      <div class="notice-content">
        <div class="notice-header">
          <div class="notice-title">
            {{ getLocalText(mostRecentNotice, 'title') }}
          </div>
        </div>

        <div class="notice-desc">
          <div class="notice-date">
            {{ formattedDate(mostRecentNotice.createdAt) }}
          </div>

          <ReCruiseButton class="notice-more" theme="outline" @click="goToPageByMobile(mostRecentNotice.id)">
            {{ $t('actions.viewMore') }}
          </ReCruiseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/ja';
import 'dayjs/locale/en';

import { YYYYMMDDDAY } from '@/const/const';
import { getLocalText, getLocalKey } from '@/utils/convert';

import NoticeService from '@/services/notice';

import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'NoticeBanner',
  components: { ReCruiseButton },
  data() {
    return {
      isFixed: false,
      mainContentsHeight: 640,
      windowHeight: window.innerHeight,

      mostRecentNotice: null,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    fixedClass() {
      return this.isFixed ? 'fixed' : '';
    },
  },
  methods: {
    getLocalText,

    handleScroll() {
      const scrollPosition = window.scrollY;
      const scrollHeight = this.mainContentsHeight - this.windowHeight;

      if (scrollPosition >= scrollHeight) {
        this.isFixed = false;
      } else {
        this.checkFixed();
      }
    },
    checkFixed() {
      if (this.windowHeight < this.mainContentsHeight) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    async getNoticeList() {
      try {
        const isOpenedLocaleKey = getLocalKey('isOpened');

        const params = {
          page: 1,
          limit: 10,
          [isOpenedLocaleKey]: true,
        };

        const { data } = await NoticeService.getNoticeList(params);
        this.mostRecentNotice = data.notices[0];
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    formattedDate(date) {
      this.setLocale(this.locale);

      return dayjs(date).format(YYYYMMDDDAY);
    },
    setLocale(locale) {
      dayjs.locale(locale);
    },
    goToPageByPC(id) {
      if (window.innerWidth > 767) return;

      this.$router.push(`/support/notice/${id}`);
    },
    goToPageByMobile(id) {
      if (window.innerWidth <= 767) return;

      this.$router.push(`/support/notice/${id}`);
    },
  },
  created() {
    this.getNoticeList();
  },
  mounted() {
    this.checkFixed();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.notice-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  background: #fffae6;
  cursor: pointer;
  padding: 16px 0;
}

.fixed {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
}

.notice-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notice-content {
  flex: 1 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

.notice-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.category.notice {
  flex-shrink: 0;
}

.category-text {
  display: inline-block;
  max-width: 60px;
  min-height: 13px;
  font-size: 11px;
  font-weight: 500;
  padding: 3px 8px;
  border: 1px solid var(--Orange-orange-300);
  border-radius: 30px;
}

.category-text.notice {
  color: var(--Orange-orange-300);
}

.notice-title {
  font-size: 16px;
  font-weight: 500;
}

.notice-desc {
  display: flex;
  align-items: center;
  gap: 23px;
}

.notice-date {
  color: var(--Grey-grey-700);
  font-size: 14px;
  font-weight: 400;
}

.notice-more {
  display: none;
  background: transparent;
}

@media screen and (min-width: 768px) {
  .notice-wrapper {
    flex-direction: row;
    cursor: default;
    padding: 13px 0;
  }

  .fixed {
    position: static;
  }

  .notice-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .notice-inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .notice-header {
    gap: 16px;
  }

  .category-text {
    padding: 5px 15px;
    max-width: 90px;
    font-size: 15px;
  }

  .notice-title {
    font-size: 18px;
  }

  .notice-date {
    font-size: 16px;
    padding-left: 0;
  }

  .notice-more {
    display: block;
    width: 202.938px;
    height: 50px;
  }
}
</style>
