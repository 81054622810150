<template>
  <div class="page-title-wrapper">
    <h1 class="page-title">{{ $t('menu.faq') }}</h1>
  </div>

  <div class="faq-inner">
    <CruiseSearch :placeholder="$t('messages.searchFAQ')" @search="search" />

    <swiper
      class="tabs_slider"
      :modules="modules"
      :slidesPerView="'auto'"
      :spaceBetween="6"
      navigation
      :breakpoints="{
        '768': {
          spaceBetween: 6,
        },
        '1352': {
          spaceBetween: 16,
        },
      }"
    >
      <swiper-slide v-for="tab in categoryList" :key="tab.code">
        <a
          href="javascript:"
          :class="{ active: isSelectedCategoryTab(tab.code) }"
          @click="getFaqListByCategory(tab.code)"
        >
          {{ tab.label }}
        </a>
      </swiper-slide>
    </swiper>

    <div class="total-count">
      {{ $t('labels.allText') }}
      <span class="number">{{ totalCount || 0 }}</span
      >{{ $t('labels.caseText') }}
    </div>

    <div v-if="faqList.length" v-for="faq in faqList" :key="faq.id">
      <AccordionItem
        :isShow="faq.isShow"
        :title="getLocalText(faq, 'title')"
        :desc="getLocalText(faq, 'content')"
        @onClick="toggleAccordion(faq.id)"
      >
        <template #titleIcon>
          <div class="question-icon">
            <img src="@/assets/images/icon/qna_question_icon.svg" alt="question" />
          </div>
        </template>

        <template #contentIcon>
          <div class="answer-icon flex-center">
            <img src="@/assets/images/icon/qna_answer_icon.svg" alt="answer" />
          </div>
        </template>
      </AccordionItem>
    </div>
    <div v-else-if="noResultsMessage" class="no-result">
      {{ noResultsMessage }}
      <ReCruiseButton class="button" @click="$router.push('/support/qna')">{{ $t('labels.ask') }}</ReCruiseButton>
    </div>
  </div>

  <CruisePagination :page="activePage" :pages="totalPage" @update:page="handlePage" />
</template>

<script>
import i18n from '@/languages/i18n';

import { getLocalText } from '@/utils/convert';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/navigation';
import 'swiper/css';

import FaqService from '@/services/faq';

import CruiseSearch from '@/components/common/CruiseSearch';
import AccordionItem from '@/components/support/faq/AccordionItem';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'Faq',
  components: { Swiper, SwiperSlide, CruiseSearch, AccordionItem, ReCruiseButton, CruisePagination },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
  data() {
    return {
      keyword: '',
      faqList: [],

      categoryList: [],
      selectedCategoryCode: '',

      activePage: 1,
      totalPage: 0,
      totalCount: 0,

      noResultsMessage: '',
    };
  },
  methods: {
    getLocalText,

    init() {
      this.getFaqList();
      this.getFaqCategoryList();
    },

    async getFaqCategoryList() {
      try {
        const { data } = await FaqService.getFaqCategoryList();
        const languageCode = i18n.global.locale;

        this.categoryList = data.map(category => {
          const code = Object.keys(category)[0];

          return {
            code: code === 'all' ? '' : code,
            label: Object.values(category)[0][languageCode],
          };
        });
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    getFaqListByCategory(code) {
      this.activePage = 1;
      this.selectedCategoryCode = code;
      this.getFaqList();
    },
    isSelectedCategoryTab(code) {
      return this.selectedCategoryCode === code;
    },

    async getFaqList(page) {
      try {
        const params = {
          categoryEn: this.selectedCategoryCode,
          keyword: this.keyword || '',
          page: page || 1,
          limit: 10,
        };

        const { data } = await FaqService.getFaqList(params);

        this.totalPage = data.pages;
        this.totalCount = data.total;

        this.faqList = data.faqList.map(faq => {
          return {
            ...faq,
            isShow: false,
          };
        });

        this.noResultsMessage = this.faqList.length === 0 ? this.$t('messages.noResultsFAQ') : '';
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    handlePage(page) {
      this.activePage = page;
      this.getFaqList(page);
    },
    search(keyword) {
      this.keyword = keyword;
      this.getFaqList();
    },
    toggleAccordion(id) {
      this.faqList.forEach(faq => {
        if (faq.id === id) {
          faq.isShow = !faq.isShow;
        } else {
          faq.isShow = false;
        }
      });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  word-break: keep-all;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.faq-inner {
  margin-bottom: 50px;
}

.total-count {
  color: var(--Grey-grey-700);
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--Grey-grey-200);
  padding: 10px 0;
}

.total-count .number {
  color: var(--Blue-blue-500);
}

.no-result {
  display: flex;
  padding: 150px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  color: var(--Grey-grey-700);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.1px;
  white-space: pre;
}

.button {
  width: 100%;
}

.tabs_slider {
  padding: 30px 2px 24px;
  margin: 0 -2px;
  border-bottom: 1px solid var(--Grey-grey-700);
}

.tabs_slider .swiper-slide {
  width: auto !important;
}

.tabs_slider .swiper-slide a {
  display: inline-block;
  border-radius: 100px;
  border: 1px solid var(--Blue-blue-500);
  font-weight: 500;
  color: var(--Blue-blue-500);
  background-color: var(--White);
  font-size: 14px;
  line-height: 38px;
  padding: 0 20px;
}

.tabs_slider .swiper-slide a.active {
  background-color: var(--Blue-blue-500);
  color: var(--White);
  font-weight: 700;
}

.tabs_slider :deep(.swiper-button-prev),
.tabs_slider :deep(.swiper-button-next) {
  display: none;
}

.question-icon,
.answer-icon {
  height: 24px;
  padding: 3px 0 3px 5px;
}

.question-icon img {
  width: 15px;
  height: 15px;
}

.answer-icon img {
  width: 12px;
  height: 15px;
}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 34px;
  }

  .total-count {
    font-size: 16px;
    padding: 30px 0;
  }

  .no-result {
    padding: 250px 0 0;
    font-size: 22px;
    gap: 60px;
  }

  .button {
    width: 160px;
  }

  .tabs_slider .swiper-slide a {
    font-size: 20px;
    padding: 0 28px;
    line-height: 54px;
  }

  .tabs_slider :deep(.swiper-button-prev),
  .tabs_slider :deep(.swiper-button-next) {
    display: inline-flex;
    width: 62px;
    height: 62px;
    top: 29px;
    margin: 0;
    background-color: var(--White);
  }

  .tabs_slider :deep(.swiper-button-prev) {
    left: 0;
  }

  .tabs_slider :deep(.swiper-button-next) {
    right: 0;
  }

  .tabs_slider :deep(.swiper-button-prev:before),
  .tabs_slider :deep(.swiper-button-next:before) {
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--White);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    content: '';
    flex: none;
  }

  .tabs_slider :deep(.swiper-button-prev:before) {
    left: 2px;
  }

  .tabs_slider :deep(.swiper-button-next:before) {
    right: 2px;
  }

  .tabs_slider :deep(.swiper-button-prev.swiper-button-disabled),
  .tabs_slider :deep(.swiper-button-next.swiper-button-disabled) {
    display: none;
  }

  .tabs_slider :deep(.swiper-wrapper) {
    height: 72px;
  }

  .tabs_slider :deep(.swiper-button-prev:after),
  .tabs_slider :deep(.swiper-button-next:after) {
    color: var(--Grey-grey-300);
    font-weight: bold;
    font-size: 20px;
    z-index: 1;
  }

  .tabs_slider :deep(.swiper-wrapper) {
    height: auto;
    padding: 2px 2px;
    margin: 0 -2px;
  }

  .question-icon,
  .answer-icon {
    width: 25px;
    height: 30px;
    padding: 3px 0 3px 5px;
  }

  .question-icon img {
    width: 20px;
    height: 20px;
  }

  .answer-icon img {
    width: 16px;
    height: 20px;
  }
}
</style>
