<template>
  <MainHeader
    @clickMenu="toggleSidebar"
    :isNavigation="routeMeta.isNavigation"
    :isMainPage="isMainPage"
    :currentScroll="currentScroll"
  />
  <main class="main-container" :class="containerClass">
    <router-view></router-view>
  </main>
  <MainFooter v-if="routeMeta.isNavigation" />

  <MainSidebar v-model:show="isSidebarOpen" />

  <!-- Popup Area -->
  <CruisePopup />
  <CruiseLoading />
</template>

<script>
import MainHeader from '@/layouts/MainHeader.vue';
import MainFooter from '@/layouts/MainFooter.vue';
import MainSidebar from '@/layouts/MainSidebar.vue';
import CruiseLoading from '@/components/spinner/CruiseLoading.vue';
import CruisePopup from '@/components/common/CruisePopup.vue';

export default {
  name: 'MainContainer',
  components: { MainHeader, MainFooter, MainSidebar, CruiseLoading, CruisePopup },
  data() {
    return {
      isSidebarOpen: false,
      currentScroll: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) window.scrollTo({ top: 0 });
    },
  },
  computed: {
    routeMeta() {
      const meta = this.$route.meta;
      return {
        isNavigation: !!meta.isNavigation,
      };
    },
    isMainPage() {
      return this.$route.path === '/' ? true : false;
    },
    containerClass() {
      return {
        'main-page': this.isMainPage,
        'show-nav-margin': this.routeMeta.isNavigation,
      };
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    updateScroll() {
      this.currentScroll = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll);
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0;
  margin-top: 60px;
}

.main-container.main-page {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .main-container {
    min-width: 1384px;
    margin-top: 80px;
  }

  .show-nav-margin {
    margin-top: 109px;
  }
}
</style>
