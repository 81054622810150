import { getItem } from '@/utils/localStorage';
import i18n from '@/languages/i18n';

const state = {
  bookingStep: {
    OSC: [
      {
        title: i18n.global.t('bookingStepList.selectCabin'),
        name: 'cruise-grade',
        path: '/booking/cruise/grade',
        isActive: true,
        isPassed: false,
        children: [],
      },
      {
        title: i18n.global.t('bookingStepList.inputPassengerInfo'),
        name: 'cruise-passenger',
        path: '/booking/cruise/passenger/0',
        isActive: false,
        isPassed: false,
      },
      {
        title: i18n.global.t('bookingStepList.extraService'),
        name: 'cruise-extra',
        path: '/booking/cruise/extra/0',
        isActive: false,
        isPassed: false,
      },
      {
        title: i18n.global.t('bookingStepList.payment'),
        name: 'cruise-payment',
        path: '/booking/cruise/payment',
        isActive: false,
        isPassed: false,
      },
    ],
    ONC: [
      {
        title: i18n.global.t('bookingStepList.selectCabin'),
        name: 'cruise-grade',
        path: '/booking/cruise/grade',
        isActive: true,
        isPassed: false,
        children: [],
      },
      {
        title: i18n.global.t('bookingStepList.inputPassengerInfo'),
        name: 'cruise-passenger',
        path: '/booking/cruise/passenger/0',
        isActive: false,
        isPassed: false,
      },
      {
        title: i18n.global.t('bookingStepList.payment'),
        name: 'cruise-payment',
        path: '/booking/cruise/payment',
        isActive: false,
        isPassed: false,
      },
    ],
    TSE: [
      {
        title: i18n.global.t('bookingStepList.selectSeatClass'),
        name: 'fast-ship-grade',
        path: '/booking/fast-ship/grade',
        isActive: true,
        isPassed: false,
        children: [],
      },
      {
        title: i18n.global.t('bookingStepList.inputPassengerInfo'),
        name: 'fast-ship-passenger',
        path: '/booking/fast-ship/passenger/0',
        isActive: false,
        isPassed: false,
      },
      {
        title: i18n.global.t('bookingStepList.selectSeat'),
        name: 'fast-ship-seat',
        path: '/booking/fast-ship/seat/from',
        isActive: false,
        isPassed: false,
      },
      {
        title: i18n.global.t('bookingStepList.payment'),
        name: 'fast-ship-payment',
        path: '/booking/fast-ship/payment',
        isActive: false,
        isPassed: false,
      },
    ],
  },

  searchBookingInfo: null || getItem('searchBookingInfo'),
  pastSearchBookingInfo: null,

  redirectTo: null,
};

const mutations = {
  updateBookingStepActive(state, stepName) {
    const routeCode = state.searchBookingInfo?.routeCode || 'OSC';
    let isPassed = true;

    state.bookingStep[routeCode].forEach(step => {
      if (step.name === stepName) {
        isPassed = false;
        step.isActive = true;
      } else {
        step.isActive = false;
      }
      step.isPassed = isPassed;
    });
  },
  updateSearchBookingInfo(state, payload) {
    state.searchBookingInfo = { ...state.searchBookingInfo, ...payload };
  },
  updatePassengerDiscount(state, { order, idx, passenger }) {
    state.searchBookingInfo.passengerTypeCountList[order].passenger[idx] = passenger;
  },
  setPastSearchBookingInfo(state, payload) {
    state.pastSearchBookingInfo = payload;
  },
  setSearchBookingInfo(state, payload) {
    state.searchBookingInfo = payload;
  },
  setRedirectTo(state, path) {
    state.redirectTo = path;
  },
  clearRedirectTo(state) {
    state.redirectTo = null;
  },
};

const actions = {
  handleDiscountCode({ commit, state }, { order, passenger, idx, saleCodeMap }) {
    passenger.leaveDiscountAppliedAmount = passenger.leavePriceAmount * saleCodeMap[passenger.leaveDiscountCode];

    if (state.searchBookingInfo.isRoundTrip) {
      passenger.returnDiscountCode = passenger.leaveDiscountCode;
      passenger.returnDiscountAppliedAmount = passenger.returnPriceAmount * saleCodeMap[passenger.returnDiscountCode];
    }

    commit('updatePassengerDiscount', { order, idx, passenger });
  },
  setRedirectTo({ commit }, path) {
    commit('setRedirectTo', path);
  },
  clearRedirectTo({ commit }) {
    commit('clearRedirectTo');
  },
};

const getters = {};

const booking = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default booking;
