<template>
  <div class="passenger-count-input flex-between" role="button" @click="toggleCabinPassengerCountLayer">
    <div class="cabin">
      {{ $t('labels.cabin') }}
      <p class="num" :class="cabinLengthClass">{{ cabinLength }}</p>
    </div>
    <div class="divide-line"><span></span></div>
    <div class="passenger">
      {{ $t('labels.passengers') }}
      <p class="num" :class="totalPassengerClass">{{ totalPassenger }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReCabinPassengerCountInput',
  emits: ['onClick'],
  components: {},
  props: {
    cabinLength: Number,
    totalPassenger: Number,
    isShow: Boolean,
  },
  computed: {
    cabinLengthClass() {
      return {
        'gray-color': !this.cabinLength,
      };
    },
    totalPassengerClass() {
      return {
        'gray-color': !this.totalPassenger,
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleCabinPassengerCountLayer() {
      this.$emit('onClick');
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.passenger-count-input {
  width: 100%;
  box-sizing: border-box;
  background: var(--White);
  height: 50px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100);
}

.cabin {
  padding-left: 16px;
}

.passenger {
  padding-right: 16px;
}

.cabin,
.passenger {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  justify-content: space-between;
  color: var(--Grey-grey-900);
}

.num {
  float: right;
}

.divide-line {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.divide-line > span {
  display: block;
  width: 1px;
  height: 28px;
  background: #dcdcdc;
}

.gray-color {
  color: var(--Grey-grey-200);
}

@media screen and (min-width: 768px) {
  .passenger-count-input {
    min-width: 80px;
  }
}
</style>
