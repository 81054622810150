<template>
  <div class="complete-wrapper">
    <div class="icon-wrapper">
      <CheckCircleIcon class="pc-icon" :size="60" />
      <CheckCircleIcon class="mobile-icon" :size="24" />
    </div>

    <div class="welcome-text">
      <div class="welcome-title">{{ $t('register.completed') }}</div>
      <div class="welcome-sub">
        <b>{{ $i18n.locale !== 'en' ? $t('panstarCruise') : '' }}</b
        >{{ $i18n.locale === 'ko' ? ' ' : '' }}<span class="gray-text"> {{ $t('register.becomeMember') }}</span>
      </div>
    </div>

    <div class="complete-info-wrapper">
      <div class="info-row">
        <div class="info-label">{{ $t('labels.name') }}</div>
        <div>{{ registerUserInfo?.name }}</div>
      </div>
      <div class="info-row">
        <div class="info-label">{{ $t('labels.userId') }}</div>
        <div>{{ registerUserInfo?.userId }}</div>
      </div>
    </div>

    <div class="compelete-btns">
      <ReCruiseButton v-if="redirectTo" class="complete-btn" @click="returnBooking">
        {{ $t('actions.returnBooking') }}
      </ReCruiseButton>
      <ReCruiseButton class="complete-btn" :theme="buttonTheme" @click="goHome">
        {{ $t('actions.goHome') }}
      </ReCruiseButton>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from 'vue-material-design-icons/CheckCircleOutline';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RegisterComplete',
  components: { CheckCircleIcon, ReCruiseButton },
  computed: {
    ...mapState('auth', ['registerUserInfo']),
    ...mapState('booking', ['redirectTo']),

    buttonTheme() {
      return this.redirectTo ? 'outline' : 'primary';
    },
  },
  methods: {
    ...mapActions('booking', ['clearRedirectTo']),

    goHome() {
      this.clearRedirectTo();
      this.$router.push('/');
    },
    returnBooking() {
      const redirectTo = this.redirectTo || '/';
      this.$router.push(redirectTo);
    },
  },
};
</script>

<style scoped>
.complete-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  color: var(--Black);
  margin-top: 20px;
}

.pc-icon,
.mobile-icon {
  color: var(--Blue-blue-600);
}

.pc-icon {
  display: none;
}

.mobile-icon {
  display: flex;
}

.welcome-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.welcome-title {
  font-size: 16px;
  font-weight: 700;
}

.welcome-sub {
  font-size: 10px;
}

.gray-text {
  color: #969696 !important;
}

.complete-info-wrapper {
  width: 100%;
  max-width: 604px;
  border-top: var(--default--border);
  font-size: 12px;
  font-weight: 400;
}

.info-row {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: var(--default--border);
}

.info-label {
  word-break: keep-all;
  width: 100px;
}

.compelete-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.complete-btn {
  width: 100%;
  height: 40px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .complete-wrapper {
    gap: 40px;
    margin-top: 0;
  }

  .pc-icon {
    display: flex;
  }

  .mobile-icon {
    display: none;
  }

  .welcome-title {
    font-size: 28px;
  }

  .welcome-sub {
    font-size: 18px;
  }

  .info-row {
    gap: 120px;
    padding: 20px;
  }

  .compelete-btns {
    gap: 17px;
    width: auto;
  }

  .complete-btn {
    width: 188px;
    height: 50px;
    font-size: 16px;
  }
}
</style>
