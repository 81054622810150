import api from '@/services/api';

const API_URL = '/api/cruiseUser';

class UserService {
  /** Login */
  async login(params) {
    try {
      await api.post(`${API_URL}/login`, params);

      return true;
    } catch (error) {
      throw error;
    }
  }

  /** Identity verification: Find ID/password */
  async checkUserExists(params) {
    try {
      const response = await api.post(`${API_URL}/checkExistUser`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Find ID: Return ID of matching user */
  async getUserId(params) {
    try {
      const response = await api.get(`${API_URL}/getUserId`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Password Finder: Issue temporary password for matching user */
  async getNewPassword(params) {
    try {
      const response = await api.put(`${API_URL}/getNewPassword`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Country Code */
  async getCountryCode(params) {
    try {
      const response = await api.get(`${API_URL}/getCountryCode`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Sign Up */
  async register(params) {
    try {
      const response = await api.post(`${API_URL}/register`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** ID duplicate check */
  async checkExistUserId(params) {
    try {
      const response = await api.get(`${API_URL}/checkExistUserId`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Identity verification: Mobile */
  async checkExistMobile(params) {
    try {
      const response = await api.post(`${API_URL}/checkExistMobile`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Identity verification: Email */
  async checkExistEmail(params) {
    try {
      const response = await api.post(`${API_URL}/checkExistEmail`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** User Info */
  async getUserInfo(params) {
    try {
      const response = await api.get(`${API_URL}/getCruiseUserInfo`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Edit User Info */
  async updateUserInfo(params) {
    try {
      const response = await api.put(`${API_URL}/put`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Change Password */
  async updatePassword(params) {
    try {
      const response = await api.post(`${API_URL}/updateUserPassword`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Token Validation */
  async isAuthenticated(user) {
    if (!user || !user.accessToken) {
      return false;
    }

    try {
    } catch (error) {
      return false;
    }
  }

  /** Verification of authentication number */
  async validate(params) {
    try {
      const response = await api.post(`${API_URL}/validate`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  /** Delete Account */
  async deleteAccount(params) {
    try {
      const response = await api.put(`${API_URL}/unSubscribe`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new UserService();
