<template>
  <div class="popup-background flex-center" :class="dimmedClass">
    <div class="popup-wrap">
      <div class="popup-content">
        <div class="popup-title">
          {{ $t('reservation.cancel.title.notAllowed') }}
        </div>

        <div class="content-list">
          <div>{{ cancelContent }}</div>

          <template v-if="showPassengerList">
            <br />
            <div class="passenger-list">
              <span class="bold">{{ $t('reservation.cancel.passengerNames') }}</span>
              {{ $i18n.locale !== 'en' ? '' : '&nbsp;' }}:
            </div>
            <div>{{ nameListString }}</div>
          </template>
        </div>
      </div>

      <div class="button-wrap">
        <ReCruiseButton class="bottom-btn" @click="close">{{ $t('actions.confirm') }}</ReCruiseButton>
      </div>
    </div>
  </div>
</template>

<script>
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'CancelFailedPopup',
  components: { ReCruiseButton },
  emits: ['close'],
  props: {
    status: {
      type: String,
      required: true, // ROUND_ALREADY_LEFT, ROUND_NOT_LEFT, PENDING_PAYMENT, ALREADY_CANCELED
    },
    dimmed: {
      type: Boolean,
      default: true,
    },
    nameList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dimmedClass() {
      return { clearDim: !this.dimmed };
    },
    cancelContent() {
      const contentMap = {
        ROUND_ALREADY_LEFT: this.$t('reservation.cancel.content.roundAlreadyLeft'),
        ROUND_NOT_LEFT: this.$t('reservation.cancel.content.roundNotLeft'),
        PENDING_PAYMENT: this.$t('reservation.cancel.content.pendingPayment'),
        ALREADY_CANCELED: this.$t('reservation.cancel.content.alreadyCanceled'),
      };
      return contentMap[this.status] || null;
    },
    showPassengerList() {
      return this.status !== 'PENDING_PAYMENT' && this.status !== 'ALREADY_CANCELED';
    },
    nameListString() {
      return this.nameList.join(', ');
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  z-index: var(--zLayoutLoading);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Backdrop-renewal);
  backdrop-filter: blur(4px);
  word-break: keep-all;
  padding: 0 20px;
}

.clearDim {
  background: transparent;
  backdrop-filter: none;
}

.popup-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  /* width: 310px; */
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.07), 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  overflow: auto;
  background: var(--White);
}

.popup-title {
  width: 100%;
  color: var(--Grey-grey-900, #333);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1.5px;
  white-space: pre-wrap;
}

.content-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: pre-wrap;
}

.content-list > div {
  font-size: 16px;
  font-weight: 400;
  word-break: auto-phrase;
  display: flex;
  line-height: 150%;
}

.popup {
  font-size: 16px;
  font-weight: 700;
}

.button-wrap {
  display: flex;
}

.bottom-btn {
  width: 100%;
  height: 55px;
  border-radius: 0;
}

.bold {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .popup-wrap {
    /* width: 357px; */
    width: 402px;
  }
}
</style>
