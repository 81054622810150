<template>
  <div class="table">
    <div class="table-header">
      <div v-for="{ key, label, style } in columns" :key="key" class="table-cell" :style="style">
        {{ $t(label) || label }}
      </div>
    </div>
    <div class="table-body">
      <div v-for="(row, index) in rows" :key="index" class="table-row">
        <div v-for="{ key, style } in columns" :key="key" class="table-cell" :style="style">
          <template v-if="$slots[key]">
            <slot :name="key" :item="row" :index="index" />
          </template>
          <template v-else>
            {{ row[key] }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CruiseTable',
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    textAlign: {
      type: String,
      default: 'start',
      validator: value => ['start', 'center', 'end'].includes(value),
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--White, var(--White));
  border-top: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  text-align: v-bind(textAlign);
}

.table-header,
.table-row {
  width: 100%;
  display: flex;
  padding: 0 20px;
  border-bottom: var(--default--border);
}

.table-cell {
  /* flex: 1; */
  padding: 13px 0;
  height: 40px;
  color: var(--Black);
  font-size: 12px;
  display: flex;
  align-items: center;
}
</style>
