import { createStore } from 'vuex';
import common from '@/store/modules/common';
import booking from '@/store/modules/booking';
import tour from '@/store/modules/tour';
import auth from '@/store/modules/auth';
import payment from '@/store/modules/payment';

export default createStore({
  modules: {
    common,
    booking,
    tour,
    auth,
    payment,
  },
});
