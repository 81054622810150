<template>
  <div class="wrapper">
    <!-- Main Contents -->
    <div class="main-contents-wrapper">
      <!-- Main Slide -->
      <MainSlider :bannerList="mainBannerList">
        <div class="modal-background" :class="positionUpClass"></div>

        <!-- Booking Search Area -->
        <div class="quick-search-wrap" :class="positionUpClass" :style="mobileImgBackground">
          <div class="quick-search-inner">
            <ReCruiseTabs theme="group" v-model:activeTab="activeTab">
              <ReCruiseTab :title="$t('cruise')">
                <ReCruiseSearchPanel v-model:isPositionUp="isCruisePositionUp" />
              </ReCruiseTab>
              <ReCruiseTab :title="$t('express')">
                <ReFastShipSearchPanel v-model:isPositionUp="isFastShipPositionUp" />
              </ReCruiseTab>
            </ReCruiseTabs>
          </div>
        </div>
      </MainSlider>

      <!-- Notice Banner -->
      <NoticeBanner />
    </div>

    <div class="contents-wrap">
      <!-- Operation Product Contents -->
      <CruiseSlider />

      <!-- Event Contents -->
      <EventContent />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { YYYYMMDD } from '@/const/const';
import { mapMutations } from 'vuex';
import { getLocalKey } from '@/utils/convert';
import { removeItem } from '@/utils/localStorage';

import MainSlider from '@/components/home/MainSlider.vue';
import NoticeBanner from '@/components/home/NoticeBanner.vue';
import CruiseSlider from '@/components/home/CruiseSlider.vue';
import EventContent from '@/components/home/EventContent.vue';
import ReCruiseTabs from '@/components/renewal/tab/ReCruiseTabs.vue';
import ReCruiseTab from '@/components/renewal/tab/ReCruiseTab.vue';
import ReCruiseSearchPanel from '@/components/renewal/home/ReCruiseSearchPanel.vue';
import ReFastShipSearchPanel from '@/components/renewal/home/ReFastShipSearchPanel.vue';
import throttle from '@/utils/throttle';

export default {
  name: 'HomeView',
  components: {
    MainSlider,
    NoticeBanner,
    CruiseSlider,
    EventContent,
    ReCruiseTabs,
    ReCruiseTab,
    ReCruiseSearchPanel,
    ReFastShipSearchPanel,
  },
  data() {
    return {
      activeTab: 0,

      isCruisePositionUp: false,
      isFastShipPositionUp: false,
      topPosition: '115px',

      mainBannerList: [
        {
          title: '열심히 달려온\n당신께 드리는 휴식',
          desc: '수험생 여러분, 수고하셨습니다!\n팬스타크루즈가 준비한 특별한 선물로 마음껏 힐링하세요.',
          imgUrlKo: '241108_slide_11exam-event.jpg',
          mobileImgUrlKo: '241108_m-slide_11exam-event.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/154',
          endDate: '20250124',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '241030_slide_12promotion.jpg',
          mobileImgUrlKo: '241030_m-slide_12promotion.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/153',
          endDate: '20241231',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '치어리더와 함께하는\n열정 가득 오사카크루즈',
          desc: '24년 11월 5일, TOP 치어리더와 함께하는 오사카 크루즈!\n치어리더들과 함께하는 열정적인 순간을 놓치지 마세요.',
          imgUrlKo: '241007_slide_11m-Cheerleader.jpg',
          mobileImgUrlKo: '241007_m-slide_11m-Cheerleader.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/150',
          endDate: '20241105',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '241002_slide_1-3panday.jpg',
          mobileImgUrlKo: '241002_m-slide_1-3panday.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/149',
          endDate: '20241009',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '240910_slide_onenight-cruise.jpg',
          mobileImgUrlKo: '240910_m-slide_onenight-cruise.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/146',
          endDate: '20241231',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '240830_slide_10th-promotion.jpg',
          mobileImgUrlKo: '240830_m-slide_10th-promotion.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/144',
          endDate: '20241031',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '240821_Shinhan_mslide.png',
          mobileImgUrlKo: '240821_Shinhan_mslide_Mobile.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '',
          link: 'https://www.shinhancard.com/pconts/html/benefit/event/1228910_2239.html',
          endDate: '20241031',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '240729_slide_9month-promotion.jpg',
          mobileImgUrlKo: '240729_m-slide_9month-promotion.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/129',
          endDate: '20240929',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '2407_slide_firework.jpg',
          mobileImgUrlKo: '2407_m-slide_firework.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/122',
          endDate: '20240712',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '2407_slide_PanDay10th-early-bird.jpg',
          mobileImgUrlKo: '2407_m-slide_PanDay10th-early-bird.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/109',
          endDate: '20240718',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner10.title'),
          desc: this.$t('mainBannerList.mainBanner10.desc'),
          imgUrlKo: '2406_slide_get-allright.jpg',
          mobileImgUrlKo: '2406_m-slide_get-allright.jpg',
          imgUrlJa: '2406_slide_get-allright.jpg', // 임시
          mobileImgUrlJa: '2406_m-slide_get-allright.jpg', // 임시
          imgUrlEn: '2406_slide_get-allright.jpg',
          mobileImgUrlEn: '2406_m-slide_get-allright.jpg',
          path: '/event/107',
          endDate: '20240719',
          endTime: '00:00',
          isOpenedKo: true,
          isOpenedJa: true, // 임시
          isOpenedEn: true,
        },
        {
          title: this.$t('mainBannerList.mainBanner9.title'),
          desc: this.$t('mainBannerList.mainBanner9.desc'),
          imgUrlKo: '2406_slide_cheerleader.jpg',
          mobileImgUrlKo: '2406_m-slide_cheerleader.jpg',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/105',
          endDate: '20240618',
          endTime: '00:00',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner8.title'),
          desc: this.$t('mainBannerList.mainBanner8.desc'),
          imgUrlKo: '2406_slide_34th-event.png',
          mobileImgUrlKo: '2406_m-slide_34th-event.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '2406_slide_34th-event(EN).jpg',
          mobileImgUrlEn: '2406_m-slide_34th-event.png',
          path: '/event/104',
          endDate: '20240819',
          endTime: '23:59',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: true,
        },
        // {
        //   title: this.$t('mainBannerList.mainBanner7.title'),
        //   desc: this.$t('mainBannerList.mainBanner7.desc'),
        //   imgUrlKo: '2406_DutyFreeSale_Main slide.png',
        //   mobileImgUrlKo: '2406_DutyFreeSale_m-slide.jpg',
        //   imgUrlJa: '',
        //   mobileImgUrlJa: '',
        //   imgUrlEn: '',
        //   mobileImgUrlEn: '',
        //   path: '/event/103',
        //   endDate: '',
        //   endTime: '',
        //   isOpenedKo: true,
        //   isOpenedJa: false,
        //   isOpenedEn: false,
        // },
        {
          title: this.$t('mainBannerList.mainBanner6.title'),
          desc: this.$t('mainBannerList.mainBanner6.desc'),
          imgUrlKo: '2405_slide_special offer 6m 1.png',
          mobileImgUrlKo: '2405_m-slide_special offer 6m 1.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/101',
          endDate: '20240630',
          endTime: '11:40',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner5.title'),
          desc: this.$t('mainBannerList.mainBanner5.desc'),
          imgUrlKo: '2405_slide_newpanstar.jpg',
          mobileImgUrlKo: '2405_m-slide_newpanstar 1.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/99',
          endDate: '20240519',
          endTime: '00:00',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner4.title'),
          desc: this.$t('mainBannerList.mainBanner4.desc'),
          imgUrlKo: '2404_slide_tsushima.png',
          mobileImgUrlKo: '2404_m-slide_tsushima.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/95',
          endDate: '',
          endTime: '',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner3.title'),
          desc: this.$t('mainBannerList.mainBanner3.desc'),
          imgUrlKo: '2404_slide_Military 1.png',
          mobileImgUrlKo: '2404_m-slide_Military 1.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/97',
          endDate: '',
          endTime: '',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: '',
          desc: '',
          imgUrlKo: '2404_slide_Tmembership.png',
          mobileImgUrlKo: '2404_m-slide_Tmembership.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/96',
          endDate: '',
          endTime: '',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
        {
          title: this.$t('mainBannerList.mainBanner1.title'),
          desc: this.$t('mainBannerList.mainBanner1.desc'),
          imgUrlKo: '2404_slide_familymonth.png',
          mobileImgUrlKo: '2404_m-slide_familymonth.png',
          imgUrlJa: '',
          mobileImgUrlJa: '',
          imgUrlEn: '',
          mobileImgUrlEn: '',
          path: '/event/98',
          endDate: '20240531',
          endTime: '00:00',
          isOpenedKo: true,
          isOpenedJa: false,
          isOpenedEn: false,
        },
      ],

      tempData: [
        {
          title: this.$t('mainBannerList.mainBanner10.title'),
          desc: this.$t('mainBannerList.mainBanner10.desc'),
          imgUrlKo: '2406_slide_get-allright.jpg',
          mobileImgUrlKo: '2406_m-slide_get-allright.jpg',
          imgUrlJa: '2406_slide_get-allright.jpg', // 임시
          mobileImgUrlJa: '2406_m-slide_get-allright.jpg', // 임시
          imgUrlEn: '2406_slide_get-allright.jpg',
          mobileImgUrlEn: '2406_m-slide_get-allright.jpg',
          path: '/event/107',
          endDate: '20240719',
          endTime: '00:00',
          isOpenedKo: true,
          isOpenedJa: true, // 임시
          isOpenedEn: true,
        },
      ],
    };
  },
  computed: {
    positionUpClass() {
      return this.isCruisePositionUp || this.isFastShipPositionUp ? 'position-up' : '';
    },
    mobileImgBackground() {
      const mobileImgUrlByLocale = getLocalKey('mobileImgUrl');
      const mobileImgUrl = this.mainBannerList[0][mobileImgUrlByLocale];

      return {
        backgroundImage: `url(${require(`@/assets/images/home/mainSlideMobile/${mobileImgUrl}`)})`,
      };
    },
  },
  methods: {
    ...mapMutations('booking', ['setSearchBookingInfo', 'setPastSearchBookingInfo']),

    init() {
      removeItem('searchBookingInfo');
      this.setSearchBookingInfo(null);
      this.setPastSearchBookingInfo(null);
      this.initBannerList();
    },
    initBannerList() {
      const isOpenedLocaleKey = getLocalKey('isOpened');
      this.mainBannerList = this.mainBannerList.filter(item => item[isOpenedLocaleKey]);

      this.removeExpiredBanners();

      if (this.mainBannerList.length === 0) {
        this.mainBannerList = this.tempData;
      } else if (this.mainBannerList.length > 10) {
        this.mainBannerList = this.mainBannerList.slice(0, 10);
      }
    },
    removeExpiredBanners() {
      const now = dayjs();

      this.mainBannerList = this.mainBannerList.filter(item => {
        if (!item.endDate && !item.endTime) return true;

        const endDateTime = dayjs(item.endDate + item.endTime, 'YYYYMMDDHH:mm');

        return now.isBefore(endDateTime);
      });
    },
    handleResize: throttle(function () {
      const windowHeight = window.innerHeight / 2 - 317;
      this.topPosition = Math.max(windowHeight, 115) + 317 + 'px';
    }, 500),
  },
  created() {
    this.init();
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.main-contents-wrapper {
  width: 100%;
  height: 100%;
}

.quick-search-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 84px 16px 24px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.quick-search-inner {
  display: flex;
  padding: 16px 16px 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 20px;
  background: var(--White);
}

/* Contents Area */
.contents-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 40px 0 30px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    font-size: 14px;
  }

  .modal-background.position-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    background: var(--Backdrop-renewal);
    z-index: 2;
  }

  .quick-search-wrap {
    position: absolute;
    padding: 0 16px;
    top: 61.2%;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    max-width: 1384px;
    transition: top 0.3s ease;
    z-index: 2;
    height: auto;
    background-image: none !important;
  }

  .quick-search-wrap.position-up {
    top: v-bind(topPosition);
    transform: translate(-50%, -50%);
  }

  .quick-search-inner {
    padding: 16px 30px 32px;
    border: 1px solid rgba(255, 255, 255, 0.63);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }

  /* Contents Area */
  .contents-wrap {
    padding: 58px 0 68px;
    gap: 68px;
  }
}
</style>
