<template>
  <div class="contents-wrapper" v-if="trip">
    <div class="detail-title">
      <h2 class="main-title">{{ $t('actions.viewDetails') }}</h2>
    </div>

    <div class="detail-voyage">
      <div>
        <span class="voyage-name">{{ trip.reservationMaster.category }}</span>
        <span class="vessel-name mobile">({{ trip.vessel.name }})</span>
      </div>

      <div>
        <span class="location">
          {{ getLocalText(trip.ports.leave.departure, 'name') }}
          <template v-if="isMulti">
            <ArrowIcon class="flex-center" :size="18" />
            <span>{{ getLocalText(trip.ports.leave.arrival, 'name') }}</span>
          </template>
        </span>
        <div class="port-icon flex-center">
          <SwapIcon v-if="isRound" :size="16" />
          <ArrowIcon v-else :size="14" />
        </div>
        <span class="location">
          <span v-if="isMulti"> {{ getLocalText(trip.ports.return.departure, 'name') }} </span>
          <span v-else> {{ getLocalText(trip.ports.leave.arrival, 'name') }} </span>
          <template v-if="isMulti">
            <ArrowIcon class="flex-center" :size="18" />
            <span>{{ getLocalText(trip.ports.return.arrival, 'name') }}</span>
          </template>
        </span>
      </div>

      <div class="departure-date pc">
        {{ $t('labels.departureDate') }}{{ colonByLocale
        }}{{ trip.reservationMaster.departureSchedule.departureExpectDate }}
      </div>

      <div class="arrival-date pc" v-if="isRound || isMulti">
        {{ $t('labels.returnDate') }}{{ colonByLocale
        }}{{ trip.reservationMaster?.returnSchedule?.departureExpectDate }}
      </div>

      <div class="vessel-name pc">{{ trip.vessel.name }}</div>
    </div>

    <div class="booking-contents-wrapper">
      <div class="header-wrapper">
        <b class="header-title">{{ $t('reservation.details') }}</b>
        <TextButton v-if="isShowCancelButton" class="cancel-btn" theme="grey" @click="toggleCancelReservationModal">
          {{ $t('labels.reservationCancel') }}
        </TextButton>
      </div>

      <div class="booking-details">
        <div class="reserver">
          <span class="bold">{{ $t('reservation.reserver') }}{{ colonByLocale }}</span
          >{{ user?.name }}
        </div>
        <div class="mileage">
          <span class="bold">{{ $t('mileage.earnedMileage') }}{{ colonByLocale }}</span
          >{{ convertCommaNumber(trip?.mileageAccumulated || 0) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
          }}{{ getReservationCurrency(trip.reservationMaster.reservation[0].reservationNumber) }}
        </div>
      </div>

      <div class="total-payment">
        <div class="total-label">{{ $t('payment.total') }}</div>
        <div class="total-info">
          <div class="total-price">
            {{ convertCommaNumber(getTotalPrice()) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
            }}{{ getReservationCurrency(trip.reservationMaster.reservation[0].reservationNumber) }}
          </div>
          <div class="total-mileage">
            ({{ $t('labels.mileage') }}{{ colonByLocale }}{{ convertCommaNumber(trip?.mileageUsed || 0)
            }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
            }}{{ getReservationCurrency(trip.reservationMaster.reservation[0].reservationNumber)
            }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;' }}{{ $t('actions.use') }})
          </div>
        </div>
      </div>

      <div class="booking-list-wrapper">
        <div class="booking-list" v-for="reservation in trip.reservationMaster.reservation">
          <div class="booking-number-wrapper">
            {{ $t('reservation.number') }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
            }}<span class="booking-number">{{ colonByLocale + reservation.reservationNumber }}</span>
          </div>

          <div class="booking-info mobile">
            <div class="booking-description">
              <div class="booking-name-customer">
                <div class="passenger-wrapper">
                  <div
                    class="passenger-info"
                    v-for="{
                      name,
                      englishName,
                      passportNumber,
                      mobile,
                      tickets,
                      sex,
                      passengerKind,
                    } in reservation.passengers"
                  >
                    <div class="info-item">
                      <div class="user-info">
                        <div class="user-name">
                          <span class="bold">{{ $t('labels.name') }}{{ colonByLocale }}</span
                          >{{ name }}
                        </div>
                        <div class="user-detail">
                          <img class="gender-icon" :src="getGenderIcon(sex)" alt="gender icon" />
                          <!-- TODO: 승객 종류 -->
                          <!-- <p>{{ passengerKind }}(장애인1급)</p> -->
                        </div>
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.phoneNumber') }}{{ colonByLocale }}</span
                        >{{ formatPhoneNumber(mobile) }}
                      </div>

                      <div v-if="routeCode === 'OSC' || routeCode === 'TSE'">
                        <span class="bold">{{ $t('profile.englishName') }}{{ colonByLocale }}</span
                        >{{ englishName }}
                      </div>
                      <div v-if="routeCode === 'OSC' || routeCode === 'TSE'">
                        <span class="bold">{{ $t('profile.passportNumber') }}{{ colonByLocale }}</span
                        >{{ passportNumber }}
                      </div>
                    </div>

                    <div class="info-item">
                      <div>
                        <span class="bold">
                          {{ getLocalText(trip.ports.leave.departure, 'name') }} ->
                          {{ getLocalText(trip.ports.leave.arrival, 'name') }}
                        </span>
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.departureDateTime') }}{{ colonByLocale }}</span>
                        {{ leaveDepartureDateTimeText }}
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.grade') }}{{ colonByLocale }}</span>
                        {{ getLocalText(tickets.leaveRooms.grade, 'gradeName') }}
                      </div>
                      <div v-if="routeCode === 'OSC'">
                        <span class="bold">{{ $t('fareDetails.extraService') }}{{ colonByLocale }}</span>
                        {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                      </div>

                      <div class="payment-status">
                        <span class="bold">{{ $t('labels.state') }}{{ colonByLocale }}</span>
                        <span :class="paymentStatusClass(tickets.leaveRooms.status)">{{
                          paymentStatus(tickets.leaveRooms.status)
                        }}</span>
                      </div>
                    </div>

                    <div class="info-item" v-if="isRound || isMulti">
                      <div>
                        <span class="bold">
                          {{ getLocalText(trip.ports.return.departure, 'name') }} ->
                          {{ getLocalText(trip.ports.return.arrival, 'name') }}
                        </span>
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.returnDateTime') }}{{ colonByLocale }}</span>
                        {{ returnDepartureDateTimeText }}
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.grade') }}{{ colonByLocale }}</span>
                        {{ getLocalText(tickets.returnRoom.grade, 'gradeName') }}
                      </div>
                      <div v-if="routeCode === 'OSC'">
                        <span class="bold">{{ $t('fareDetails.extraService') }}{{ colonByLocale }}</span>
                        {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                      </div>

                      <div class="payment-status">
                        <span class="bold">{{ $t('labels.state') }}{{ colonByLocale }}</span>
                        <span :class="paymentStatusClass(tickets.returnRoom.status)">{{
                          paymentStatus(tickets.returnRoom.status)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap_table pc">
            <table class="detail_table">
              <colgroup>
                <col width="110px" />
                <col width="80px" />
                <col width="162px" />

                <template v-if="routeCode === 'OSC' || routeCode === 'TSE'">
                  <col width="162px" />
                  <col width="162px" />
                </template>

                <col width="130px" />
                <col width="150px" />
                <col v-if="routeCode === 'OSC'" width="120px" />
                <col width="165px" />

                <template v-if="isRound || isMulti">
                  <col width="130px" />
                  <col width="150px" />
                  <col v-if="routeCode === 'OSC'" width="120px" />
                  <col width="165px" />
                </template>
              </colgroup>
              <thead>
                <tr>
                  <th rowspan="2">{{ $t('labels.name') }}</th>
                  <th rowspan="2" class="txt_no_pw">{{ $t('profile.gender') }}</th>
                  <th rowspan="2">{{ $t('labels.phoneNumber') }}</th>

                  <template v-if="routeCode === 'OSC' || routeCode === 'TSE'">
                    <th rowspan="2" class="txt_no_pw">{{ $t('profile.englishName') }}</th>
                    <th class="txt_no_pw" rowspan="2">{{ $t('profile.passportNumber') }}</th>
                  </template>

                  <th :colspan="routeCode === 'OSC' ? 4 : 3">
                    {{ getLocalText(trip.ports.leave.departure, 'name') }} ->
                    {{ getLocalText(trip.ports.leave.arrival, 'name') }}
                  </th>
                  <th v-if="isRound || isMulti" :colspan="routeCode === 'OSC' ? 4 : 3">
                    {{ getLocalText(trip.ports.return.departure, 'name') }} ->
                    {{ getLocalText(trip.ports.return.arrival, 'name') }}
                  </th>
                </tr>
                <tr>
                  <th>{{ $t('labels.departureDateTime') }}</th>
                  <th>{{ $t('labels.grade') }}</th>
                  <th v-if="routeCode === 'OSC'" class="txt_no_pw">{{ $t('fareDetails.extraService') }}</th>
                  <th>{{ $t('labels.state') }}</th>

                  <template v-if="isRound || isMulti">
                    <th>{{ $t('labels.returnDateTime') }}</th>
                    <th>{{ $t('labels.grade') }}</th>
                    <th v-if="routeCode === 'OSC'" class="txt_no_pw">{{ $t('fareDetails.extraService') }}</th>
                    <th>{{ $t('labels.state') }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="{
                    name,
                    englishName,
                    passportNumber,
                    mobile,
                    tickets,
                    sex,
                    passengerKind,
                  } in reservation.passengers"
                >
                  <th class="txt_center txt_no_pw">{{ name }}</th>
                  <td>{{ getGenderText(sex) }}</td>
                  <td>{{ formatPhoneNumber(mobile) }}</td>

                  <template v-if="routeCode === 'OSC' || routeCode === 'TSE'">
                    <td>{{ englishName }}</td>
                    <td>{{ passportNumber }}</td>
                  </template>

                  <td>{{ leaveDepartureDateTimeText }}</td>
                  <td>
                    {{ getLocalText(tickets.leaveRooms.grade, 'gradeName') }}
                  </td>
                  <td v-if="routeCode === 'OSC'">
                    {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                  </td>
                  <td :class="paymentStatusClass(tickets.leaveRooms.status)">
                    {{ paymentStatus(tickets.leaveRooms.status) }}
                  </td>

                  <template v-if="isRound || isMulti">
                    <td>{{ returnDepartureDateTimeText }}</td>
                    <td>
                      {{ getLocalText(tickets.returnRoom.grade, 'gradeName') }}
                    </td>
                    <td v-if="routeCode === 'OSC'">
                      {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                    </td>
                    <td :class="paymentStatusClass(tickets.returnRoom.status)">
                      {{ paymentStatus(tickets.returnRoom.status) }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="notice-wrapper">
      <b class="notice-title">{{ $t('labels.changeFeeGuide') }}</b>
      <ul class="notice-list">
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.ticketIssueTimeLimitNotice') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.ticketChangePolicyNotice') }}
        </li>
      </ul>
    </div>

    <CancelReservationModal
      v-model:showModal="isShowCancelReservationModal"
      :reservationDetail="trip"
      :isRound="isRound"
      :isMulti="isMulti"
      :routeCode="routeCode"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { YYYYMMDDHHMM } from '@/const/const';
import { convertCommaNumber, getLocalText, formatPhoneNumber, formatDate } from '@/utils/convert';
import ReservationService from '@/services/reservation';

import SwapIcon from 'vue-material-design-icons/SwapHorizontal';
import ArrowIcon from 'vue-material-design-icons/ArrowRight';
import CheckBold from 'vue-material-design-icons/CheckBold';
import CancelNotice from '@/components/booking/contents/complete/CancelNotice.vue';
import TextButton from '@/components/button/TextButton.vue';
import CancelReservationModal from '@/components/myPage/CancelReservationModal.vue';

export default {
  name: 'TripDetail',
  components: { SwapIcon, ArrowIcon, CheckBold, CancelNotice, TextButton, CancelReservationModal },
  data() {
    return {
      trip: null,
      isShowCancelReservationModal: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    id() {
      return this.$route.params.id;
    },
    isMulti() {
      const { ports } = this.trip;
      if (
        !!ports?.return?.departure?.code &&
        (ports.leave.departure?.code !== ports?.return?.arrival?.code ||
          ports.leave.arrival?.code !== ports?.return?.departure?.code)
      ) {
        return true;
      }
      return false;
    },
    isRound() {
      return !!this.trip.ports?.return?.departure?.code;
    },
    passengerNumberLabel() {
      const total = this.trip.reservationMaster.reservation[0].passengers.length;
      return total - 1 > 0 ? `${this.$t('labels.others')} ${total - 1}${this.$t('labels.people')}` : '';
    },
    routeCode() {
      return this.trip.reservationMaster.route.code;
    },
    colonByLocale() {
      const localeColonMap = {
        ko: ': ',
        en: ' : ',
        ja: '：',
      };

      return localeColonMap[this.$i18n.locale] || ' : ';
    },
    tripState() {
      return this.$route.query.tripState;
    },
    isShowCancelButton() {
      return false;
      const isFutureTrip = this.tripState === 'future';
      const { status } = this.trip.reservationMaster;
      const validRouteCodes = ['OSC', 'TSE'];

      return isFutureTrip && status !== 'EXP' && validRouteCodes.includes(this.routeCode);
    },
    leaveDepartureDateTimeText() {
      return this.formatDate(this.trip.reservationMaster.departureSchedule.eta, YYYYMMDDHHMM);
    },
    returnDepartureDateTimeText() {
      return this.formatDate(this.trip.reservationMaster?.returnSchedule?.eta, YYYYMMDDHHMM);
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.getTripDetail();
      },
    },
  },
  methods: {
    convertCommaNumber,
    getLocalText,
    formatPhoneNumber,
    formatDate,

    async getTripDetail() {
      try {
        const params = {
          id: this.id,
          userId: this.user.userId,
        };

        const { data } = await ReservationService.getMyTripDetail(params);
        this.trip = data;

        this.trip.reservationMaster.reservation.forEach(reservation => {
          reservation.passengers.forEach(passenger => {
            passenger.tickets.leaveRooms.isSelected = false;
            passenger.tickets.returnRoom.isSelected = false;
          });
        });
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
    getTotalPrice() {
      return this.trip.reservationMaster.reservation.reduce((acc, cur) => {
        return (
          acc +
          cur.passengers.reduce((acc, cur) => {
            return acc + cur.tickets.paymentDetail.total;
          }, 0)
        );
      }, 0);
    },
    getReservationCurrency(reservationNumber) {
      const reservationType = reservationNumber.substring(0, 2);
      return reservationType === 'WR' ? this.$t('currency.krw') : this.$t('currency.jpy');
    },
    getGenderText(gender) {
      const genderText = gender === 'M' ? this.$t('profile.male') : this.$t('profile.female');
      return genderText;
    },
    getGenderIcon(gender) {
      return require(`@/assets/images/icon/gender_icon_${gender}.svg`);
    },
    toggleCancelReservationModal() {
      this.isShowCancelReservationModal = true;
    },
    paymentStatusClass(status) {
      const statusMapping = {
        PND: 'payment-waiting',
        CMP: 'payment-completed',
        EXP: 'payment-expiration',
        CAN: 'payment-cancellation',
      };

      return status ? statusMapping[status] : '';
    },
    paymentStatus(status) {
      const statusMapping = {
        PND: 'payment.waiting',
        CMP: 'payment.completed',
        EXP: 'payment.expiration',
        CAN: 'payment.cancellation',
      };

      return status ? this.$t(statusMapping[status]) : '';
    },
  },
};
</script>

<style scoped>
.contents-wrapper {
  width: 100%;
  padding: 24px 16px 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-title {
  color: var(--Grey-grey-900, #333);
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.booking-info-wrapper {
  padding: 20px;
  border: var(--default--border);
}

.booking-contents-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 600;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.cancel-btn {
  font-size: 16px !important;
}

.payment-title {
  font-size: 14px;
}

.detail-voyage,
.booking-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-voyage {
  padding: 16px 0;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
}

.detail-voyage > div {
  display: flex;
  align-items: center;
  height: 28px;
}

.voyage-name {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-right: 4px;
}

.pc {
  display: none !important;
}

.port-icon {
  width: 28px;
  min-width: 28px;
  height: 28px;
  background: #f0f0f0;
  border-radius: 5px;
  margin: 0 10px;
}

.port-icon > span {
  color: #575757;
}

.departure-date {
  margin-right: 20px;
}

.booking-details {
  background: var(--Grey-grey-50, #f5f7f9);
  color: var(--Grey-grey-900, #333);
  font-size: 16px;
  padding: 16px;
  gap: 8px;
}

.booking-details > div {
  font-weight: 500;
}

.booking-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.booking-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.booking-number-wrapper {
  font-size: 20px;
  font-weight: 600;
}

.booking-number {
  font-weight: 500;
}

.booking-info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100);
  font-size: 14px;
}

.booking-description {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.booking-name-customer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.account-number {
  color: #f00;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.passenger-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.passenger-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 34px;
}

.passenger-info:not(:last-child) {
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
  padding-bottom: 16px;
}

.passenger-info > span {
  flex: 1 0;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.user-detail {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--Grey-grey-700);
  background: var(--White);
  color: var(--Grey-grey-700);
  font-size: 12px;
  font-weight: 400;
}

.gender-icon {
  width: 14px;
  height: 14px;
}

.location {
  display: flex;
  gap: 3px;
  font-weight: 500;
}

.location > * {
  font-weight: 500;
}

.payment-waiting {
  color: var(--Orange-orange-400, #f78865) !important;
}

.payment-completed {
  color: var(--Blue-blue-500, #446ceb) !important;
}

.payment-expiration {
  color: var(--Orange-orange-500, #f64852) !important;
}

.payment-cancellation {
  color: red !important;
}

.total-payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}

.total-label {
  font-size: 18px;
  font-weight: 600;
}

.total-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.total-price {
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
}

.total-mileage {
  font-size: 18px;
  font-weight: 400;
}

.notice-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notice-title {
  font-size: 18px;
  font-weight: 600;
}

.notice-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notice-list li {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
  word-break: keep-all;
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.check-icon {
  color: var(--Grey-grey-900, #333);
}

@media screen and (min-width: 768px) {
  .contents-wrapper {
    max-width: 1352px;
    padding: 60px 16px;
    gap: 0;
  }

  .main-title {
    font-size: 34px;
    margin-bottom: 32px;
  }

  .header-title {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 24px;
    margin-bottom: 0;
  }

  .cancel-btn {
    font-size: 20px !important;
  }

  .detail-voyage {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    padding: 32px 0;
    font-size: 20px;
    margin-bottom: 16px;
  }

  .voyage-name {
    font-size: 20px;
    margin-right: 0;
  }

  .mobile {
    display: none !important;
  }

  .pc {
    display: block !important;
  }

  .booking-list {
    gap: 16px;
  }

  .booking-contents-wrapper {
    margin-bottom: 40px;
  }
  .booking-details {
    height: 58px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    margin-bottom: 8px;
    font-size: 22px;
  }

  .booking-number-wrapper {
    display: flex;
    height: 50px;
    align-items: center;
    font-size: 22px;
  }

  .booking-info {
    flex-direction: row;
  }

  .booking-description {
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .booking-name-customer {
    justify-content: center;
    gap: 24px;
  }

  .passenger-info {
    flex-direction: row;
    padding: 0;
  }

  .passenger-info:not(:last-child) {
    border-bottom: none;
  }

  .total-payment {
    justify-content: flex-end;
    gap: 124px;
    padding: 0 16px 16px;
  }

  .total-label {
    font-size: 22px;
  }

  .total-info {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .total-price {
    font-size: 24px;
    line-height: 100%;
  }

  .total-mileage {
    font-size: 22px;
  }

  .notice-wrapper {
    margin-bottom: 80px;
    gap: 16px;
  }

  .notice-title {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 24px;
  }

  .notice-list {
    gap: 4px;
  }

  .notice-list li {
    font-size: 18px;
  }
}

/********************************************************
   component table
********************************************************/
.wrap_table {
  overflow: auto;
}

/********************************************************
  Detail Table
********************************************************/

.detail_table {
  width: 100%;
}
.wrap_table + .check_info {
  margin-top: 16px;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  white-space: nowrap;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tbody tr th.bg_th .txt_sub {
  font-size: 18px;
  font-weight: normal;
}
.detail_table tr td {
  white-space: nowrap;
  padding: 16px;
  font-size: 18px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table .txt_no_pw {
  white-space: nowrap;
}
.detail_table .txt_pw {
  white-space: pre-wrap;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
</style>
