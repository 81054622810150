<template>
  <div class="notice-wrapper">
    <b class="notice-title">{{ $t('labels.cancelFeeGuide') }}</b>
    <ul class="notice-list">
      <template v-if="routeCode === 'ONC'">
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.oneNightPolicy.before15Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.oneNightPolicy.before10Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>

          {{ $t('cancelNotice.oneNightPolicy.before7Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>

          {{ $t('cancelNotice.oneNightPolicy.before2Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.oneNightPolicy.before0Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          <span class="alert-text">
            {{ $t('cancelNotice.cancellationAndRefundGuide') }}
          </span>
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.changeFee') }}
        </li>
      </template>
      <template v-else>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.cruisePolicy.before3Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.cruisePolicy.before2Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.cruisePolicy.before1Days') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.cruisePolicy.before2Hours') }}
        </li>
        <li>
          <span class="check-icon"><CheckBold :size="13" /></span>
          {{ $t('cancelNotice.cruisePolicy.after2Hours') }}
        </li>
        <li class="alert-notice">
          <span class="alert">※</span>
          {{ $t('cancelNotice.refundPolicyNote') }}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import CheckBold from 'vue-material-design-icons/CheckBold';

export default {
  name: 'CancelNotice',
  components: { CheckBold },
  props: {
    routeCode: String,
  },
};
</script>

<style scoped>
.notice-wrapper {
  display: flex;
  flex-direction: column;
}

.notice-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.notice-list li {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
  word-break: keep-all;
  display: flex;
  align-items: center;
  gap: 4px;
}

.check-icon {
  color: var(--Grey-grey-900, #333);
}

.alert-notice {
  margin-top: 10px;
}

.alert {
  font-size: 14px;
  font-weight: 700;
}

.alert-text {
  color: var(--Orange-orange-500);
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .notice-wrapper {
    margin-bottom: 80px;
  }

  .notice-title {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 24px;
    margin-bottom: 0px;
  }
}
</style>
