import axios from 'axios';
import i18n from '@/languages/i18n';
import { startLoading, stopLoading } from '@/utils/loadingHelper';

axios.interceptors.request.use(
  config => {
    const currentLocale = i18n.global.locale;
    config.headers['Accept-Language'] = currentLocale;

    startLoading();

    return config;
  },
  error => {
    stopLoading();

    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => {
    stopLoading();

    if (response.data.code === 0) {
      return response.data;
    } else {
      return Promise.reject(response.data.message || 'Unknown Error');
    }
  },
  error => {
    stopLoading();
    
    return Promise.reject(error.response.data.message || error.message);
  },
);

export default axios;
