<template>
  <div class="list-wrapper">
    <div class="list-header" @click="toggleAccordion">
      <div class="header">
        <slot name="titleIcon"></slot>
        <div class="list-title">{{ title }}</div>
      </div>

      <template v-if="isArrowIcon">
        <template v-if="$slots.iconImg">
          <div :class="activeClass">
            <slot name="iconImg"></slot>
          </div>
        </template>

        <template v-else>
          <div class="arrow-icon" :class="activeClass">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_1799_15185)">
                <path
                  d="M7.41 8.59009L12 13.1701L16.59 8.59009L18 10.0001L12 16.0001L6 10.0001L7.41 8.59009Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_1799_15185">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </template>
      </template>
    </div>

    <div v-if="isShow" class="list-content">
      <slot name="contentIcon"></slot>
      <div class="list-desc">{{ desc }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionItem',
  emits: ['onClick'],
  components: {},
  props: {
    title: String,
    desc: String,
    isArrowIcon: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeClass() {
      return {
        active: this.isShow,
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleAccordion() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
  border-bottom: 1px solid var(--Grey-grey-200);
}

.list-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.header {
  display: flex;
  gap: 10px;
}

.list-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  color: var(--Grey-grey-300);
  cursor: pointer;
  transition: transform 0.5s ease, color 0.5s ease;
}

.arrow-icon.active {
  color: var(--Blue-blue-500);
  transform: rotate(180deg);
}

.list-content {
  display: flex;
  gap: 10px;
}

.list-desc {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
}

@media screen and (min-width: 768px) {
  .list-wrapper {
    padding: 28px 0;
    gap: 20px;
  }

  .list-header {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .header {
    gap: 15px;
  }

  .list-title {
    font-size: 20px;
  }

  .arrow-icon {
    width: 30px;
    height: 30px;
  }

  .arrow-icon svg{
    width: 30px;
    height: 30px;
  }

  .list-content {
    gap: 20px;
  }

  .list-desc {
    font-size: 18px;
  }
}
</style>
