<template>
  <div class="term-agreement-wrapper">
    <div class="term-agreement-header">
      <ReCruiseCheckbox class="label-title" v-model="isAgreed" :label="label" :required="required" :iconSize="24" />
    </div>
    <div class="term-content">
      {{ content }}
    </div>
  </div>
</template>

<script>
import ReCruiseCheckbox from '@/components/renewal/common/ReCruiseCheckbox.vue';

export default {
  name: 'TermAgreementItem',
  emits: ['update:modelValue'],
  components: { ReCruiseCheckbox },
  props: {
    modelValue: Boolean,
    required: Boolean,
    label: String,
    content: String,
  },
  data() {
    return {
      isAgreed: false,
    };
  },
  watch: {
    isAgreed(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue() {
      this.isAgreed = this.modelValue;
    },
  },
  methods: {},
};
</script>

<style scoped>
.term-agreement-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.term-agreement-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -1.5px;
}

.term-content {
  display: flex;
  height: 200px;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100);

  color: var(--Grey-grey-700);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  overflow: auto;
  white-space: pre-wrap;
}

@media screen and (min-width: 768px) {
  .term-agreement-wrapper {
    gap: 24px;
  }

  .label-title {
    font-size: 24px;
  }

  .term-content {
    gap: 20px;
  }

  .term-content {
    height: 300px;
  }
}
</style>
