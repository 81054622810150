<template>
  <ReCruiseModal :showModal="showModal" @update:showModal="updateShowModal" title="회원 필수 정보 업데이트">
    <template #content>
      <div class="txt-area">
        <div class="sub-txt">
          고객님 계정에서 하단의 필수정보가 누락된 것으로 확인됩니다.<br />
          필수정보를 모두 입력해주셔야 팬스타크루즈 웹사이트의 이용이 가능합니다.
        </div>
      </div>

      <div class="info-field-wrapper">
        <div class="info-field">
          <div class="info-label">이름<span class="required-star">*</span></div>
          <div class="input-width">
            <ReCruiseInput v-model="userInfo.name" placeholder="이름 입력" :isError="!userInfo.name" />

            <div v-if="messageList.name" class="input-message">{{ messageList.name }}</div>
          </div>
        </div>

        <div class="info-field">
          <div class="info-label">생년월일<span class="required-star">*</span></div>
          <div class="input-width">
            <ReCruiseInput
              v-model="userInfo.birthDay"
              placeholder="숫자만 입력 (YYYYMMDD)"
              maxLength="8"
              :isError="!userInfo.birthDay"
            />
            <div v-if="messageList.birthDay" class="input-message">{{ messageList.birthDay }}</div>
          </div>
        </div>
        <div class="info-field">
          <div class="info-label">성별<span class="required-star">*</span></div>
          <div class="input-field">
            <ReCruiseRadio v-model="userInfo.sex" label="남자" value="M" name="sex" />
            <ReCruiseRadio v-model="userInfo.sex" label="여자" value="F" name="sex" />
          </div>
        </div>
        <div class="info-field">
          <div class="info-label">연락처<span class="required-star">*</span></div>
          <div class="input-width number-wrapper">
            <RePhoneNumberAuth
              class="user-info-number"
              v-model:isVerified="isVerified"
              v-model:userPhoneNumber="userInfo.mobile"
              :isError="!userInfo.mobile"
              csInquiryMessage="(고객센터 문의: 1577-9996/9666)"
            />
            <div v-if="messageList.mobile" class="input-message">{{ messageList.mobile }}</div>
          </div>
        </div>
        <div class="info-field">
          <div class="info-label">이메일<span class="required-star">*</span></div>
          <div class="input-width">
            <div class="input-width email-wrapper">
              <div class="email-field">
                <ReCruiseInput
                  class="input-width email"
                  placeholder="이메일 입력"
                  v-model="emailId"
                  :isError="!emailId"
                />
                <span>@</span>
                <ReCruiseInput
                  class="input-width"
                  placeholder="직접입력"
                  v-model="emailDomain"
                  :readonly="emailReadonly"
                  :isError="!emailDomain"
                />
              </div>
              <ReCruiseSelect class="email-selector" v-model="emailTemplate" :options="emailList" />
            </div>
            <div v-if="!emailId || !emailDomain" class="input-message">{{ messageList.email }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">취소</ReCruiseButton>
      <ReCruiseButton class="confirm-btn" @click="updateUserInfo">업데이트</ReCruiseButton>
    </template>
  </ReCruiseModal>
</template>

<script>
import UserService from '@/services/user';
import { mapState } from 'vuex';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';
import ReCruiseRadio from '@/components/renewal/common/ReCruiseRadio.vue';
import ReCruiseSelect from '@/components/renewal/common/ReCruiseSelect.vue';
import RePhoneNumberAuth from '@/components/renewal/common/RePhoneNumberAuth.vue';

export default {
  name: 'UserInfoModal',
  components: {
    ReCruiseModal,
    ReCruiseButton,
    CruiseInputField,
    ReCruiseInput,
    ReCruiseRadio,
    ReCruiseSelect,
    RePhoneNumberAuth,
  },
  emits: ['update:showModal'],
  props: {
    showModal: Boolean,
    userId: String,
  },
  data() {
    return {
      userInfo: {
        name: '',
        birthDay: '',
        sex: '',
        mobile: '',
        email: '',
      },
      originUserInfo: {},

      isVerified: false,

      emailId: '',
      emailDomain: '',
      emailReadonly: false,
      emailTemplate: '직접입력',
      emailList: ['직접입력', 'naver.com', 'gmail.com', 'daum.net', 'hotmail.com'],
    };
  },
  watch: {
    emailTemplate(value) {
      const isDirect = value === '직접입력';
      this.emailReadonly = !isDirect;
      this.emailDomain = isDirect ? '' : value;
    },
  },
  computed: {
    ...mapState('auth', ['user']),

    mobileClass() {
      return window.innerWidth < 768 ? 'mobile' : '';
    },
    convertedEmail() {
      return this.emailId && this.emailDomain ? `${this.emailId}@${this.emailDomain}` : '';
    },
    messageList() {
      const messageList = {
        name: '이름을 입력해주세요.',
        birthDay: '생년월일을 입력해주세요.',
        mobile: '연락처를 입력해주세요.',
        email: '이메일을 입력해주세요.',
      };

      messageList.name = this.userInfo.name ? '' : messageList.name;
      messageList.birthDay = this.userInfo.birthDay ? '' : messageList.birthDay;
      messageList.mobile = this.userInfo.mobile ? '' : messageList.mobile;
      messageList.email = this.convertedEmail ? '' : messageList.email;

      return messageList;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      // await this.getUserInfo();
      this.setEmailAddress();
    },
    async getUserInfo() {
      try {
        const params = { userId: this.userId };
        const { data } = await UserService.getUserInfo(params);
        this.userInfo = data;
        this.originUserInfo = { ...data };
      } catch (error) {
        this.$alert({ content: error });
        this.isVerified = false;
      }
    },
    setEmailAddress() {
      this.emailId = this.userInfo?.email.split('@')[0];
      this.emailDomain = this.userInfo?.email.split('@')[1];
    },
    updateShowModal(value) {
      this.$store.dispatch('auth/logout');
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },

    infoValidation() {
      if (
        !this.userInfo.name.trim() ||
        !this.userInfo.birthDay ||
        !this.userInfo.sex ||
        !this.userInfo.mobile ||
        !this.convertedEmail
      ) {
        this.$alert({ content: '필수값을 모두 입력해주세요.' });
        return;
      }

      return true;
    },
    async updateUserInfo() {
      if (!this.infoValidation()) {
        return;
      }

      const isPhoneNumberChanged = this.originUserInfo.mobile !== this.userInfo.mobile;
      if (isPhoneNumberChanged && !this.isVerified) {
        this.$alert({ content: '휴대폰 번호 인증을 완료해 주세요.' });
        return;
      }

      try {
        const params = { ...this.userInfo, email: this.convertedEmail !== '@' ? this.convertedEmail : '' };
        await UserService.updateUserInfo(params);

        this.$alert({ content: '회원정보 업데이트가 완료되었습니다.\n다시 로그인 해주세요.' }).then(() => {
          window.location.reload();
        });

        this.closeModal();
      } catch (error) {
        this.$alert({ content: error });
      }
    },
  },
};
</script>

<style scoped>
.sub-txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.28px;
  border-bottom: 1px solid var(--Grey-grey-100);
  padding-bottom: 20px;
  margin-bottom: 20px;
  word-break: keep-all;
}

.highlight {
  color: var(--Orange-orange-300);
  font-weight: 700;
}

.border-none {
  border-bottom: 0;
  padding-top: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-btns {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  z-index: var(--zContentsPopover);
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

.info-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 123%;
}

.info-field {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.info-label {
  width: 17%;
  word-break: break-all;
}

.input-width {
  flex: 1 0;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
}

.email-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.email-field {
  display: flex;
  align-items: center;
  gap: 6px;
}

.email-selector {
  width: 100%;
  height: 40px;
}

.required-star {
  color: var(--Orange-orange-500);
}

.input-message {
  margin-top: 5px;
  color: var(--Orange-orange-500);
}

:deep(.number-wrapper .label-wrapper) {
  display: none;
}

@media screen and (min-width: 768px) {
  .sub-txt {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .border-none {
    padding-top: 25px;
  }

  .modal-btns {
    justify-content: flex-end;
    padding: 16px;
    gap: 16px;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--White);
    width: 30%;
    min-width: 600px;
    border-radius: 0 0 10px 10px;
  }

  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }

  .info-field-wrapper {
    height: 152%;
  }

  .info-field {
    padding: 0 20px;
  }

  .info-label {
    width: 28%;
  }

  :deep(.options-wrapper) {
    top: 45px;
  }
}
</style>
