<template>
  <div class="panel-wrapper">
    <div class="panel-left">
      <!-- Route Type Select -->
      <div ref="routeWrap" class="way-select-wrapper">
        <SelectModalOnlyMobile
          v-model="routeType"
          v-model:isOpen="isOpenRouteTypeOptions"
          valueProperty="isRoundTrip"
          :options="routeTypeList"
          :title="$t('labels.selectRouteType')"
          @onChange="initDateRangeByRouteType"
        >
          <template #option="{ item }">
            <span :title="$t(item.label)">{{ $t(item.label) }}</span>
          </template>
        </SelectModalOnlyMobile>
      </div>

      <!-- Departure/Arrival Select  -->
      <div class="port-select-wrapper flex-between" ref="portWrap">
        <SelectGroupModalOnlyMobile
          v-model="portState.from.departure"
          :isOpen="isOpenDepartureOptions"
          valueProperty="portCode"
          :options="departureList"
          :title="$t('labels.selectDeparture')"
          :placeholder="$t('labels.selectDeparture')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleDeparture"
          @onChange="selectDeparture"
        >
          <template #option="{ item }">
            <span :title="item.portName">{{ item.portName }}</span>
          </template>
        </SelectGroupModalOnlyMobile>

        <div class="port-icon flex-center">
          <img :src="fromPortArrowIcon" alt="arrow port icon" />
        </div>

        <SelectGroupModalOnlyMobile
          v-model="portState.from.arrival"
          :isOpen="isOpenArrivalOptions"
          valueProperty="portCode"
          :options="arrivalList"
          :title="$t('labels.selectArrival')"
          :placeholder="$t('labels.selectArrival')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleArrival"
          @onChange="selectArrival"
        >
          <template #option="{ item }">
            {{ item.portName }}
          </template>
        </SelectGroupModalOnlyMobile>
      </div>

      <!-- Departure/Arrival Select: Multi  -->
      <div v-if="routeType.tripType === 'multi'" class="port-select-wrapper flex-between" ref="multiWrap">
        <SelectGroupModalOnlyMobile
          v-model="portState.to.departure"
          :isOpen="isMultiDeparture"
          valueProperty="portCode"
          :options="arrivalList"
          :title="$t('labels.selectDeparture')"
          :placeholder="$t('labels.selectDeparture')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleMultiDeparture"
          @onChange="selectMultiDeparture"
        >
          <template #option="{ item }">
            <span :title="item.portName">{{ item.portName }}</span>
          </template>
        </SelectGroupModalOnlyMobile>

        <div class="port-icon flex-center">
          <img :src="toPortArrowIcon" alt="arrow port icon" />
        </div>

        <SelectGroupModalOnlyMobile
          v-model="portState.to.arrival"
          :isOpen="isMultiArrival"
          valueProperty="portCode"
          :options="multiArrivalList"
          :title="$t('labels.selectArrival')"
          :placeholder="$t('labels.selectArrival')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleMultiArrival"
          @onChange="selectMultiArrival"
        >
          <template #option="{ item }">
            <span :title="item.portName">{{ item.portName }}</span>
          </template>
        </SelectGroupModalOnlyMobile>
      </div>

      <!-- Seat/Passenger Select -->
      <div class="passenger-count-select-wrapper" ref="passengerCountWrap" @click="isClickPort">
        <RePassengerCountInput
          :totalPassenger="totalPassengerCount.total"
          :disabled="disableSelectDateDropbox"
          @onClick="togglePassengerCountLayer"
        />
      </div>

      <!-- Departure/Arrival Date Select -->
      <div class="date-select-wrapper" ref="calendarWrap" @click="isClickPort">
        <ReCruiseDatePicker
          v-model:dateRange="dateRange"
          :range="routeType.isRoundTrip"
          :label="dateLabel"
          :disabled="disableSelectDateDropbox"
          @update:dateRange="selectDate"
          @onClick="toggleCalendar"
        />
      </div>
    </div>

    <!-- Search Button -->
    <div class="panel-right">
      <ReCruiseButton class="search-btn" :disabled="isSearchDisabled" @click="goBookingPage">
        {{ $i18n.locale !== 'ja' ? $t('actions.reserve') : $t('actions.search') }}
      </ReCruiseButton>
    </div>
  </div>

  <!-- Seat/Passenger Layer -->
  <RePassengerCountModal
    v-model:showModal="isOpenPassengerCountLayer"
    :maxCount="maxCount"
    :modalTitle="$t('labels.selectPassengers')"
    typeText="labels.passengers"
    :noticeList="noticeList"
    :passengerTypeCountList="passengerTypeCountList"
    @selectedConfirm="togglePassengerCountLayer"
  />

  <!-- Calendar Layer -->
  <ReCalendarModal
    v-model:showModal="isOpenCalendar"
    v-model:dateRange="dateRange"
    :range="routeType.isRoundTrip"
    :dateRange="dateRange"
    :allowedDate="filteredScheduleList"
    @update:dateRange="selectDate"
    :label="dateLabel"
    :disabled="disableSelectDateDropbox"
    :doubleCalendar="true"
    @selectedConfirm="toggleCalendar"
  />
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { YYYYMMDD } from '@/const/const';

import ReservationService from '@/services/reservation';
import GradeService from '@/services/grade';
import ReCruiseDatePicker from '@/components/renewal/common/ReCruiseDatePicker.vue';
import RePassengerCountInput from '@/components/renewal/common/RePassengerCountInput.vue';
import ReCalendarModal from '@/components/renewal/home/ReCalendarModal.vue';
import RePassengerCountModal from '@/components/renewal/home/RePassengerCountModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import SelectModalOnlyMobile from '@/components/renewal/modal/SelectModalOnlyMobile.vue';
import SelectGroupModalOnlyMobile from '@/components/renewal/modal/SelectGroupModalOnlyMobile.vue';

const defaultPassenger = { adult: 1, child: 0, infant: 0 };

export default {
  name: 'ReFastShipSearchPanel',
  emits: ['update:isPositionUp'],
  props: {
    isPositionUp: Boolean,
  },
  components: {
    ReCruiseDatePicker,
    RePassengerCountInput,
    ReCalendarModal,
    RePassengerCountModal,
    ReCruiseButton,
    SelectModalOnlyMobile,
    SelectGroupModalOnlyMobile,
  },
  data() {
    return {
      routeType: { isRoundTrip: true, tripType: 'multi', label: 'labels.multiRoute' },
      routeTypeList: [
        {
          isRoundTrip: true,
          tripType: 'roundTrip',
          label: 'labels.roundTrip',
        },
        {
          isRoundTrip: false,
          tripType: 'oneWay',
          label: 'labels.oneWay',
        },
        {
          isRoundTrip: true,
          tripType: 'multi',
          label: 'labels.multiRoute',
        },
      ],
      currentDate: dayjs().format(YYYYMMDD),
      departureList: [],
      arrivalList: [],
      multiArrivalList: [],

      portState: {
        from: {
          departure: null,
          arrival: null,
        },
        to: {
          departure: null,
          arrival: null,
        },
      },

      searchState: { isRoundTrip: true, tripType: 'roundTrip' },

      dateRange: { from: null, to: null },
      dateLabel: { from: 'labels.goingDay', to: 'labels.comingDay' },

      isOpenRouteTypeOptions: false, // Route Type Layer
      isOpenDepartureOptions: false, // Departure Select Layer
      isOpenArrivalOptions: false, // Arrival Select Layer
      isOpenCalendar: false, // Departure/Arrival Date Select Calendar
      isOpenPassengerCountLayer: false, // Cabel/Passenger Select Layer

      // multi
      isMultiDeparture: false,
      isMultiArrival: false,

      scheduleList: [],
      departureSchedule: [],

      passengerTypeCountList: [{ ...defaultPassenger }],
      maxCount: 1,

      voyageNo: { leaveVoyageNo: null, returnVoyageNo: null },
      schedule: { from: null, to: null },

      availableGradeList: null,

      passengerInfo: {
        type: '',
        name: { value: '', required: true, error: false, message: 'passenger.name' },
        country: { value: '', required: true, error: false, message: 'profile.nationality' },
        year: { value: '', required: true, error: false, message: 'time.year' },
        month: { value: '', required: true, error: false, message: 'time.month' },
        day: { value: '', required: true, error: false, message: 'time.day' },
        sex: { value: '', required: true, error: false, message: 'profile.gender' },
        firstNameEn: { value: '', required: true, error: false, message: 'profile.firstName' },
        lastNameEn: { value: '', required: true, error: false, message: 'profile.lastName' },
        passportNumber: { value: '', required: true, error: false, message: 'profile.passportNumber' },
        // countryNumber: { value: '', required: true, error: false, message: 'profile.countryCode' },
        mobile: { value: '', required: true, error: false, message: 'labels.phoneNumber' },
        address: '',
        email: '',
        emailDomain: '',
        emailTemplate: '',

        leaveGradeCode: '',
        leavePriceAmount: 0,
        leaveDiscountCode: '',
        leaveDiscountAppliedAmount: 0,

        leaveMileageAmount: 0,
        leaveShuttleAmount: 0,

        returnGradeCode: '',
        returnPriceAmount: 0,
        returnDiscountCode: '',
        returnDiscountAppliedAmount: 0,

        // fast ship
        leaveSeatNumber: '',
        returnSeatNumber: '',
      },
      noticeList: ['reservation.guide.express.passengersAge', 'reservation.guide.express.fishingAndCycling'],

      needsPortSelection: false,
      isInitDate: false,
      isChangedFromPort: false,
    };
  },
  watch: {
    passengerTypeCountList: {
      handler() {
        this.dateRange = { from: null, to: null };
      },
      deep: true,
    },
    'routeType.tripType'(newValue, oldValue) {
      if (newValue !== oldValue && this.isChangedFromPort) {
        this.portState.to.departure = null;
        this.portState.to.arrival = null;
        this.isChangedFromPort = false;
      }
    },
    'portState.from': {
      handler() {
        if (this.portState.to.departure && this.portState.to.arrival) {
          this.isChangedFromPort = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    totalPassengerCount() {
      return this.passengerTypeCountList.reduce((acc, cur) => {
        Object.keys(cur).forEach(key => {
          acc[key] = (acc[key] || 0) + cur[key];
          acc.total = (acc.total || 0) + cur[key];
        });
        return acc;
      }, {});
    },
    canSearch() {
      const isSchedule = this.routeType.isRoundTrip ? this.dateRange.from && this.dateRange.to : this.dateRange.from;
      const { from, to } = this.portState;
      const isPort = this.routeType.isRoundTrip ? from.departure && from.arrival : from.departure;
      const isMulti = this.routeType.tripType === 'multi' ? to.departure && to.arrival : true;

      return isSchedule && isPort && isMulti;
    },
    isSearchDisabled() {
      return !this.canSearch;
    },
    isPortSelectionComplete() {
      const isMulti = this.routeType.tripType === 'multi';
      const { from, to } = this.portState;

      const isPortComplete = port => port.departure && port.arrival;

      if (isMulti) {
        return isPortComplete(from) && isPortComplete(to);
      } else {
        return isPortComplete(from);
      }
    },
    disableSelectDateDropbox() {
      if (this.dateRange.from && this.dateRange.to) {
        return false;
      }
      return !this.dateRange.from || !this.dateRange.to;
    },
    filteredScheduleList() {
      return this.scheduleList?.map(schedule => schedule.departureExpectDt);
    },
    fromPortArrowIcon() {
      return require(`@/assets/images/icon/${this.getFromPortArrowIconFileName()}.svg`);
    },
    toPortArrowIcon() {
      return require(`@/assets/images/icon/${this.getToPortArrowIconFileName()}.svg`);
    },
  },
  methods: {
    ...mapMutations('booking', ['updateSearchBookingInfo']),

    async getDepartureList() {
      try {
        const { data } = await ReservationService.getDeparturePortList({ voyageType: 'fastShip' });
        this.departureList = data;
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    handleAnimation(elementRef, condition) {
      if (condition) {
        let element = this.$refs[elementRef];
        element.classList.add('shake-animation');
        this.needsPortSelection = true;

        setTimeout(() => {
          element.classList.remove('shake-animation');
          this.needsPortSelection = false;
        }, 500);
      }
    },
    isClickPort() {
      this.isLayoutUp = !this.isLayoutUp;

      this.handleAnimation('portWrap', !this.portState.from.departure || !this.portState.from.arrival);
      if (this.routeType.tripType === 'multi') {
        this.handleAnimation('multiWrap', !this.portState.to.departure || !this.portState.to.arrival);
      }
    },
    initDateRangeByRouteType() {
      this.dateRange = { from: null, to: null };
      this.schedule = { from: null, to: null };
    },
    toggleDeparture() {
      this.isOpenDepartureOptions = !this.isOpenDepartureOptions;
      this.isOpenArrivalOptions = false;
    },
    toggleArrival() {
      if (!this.portState.from.departure) {
        this.toggleDeparture();
        return;
      }
      this.isOpenDepartureOptions = false;
      this.isOpenArrivalOptions = !this.isOpenArrivalOptions;
    },
    toggleMultiDeparture() {
      if (this.routeType.tripType === 'multi' && !this.portState.from.departure) return;

      this.isMultiDeparture = !this.isMultiDeparture;
      this.isMultiArrival = false;
    },
    toggleMultiArrival() {
      if (this.routeType.tripType === 'multi' && !this.portState.from.departure) return;

      if (!this.portState.to.departure) {
        this.toggleMultiDeparture();
        return;
      }
      this.isMultiDeparture = false;
      this.isMultiArrival = !this.isMultiArrival;
    },
    togglePassengerCountLayer() {
      if (!this.isPortSelectionComplete) {
        this.isOpenPassengerCountLayer = false;
        return;
      }
      this.isOpenPassengerCountLayer = !this.isOpenPassengerCountLayer;
      this.$emit('update:isPositionUp', this.isOpenPassengerCountLayer);
    },
    handleClickOutside(e) {
      if (!this.$refs.routeWrap.contains(e.target)) {
        this.isOpenRouteTypeOptions = false;
      }

      if (!this.$refs.portWrap.contains(e.target)) {
        this.isOpenDepartureOptions = false;
        this.isOpenArrivalOptions = false;
      }

      if (!this.$refs.multiWrap?.contains(e.target)) {
        this.isMultiDeparture = false;
        this.isMultiArrival = false;
      }

      if (!this.$refs.calendarWrap.contains(e.target)) {
        if (!this.isInitDate) this.isOpenCalendar = false;
        this.isInitDate = false;
      }

      if (!this.$refs.passengerCountWrap.contains(e.target)) {
        this.isOpenPassengerCountLayer = false;
      }

      if (!this.isOpenCalendar && !this.isOpenPassengerCountLayer) {
        this.$emit('update:isPositionUp', false);
      }
    },
    selectDeparture() {
      const { tripType } = this.routeType;
      const { from, to } = this.portState;

      const isMultiPortCompleted =
        tripType === 'multi' && from.departure && from.arrival && (to.departure || to.arrival);

      this.dateRange = { from: null, to: null };
      this.isOpenDepartureOptions = false;
      this.isOpenArrivalOptions = true;
      from.arrival = null;
      this.arrivalList = from.departure.portInfo;

      if (isMultiPortCompleted) {
        to.departure = null;
        to.arrival = null;
      }
    },
    async selectArrival() {
      this.isOpenArrivalOptions = false;
      this.departureSchedule = await this.getScheduleList(
        this.portState.from.departure.portCode,
        this.portState.from.arrival.portCode,
      );
      this.scheduleList = this.departureSchedule;
    },
    selectMultiDeparture() {
      this.dateRange = { from: null, to: null };
      this.isMultiDeparture = false;
      this.isMultiArrival = true;
      this.portState.to.arrival = null;
      this.multiArrivalList = this.departureList.find(
        item => item.portCode === this.portState.to.departure.portCode,
      ).portInfo;
    },
    async selectMultiArrival() {
      this.isMultiArrival = false;
    },
    findSchedule(date) {
      return this.scheduleList.find(schedule => date.isSame(schedule.departureExpectDt, 'date'));
    },
    async handleCabinListRequest(from, to, fromSchedule, toSchedule) {
      const { isRoundTrip, tripType } = this.routeType;
      const params = {
        leaveVoyageNumber: fromSchedule?.voyageNo || null,
        returnVoyageNumber: toSchedule?.voyageNo || null,
      };

      try {
        await this.getAvailableSeatList(params.leaveVoyageNumber, params.returnVoyageNumber);
      } catch (error) {
        if (!this.isInitDate) this.isInitDate = true;

        if (from && !to) {
          this.dateRange = { from: null, to: null };

          this.voyageNo.leaveVoyageNo = null;
          if (this.voyageNo.returnVoyageNo) this.voyageNo.returnVoyageNo = null;

          this.schedule.from = null;
          if (this.schedule.to) this.schedule.to = null;

          if (tripType === 'multi' || isRoundTrip) {
            this.scheduleList = this.departureSchedule;
          }
        }

        if (from && to) {
          this.dateRange.to = null;

          this.voyageNo.returnVoyageNo = null;

          this.schedule.to = null;

          if (tripType === 'multi') {
            const { departure, arrival } = this.portState.to;
            this.scheduleList = await this.getScheduleList(departure.portCode, arrival.portCode, from.format(YYYYMMDD));
          } else if (isRoundTrip) {
            const { departure, arrival } = this.portState.from;
            this.scheduleList = await this.getScheduleList(arrival.portCode, departure.portCode, from.format(YYYYMMDD));
          }
        }

        this.$alert({ content: error });
      }
    },
    async selectDate(dateRange) {
      const { from, to } = dateRange;
      const { isRoundTrip, tripType } = this.routeType;

      if (from && !to) {
        const fromSchedule = this.findSchedule(from);
        this.schedule.from = fromSchedule;
        this.voyageNo.leaveVoyageNo = fromSchedule.voyageNo;

        if (tripType === 'multi') {
          const { departure, arrival } = this.portState.to;
          this.scheduleList = await this.getScheduleList(departure.portCode, arrival.portCode, from.format(YYYYMMDD));
        } else if (isRoundTrip) {
          const { departure, arrival } = this.portState.from;
          this.scheduleList = await this.getScheduleList(arrival.portCode, departure.portCode, from.format(YYYYMMDD));
        }

        await this.handleCabinListRequest(from, to, fromSchedule);
      }

      if (from && to) {
        const toSchedule = this.findSchedule(to);
        this.schedule.to = toSchedule;
        this.voyageNo.returnVoyageNo = toSchedule.voyageNo;
        this.scheduleList = this.departureSchedule;

        await this.handleCabinListRequest(from, to, this.schedule.from, toSchedule);
      }
    },
    async getScheduleList(departurePortCode, arrivalPortCode, date = this.currentDate) {
      try {
        const query = { date, departurePort: departurePortCode, arrivalPort: arrivalPortCode };

        const { data } = await ReservationService.getAvailableSchedule(query);
        return data;
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
    async getAvailableSeatList(leaveVoyageNumber, returnVoyageNumber) {
      try {
        const query = {
          leaveVoyageNumber,
          returnVoyageNumber,
          totalPassengers: this.totalPassengerCount.total,
        };
        const { data } = await GradeService.getAvailableSeatList(query);
        this.availableGradeList = data;
      } catch (error) {
        throw error;
      }
    },
    async goBookingPage() {
      try {
        if (!this.canSearch) {
          this.$alert({ content: this.$t('messages.setSearchConditions') });
          return;
        }

        await this.getAvailableSeatList(this.voyageNo.leaveVoyageNo, this.voyageNo.returnVoyageNo);

        this.updateSearchBookingInfo({
          ...this.voyageNo,
          schedule: this.schedule,
          routeCode: 'TSE',
          category: 'fastShip',
          bookingType: 'fastShip',
          bookingName: this.portState.from.departure.voyageName,
          portState: this.portState,
          isRoundTrip: this.routeType.isRoundTrip,
          tripType: this.routeType.tripType,
          dateRange: this.dateRange,
          passengerTypeCountList: this.passengerTypeCountList.map(item => {
            const passenger = [];

            for (const key in item) {
              for (let i = 0; i < item[key]; i++) {
                const info = JSON.parse(JSON.stringify(this.passengerInfo));
                info.type = key;
                passenger.push(info);
              }
            }
            return { ...item, passenger };
          }),
          availableGradeList: this.availableGradeList,
        });

        const redirect = '/booking/fast-ship/grade/from';
        this.$router.push({ path: '/agreement/daemado', query: { redirect } });
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
    toggleCalendar() {
      if (!this.isPortSelectionComplete) {
        this.isOpenCalendar = false;
        return;
      }

      this.isOpenCalendar = !this.isOpenCalendar;
      this.$emit('update:isPositionUp', this.isOpenCalendar);
    },
    getFileName(baseName, isPortSelectionRequired) {
      return isPortSelectionRequired ? `${baseName}_red` : baseName;
    },
    getFromPortArrowIconFileName() {
      const isRoundTrip = this.routeType.isRoundTrip && this.routeType.tripType !== 'multi';
      const baseFileName = isRoundTrip ? 'swap_icon' : 'arrow_right_icon';
      const isFromPortSelection = this.portState.from.departure && this.portState.from.arrival;
      return this.getFileName(baseFileName, this.needsPortSelection && !isFromPortSelection);
    },
    getToPortArrowIconFileName() {
      return this.getFileName('arrow_right_icon', this.needsPortSelection);
    },
  },
  mounted() {
    this.getDepartureList();
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  font-size: 16px;
}

.panel-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
}

/* Route Type Select */
.way-select-wrapper {
  width: 100%;
  font-size: 16px;
}

/* Departure/Arrival Date Select */
.date-select-wrapper {
  width: 100%;
  cursor: pointer;
}

/* Departure/Arrival Port, Passengers Common */
.port-select-wrapper {
  width: 100%;
  box-sizing: border-box;
  background: var(--White);
  height: 50px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100);
}

.departure-select,
.arrival-select,
.passenger {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding-left: 16px;
}

.departure-select > label,
.arrival-select > label {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 52px;
  left: 0;
  width: 125px;
  border-radius: 5px;
  border-top: 0.5px solid #f0f0f0;
  border-bottom: 0.5px solid #f0f0f0;
  background: var(--White);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: var(--zContentsPopover);
  overflow: hidden;
}

.dropdown-content.right {
  left: auto;
  right: 0;
}

.dropdown-item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  color: var(--Blue-blue-500);
  background: var(--Blue-blue-50);
}

.active-cruise {
  color: var(--Blue-blue-500);
}

/* Departure/Arrival Port Icon */
.port-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
}

/* Seat/Passenger Select */
.passenger-count-select-wrapper {
  position: relative;
  width: 100%;
}

.passenger {
  justify-content: space-between;
  padding-right: 16px;
  color: var(--Grey-grey-900);
}

.passenger-count-select-wrapper .num {
  float: right;
}

.divide-line {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.divide-line > span {
  display: block;
  width: 1px;
  height: 28px;
  background: #dcdcdc;
}

/* /* Search Button */
.search-btn {
  width: 100%;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .panel-wrapper {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    gap: 16px;
  }

  .panel-left {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 0;
    width: calc(100% - 200px);
  }

  .way-select-wrapper {
    max-width: 130px;
  }

  :deep(.current-calendar),
  :deep(.next-calendar) {
    width: 521px;
    margin: 32px 0;
  }

  .passenger-count-select-wrapper {
    position: inherit;
    max-width: 104px;
  }

  .panel-right {
    width: 200px;
  }

  .search-btn {
    width: 100%;
    max-width: 200px;
  }

  .date-select-wrapper {
    min-width: 264px;
  }
}
</style>
