import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index';

// layouts
import MainContainer from '@/layouts/MainContainer.vue';

// pages
import HomeView from '@/views/HomeView.vue';

import TourView from '@/views/TourView.vue';

import EventView from '@/views/EventView.vue';
import EventList from '@/components/event/EventList.vue';
import EventDetail from '@/components/event/EventDetail.vue';

import MyPageView from '@/views/MyPageView.vue';
import TripDetail from '@/components/myPage/TripDetail.vue';

import UserInfoView from '@/views/UserInfoView.vue';

import LoginView from '@/views/LoginView.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import LoginSearch from '@/components/login/LoginSearch.vue';

import RegisterView from '@/views/RegisterView.vue';
import RegisterTerms from '@/components/register/RegisterTerms.vue';
import RegisterDetails from '@/components/register/RegisterDetails.vue';
import RegisterComplete from '@/components/register/RegisterComplete.vue';

import SupportView from '@/views/SupportView.vue';

import Notice from '@/components/support/notice/Notice.vue';
import NoticeDetail from '@/components/support/notice/NoticeDetail.vue';

import PersonalQna from '@/components/support/qna/PersonalQna.vue';
import GroupBookingQna from '@/components/support/qna/GroupBookingQna.vue';

import Faq from '@/components/support/faq/Faq.vue';

import AgreementView from '@/views/AgreementView.vue';
import OsakaAgreement from '@/components/booking/cruise/OsakaAgreement.vue';
import DaemadoAgreement from '@/components/booking/fastShip/DaemadoAgreement.vue';

// information
import InformationView from '@/views/InformationView.vue';
import OsakaProduct from '@/components/information/product/osaka/OsakaProduct.vue';
import OsakaPrice from '@/components/information/product/osaka/OsakaPrice.vue';
import OsakaProcedure from '@/components/information/product/osaka/OsakaProcedure.vue';
import OsakaVehicle from '@/components/information/product/osaka/OsakaVehicle.vue';
import OsakaBaggage from '@/components/information/product/osaka/OsakaBaggage.vue';

import DaemadoProduct from '@/components/information/product/daemado/DaemadoProduct.vue';
import DaemadoPrice from '@/components/information/product/daemado/DaemadoPrice.vue';
import DaemadoProcedure from '@/components/information/product/daemado/DaemadoProcedure.vue';
import DaemadoBaggage from '@/components/information/product/daemado/DaemadoBaggage.vue';

import OneNightProduct from '@/components/information/product/oneNight/OneNightProduct.vue';
import OneNightPrice from '@/components/information/product/oneNight/OneNightPrice.vue';
import OneNightProcedure from '@/components/information/product/oneNight/OneNightProcedure.vue';

import DreamFacility from '@/components/information/facility/dream/DreamFacility.vue';
import DreamRoom from '@/components/information/facility/dream/DreamRoom.vue';

import DaemadoFacility from '@/components/information/facility/daemado/DaemadoFacility.vue';
import DaemadoSeat from '@/components/information/facility/daemado/DaemadoSeat.vue';

import SbPaymentSuccess from '@/components/payment/SbPaymentSuccess.vue';

import NotFound from '@/views/NotFound.vue';

// lazy load
const PassengerInfo = () => import('@/components/booking/contents/BookingPassengerInfo.vue');
const BookingComplete = () => import('@/components/booking/contents/BookingComplete.vue');

// cruise
const CruiseBooking = () => import('@/components/booking/cruise/CruiseBooking.vue');
const CruiseTourInfo = () => import('@/components/booking/cruise/CruiseTourInfo.vue');
const CruiseGrade = () => import('@/components/booking/cruise/CruiseGrade.vue');
const CruiseSchedule = () => import('@/components/booking/cruise/CruiseSchedule.vue');
const CruisePayment = () => import('@/components/booking/cruise/CruisePayment.vue');
const CruiseExtraService = () => import('@/components/booking/cruise/CruiseExtraService.vue');

// fast ship
const FastShipBooking = () => import('@/components/booking/fastShip/FastShipBooking.vue');
const FastShipTourInfo = () => import('@/components/booking/fastShip/FastShipTourInfo.vue');
const FastShipGrade = () => import('@/components/booking/fastShip/FastShipGrade.vue');
const FastShipSchedule = () => import('@/components/booking/fastShip/FastShipSchedule.vue');
const BookingSeatSelect = () => import('@/components/booking/fastShip/BookingSeatSelect.vue');
const FastShipPayment = () => import('@/components/booking/fastShip/FastShipPayment.vue');

const allowSpecificPaths = (_, from, next, allowedPaths = ['/'], redirect) => {
  if (allowedPaths.includes(from.path)) {
    next();
  } else {
    next(redirect || allowedPaths[0]);
  }
};

const allowSpecificNames = (_, from, next, allowedNames = ['home'], redirect) => {
  if (allowedNames.includes(from.name)) {
    next();
  } else {
    next(redirect || allowedNames[0]);
  }
};

const routes = [
  {
    path: '/',
    component: MainContainer,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { isNavigation: true },
      },
      {
        path: '/tour',
        name: 'tour',
        component: TourView,
        meta: { isNavigation: true },
      },
      {
        path: '/product',
        name: 'product',
        redirect: '/product/osaka/info',
        component: InformationView,
        meta: { isNavigation: true },
        children: [
          {
            path: 'osaka',
            children: [
              { path: 'info', component: OsakaProduct },
              { path: 'price', component: OsakaPrice },
              { path: 'procedure', component: OsakaProcedure },
              { path: 'vehicle', component: OsakaVehicle },
              { path: 'baggage', component: OsakaBaggage },
            ],
          },
          {
            path: 'daemado',
            children: [
              { path: 'info', component: DaemadoProduct },
              { path: 'price', component: DaemadoPrice },
              { path: 'procedure', component: DaemadoProcedure },
              { path: 'baggage', component: DaemadoBaggage },
            ],
          },
          {
            path: 'one-night',
            children: [
              { path: 'info', component: OneNightProduct },
              { path: 'price', component: OneNightPrice },
              { path: 'procedure', component: OneNightProcedure },
            ],
          },
        ],
      },
      {
        path: '/facility',
        name: 'facility',
        redirect: '/facility/dream/info',
        component: InformationView,
        meta: { isNavigation: true },
        children: [
          {
            path: 'dream',
            children: [
              { path: 'info', component: DreamFacility },
              { path: 'room', component: DreamRoom },
            ],
          },
          {
            path: 'daemado',
            children: [
              { path: 'info', component: DaemadoFacility },
              { path: 'seat', component: DaemadoSeat },
            ],
          },
        ],
      },
      {
        path: '/event',
        name: 'event',
        component: EventView,
        meta: { isNavigation: true },
        children: [
          { path: '', name: 'event-list', component: EventList },
          { path: ':id', name: 'event-detail', component: EventDetail },
        ],
      },
      {
        path: '/my-page',
        name: 'my-page',
        component: MyPageView,
        meta: { isNavigation: true, requiresAuth: true },
      },
      {
        path: 'trip/:id',
        name: 'trip-detail',
        component: TripDetail,
        meta: { isNavigation: true },
      },
      {
        path: '/user-info',
        name: 'user-info',
        component: UserInfoView,
        meta: { isNavigation: true, requiresAuth: true },
      },
      {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: { isNavigation: false },
        children: [
          { path: '', name: 'login-form', component: LoginForm },
          { path: 'search/:type', name: 'login-search', component: LoginSearch },
        ],
      },
      {
        path: '/register',
        name: 'register',
        redirect: '/register/terms',
        component: RegisterView,
        meta: { isNavigation: false },
        children: [
          { path: 'terms', name: 'terms', component: RegisterTerms },
          {
            path: 'details',
            name: 'details',
            component: RegisterDetails,
            beforeEnter: (...arg) => allowSpecificPaths(...arg, ['/register/terms']),
          },
          {
            path: 'complete',
            name: 'complete',
            component: RegisterComplete,
            beforeEnter: (...arg) => allowSpecificPaths(...arg, ['/register/details'], '/'),
          },
        ],
      },
      {
        path: '/booking',
        name: 'booking',
        children: [
          {
            path: 'cruise',
            component: CruiseBooking,
            meta: { isNavigation: false, bookingType: 'cruise', isBooking: true },
            children: [
              { path: 'tour', name: 'cruise-tour', component: CruiseTourInfo },
              { path: 'grade', redirect: '/booking/cruise/grade/0' },
              { path: 'grade/:order', name: 'cruise-grade', component: CruiseGrade },
              { path: 'schedule/:direction', name: 'cruise-schedule', component: CruiseSchedule },
              { path: 'passenger/:order', name: 'cruise-passenger', component: PassengerInfo },
              { path: 'extra/:order', name: 'cruise-extra', component: CruiseExtraService },
              { path: 'payment', name: 'cruise-payment', component: CruisePayment },
              { path: 'complete', name: 'cruise-complete', component: BookingComplete, meta: { requiresAuth: true } },
            ],
          },
          {
            path: 'fast-ship',
            component: FastShipBooking,
            meta: { isNavigation: false, bookingType: 'fastShip', isBooking: true },
            children: [
              { path: 'tour', name: 'fast-ship-tour', component: FastShipTourInfo },
              { path: 'grade', redirect: '/booking/fast-ship/grade/from' },
              { path: 'grade/:direction', name: 'fast-ship-grade', component: FastShipGrade },
              { path: 'schedule/:direction', name: 'fast-ship-schedule', component: FastShipSchedule },
              { path: 'passenger/:order', name: 'fast-ship-passenger', component: PassengerInfo },
              { path: 'seat/:direction', name: 'fast-ship-seat', component: BookingSeatSelect },
              { path: 'payment', name: 'fast-ship-payment', component: FastShipPayment },
              {
                path: 'complete',
                name: 'fast-ship-complete',
                component: BookingComplete,
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: 'sb-payment/success',
            component: SbPaymentSuccess,
            meta: { isNavigation: false, requiresAuth: true, isBooking: true },
          },
        ],
      },
      {
        path: '/support',
        name: 'support',
        redirect: '/support/notice',
        component: SupportView,
        meta: { isNavigation: true },
        children: [
          {
            path: 'notice',
            children: [
              { path: '', name: 'notice', component: Notice },
              { path: ':id', name: 'notice-detail', component: NoticeDetail },
            ],
          },
          {
            path: 'qna',
            children: [
              { path: '', name: 'qna', component: PersonalQna },
              { path: 'group-booking', name: 'group-booking', component: GroupBookingQna },
            ],
          },
          { path: 'faq', name: 'faq', component: Faq },
        ],
      },
      {
        path: '/agreement',
        name: 'agreement',
        redirect: '/agreement/osaka',
        component: AgreementView,
        beforeEnter: (...arg) => allowSpecificNames(...arg, ['home', 'tour', 'cruise-tour', 'fast-ship-tour'], '/'),
        meta: { isNavigation: false },
        children: [
          { path: 'osaka', name: 'osaka', component: OsakaAgreement },
          { path: 'daemado', name: 'daemado', component: DaemadoAgreement },
        ],
      },
    ],
  },
  // {
  //   path: '/not-found',
  //   name: 'NotFound',
  //   component: NotFound,
  // },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  await store.dispatch('auth/isLoggedIn');
  const isAuthenticated = store.state.auth.status.loggedIn;

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login-form', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
