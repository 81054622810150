import api from '@/services/api';

const API_URL = '/api/route';

class RouteService {
  async getRouteList() {
    try {
      const response = await api.get(`${API_URL}/getList`);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new RouteService();
