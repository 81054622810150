<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('feeAndDiscount.title') }}</div>
      <div class="txt_det">{{ $t('feeAndDiscount.oneNightIntro') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <!-- Tabs -->
      <ul class="component_tabs">
        <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }">
          <a href="javascript:" @click="currentTab = index">{{ $t(tab) }}</a>
        </li>
      </ul>
      <!-- //Tabs -->
      <div class="wrap_tabs">
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 0">
          <div class="wrap_table">
            <table class="component_table">
              <colgroup>
                <col width="166px" />
                <col width="*" />
                <col width="*" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomType') }}</th>
                  <th>
                    {{ $t('feeAndDiscount.roomType.loyal') }}
                    <div class="det_view">OCEAN VIEW</div>
                  </th>
                  <th>
                    {{ $t('feeAndDiscount.roomType.deluxe') }}
                    <div class="det_view">OCEAN VIEW</div>
                  </th>
                  <!-- <th>
                    {{ $t('feeAndDiscount.roomType.junior') }}
                    <div class="det_view">OCEAN VIEW</div>
                  </th> -->
                  <th>
                    {{ $t('feeAndDiscount.roomType.family') }}
                    <div class="det_view">OCEAN VIEW</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.oneNightFee') }}</th>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '350,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '250,000' }) }}</td>
                  <!-- <td>{{ $t('feeAndDiscount.perPerson', { price: '230,000' }) }}</td> -->
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '200,000' }) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.koreaStraitFee') }}</th>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '390,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '280,000' }) }}</td>
                  <!-- <td>{{ $t('feeAndDiscount.perPerson', { price: '250,000' }) }}</td> -->
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '220,000' }) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomOption') }}</th>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.loyal.item1') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.loyal.item2') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.loyal.item3') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.loyal.item4') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.loyal.item5') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.deluxe.item1') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.deluxe.item2') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.deluxe.item3') }}</li>
                    </ul>
                  </td>
                  <!-- <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.junior.item1') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.junior.item2') }}</li>
                    </ul>
                  </td> -->
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.family.item1') }}</li>
                      <li>{{ $t('feeAndDiscount.oceanInfo.roomOptions.family.item2') }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomCategory') }}</th>
                  <td>{{ $t('feeAndDiscount.oceanInfo.roomCategory.loyal') }}</td>
                  <td>{{ $t('feeAndDiscount.oceanInfo.roomCategory.deluxe') }}</td>
                  <!-- <td>{{ $t('feeAndDiscount.oceanInfo.roomCategory.junior') }}</td> -->
                  <td>{{ $t('feeAndDiscount.oceanInfo.roomCategory.family') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.person') }}</th>
                  <td>2</td>
                  <td>2</td>
                  <!-- <td>2</td> -->
                  <td>3</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomCount') }}</th>
                  <td>2</td>
                  <td>26</td>
                  <!-- <td>6</td> -->
                  <td>19</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomLocation') }}</th>
                  <td>A</td>
                  <td>A</td>
                  <!-- <td>A</td> -->
                  <td>B</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- box Info -->
          <div class="box_info">
            <ul class="list_check">
              <li>
                {{ $t('feeAndDiscount.oceanInfo.annotation.item6.part1')
                }}<b>{{ $t('feeAndDiscount.oceanInfo.annotation.item6.part2') }}</b
                >{{ $t('feeAndDiscount.oceanInfo.annotation.item6.part3') }}
              </li>
            </ul>
          </div>
          <!-- //box Info -->
          <ol class="view_detail">
            <li>
              <div class="area_detail">
                <div class="label_title">{{ $t('feeAndDiscount.inExclusionInfo.title') }}</div>
                <!-- Note -->
                <div class="view_note">
                  <!-- ui Note -->
                  <div class="ui_note">
                    <em class="img_note_check"></em>
                    <div class="txt_note">
                      <div class="label_note">{{ $t('feeAndDiscount.inExclusionInfo.inclusions') }}</div>
                      {{ $t('feeAndDiscount.inExclusionInfo.includedDetail') }}
                    </div>
                  </div>
                  <!-- //ui Note -->
                  <!-- ui Note -->
                  <div class="ui_note">
                    <em class="img_note_check2"></em>

                    <div class="txt_note">
                      <div class="label_note">{{ $t('feeAndDiscount.inExclusionInfo.exclusions') }}</div>
                      <div>
                        <div class="note_det">
                          <b>{{ $t('feeAndDiscount.inExclusionInfo.excludedDetail.desc') }}</b
                          >{{ $t('feeAndDiscount.inExclusionInfo.excludedDetail.price') }}
                        </div>
                        <div class="note_det">{{ $t('feeAndDiscount.inExclusionInfo.excludedDetail.etc') }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- //ui Note -->
                </div>
                <!-- //Note -->
                <div class="txt_detail">
                  <ul class="list_dot">
                    <li>{{ $t('feeAndDiscount.inExclusionInfo.extraCharge') }}</li>
                    <li>{{ $t('feeAndDiscount.inExclusionInfo.addCharge') }}</li>
                  </ul>
                </div>
              </div>
            </li>
          </ol>
        </div>
        <!-- //Tab contents  -->
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 1">
          <div class="wrap_table">
            <table class="component_table">
              <colgroup>
                <col width="166px" />
                <col width="*" />
                <col width="*" />
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomType') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standardA') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standardACombi') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standard1Bunk') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standard2Bunk') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standardTwin') }}</th>
                  <th>{{ $t('feeAndDiscount.roomType.standardGroup') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.oneNightFee') }}</th>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '160,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '180,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '150,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '130,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '150,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '130,000' }) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.koreaStraitFee') }}</th>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '180,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '200,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '170,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '150,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '170,000' }) }}</td>
                  <td>{{ $t('feeAndDiscount.perPerson', { price: '150,000' }) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomOption') }}</th>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardA') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardACombi') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardBunk') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardBunk') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardTwin') }}</li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list_dot">
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardGroup.ocean') }}</li>
                      <li>{{ $t('feeAndDiscount.insideInfo.roomOptions.standardGroup.inside') }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.person') }}</th>
                  <td>4</td>
                  <td>4</td>
                  <td>2</td>
                  <td>4</td>
                  <td>2</td>
                  <td>{{ $t('feeAndDiscount.groupRoom') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomCategory') }}</th>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardA') }}</td>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardACombi') }}</td>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardBunk') }}</td>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardBunk') }}</td>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardTwin') }}</td>
                  <td>{{ $t('feeAndDiscount.insideInfo.roomCategory.standardGroup') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomCount') }}</th>
                  <td>34</td>
                  <td>8</td>
                  <td>4</td>
                  <td>2</td>
                  <td>22</td>
                  <td>{{ $t('feeAndDiscount.groupRoom') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('feeAndDiscount.tableHeader.roomLocation') }}</th>
                  <td>A, B</td>
                  <td>A</td>
                  <td>B</td>
                  <td>B</td>
                  <td>B</td>
                  <td>A, B</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- //Tab contents  -->
      </div>

      <ul class="component_tabs">
        <li v-for="(tab, index) in tabs2" :key="index" :class="{ active: currentTab2 === index }">
          <a href="javascript:" @click="currentTab2 = index">{{ $t(tab) }}</a>
        </li>
      </ul>
      <div class="wrap_tabs">
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab2 === 0">
          <div class="view_detail">
            <div class="wrap_table">
              <table class="detail_table">
                <colgroup>
                  <col width="15%" />
                  <col width="15%" />
                  <col width="15%" />
                  <col width="33%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('feeAndDiscount.oneNightDiscountPolicy.headerList.division') }}</th>
                    <th>{{ $t('feeAndDiscount.oneNightDiscountPolicy.headerList.target') }}</th>
                    <th>{{ $t('feeAndDiscount.oneNightDiscountPolicy.headerList.price') }}</th>
                    <th>{{ $t('feeAndDiscount.oneNightDiscountPolicy.headerList.document') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="bg_th txt_center">{{ $t('feeAndDiscount.oneNightDiscountPolicy.division.infant') }}</th>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.target.infant') }}</td>
                    <td>{{ $t('fareDetails.free') }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.document.infant') }}</td>
                  </tr>
                  <tr>
                    <th class="bg_th txt_center" rowspan="2">
                      {{ $t('feeAndDiscount.oneNightDiscountPolicy.division.disability') }}
                    </th>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.target.disability', { num: '1~3' }) }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.discountAmount', { num: '30' }) }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.document.disability1to3Grade') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.target.disability', { num: '4~6' }) }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.discountAmount', { num: '20' }) }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.document.disability4to6Grade') }}</td>
                  </tr>
                  <tr>
                    <th class="bg_th txt_center">
                      {{ $t('feeAndDiscount.oneNightDiscountPolicy.division.nationalMerit') }}
                    </th>
                    <td></td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.discountAmount', { num: '20' }) }}</td>
                    <td>{{ $t('feeAndDiscount.oneNightDiscountPolicy.document.nationalMerit') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- //Tab contents  -->

        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab2 === 1">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.oneNightRefundPolicy.roomPrice') }}</div>
                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="50%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.refundRequestDate') }}</th>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.refund') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.beforeNDays', { num: 15 }) }}</th>
                        <td>90%</td>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.beforeNDays', { num: 10 }) }}</th>
                        <td>80%</td>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.beforeNDays', { num: 7 }) }}</th>
                        <td>70%</td>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.beforeNDays', { num: 2 }) }}</th>
                        <td>50%</td>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.oneNightRefundPolicy.today') }}</th>
                        <td>0%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab2 === 2">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.voyageCancellation.label') }}</div>
                <div class="txt_message">
                  {{ $t('feeAndDiscount.voyageCancellation.detail') }}
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OneNightPrice',
  data() {
    return {
      currentTab: 0,
      tabs: ['feeAndDiscount.tabs.ocean', 'feeAndDiscount.tabs.inside'],
      currentTab2: 0,
      tabs2: [
        'feeAndDiscount.tabs.discountPolicy',
        'feeAndDiscount.tabs.refundPolicy',
        'feeAndDiscount.tabs.voyageCancel',
      ],
    };
  },
};
</script>
<style scoped>
/********************************************************
   component tabs
********************************************************/
.tab_content {
  padding-bottom: 60px;
}
.component_tabs {
  margin-bottom: 60px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #446ceb;
}
.component_tabs li {
  flex: auto;
  width: 100%;
}
.component_tabs li + li {
  border-left: 1px solid #446ceb;
}
.component_tabs li a {
  display: block;
  line-height: 68px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #446ceb;
  font-weight: 600;
}
.component_tabs li.active a {
  background-color: #446ceb;
  color: #fff;
}
@media (max-width: 767px) {
  .component_tabs {
    margin-bottom: 24px;
  }
  .component_tabs li a {
    line-height: 48px;
    font-size: 16px;
  }
}

/********************************************************
   component table
********************************************************/
.wrap_table {
  width: 100%;
  overflow: auto;
}
.component_table {
  width: 100%;
  white-space: nowrap;
}

.component_table tr th {
  padding: 26px 0;
  height: 100px;
  text-align: center;
  border: 1px solid #dddfe2;
  border-left: none;
  background-color: #f5f7f9;
  font-size: 20px;
  color: #686d72;
  font-weight: 600;
  word-break: auto-phrase;
}
.component_table tr td:last-of-type,
.component_table tr th:last-of-type {
  border-right: none;
}
.component_table tr td {
  padding: 26px 0;
  height: 100px;
  text-align: center;
  font-size: 20px;
  color: #686d72;
  border: 1px solid #dddfe2;
  white-space: pre;
}
.component_table tr th .det_view {
  color: #f5a13e;
  font-size: 14px;
  font-weight: normal;
}
.component_table tr td .list_dot li {
  font-size: 20px;
  color: #686d72;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component_table tr td .list_dot li:before {
  display: inline-block;
  margin-right: 13px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #b6bbbf;
  content: '';
}
.list_dot + .wrap_table {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .component_table {
    white-space: nowrap;
  }
  .component_table tr td,
  .component_table tr th {
    font-size: 16px;
    padding: 16px 13px;
    height: 70px;
  }
  .component_table tr th .det_view {
    font-size: 12px;
  }
  .component_table tr td .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .component_table tr td .list_dot li:before {
    display: none;
  }
}

/********************************************************
  Detail Table
********************************************************/
.detail_table {
  width: 100%;
}
.detail_table + .txt_detail {
  margin-top: 20px;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  font-size: 20px;
  color: #686d72;
  font-weight: 600;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tr td {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
  white-space: nowrap;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  white-space: nowrap;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .detail_table tbody tr th.bg_th {
    font-size: 16px;
  }
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    /* text-align: left; */
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
}

/********************************************************
   box Info
********************************************************/
.box_info {
  margin-top: 60px;
  padding: 54px 60px;
  display: flex;
  background-color: #fffae6;
}
.box_info + .view_detail {
  margin-top: 60px;
}
.box_info:before {
  display: inline-block;
  margin-right: 30px;
  width: 51px;
  height: 45px;
  background: url('@/assets/images/information/icon/icon_info.svg') no-repeat;
  content: '';
  flex: none;
}
.box_info .list_check {
  white-space: pre-wrap;
}
.box_info .list_check li {
  padding-left: 33px;
  line-height: 32px;
  position: relative;
  color: #686d72;
  font-size: 20px;
  font-weight: 500;
}
.box_info .list_check li b {
  color: #f56a3e;
  font-weight: 600;
}
.box_info .list_check li:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
  margin-right: 12px;
  width: 21px;
  height: 29px;
  background: url('@/assets/images/information/icon/icon_check.svg') no-repeat;
  content: '';
}

@media (max-width: 767px) {
  .box_info {
    padding: 32px 16px;
    margin-top: 24px;
    flex-direction: column;
    align-items: center;
  }
  .box_info + .view_detail {
    margin-top: 24px;
  }
  .box_info:before {
    margin: 0 0 24px 0;
    width: 41px;
    height: 36px;
    background-size: 100% 100%;
  }
  .box_info .list_check li {
    padding-left: 29px;
    font-size: 16px;
    line-height: 26px;
  }
  .box_info .list_check li + li {
    margin-top: 16px;
  }
  .box_info .list_check li:before {
    top: 5px;
    width: 17px;
    height: 24px;
    background-size: 100%;
  }
}

.view_detail {
  display: flex;
}
.view_detail li {
  width: 100%;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail > li .txt_message {
  font-size: 22px;
  line-height: 31px;
  color: #505458;
  font-weight: 600;
  white-space: pre-wrap;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
.view_detail .area_detail .label_title .txt_sub {
  font-size: 20px;
  font-weight: 500;
  color: #304da7;
}
.view_detail .area_detail .txt_detail {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li b {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }
  .view_detail .area_detail .txt_detail {
    font-size: 16px;
    line-height: 20px;
  }
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_dot li .list_sub {
    font-size: 16px;
  }
  .view_detail .area_detail + .area_detail {
    padding: 60px 0 0 0;
  }
  .view_detail .area_detail > li .txt_message {
    font-size: 16px;
    line-height: 22px;
  }
}

.view_note {
  display: flex;
  margin-bottom: 32px;
}
.view_note .ui_note {
  flex: auto;
  display: flex;
  width: 100%;
  align-items: center;
}
.view_note .img_note_check2,
.view_note .img_note_check {
  display: inline-block;
  flex: none;
  width: 200px;
  height: 200px;
  margin-right: 50px;
  background: url('@/assets/images/information/icon/img_note.svg') no-repeat;
}
.view_note .img_note_check2 {
  background: url('@/assets/images/information/icon/img_note2.svg') no-repeat;
}
.view_note .txt_note {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
}
.view_note .txt_note .note_det {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_note .txt_note .note_det b {
  font-weight: 600;
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_note .txt_note .note_det + .note_det {
  margin-top: 10px;
}
.view_note .txt_note .label_note {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #505458;
  line-height: 31px;
}
@media (max-width: 767px) {
  .view_note {
    flex-direction: column;
  }
  .view_note .ui_note + .ui_note {
    margin-top: 16px;
  }
  .view_note .ui_note {
    flex-direction: column;
  }
  .view_note .txt_note {
    text-align: center;
  }
  .view_note .txt_note .label_note {
    font-size: 20px;
  }
  .view_note .txt_note {
    font-size: 16px;
    line-height: 24px;
  }
  .view_note .img_note_check,
  .view_note .img_note_check2 {
    margin: 0 0 16px 0;
  }
  .view_note .txt_note .note_det b,
  .view_note .txt_note .note_det {
    font-size: 16px;
    line-height: 19px;
  }
}
</style>
