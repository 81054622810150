<template>
  <div class="flex-column details-container">
    <div class="flex-column details-wrapper">
      <div class="flex-column details-header">
        <div class="sub-title">{{ $t('register.inputUserInfo') }}</div>
        <div class="title-description"><span class="required-star">*</span>{{ $t('messages.requiredField') }}</div>
      </div>
      <div class="details-field">
        <!-- <div class="category-title">{{ $t('user.info') }}</div> -->
        <div class="input-fields-wrapper">
          <div class="detail-wrapper">
            <div class="name">
              <CruiseInputField :label="$t('labels.name')" required>
                <div class="input-field">
                  <CruiseInput class="input-width" :placeholder="$t('labels.inputName')" v-model="userInfo.name" />
                </div>
              </CruiseInputField>
            </div>

            <div class="id">
              <CruiseInputField :label="$t('labels.userId')" :helperText="$t('validation.userId')" required>
                <div class="input-field">
                  <CruiseInput
                    type="userId"
                    class="input-width-small"
                    :placeholder="$t('login.inputId')"
                    v-model="userInfo.userId"
                  />
                  <CruiseButton class="id-check-btn" @keydown.enter.prevent @click="checkExistId">
                    {{ $t('labels.checkIdDuplication') }}
                  </CruiseButton>
                </div>
              </CruiseInputField>
              <p v-if="userInfo.userId && !isCheckExistId" class="id-result-msg">{{ idCheckMessage }}</p>
            </div>
          </div>

          <div class="detail-wrapper password">
            <CruiseInputField :label="$t('labels.password')" :helperText="$t('validation.password')" required>
              <form class="input-field">
                <CruiseInput
                  class="input-width"
                  type="password"
                  :placeholder="$t('login.inputPassword')"
                  v-model="userInfo.password"
                />
              </form>
            </CruiseInputField>
            <CruiseInputField :label="$t('password.confirmPassword')" required>
              <form class="input-field">
                <CruiseInput
                  class="input-width"
                  type="password"
                  :placeholder="$t('password.reInputPassword')"
                  v-model="confirmPassword"
                />
              </form>
            </CruiseInputField>
          </div>

          <div class="detail-wrapper">
            <div class="birth">
              <CruiseInputField :label="$t('profile.dateOfBirth')" required>
                <div class="input-field">
                  <CruiseInput
                    class="input-width"
                    :placeholder="$t('validation.numberOnlBirth')"
                    v-model="userInfo.birthday"
                    maxLength="10"
                    @onInput="validateDate"
                  />
                </div>
              </CruiseInputField>
            </div>

            <div class="gender">
              <CruiseInputField class="sex-wrapper" :label="$t('profile.gender')" required>
                <div class="input-field">
                  <CruiseRadio :label="$t('profile.male')" value="M" name="sex" v-model="userInfo.sex" />
                  <CruiseRadio :label="$t('profile.female')" value="F" name="sex" v-model="userInfo.sex" />
                </div>
              </CruiseInputField>
            </div>
          </div>

          <div class="detail-wrapper">
            <div class="email">
              <CruiseInputField v-if="$i18n.locale === 'ko'" :label="$t('profile.email')" required>
                <div class="email-wrapper">
                  <div class="email-field">
                    <CruiseInput type="emailAddress" :placeholder="$t('profile.inputEmail')" v-model="userInfo.email" />
                    <span>@</span>
                    <CruiseInput
                      type="emailDomain"
                      class="input-width"
                      :placeholder="$t('profile.inputSelf')"
                      v-model="emailDomain"
                      :readonly="emailReadonly"
                    />
                  </div>
                  <CruiseSelect class="email-selector" v-model="emailTemplate" :options="emailList" />
                </div>
              </CruiseInputField>

              <EmailAuth
                v-else
                v-model:isVerified="isVerified"
                v-model:userEmail="userInfo.email"
                v-model:userEmailDomain="emailDomain"
              />
            </div>
            <div class="phone-number">
              <PhoneNumberAuth
                v-if="$i18n.locale === 'ko'"
                v-model:isVerified="isVerified"
                v-model:userPhoneNumber="userInfo.phoneNumber"
              />

              <CruiseInputField v-else :label="$t('labels.phoneNumber')" required>
                <div class="input-field">
                  <CruiseInput
                    class="input-width"
                    :placeholder="$t('messages.phoneNumber')"
                    v-model="userInfo.phoneNumber"
                  />
                </div>
              </CruiseInputField>
            </div>
          </div>
        </div>
      </div>
      <div class="details-footer">
        <CruiseButton class="next-btn" @click="goNext">{{ $t('register.signUp') }}</CruiseButton>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user';
import { mapState, mapMutations } from 'vuex';
import { regExp, validators } from '@/utils/validator';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseRadio from '@/components/common/CruiseRadio.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';
import PhoneNumberAuth from '@/components/common/PhoneNumberAuth.vue';
import EmailAuth from '@/components/login/EmailAuth.vue';

export default {
  name: 'RegisterDetails',
  components: {
    CruiseButton,
    CruiseInputField,
    CruiseInput,
    CruiseRadio,
    CruiseSelect,
    PhoneNumberAuth,
    EmailAuth,
  },
  data() {
    return {
      userInfo: {
        name: '',
        userId: '',
        password: '',
        birthday: '',
        sex: 'M',
        email: '',
        phoneNumber: '',
      },

      confirmPassword: '',

      emailDomain: '',
      emailReadonly: false,
      emailTemplate: this.$t('profile.inputSelf'),
      emailList: [this.$t('profile.inputSelf'), 'naver.com', 'gmail.com', 'daum.net', 'hotmail.com'],

      selectedCountryNumberCode: null,

      isVerified: false,

      regExp,

      isCheckExistId: false,
      idCheckMessage: this.$t('messages.checkIdDuplication'),
    };
  },
  watch: {
    emailTemplate(value) {
      const isDirect = value === this.$t('profile.inputSelf');
      this.emailReadonly = !isDirect;
      this.emailDomain = isDirect ? '' : value;
    },
    'userInfo.userId'(newValue, oldValue) {
      if (newValue !== oldValue && this.isCheckExistId) {
        this.isCheckExistId = false;
      }
    },
  },
  computed: {
    ...mapState('auth', ['registerUserInfo']),

    convertedEmail() {
      return `${this.userInfo.email}@${this.emailDomain}`;
    },
    convertedBirth() {
      const replacedValue = this.userInfo.birthday.replace(regExp.dot, '');
      return replacedValue;
    },
  },
  methods: {
    ...mapMutations('auth', ['updatedRegisterUserInfo']),

    convertDateFormat(value) {
      value = value.replace(regExp.notNumber, '');
      value = value.slice(0, 8);

      if (value.length > 4) {
        value = value.slice(0, 4) + '.' + value.slice(4);
      }
      if (value.length > 7) {
        value = value.slice(0, 7) + '.' + value.slice(7);
      }
      return value;
    },
    validateDate(e) {
      const convertedDate = this.convertDateFormat(e.target.value);
      this.userInfo.birthday = convertedDate;
      e.target.value = convertedDate;
    },
    registerValidation() {
      if (!this.userInfo.name.trim()) {
        this.$alert({ content: this.$t('messages.enterName') });
        return;
      }

      if (!this.userInfo.userId) {
        this.$alert({ content: this.$t('messages.enterUserId') });
        return;
      }

      if (!validators.isValidId(this.userInfo.userId)) {
        this.$alert({ content: this.$t('validation.alertUserId') });
        return;
      }

      if (!this.isCheckExistId) {
        this.$alert({ content: this.idCheckMessage });
        return;
      }

      if (!this.userInfo.password) {
        this.$alert({ content: this.$t('messages.enterPassword') });
        return;
      }

      if (!validators.isValidPassword(this.userInfo.password)) {
        this.$alert({ content: this.$t('validation.alertPassword') });
        return;
      }

      if (!this.confirmPassword) {
        this.$alert({ content: this.$t('messages.checkPassword') });
        return;
      }

      if (this.userInfo.password !== this.confirmPassword) {
        this.$alert({ content: this.$t('messages.passwordMismatch') });
        return;
      }

      if (!this.userInfo.birthday) {
        this.$alert({ content: this.$t('messages.enterBitrh') });
        return;
      }

      if (!validators.isValidBirth(this.userInfo.birthday)) {
        this.$alert({ content: this.$t('validation.alertBirth') });
        return;
      }

      if (!this.userInfo.sex) {
        this.$alert({ content: this.$t('messages.selectGender') });
        return;
      }

      if (this.$i18n.locale === 'ko' && (!this.userInfo.email || !this.emailDomain)) {
        this.$alert({ content: this.$t('messages.enterEmail') });
        return;
      }

      if (this.$i18n.locale === 'ko' && !validators.isValidEmail(this.convertedEmail)) {
        this.$alert({ content: this.$t('messages.checkEmail') });
        return;
      }

      // if (!this.selectedCountryNumberCode) {
      //   this.$alert({ content: this.$t('messages.selectCountryCode') });
      //   return;
      // }

      if (!this.userInfo.phoneNumber) {
        this.$alert({ content: this.$t('messages.enterPhoneNumber') });
        return;
      }

      if (this.$i18n.locale === 'ko' && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completePhoneVerification') });
        return;
      }

      if (this.$i18n.locale !== 'ko' && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completeEmailVerification') });
        return;
      }

      return true;
    },
    async checkExistId() {
      if (!validators.isValidId(this.userInfo.userId)) {
        this.$alert({ content: this.$t('validation.alertUserId') });
        return;
      }

      try {
        const param = { userId: this.userInfo.userId };
        const response = await UserService.checkExistUserId(param);
        this.$alert({ content: response.message });
        this.isCheckExistId = true;
      } catch (error) {
        this.isCheckExistId = false;
        this.userInfo.userId = '';
        this.$alert({ content: error });
      }
    },
    async goNext() {
      if (!this.registerValidation() || !this.isVerified || !this.isCheckExistId) {
        return;
      }

      const updateUserInfo = {
        userId: this.userInfo.userId,
        password: this.userInfo.password,
        // country: this.selectedCountryNumberCode,
        birthDay: this.convertedBirth,
        sex: this.userInfo.sex,
        name: this.userInfo.name,
        mobile: this.userInfo.phoneNumber,
        email: this.convertedEmail,
      };

      this.updatedRegisterUserInfo(updateUserInfo);

      try {
        await UserService.register(this.registerUserInfo);
        this.$router.push('complete');
      } catch (error) {
        this.$alert({ content: error });
      }
    },
  },
};
</script>

<style scoped>
.details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--Black);
}

.details-wrapper {
  flex: 1 0;
  gap: 40px;
}

.details-header {
  gap: 10px;
}

.sub-title {
  font-size: 14px;
  font-weight: 700;
}

.title-description {
  font-size: 12px;
}

.required-star {
  color: var(--Orange-orange-500);
}

.details-field {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.category-title {
  font-size: 14px;
  font-weight: 700;
  padding: 12px 0;
  border-bottom: var(--default--border);
}

.input-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.input-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.id-check-btn {
  width: 93px;
  min-width: 93px;
}

.id-result-msg {
  font-size: 12px;
  margin-top: 10px;
  color: var(--Red);
}

.detail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.email-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-field {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
}

.email-selector {
  width: 50%;
  max-width: 93px;
}

.details-footer {
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
}

.next-btn {
  width: 156px;
}

@media screen and (min-width: 768px) {
  .details-container {
    gap: 20px;
  }

  .details-wrapper {
    gap: 10px;
  }

  .details-header {
    gap: 10px;
  }

  .sub-title {
    font-size: 18px;
  }

  .details-field {
    flex-direction: row;
    justify-content: space-between;
    border: var(--default--border);
    padding: 20px;
    background: #fff;
    font-size: 12px;
  }

  .category-title {
    font-size: 16px;
    padding: 0;
    border: none;
  }

  .input-fields-wrapper {
    width: 100%;
  }

  .input-field {
    gap: 20px;
  }

  .email-field {
    max-width: 416px;
  }

  .email-selector {
    max-width: 120px;
  }

  .detail-wrapper {
    flex-direction: row;
    gap: 40px;
  }

  .detail-wrapper > div {
    width: 50%;
  }

  .next-btn {
    width: 188px;
  }

  :deep(.input-width) {
    max-width: 100% !important;
  }
}
</style>
