<template>
  <router-view />
</template>

<script>
import UrlService from '@/services/url';
import i18n from '@/languages/i18n';

export default {
  name: 'App',
  watch: {
    '$i18n.locale': 'updateDocument',
  },
  methods: {
    init() {
      this.$store.dispatch('auth/isLoggedIn');
      this.handleTicket();
    },
    handleTicket() {
      const search = window.location.search;
      const key = search.slice(0, 2);
      if (key === '?=') {
        const value = search.slice(2, 10);
        this.getLongUrl(value);
      } else {
        if (
          process.env.VUE_APP_MODE !== 'DEV' &&
          process.env.VUE_APP_MODE !== 'TEST' &&
          window.location.origin !== 'https://www.panstarcruise.com'
        ) {
          window.location.href = 'https://www.panstarcruise.com';
        }
      }
    },
    async getLongUrl(shortUrl) {
      try {
        const { data } = await UrlService.getLongUrl(shortUrl);
        const convertUrl = decodeURIComponent(data.longUrl).replace('www', 'ticket');
        window.location.href = convertUrl;
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
    updateDocument() {
      const title = this.$t('panstarCruise');
      const locale = i18n.global.locale;
      document.title = locale === 'en' ? title : `${title} PanStar Cruise`;

      const userLang = navigator.language || navigator.userLanguage;
      let ogTitle = '팬스타크루즈';
      let ogDescription = '팬스타크루즈';
      let description =
        '팬스타크루즈는 부산-오사카 크루즈 페리, 부산항 주말 원나잇크루즈, 부산-대마도(히타카츠, 이즈하라) 고속선을 운항하고 있습니다.';

      if (userLang.includes('ko')) {
        ogTitle = '팬스타크루즈';
        ogDescription = '팬스타크루즈 승선을 환영합니다!';
        description =
          '팬스타크루즈는 부산-오사카 크루즈 페리, 부산항 주말 원나잇크루즈, 부산-대마도(히타카츠, 이즈하라) 고속선을 운항하고 있습니다.';
      } else if (userLang.includes('ja')) {
        ogTitle = 'パンスタクルーズ';
        ogDescription = 'パンスタクルーズへようこそ！';
        description =
          'パンスタクルーズは、釜山-大阪間のフェリー、釜山港からの週末ワンナイトクルーズ、釜山-対馬（比田勝、厳原）間の高速船を運航しています。';
      } else if (userLang.includes('en')) {
        ogTitle = 'PanStar Cruise';
        ogDescription = 'Welcome to PanStar Cruise!';
        description =
          'PanStar Cruise operates the Busan-Osaka cruise ferry, Busan Port weekend one-night cruise, and Busan-Tsushima (Hitakatsu, Izuhara) high-speed ferry.';
      }

      this.setMetaTag('og:title', ogTitle);
      this.setMetaTag('og:description', ogDescription);
      this.setMetaTag('description', description);
    },
    setMetaTag(name, content) {
      let element =
        document.querySelector(`meta[name='${name}']`) || document.querySelector(`meta[property='${name}']`);
      if (element) {
        element.setAttribute('content', content);
      } else {
        const meta = document.createElement('meta');
        if (name.startsWith('og:')) {
          meta.setAttribute('property', name);
        } else {
          meta.setAttribute('name', name);
        }
        meta.setAttribute('content', content);
        document.head.appendChild(meta);
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.updateDocument();
  },
};
</script>
