import api from '@/services/api';

const API_URL = '/api/grade';

class GradeService {
  async getAvailableCabinList(params) {
    try {
      const response = await api.post(`${API_URL}/getAvailableRooms`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAvailableCabinListForWeek(params) {
    try {
      const response = await api.post(`${API_URL}/getAvailableRoomsForWeek`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAvailableSeatList(params) {
    try {
      const response = await api.get(`${API_URL}/getAvailableSeats`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAvailableSeatListForWeek(params) {
    try {
      const response = await api.post(`${API_URL}/getAvailableSeatsForWeek`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getOccupiedSeats(params) {
    try {
      const response = await api.get(`${API_URL}/getOccupiedSeats`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSingleChargeAmount(params) {
    try {
      const response = await api.get(`${API_URL}/getSingleChargeAmount`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new GradeService();
