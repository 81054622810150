<template>
  <div class="calendar-body">
    <div class="week day">
      <div v-for="(day, idx) in dayOfWeek" class="unit" :key="idx">
        <span class="empty">{{ day }}</span>
      </div>
    </div>
    <div v-for="week in calendar" class="week" :key="week">
      <div v-for="date in week" class="unit" :key="date">
        <div v-if="!date" class="empty"></div>
        <template v-if="date">
          <div class="date-bg" :class="dateBgClass(date)">
            <button class="date" :class="dateClass(date)" @click="handleBtnClick(date)">
              <span class="date-text">{{ date.date() }}</span>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const dayOfWeek = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  ko: ['일', '월', '화', '수', '목', '금', '토'],
  ja: ["日", "月", "火", "水", "木", "金", "土"],
};

export default {
  name: 'CalendarBody',
  emits: ['update:dateRange'],
  props: {
    dateRange: Object,
    date: Object,
    range: Boolean,
    allowedDate: Array,
  },
  data() {
    return {
      today: dayjs(),
      selectedDate: null,
    };
  },
  computed: {
    dayOfWeek() {
      return dayOfWeek[this.$i18n.locale];
    },
    calendar() {
      const firstDay = this.date.startOf('month').day();
      const lastDate = this.date.endOf('month').date();
      const rows = Math.ceil((firstDay + lastDate) / 7);
      const calendar = [];
      let dateNumber = 1;

      for (let i = 0; i < rows; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDay) {
            week.push(null);
          } else if (dateNumber <= lastDate) {
            const date = this.date.date(dateNumber);
            week.push(date);
            dateNumber++;
          } else {
            week.push(null);
          }
        }

        calendar.push(week);
      }
      return calendar;
    },
  },
  methods: {
    updateDateRange(dateRange) {
      this.$emit('update:dateRange', dateRange);
    },
    handleBtnClick(date) {
      if (!this.range) {
        this.updateDateRange({ from: date, to: null });
      } else {
        const { from, to } = this.dateRange;
        if (from && to) {
          this.updateDateRange({ from: date, to: null });
        } else if (from) {
          if (date.isBefore(from)) {
            this.updateDateRange({ from: date, to: from });
          } else {
            this.updateDateRange({ from, to: date });
          }
        } else {
          this.updateDateRange({ from: date, to: null });
        }
      }
    },
    dateBgClass(date) {
      if (this.range && this.dateRange.from && this.dateRange.to) {
        const { from, to } = this.dateRange;
        return date.isSame(from, 'day') && date.isSame(to, 'day')
          ? ''
          : date.isSame(from, 'day')
          ? 'from'
          : date.isSame(to, 'day')
          ? 'to'
          : date.isBetween(from, to, 'day')
          ? 'middle'
          : '';
      }
      return '';
    },
    isDateSelected(date) {
      const { from, to } = this.dateRange;
      return date && (from || to) ? date.isSame(from, 'day') || date.isSame(to, 'day') : false;
    },
    dateClass(date) {
      const { from, to } = this.dateRange;
      const isAllowed = this.allowedDate
        ? this.allowedDate.some(item => date.isSame(item, 'day'))
        : date.isSameOrAfter(this.today, 'day');

      return {
        highlightFrom: isAllowed,
        highlightTo: from && !to && isAllowed && this.range,
        today: date.isSame(this.today, 'day'),
        selected: this.isDateSelected(date),
        disabled: date.isBefore(this.today, 'day') || !isAllowed,
      };
    },
  },
};
</script>

<style scoped>
.calendar-body {
  display: flex;
  flex-direction: column;
}

.week {
  display: flex;
}

.week.day {
  display: none;
}

.unit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
  text-align: center;
  color: var(--Grey-grey-600);
}

.empty {
  width: 35px;
  width: 100%;
  box-sizing: content-box;
}

.unit.disabled {
  color: var(--Grey-grey-600);
  pointer-events: none;
  opacity: 0.5;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unit:first-child,
.unit:first-child .date {
  color: var(--Orange-orange-500) !important;
}

.date-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
}

.date-bg.from {
  background-image: linear-gradient(90deg, var(--White) 50%, var(--SEABLUE-SEABLUE_BRIGHT) 0);
}

.unit.middle,
.date-bg.middle {
  background: var(--SEABLUE-SEABLUE_BRIGHT);
}

.date-bg.to {
  background-image: linear-gradient(-90deg, var(--White) 50%, var(--SEABLUE-SEABLUE_BRIGHT) 0);
}

.date {
  width: 30px;
  height: 30px;
  position: relative;
}

.date.disabled {
  pointer-events: none;
}

.date.today {
  width: 30px;
  height: 30px;
  background-color: var(--GRAY-GRAY_BORDER);
  color: var(--SEABLUE-SEABLUE) !important;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  border-radius: 100%;
}

.date.today::after {
  font-size: 9px;
  content: '';
  position: absolute;
  top: -20px;
  color: var(--SEABLUE-SEABLUE);
  font-weight: 700;
}

.date.highlightFrom {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #c5d1f9;
}

.date.highlightTo {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #fcd1c3;
}

.unit .date.selected {
  color: var(--White) !important;
  border-radius: 100%;
  font-weight: 700;
  border: none;
  background: var(--SEABLUE-SEABLUE);
}

.date-text {
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .week.day {
    display: flex;
  }
}
</style>
