<template>
  <component :is="tag" v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'CruiseDistributor',
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  provide() {
    return {
      distributed: this.props,
    };
  },
  computed: {
    props() {
      return this.$attrs;
    },
  },
};
</script>

<style scoped>
div {
  width: 100%;
  height: 100%;
}
</style>
