<template>
  <div class="user-nav">
    <div v-if="user" class="user-wrapper flex-column">
      <div class="flex-between">
        <div class="user-info">
          <div class="user-icon flex-center">
            <img src="@/assets/images/icon/user_icon.svg" alt="user icon" />
          </div>
          <div class="name-wrapper">
            <b class="my-info"> {{ user?.name }} </b>{{ $t('user.sir') }}
          </div>
        </div>

        <ReCruiseButton class="login-btn" theme="outline" @click="logout">{{ $t('user.logout') }}</ReCruiseButton>
      </div>

      <div class="lnb-wrapper">
        <div class="lnb-btn" @click="goPage('user-info')">{{ $t('menu.editProfile') }}</div>
        <div class="separator"></div>
        <div class="lnb-btn" @click="goPage('my-page')">{{ $t('menu.myReservations') }}</div>
      </div>
    </div>

    <div class="login-wrapper" v-else>
      <ReCruiseButton class="login-btn" theme="outline" @click="goLoginPage">{{ $t('user.login') }}</ReCruiseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'UserNav',
  components: { ReCruiseButton },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    closeTheSidebar() {
      this.$emit('update:show', false);
    },
    goLoginPage() {
      this.closeTheSidebar();
      this.$router.push('/login');
    },
    goPage(path) {
      if (path) {
        this.closeTheSidebar();
        this.$router.push(`/${path}`);
      }
    },
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
        this.$router.push('/');
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
  },
};
</script>

<style scoped>
.user-nav {
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid var(--Grey-grey-50, #f5f7f9);
}

.user-wrapper {
  gap: 8px;
}

.user-info {
  min-width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.user-icon {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  margin-right: 10px;
}

.user-icon > span {
  color: #cdcdcd;
}

.name-wrapper {
  color: var(--Grey-grey-900, #333);
  font-size: 18px;
  font-weight: 400;
}

.my-info {
  color: var(--Blue-blue-500, #446ceb);
  font-size: 18px;
  font-weight: 700;
}

.lnb-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.lnb-btn {
  cursor: pointer;
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 16px;
  font-weight: 500;
}

.separator {
  width: 1px;
  height: 19px;
  background: var(--Grey-grey-100, #dddfe2);
}

.login-btn {
  width: 100px;
  height: 33px;
  padding: 7px 0;
  color: var(--Blue-blue-500, #446ceb);
  font-size: 16px;
  font-weight: 500;
}
</style>
