<template>
  <div class="passenger-count-wrapper">
    <div class="passenger-count-inner">
      <div v-if="maxCount > 1" v-for="(no, i) in maxCount" :key="i" class="passenger-count-item">
        <div class="passenger-count-header">
          <p :class="cabinTitleClass(i)" class="passenger-count-label">{{ header }} {{ no }}</p>
        </div>

        <div v-if="hasPassengerTypes(i)" class="passenger-count-info">
          <ul class="passenger-type-list">
            <li class="passenger-type">
              <span class="passenger-label">{{ $t('age.adult') }}</span>
              <ReCruiseCount
                v-model:count="passengerTypeCountList[i].adult"
                :minCount="departure?.highlight ? Math.max(2, adultMin) : Math.max(1, adultMin)"
                :maxCount="adultMax"
              ></ReCruiseCount>
            </li>
            <li class="passenger-type" v-if="!departure?.highlight">
              <span class="passenger-label">{{ $t('age.child') }}</span>
              <ReCruiseCount v-model:count="passengerTypeCountList[i].child" :maxCount="getMaxCount(i, true)" />
            </li>
            <li class="passenger-type">
              <span class="passenger-label">{{ $t('age.infant') }}</span>
              <ReCruiseCount v-model:count="passengerTypeCountList[i].infant" :maxCount="getMaxCount(i)" />
            </li>
          </ul>
        </div>

        <div class="passenger-count-buttons">
          <ReCruiseButton v-if="canAddCabin(i)" theme="outline" class="passenger-button" @click.stop="addCabin">
            + {{ $t('actions.add') }}
          </ReCruiseButton>
          <ReCruiseButton v-else-if="disabledAddCabin(i)" theme="outline" disabled class="passenger-button">
            + {{ $t('actions.add') }}
          </ReCruiseButton>

          <ReCruiseButton
            v-else-if="canRemoveCabin(i)"
            theme="danger"
            class="passenger-button"
            @click.stop="removeCabin(i)"
          >
            {{ $t('actions.delete') }}
          </ReCruiseButton>
        </div>
      </div>

      <div v-else class="passenger-count-item">
        <div class="passenger-count-header">
          <p class="passenger-count-label">{{ $t(header) }}</p>
        </div>

        <div v-if="passengerTypeCountList[0]" class="passenger-count-info">
          <ul class="passenger-type-list">
            <li class="passenger-type">
              <span class="passenger-label">{{ $t('age.adult') }}</span>
              <ReCruiseCount
                v-model:count="passengerTypeCountList[0].adult"
                :minCount="adultMin"
                :maxCount="adultMax"
              ></ReCruiseCount>
            </li>
            <li class="passenger-type">
              <span class="passenger-label">{{ $t('age.child') }}</span>
              <ReCruiseCount v-model:count="passengerTypeCountList[0].child" :maxCount="getMaxCount(0, true)" />
            </li>
            <li class="passenger-type">
              <span class="passenger-label">{{ $t('age.infant') }}</span>
              <ReCruiseCount v-model:count="passengerTypeCountList[0].infant" :maxCount="getMaxCount(0)" />
            </li>
          </ul>
        </div>

        <div class="passenger-count-buttons"></div>
      </div>

      <ul class="notice-wrapper">
        <li class="notice-list" v-if="noticeList" v-for="notice in noticeList">
          <span class="check-icon">&#10003;</span> {{ $t(notice) }}
        </li>
      </ul>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import ReCruiseCount from '@/components/renewal/common/ReCruiseCount.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'RePassengerCount',
  components: { ReCruiseCount, ReCruiseButton },
  emits: ['update:addCabin', 'update:removeCabin'],
  props: {
    maxCount: Number,
    adultMin: {
      type: Number,
      default: 1,
    },
    adultMax: {
      type: Number,
      default: 6,
    },
    childMaxCount: Number,
    infantMaxCount: Number,
    passengerTypeCountList: Array,
    departure: Object,
    header: String,
    noticeList: Array,
  },
  data() {
    return {};
  },
  methods: {
    cabinTitleClass(index) {
      return !this.passengerTypeCountList[index] ? 'gray-color' : '';
    },
    hasPassengerTypes(index) {
      return !!this.passengerTypeCountList[index];
    },
    canAddCabin(index) {
      return !this.passengerTypeCountList[index] && this.passengerTypeCountList[index - 1];
    },
    disabledAddCabin(index) {
      return !this.passengerTypeCountList[index - 1];
    },
    canRemoveCabin(index) {
      return !!this.passengerTypeCountList[index];
    },
    getMaxCount(idx, isChild) {
      const { adult, child, infant } = this.passengerTypeCountList[idx];

      const maxCount = adult * 2 - (isChild ? infant : child);

      if (this.maxCount === 1) {
        return 7 - (adult + (isChild ? infant : child));
      } else {
        if (isChild && this.childMaxCount) {
          return Math.min(this.childMaxCount, maxCount);
        }

        if (!isChild && this.infantMaxCount) {
          return Math.min(this.infantMaxCount, maxCount);
        }
      }

      return maxCount;
    },
    addCabin() {
      this.$emit('update:addCabin');
    },
    removeCabin(index) {
      this.$emit('update:removeCabin', index);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
/* Seat/Passenger Select Layer */
.passenger-count-wrapper {
  padding: 0 16px;
  color: var(--LG-Black);
  background: var(--White);
  top: 50px;
  left: 0px;
}

.passenger-count-inner {
  padding-bottom: 60px;
}

.passenger-count-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.passenger-count-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
}

.passenger-count-label {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

.passenger-count-info {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.passenger-type-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.passenger-type {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.passenger-count-item:first-child .passenger-count-buttons {
  opacity: 0;
}

.passenger-count-buttons {
  position: absolute;
  right: 0;
}

.passenger-button {
  width: 80px;
  height: 36px;
}

.notice-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 0;
}

.notice-list {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.42px;
  word-break: keep-all;
  text-indent: -14px;
  padding-left: 14px;
}

.check-icon {
  font-weight: 600;
}

.confirm-btn {
  width: 100%;
}

.gray-color {
  color: var(--Grey-grey-200);
}

@media screen and (min-width: 768px) {
  .passenger-count-wrapper {
    font-size: 16px;
    padding: 0;
  }

  .passenger-count-inner {
    padding-top: 32px;
    padding-bottom: 0;
  }

  .passenger-count-item {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 16px;
  }

  .passenger-count-header {
    width: auto;
  }

  .passenger-count-info {
    margin: 0;
    width: 645px;
  }

  .passenger-count-label {
    width: auto;
    margin: 0;
    font-weight: 600;
  }

  .passenger-type-list {
    flex-direction: row;
    gap: 80px;
  }

  .passenger-type {
    width: auto;
    justify-content: space-between;
    gap: 16px;
  }

  .passenger-count-buttons {
    position: static;
  }

  .passenger-button {
    font-size: 16px;
  }

  .notice-wrapper {
    padding: 20px 16px 0 16px;
  }
}
</style>
