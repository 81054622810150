const makeKey = () => Math.random().toString(36).substring(2);

const makeUid = () => {
  const uid = new Set();
  return () => {
    let key = makeKey();

    while (uid.has(key)) {
      key = makeKey();
    }
    uid.add(key);

    return 'uid-' + key;
  };
};

const result = makeUid();

export default result;
