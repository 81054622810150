<template>
  <div class="user-nav">
    <div v-if="user" ref="userInfo" class="user-info" @click="togglePopUp">
      <div class="user-icon flex-center">
        <img src="@/assets/images/icon/user_icon.svg" alt="user icon" />
      </div>
      <div class="name-wrapper" :class="{ active }">
        <b class="my-info" :class="{ active }"> {{ user?.name }} </b>{{ $t('user.sir') }}
      </div>

      <div class="my-modal" v-if="isShow" @click.stop>
        <div class="mileage-wrapper">
          <div class="mileage-text">{{ $t('labels.mileage') }}</div>
          <ReCruiseInput
            class="mileage-input"
            :placeholder="`${convertCommaNumber(mileage)} ${
              $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy')
            } `"
            readonly
          />
        </div>
        <ReCruiseButton class="my-page-btn" @click="goPage('my-page')">{{ $t('menu.myReservations') }}</ReCruiseButton>
        <div class="lnb-btn" @click="goPage('user-info')">{{ $t('menu.editProfile') }}</div>
      </div>
    </div>

    <div class="login-wrapper" v-else>
      <ReCruiseButton class="login-btn" :class="{ active }" :theme="active ? 'outline' : 'normal'" @click="goLoginPage">
        {{ $t('user.login') }}
      </ReCruiseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { convertCommaNumber } from '@/utils/convert';

import MileageService from '@/services/mileage';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';

export default {
  name: 'MainUserNav',
  components: { ReCruiseButton, ReCruiseInput },
  props: {
    active: Boolean,
  },
  data() {
    return {
      isShow: false,
      mileage: 0,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getUserMileage();
        }
      },
    },
  },
  methods: {
    convertCommaNumber,

    async getUserMileage() {
      if (!this.user) return;

      try {
        const query = {
          userId: this.user.userId,
        };
        const { data } = await MileageService.getUserMileage(query);
        this.mileage = data.mileage;
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },

    handleClick(e) {
      if (!this.$refs?.userInfo?.contains(e.target)) {
        this.isShow = false;
        return;
      }
    },
    togglePopUp() {
      this.isShow = !this.isShow;
    },
    goLoginPage() {
      this.togglePopUp();
      this.$router.push('/login');
    },
    goPage(path) {
      if (path) {
        this.togglePopUp();
        this.$router.push(`/${path}`);
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClick);
  },
};
</script>

<style scoped>
.user-nav {
  display: none;
}

.user-info {
  min-width: 80px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.user-icon {
  width: 24px;
  height: 24px;
  border-radius: 30px;
  margin-right: 10px;
}

.user-icon > span {
  color: #cdcdcd;
}

.name-wrapper {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 400;
}

.header-container:hover .name-wrapper,
.name-wrapper.active {
  color: var(--Grey-grey-900, #333);
}

.my-info {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.header-container:hover .my-info,
.my-info.active {
  color: var(--Blue-blue-500, #446ceb);
}

.lnb-btn {
  cursor: pointer;
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 16px;
  font-weight: 500;
}

.login-btn {
  width: 116px;
  height: 33px;
  padding: 7px 0;
  font-size: 16px;
  font-weight: 500;

  color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
  background: none;
}

.login-btn.active {
  color: var(--Blue-blue-500, #446ceb);
  background: #fff;
}

.my-modal {
  cursor: default;
  position: absolute;
  top: 34px;
  right: 0;

  display: flex;
  width: 328px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;

  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
  background: #fff;
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
}

.mileage-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}

.mileage-text {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 16px;
  font-weight: 500;
  width: 56px;
  flex-shrink: 0;
}

.mileage-input {
  width: 194px;
  text-align: right !important;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  background: var(--Grey-grey-50, #f5f7f9);
}

.my-page-btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .user-nav {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding-top: 4px;
  }
}
</style>
