<template>
  <CruiseInputField label="연락처" required>
    <div class="phone-number-wrapper">
      <div class="phone-number-box">
        <ReCruiseInput
          placeholder="휴대폰 번호 입력(-제외)"
          v-model="phoneNumber"
          :disabled="isSuccessAuth"
          type="number"
          :isError="isError"
        />
      </div>
      <ReCruiseButton
        v-show="!phoneNumber || isChangedMobile"
        :theme="authButtonTheme"
        class="auth-request-btn"
        @keydown.enter.prevent
        @click="requestAuth"
      >
        {{ authButtonText }}
      </ReCruiseButton>
    </div>

    <div class="auth-number-wrap" v-show="!phoneNumber || isChangedMobile">
      <div class="auth-number-inner">
        <div class="auth-number-box">
          <ReCruiseInput
            placeholder="인증번호 입력"
            v-model.number="authCodeInput"
            :disabled="!authCode || isVerified"
            type="number"
            :isError="authCode && !isVerified"
          />
          <span v-if="timer" class="timer">{{ formattedTime }}</span>
        </div>
        <ReCruiseButton
          class="auth-request-btn"
          :theme="authConfirmButtonTheme"
          @keydown.enter.prevent
          @click="authConfirm"
          :disabled="isVerified"
        >
          인증확인
        </ReCruiseButton>
      </div>
      <p v-if="authResultMessage" class="auth-result-msg" :class="authSuccessClass">{{ authResultMessage }}</p>
    </div>
  </CruiseInputField>
</template>
<script>
import { regExp } from '@/utils/validator';

import UserService from '@/services/user';

import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';

export default {
  name: 'RePhoneNumberAuth',
  emits: ['update:isVerified', 'update:userPhoneNumber'],
  components: { ReCruiseButton, CruiseInputField, ReCruiseInput },
  props: {
    isVerified: Boolean,
    userPhoneNumber: String,
    userName: String,
    userId: String,
    isError: Boolean,
    csInquiryMessage: String,
  },
  data() {
    return {
      originPhoneNumber: this.userPhoneNumber,
      phoneNumber: this.userPhoneNumber || '',
      authCodeInput: null,
      authCode: null, // 인증코드
      authButtonText: '인증요청',
      authResultMessage: '',

      timer: null,
      timeLeft: 300, // 타이머 5분
      message: '',

      isChangedMobile: false,

      regExp,
    };
  },
  watch: {
    phoneNumber(value) {
      this.isChangedMobile = value !== this.originPhoneNumber;
      this.$emit('update:userPhoneNumber', value);
    },
  },
  computed: {
    searchType() {
      return this.$route.params.type || '';
    },
    isSuccessAuth() {
      return !!this.authCode || this.isVerified;
    },
    authButtonTheme() {
      return this.authCode ? 'outline' : 'primary';
    },
    formattedTime() {
      let minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    authConfirmButtonTheme() {
      return this.timer ? 'primary' : 'disabled';
    },
    authSuccessClass() {
      return this.isVerified ? 'success' : '';
    },
  },
  methods: {
    async getAuthCode() {
      try {
        let params = {
          target: this.searchType,
          mobile: this.phoneNumber,
          ...(this.userName && { name: this.userName }),
          ...(this.userId && { userId: this.userId }),
        };

        if (!this.searchType) {
          const param = { mobile: this.phoneNumber };
          const response = await UserService.checkExistMobile(param);
          return response;
        } else {
          if (this.searchType === 'id' && !this.userName) {
            this.$alert({ content: '이름을 입력해 주세요.' });
            return;
          }
          if (this.searchType === 'pw' && !this.userId) {
            this.$alert({ content: '아이디를 입력해 주세요.' });
            return;
          }

          const response = await UserService.checkUserExists(params);
          return response;
        }
      } catch (error) {
        this.$alert({ content: `${error}\n${this.csInquiryMessage || ''}` || '휴대전화번호를 확인해 주세요.' });
      }
    },
    async requestAuth() {
      if (!this.phoneNumber) {
        this.$alert({ content: '휴대전화번호를 입력해 주세요.' });
        return;
      }

      if (this.authCode) {
        this.resetTimer();
      } else {
        const response = await this.getAuthCode();

        this.authCode = response?.data;
        this.authResultMessage = response?.message;

        const isExistPhoneNumber = !response;

        if (!isExistPhoneNumber) {
          if (this.timer) {
            clearInterval(this.timer);
            this.timeLeft = 300;
          } else {
            this.authButtonText = '재요청';
          }

          this.timer = setInterval(() => {
            if (this.timeLeft > 0) {
              this.timeLeft -= 1;
            } else {
              this.resetTimer();
            }
          }, 1000);
        }
      }
    },
    async authConfirm() {
      if (!this.authCode) {
        return;
      }

      if (!this.authCodeInput) {
        this.$alert({ content: '인증번호를 입력해 주세요.' });
        return;
      }

      try {
        const params = {
          encryptedNumber: this.authCode,
          number: this.authCodeInput,
        };

        const { data } = await UserService.validate(params);

        if (data) {
          this.authResultMessage = '인증번호가 확인되었습니다.';
          this.$emit('update:isVerified', true);
          this.$emit('update:userPhoneNumber', this.phoneNumber);
          this.timer = null;
        } else {
          this.authResultMessage = '인증번호가 일치하지 않습니다.';
          this.$emit('update:isVerified', false);
        }
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    resetTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.timeLeft = 300;
      this.authButtonText = '인증요청';
      this.authCode = null;
      this.authCodeInput = null;
      this.authResultMessage = '';
      this.$emit('update:isVerified', false);
    },
  },
};
</script>

<style scoped>
.input-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.phone-number-wrapper,
.phone-number-box {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.number-selector {
  width: 100px;
  min-width: 100px;
}

.auth-number-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.auth-number-inner {
  display: flex;
  gap: 5px;
}

.auth-number-box {
  position: relative;
  width: 100%;
}

.timer {
  position: absolute;
  top: 11px;
  right: 16px;
  font-size: 14px;
}

.auth-request-btn {
  width: 93px;
  height: 40px;
  font-size: 14px;
}

.auth-result-msg {
  color: var(--Orange-orange-500);
}

.auth-result-msg.success {
  color: var(--Blue-blue-500);
}

@media screen and (min-width: 768px) {
  .input-fields-wrapper {
    width: 80%;
  }

  .phone-number-wrapper,
  .auth-number-inner {
    gap: 10px;
  }

  .auth-request-btn {
    min-width: 93px;
  }
}
</style>
