<template>
  <div class="event-detail-wrap">
    <div class="inner">
      <div class="event-detail-header">
        <div
          v-if="eventDetail.categoryEn === 'benefit'"
          class="category event"
          :class="eventCategoryClass(eventDetail.isOn)"
        >
          <span class="category-text event" :class="eventCategoryClass(eventDetail.isOn)">
            {{ eventStatusText(eventDetail.isOn) }}
          </span>
        </div>
        <div class="event-title">{{ eventDetailNameByLocale }}</div>
        <div class="event-date">{{ periodText(eventDetail) }}</div>
      </div>

      <div class="event-content" :style="eventContentStyle">
        <img
          ref="eventDetailImage"
          usemap="#event-map"
          :src="mainImageUrlByLocale"
          @load="setImageMap"
          alt="event detail image"
        />

        <map name="event-map" id="event-map">
          <area
            v-for="(detail, index) in eventDetailsByLocale"
            :key="index"
            :coords="detail.coordinates"
            href="#"
            onclick="return false;"
            shape="rect"
            alt="event detail image"
            @click="executeButtonEvent(detail)"
          />
        </map>
      </div>
    </div>

    <ReCruiseButton class="button" @click="$router.push('/event')">{{ $t('actions.backToList') }}</ReCruiseButton>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { getLocalText, getLocalKey, formatDateRange } from '@/utils/convert';

import EventService from '@/services/event';
import CouponService from '@/services/coupon';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'EventDetail',
  components: { ReCruiseButton },
  data() {
    return {
      eventDetail: {},
      eventContentStyle: { width: '100%', height: 'auto' },
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    eventId() {
      return this.$route.params.id;
    },
    eventDetailNameByLocale() {
      return this.eventDetail[getLocalKey('name')];
    },
    eventDetailsByLocale() {
      return this.eventDetail[getLocalKey('eventDetails')];
    },
    mainImageUrlByLocale() {
      return this.eventDetail[getLocalKey('mainImageUrl')];
    },
    openedLanguage() {
      return this.eventDetail[getLocalKey('isOpened')];
    },
  },
  methods: {
    getLocalText,
    getLocalKey,
    formatDateRange,

    async getEventDetail() {
      if (!this.eventId) return;

      try {
        const { data } = await EventService.getEventDetail(this.eventId);
        this.eventDetail = data;

        if (!this.openedLanguage) {
          this.$router.push('/event');
        }
      } catch (error) {
        this.$router.replace({ name: 'NotFound' });
      }
    },
    eventCategoryClass(status) {
      if (status) {
        return 'on';
      } else {
        return 'off';
      }
    },
    eventStatusText(status) {
      return status ? this.$t('event.inProgress') : this.$t('event.close');
    },
    periodText(event) {
      return event.isUnlimited ? this.$t('labels.alwaysOn') : formatDateRange(event.startDate, event.endDate);
    },
    setImageMap() {
      const detailImage = this.$refs.eventDetailImage;

      this.eventContentStyle = {
        width: detailImage.width + 'px',
        height: detailImage.height + 'px',
      };

      this.eventDetailsByLocale?.forEach(detail => {
        const newCoords = [];
        detail?.coordinates?.split(',').forEach(coord => {
          newCoords.push(parseInt(coord) / (detailImage.naturalWidth / detailImage.width));
        });

        detail.coordinates = newCoords.join(',');
      });
    },
    async executeButtonEvent(detail) {
      if (detail.isUrl && detail.linkUrl) {
        window.open(detail.linkUrl);
      } else if (!detail.isUrl && detail.couponCode) {
        if (!this.user) {
          const result = await this.$confirm({ content: this.$t('messages.cannotIssueCouponByLogin') });

          if (result) {
            this.$router.push('/login?redirect=' + location.pathname);
          }
          return;
        }

        try {
          const params = {
            pcid: this.user.userId,
            couponCode: detail.couponCode,
          };

          const now = dayjs();
          const eventExpiry = dayjs(`${this.eventDetail.endDate} ${this.eventDetail.endTime}`, 'YYYYMMDD HH:mm');

          if (now.isAfter(eventExpiry)) {
            this.$alert({ content: this.$t('messages.couponDownloadPeriodExpired') });
            return;
          }

          const { message } = await CouponService.postUserCouponByCouponCode(params);
          this.$alert({ content: message });
        } catch (error) {
          this.$alert({ content: error });
          console.error(error);
        }
      }
    },
  },
  mounted() {
    this.getEventDetail();
  },
};
</script>

<style scoped>
.event-detail-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner {
  width: 100%;
  max-width: 800px;
  margin-bottom: 50px;
}

.event-detail-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0 16px;
  border-bottom: 1px solid var(--Grey-grey-200);
  margin-bottom: 20px;
}

.event-info {
  display: flex;
  flex-direction: column;
  gap: 17px;
  text-align: center;
}

.event-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 10px;
}

.event-date {
  color: var(--Grey-grey-600);
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
}

.event-desc {
  margin-bottom: 15px;
}

.event-content {
  width: 100%;
  border: var(--default--border);
  background: var(--White);
}

.category {
  width: fit-content;
  height: 19px;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 16px;
}

.category.event {
  height: 22px;
  padding: 4px 10px;
}

.category.event.on {
  border: 1px solid var(--Blue-blue-500);
}

.category.event.off {
  border: 1px solid var(--Grey-grey-700);
}

.category-text {
  width: fit-content;
  height: 13px;
  font-size: 11px;
  font-weight: 500;
}

.category-text.event {
  height: 14px;
  font-size: 12px;
}

.category-text.on {
  color: var(--Blue-blue-500);
}

.category-text.off {
  color: var(--Grey-grey-700);
}

.button {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .event-detail-wrap {
    padding-top: 36px;
  }

  .inner {
    margin-bottom: 100px;
  }

  .event-detail-header {
    padding-bottom: 30px;
    margin-bottom: 60px;
  }

  .event-info {
    gap: 28px;
  }

  .category {
    margin-bottom: 30px;
  }

  .event-title {
    font-size: 34px;
    line-height: 36px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .event-date {
    font-size: 20px;
  }

  .event-period {
    font-size: 22px;
  }

  .event-desc {
    font-size: 25px;
    margin-bottom: 28px;
  }

  .category {
    height: 28px;
    padding: 5px 15px;
  }

  .category.event {
    height: 28px;
    padding: 5px 15px;
  }

  .category-text.event {
    height: 18px;
    font-size: 14.799px;
  }

  .button {
    width: 160px;
  }
}
</style>
