<template>
  <div class="search-wrapper container-default">
    <div class="search-title">{{ searchTitle }}</div>
    <div class="search-form-wrapper">
      <CruiseInputField v-if="isSearchTypeId" class="name-wrap" :label="$t('labels.name')" required>
        <CruiseInput class="name" :placeholder="$t('labels.inputName')" v-model="userName" :disabled="isVerified" />
      </CruiseInputField>

      <CruiseInputField v-else class="name-wrap" :label="$t('labels.userId')" required>
        <CruiseInput type="userId" class="name" :placeholder="$t('login.inputId')" v-model="userId" />
      </CruiseInputField>

      <PhoneNumberAuth
        v-if="$i18n.locale === 'ko'"
        v-model:isVerified="isVerified"
        v-model:userPhoneNumber="userPhoneNumber"
        :userName="userName"
        :userId="userId"
      />

      <EmailAuth
        v-else
        v-model:isVerified="isVerified"
        v-model:userEmail="emailId"
        v-model:userEmailDomain="emailDomain"
        :userName="userName"
        :userId="userId"
      />

      <CruiseButton class="confirm-btn" @click="search">{{ $t('actions.confirm') }}</CruiseButton>
    </div>

    <LoginSearchModal
      v-model:showModal="isShowLoginModal"
      :title="searchTitle"
      :searchType="searchType"
      :searchResult="searchResult"
    />
  </div>
</template>
<script>
import UserService from '@/services/user';

import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import LoginSearchModal from '@/components/login/LoginSearchModal.vue';
import PhoneNumberAuth from '@/components/common/PhoneNumberAuth.vue';
import EmailAuth from './EmailAuth.vue';

export default {
  name: 'LoginSearch',
  components: { CruiseInputField, CruiseInput, CruiseButton, LoginSearchModal, PhoneNumberAuth, EmailAuth },
  data() {
    return {
      isShowLoginModal: false,

      userName: '',
      userId: '',
      userPhoneNumber: '',

      emailId: '',
      emailDomain: '',

      isVerified: false,

      searchResult: {},
    };
  },
  computed: {
    searchType() {
      return this.$route.params.type;
    },
    searchTitle() {
      return this.isSearchTypeId ? this.$t('login.findId') : this.$t('login.findPassword');
    },
    isSearchTypeId() {
      return this.searchType === 'id';
    },
    convertedEmail() {
      return `${this.emailId}@${this.emailDomain}`;
    },
  },
  watch: {
    searchType() {
      window.location.reload();
    },
  },
  methods: {
    searchValidation() {
      if (this.isSearchTypeId && !this.userName.trim()) {
        this.$alert({ content: this.$t('messages.enterName') });
        return;
      }

      if (!this.isSearchTypeId && !this.userId.trim()) {
        this.$alert({ content: this.$t('messages.enterUserId') });
        return;
      }

      if (this.$i18n.locale === 'ko' && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completePhoneVerification') });
        return;
      }

      if (this.$i18n.locale !== 'ko' && !this.isVerified) {
        this.$alert({ content: this.$t('messages.completeEmailVerification') });
        return;
      }

      return true;
    },
    async getSearchResult() {
      try {
        let params = {
          mobile: this.$i18n.locale === 'ko' ? this.userPhoneNumber : null,
          email: this.$i18n.locale !== 'ko' ? this.convertedEmail : null,
        };

        if (this.isSearchTypeId) {
          params.name = this.userName;
        } else {
          params.userId = this.userId;
        }

        const { data } = this.isSearchTypeId
          ? await UserService.getUserId(params)
          : await UserService.getNewPassword(params);

        this.searchResult = data;

        this.isShowLoginModal = true;
      } catch (error) {
        this.$alert({ content: error });
        this.isVerified = false;
      }
    },
    search() {
      if (!this.searchValidation() || !this.isVerified) {
        return;
      }

      this.getSearchResult();
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  width: 100%;
  height: 100%;
  padding: 48px 16px 20px;
  font-size: 12px;
}

.search-wrapper > * {
  width: 100%;
}

.search-title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 48px;
}

.search-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.phone-number-inner {
  display: flex;
  gap: 10px;
  width: 100%;
  height: auto;
}

.country-code {
  width: 100%;
  max-width: 120px;
}

.confirm-btn {
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  .search-wrapper {
    font-size: 14px;
  }

  .search-form-wrapper {
    width: 100%;
    max-width: 484px;
    margin: 0 auto;
    gap: 22px;
  }

  .search-title {
    margin-bottom: 40px;
  }
}
</style>
