<template>
  <div class="trip-item">
    <div class="trip-content">
      <div class="trip-title flex-between">
        <h3 class="trip-name">{{ trip.reservationMaster.category }}</h3>

        <span class="departure-date" v-if="trip.leftDate > 0">
          <template v-if="isEnglish">
            <span class="text-highlight"> {{ trip.leftDate }} </span>
            {{ trip.leftDate === 1 ? 'day' : $t('labels.deforeDays') }}
            before {{ $t('tourProduct.departure').toLowerCase() }}
          </template>
          <template v-else>
            {{ $t('tourProduct.departure') }}
            <span class="text-highlight"> {{ trip.leftDate }} </span>{{ $t('labels.deforeDays') }}
          </template>
        </span>

        <span class="departure-date" v-else-if="trip.leftDate === 0">
          <template v-if="isEnglish">
            <span class="text-highlight"> {{ $t('tourProduct.dayTrip') }}</span>
            of {{ $t('tourProduct.departure').toLowerCase() }}
          </template>
          <template v-else>
            {{ $t('tourProduct.departure') }}
            <span class="text-highlight"> {{ $t('tourProduct.dayTrip') }} </span>
          </template>
        </span>
      </div>

      <div class="trip-info">
        <div class="trip-port flex-between">
          <div class="port-wrapper">
            <div class="departure">
              <div class="port-name">{{ getLocalText(trip.ports.leave.departurePort, 'name') }}</div>
              <div class="date">{{ trip.reservationMaster.departureSchedule.departureExpectDate }}</div>
            </div>

            <div class="port-icon flex-center">
              <SwapIcon
                class="flex-center"
                v-if="!!trip.reservationMaster?.returnSchedule?.departureExpectDate"
                :size="16"
              />
              <ArrowIcon class="flex-center" v-else :size="14" />
            </div>

            <div class="arrival">
              <div class="port-name">{{ getLocalText(trip.ports.leave.arrivalPort, 'name') }}</div>
              <div class="date">
                {{
                  trip.reservationMaster.returnSchedule.departureExpectDate ||
                  trip.reservationMaster.departureSchedule.departureExpectDate
                }}
              </div>
            </div>
          </div>

          <div class="text-vessel">{{ trip.vesselName }}</div>
        </div>

        <div class="reservation-info">
          <div class="reservation-wrapper">
            <div class="reservation-number">{{ $t('reservation.number') }}</div>

            <div>
              <div class="reservation-item" v-for="(item, key, i) in trip.grades.leave">
                {{ $t('labels.cabin') }} {{ i + 1 }} ({{ item }}){{ colonByLocale }}{{ key }}
              </div>
            </div>
          </div>

          <div class="payment-wrapper">
            <div class="payment-method">
              {{ trip.reservationMaster.paymentMethod }}{{ paymentStatus }}<br />
              <template v-if="isPendingWithAccount">
                {{ trip.reservationMaster.paymentResponse.bankname }}{{ colonByLocale
                }}{{ trip.reservationMaster.paymentResponse.account }}
              </template>
            </div>

            <div class="detail" @click="goToDetail">{{ $t('actions.viewDetails') }} ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="trip-image-wrapper">
      <img :src="imgSrc" alt="trip image" />
    </div>
  </div>
</template>

<script>
import { getLocalText } from '@/utils/convert';

import SwapIcon from 'vue-material-design-icons/SwapHorizontal';
import ArrowIcon from 'vue-material-design-icons/ArrowRight';

export default {
  name: 'CruiseTripItem',
  components: { SwapIcon, ArrowIcon },
  props: {
    trip: Object,
    tripState: {
      type: String,
      default: 'future',
      validator: value => ['future', 'past'].includes(value),
    },
  },
  computed: {
    imgSrc() {
      const category = this.trip.reservationMaster.route.code === 'OSC' ? 'osaka' : 'onenight';
      const src = `https://newpanstarcruisestorage.blob.core.windows.net/reservation/${category}.png`;
      return src;
    },
    colonByLocale() {
      const localeColonMap = {
        ko: ': ',
        en: ' : ',
        ja: '：',
      };

      return localeColonMap[this.$i18n.locale] || ' : ';
    },
    paymentStatus() {
      const statusMapping = {
        PND: 'payment.waiting',
        CMP: 'payment.completed',
        EXP: 'payment.expiration',
        CAN: 'payment.cancellation',
      };

      const status = this.trip.reservationMaster.status;

      return status ? `${this.colonByLocale}${this.$t(statusMapping[status]) || ''}` : '';
    },
    isPendingWithAccount() {
      return this.trip.reservationMaster.status === 'PND' && this.trip.reservationMaster.paymentResponse?.account;
    },
    isEnglish() {
      return this.$i18n.locale === 'en';
    },
  },
  methods: {
    getLocalText,

    goToDetail() {
      this.$router.push({
        name: 'trip-detail',
        params: { id: this.trip.reservationMaster.id },
        query: { tripState: this.tripState },
      });
    },
  },
};
</script>

<style scoped>
.trip-item {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 16px;
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
}

.trip-item:last-child {
  border-bottom: none;
}
.trip-content {
  flex: 1 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.trip-title {
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
  padding: 0 0 16px;
}

.trip-name {
  color: var(--Grey-grey-900, #333);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1.5px;
}

.departure-date {
  color: var(--Grey-grey-800, #505458);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.4px;
  text-align: right;
}

.text-highlight {
  font-weight: 600;
  color: var(--Blue-blue-500, #446ceb);
}

.trip-info {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  height: 100%;
}

.trip-port {
  display: flex;
  justify-content: space-between;
}

.text-vessel {
  display: none;
}

.port-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  border-top: 1px solid var(--Grey-grey-50, #f5f7f9);
  border-bottom: 1px solid var(--Grey-grey-50, #f5f7f9);
}

.departure,
.arrival {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}

.port-name {
  color: var(--Grey-grey-800, #505458);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.date {
  color: var(--Grey-grey-700, #686d72);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
}

.port-icon {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  background: #f5f7f9;
  border-radius: 5px;
  color: #575757;
}

.reservation-info {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.reservation-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.reservation-number {
  color: var(--Grey-grey-700, #686d72);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.reservation-item {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.32px;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-method {
  color: var(--Grey-grey-600, #858b92);
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.detail {
  color: var(--Blue-blue-500, #446ceb);
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.trip-image-wrapper {
  width: 100%;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .trip-item {
    height: 420px;
    padding: 60px 0;
    flex-direction: row-reverse;
    gap: 32px;
  }

  .trip-content {
    gap: 24px;
  }

  .trip-title {
    padding: 24px 16px;
  }

  .trip-name {
    font-size: 28px;
  }

  .trip-info {
    justify-content: space-between;
    padding: 0 16px;
  }

  .departure-date {
    font-size: 20px;
  }

  .port-wrapper {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 48px;

    padding: 0;
    border: none;
  }

  .port-name {
    font-size: 22px;
  }

  .date {
    font-size: 20px;
  }

  .reservation-info {
    padding-top: 0;
    align-items: flex-end;
  }

  .text-vessel {
    display: block;
    color: var(--Grey-grey-700, #686d72);
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.4px;
  }

  .reservation-number {
    font-size: 20px;
  }

  .reservation-item {
    font-size: 20px;
  }

  .payment-wrapper {
    justify-content: flex-end;
    gap: 16px;
  }

  .payment-method {
    font-size: 20px;
  }

  .detail {
    font-size: 20px;
  }

  .trip-image-wrapper {
    width: 391px;
    height: 300px;
  }
}
</style>
