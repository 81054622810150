<template>
  <div class="add-coupon-wrapper">
    <span class="add-coupon-label">{{ $t('coupon.inputPromotionCode') }}</span>
    <div class="label-input-wrapper">
      <ReCruiseInput class="add-input" :placeholder="$t('coupon.inputCode')" v-model="couponCode" />
      <ReCruiseButton class="add-btn" @click="applyCoupon">{{ $t('actions.apply') }}</ReCruiseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CouponService from '@/services/coupon';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'AddCoupon',
  components: { ReCruiseInput, ReCruiseButton },
  emits: ['onAddCoupon'],
  data() {
    return {
      couponCode: '',
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    async applyCoupon() {
      try {
        const body = {
          couponCode: this.couponCode,
          pcid: this.user.PCID,
        };
        const { message } = await CouponService.postUserCouponByCouponCode(body);
        this.$alert({ content: message });
        this.couponCode = '';
        this.$emit('onAddCoupon');
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.add-coupon-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label-input-wrapper {
  display: flex;
  gap: 10px;
}

.add-coupon-label {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 14px;
  font-weight: 500;
}

.add-btn {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .add-coupon-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .label-input-wrapper {
    align-items: center;
    gap: 8px;
  }

  .add-coupon-label {
    font-size: 16px;
  }

  .add-input {
    width: 300px;
  }

  .add-btn {
    width: 150px;
  }
}
</style>
