<template>
  <ReCruiseModal
    :showModal="showModal"
    @update:showModal="updateShowModal"
    :title="$t('labels.reservationCancel')"
    autoHeight
  >
    <template #content>
      <div class="account-wrapper">
        <div class="account-header">{{ $t('labels.refundAccountInfo') }}</div>

        <div class="account-bank">
          <CruiseInputField :label="$t('labels.selectBank')" required>
            <ReCruiseSelect
              class="bank-selector"
              v-model="refundAccountInfo.bankCode"
              valueProperty="code"
              :options="bankCodeList"
            >
              <template #option="{ item }">
                {{ item.name }}
              </template>
            </ReCruiseSelect>
          </CruiseInputField>
        </div>

        <div class="account-details">
          <CruiseInputField :label="$t('labels.depositor')" required>
            <ReCruiseInput :placeholder="$t('labels.inputDepositor')" v-model="refundAccountInfo.depositor" />
          </CruiseInputField>
          <CruiseInputField :label="$t('labels.accountNumber')" required>
            <ReCruiseInput
              type="number"
              :placeholder="$t('labels.inputAccountNumber')"
              v-model="refundAccountInfo.account"
            />
          </CruiseInputField>
        </div>

        <div class="warning-text">{{ $t('messages.refundAccountInfoWarning') }}</div>
      </div>

      <div class="flex-end">
        <div class="refund_wrapper">
          <div class="cancel_info">
            <div class="flex-between">
              <div class="cancel_title">{{ $t('labels.cancelPrice') }}</div>
              <div class="cancel_price">
                {{ convertCommaNumber(refundDetail.cancelAmount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                }}{{ getReservationCurrency(reservationNumber) }}
              </div>
            </div>
            <div class="flex-between">
              <div class="cancel_title">{{ $t('labels.cancelFee') }}</div>
              <div class="cancel_fee">
                {{ (refundDetail.cancelFee > 0 && '-' + convertCommaNumber(refundDetail.cancelFee)) || '0'
                }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;' }}{{ getReservationCurrency(reservationNumber) }}
              </div>
            </div>
          </div>

          <div class="refund_info flex-between">
            <div class="refund_title">{{ $t('labels.refundPrice') }}</div>
            <div class="refund_amount">
              {{ convertCommaNumber(refundDetail.refundAmount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
              }}{{ getReservationCurrency(reservationNumber) }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">
        {{ $t('actions.close') }}
      </ReCruiseButton>
      <ReCruiseButton
        class="confirm-btn"
        :theme="confirmButtonTheme"
        :disabled="!isRefundAccountComplete"
        @click="cancelVirtualAccountReservation"
      >
        {{ $t('actions.proceed') }}
      </ReCruiseButton>
    </template>
  </ReCruiseModal>
</template>

<script>
import PaymentService from '@/services/payment';
import { convertCommaNumber } from '@/utils/convert';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import ReCruiseSelect from '@/components/renewal/common/ReCruiseSelect.vue';
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'AccountRefundModal',
  components: { ReCruiseModal, CruiseInputField, ReCruiseSelect, ReCruiseInput, ReCruiseButton },
  emits: ['update:showModal', 'cancelVirtualAccountReservation'],
  props: {
    showModal: Boolean,
    refundDetail: Object,
    reservationNumber: String,
  },
  data() {
    return {
      refundAccountInfo: {
        bankCode: '',
        depositor: '',
        account: '',
      },
      bankCodeList: [],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    mobileClass() {
      return this.isMobile ? 'mobile' : '';
    },
    confirmButtonTheme() {
      return this.isRefundAccountComplete ? 'primary' : 'disabled';
    },
    isRefundAccountComplete() {
      return this.refundAccountInfo.bankCode && this.refundAccountInfo.depositor && this.refundAccountInfo.account;
    },
  },
  methods: {
    convertCommaNumber,

    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },
    async getBankCodeList() {
      const { data } = await PaymentService.getBankCodeList();
      this.bankCodeList = data;
    },
    cancelVirtualAccountReservation() {
      if (this.isRefundAccountComplete) {
        this.$emit('cancelVirtualAccountReservation', this.refundAccountInfo);
        return;
      } else {
        this.$alert({ content: this.$t('messages.refundAccountInfoRequired') });
      }
    },
    getReservationCurrency(reservationNumber) {
      const reservationType = reservationNumber.substring(0, 2);
      return reservationType === 'WR' ? this.$t('currency.krw') : this.$t('currency.jpy');
    },
  },
  async created() {
    await this.getBankCodeList();
  },
  beforeDestroy() {
    document.body.style.overflow = 'hidden';
  },
};
</script>

<style scoped>
.account-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 36px;
}

.account-header {
  font-size: 16px;
  font-weight: 600;
}

:deep(.options-wrapper) {
  height: 200px;
  overflow-y: auto;
}

.bank-selector {
  height: 40px;
}

.account-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

:deep(.input-label) {
  font-size: 14px;
  font-weight: 500;
}

.warning-text {
  font-size: 14px;
  color: var(--Orange-orange-500);
}

.refund_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cancel_info {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #dddfe2;
}

.cancel_title,
.cancel_price,
.cancel_fee {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 14px;
  font-weight: 500;
}

.refund_title {
  color: var(--Grey-grey-900);
  font-size: 16px;
  font-weight: 500;
}

.refund_amount {
  color: var(--Grey-grey-900);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

@media screen and (min-width: 768px) {
  :deep(.modal-contents) {
    width: 100%;
    max-width: 1070px;
  }

  .mobile {
    display: none !important;
  }

  :deep(.options-wrapper) {
    top: 42px;
  }

  :deep(.option-item) {
    line-height: 40px;
    height: 40px;
  }

  .account-wrapper {
    margin-bottom: 24px;
  }

  .bank-selector {
    /* width: calc(50% - 12px); */
  }

  .account-details {
    flex-direction: row;
    gap: 24px;
  }

  .refund_wrapper {
    width: 50%;
  }

  .cancel_title,
  .cancel_price,
  .cancel_fee {
    font-size: 14px;
  }

  .refund_title {
    font-size: 16px;
    font-weight: 500;
  }

  .refund_amount {
    font-size: 22px;
    font-weight: 600;
  }

  :deep(.modal-btns) {
    width: 100%;
    max-width: 1070px;
  }

  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 94px;
  }
}
</style>
