<template>
  <div class="tour-schedule-wrapper">
    <div class="tour-header">
      <div class="tour-sub" v-if="term">{{ term }}</div>
      <h1 class="tour-title">{{ getLocalText(tour.packageTour, 'name') }}</h1>
      <div class="tour-route" v-if="routeTag">{{ routeTag }}</div>
    </div>

    <div class="tour-contents">
      <div class="grade-select-wrapper">
        <div v-if="$i18n.locale === 'en'" class="header-title">
          <span class="blue-text"> {{ totalScheduleCount }} </span> {{ $t('tourProduct.availableSchedules') }}
        </div>

        <div v-else class="header-title">
          {{ $t('tourProduct.availableSchedules') }} <span class="blue-text"> {{ totalScheduleCount }} </span
          >{{ $t('tourProduct.count') }}
        </div>

        <!-- Lowest price tab by grade -->
        <ul class="grade-tab-wrapper">
          <li
            v-for="grade in tour.minimumPrices"
            class="schedule-tab-box"
            :class="{ active: selectedGrade?.gradeCode === grade.gradeCode }"
            @click="selectGradeTab(grade)"
          >
            <div>
              <div class="grade-text">{{ grade.name }}</div>
              <div class="grade-price">{{ convertCommaNumber(grade.price) }}~</div>
            </div>
          </li>
        </ul>
      </div>

      <div class="recommend-schedule-wrapper">
        <div class="header-title blue-text">{{ $t('tourProduct.recommendedJourney') }}</div>
        <VoyageInfoItem
          :item="recommendTour"
          :active="selectedScheduleIdx === -1"
          :selectedGrade="selectedGrade"
          :price="tour.packageTour.packagePrice"
          @onClick="selectSchedule(recommendTour, -1)"
        />
      </div>

      <div class="voyage-schedule-wrapper">
        <template v-for="(arr, yearMonth) in scheduleList">
          <div class="header-title">{{ formatMonthYear(yearMonth) }}</div>
          <ul class="voyage-list">
            <VoyageInfoItem
              v-for="(item, idx) in arr"
              :key="`${yearMonth}${idx}`"
              :item="item"
              :active="`${yearMonth}${idx}` === selectedScheduleIdx"
              :selectedGrade="selectedGrade"
              :price="tour.packageTour.packagePrice"
              @onClick="selectSchedule(item, `${yearMonth}${idx}`)"
            />
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { convertCommaNumber, getLocalText } from '@/utils/convert';
import VoyageInfoItem from '@/components/tour/VoyageInfoItem';

export default {
  name: 'TourScheduleItem',
  emits: ['update:selectedSchedule', 'update:selectedGrade'],
  components: { VoyageInfoItem },
  props: { tour: Object, selectedGrade: Object, selectedSchedule: Object },
  data() {
    return {
      selectedScheduleIdx: null,
    };
  },
  computed: {
    term() {
      const { isRound, term } = this.tour.packageTour;
      const nightText = term - 1 === 1 ? 'Night' : 'Nights';
      const termText =
        this.$i18n.locale === 'en'
          ? `${term - 1} ${nightText} ${term} Days`
          : `${term - 1}${this.$t('tourProduct.night')} ${term}${this.$t('tourProduct.day')}`;

      return isRound ? (term > 1 ? termText : this.$t('tourProduct.dayTrip')) : '';
    },
    routeTag() {
      return this.getLocalText(this.tour.packageTour, 'tagRoute');
    },
    scheduleList() {
      return this.tour.packageTour.packageTourSchedule.reduce((acc, item) => {
        const yearMonth = item.leaveDate.slice(0, 6);
        if (!acc[yearMonth]) {
          acc[yearMonth] = [];
        }
        acc[yearMonth].push(item);
        return acc;
      }, {});
    },
    totalScheduleCount() {
      return Object.values(this.scheduleList).reduce((acc, arr) => acc + arr.length, 0);
    },
    recommendTour() {
      return this.findRecommandedVoyage(this.tour.packageTour.packageTourSchedule);
    },
  },
  methods: {
    convertCommaNumber,
    getLocalText,

    formatMonthYear(dateString) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const local = this.$i18n.locale;

      return local !== 'en'
        ? `${year}${this.$t('time.year')} ${parseInt(month)}${this.$t('time.month')}`
        : `${year}.${parseInt(month)}`;
    },
    selectGradeTab(selectedGrade) {
      this.selectedScheduleIdx = null;
      this.$emit('update:selectedSchedule', null);
      this.$emit('update:selectedGrade', selectedGrade);
    },
    selectSchedule(schedule, idx) {
      this.selectedScheduleIdx = idx;
      this.$emit('update:selectedSchedule', schedule);
    },
    findRecommandedVoyage(voyages) {
      if (voyages.length === 0) return null;

      let latestVoyage = voyages[0];

      voyages.forEach(voyage => {
        if (voyage.leaveDate < latestVoyage.leaveDate) {
          latestVoyage = voyage;
        } else if (voyage.leaveDate === latestVoyage.leaveDate) {
          const voyageGrade = voyage.packageTourGrades.find(
            item => item.grade.gradeCode === this.selectedGrade.gradeCode,
          );
          const latestVoyageGrade = latestVoyage.packageTourGrades.find(
            item => item.grade.gradeCode === this.selectedGrade.gradeCode,
          );
          latestVoyage =
            voyageGrade.leavePrice + (voyageGrade?.returnPrice || 0) <
            latestVoyageGrade.leavePrice + (latestVoyageGrade?.returnPrice || 0)
              ? voyage
              : latestVoyage;
        }
      });

      return latestVoyage;
    },
  },
};
</script>

<style scoped>
.tour-header {
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.tour-sub {
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.7px;
  margin-bottom: 2px;
}

.tour-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}

.tour-route {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--Blue-blue-500);
  background: var(--White);
  color: var(--Blue-blue-500);
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
}

.tour-contents {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.grade-select-wrapper {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--Grey-grey-50);
}

.recommend-schedule-wrapper,
.voyage-schedule-wrapper {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.voyage-schedule-wrapper:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.header-title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;
}
.header-title:first-child {
  margin-top: 0;
}

.blue-text {
  color: var(--Blue-blue-500);
}

.grade-tab-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.schedule-tab-box {
  width: calc(33.333333% - 5.333333px);
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100);
  cursor: pointer;
  text-align: center;
}

.schedule-tab-box.active {
  color: var(--White);
  border: 1px solid var(--Blue-blue-500);
  background: var(--Blue-blue-500);
}

.grade-text {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  word-break: keep-all;
  margin-bottom: 7px;
}

.grade-price {
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
}

.voyage-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (min-width: 768px) {
  .grade-tab-wrapper {
    gap: 20px;
  }

  .schedule-tab-box {
    width: calc(33.333333% - 13.333333px);
  }
}
</style>
