<template>
  <div class="input-wrapper" :class="size">
    <input
      class="cruise-input text-ellipsis"
      :class="inputClass"
      :id="safeId"
      :type="inputType"
      v-bind="attrs"
      autocomplete="off"
      placeholder=""
      :value="modelValue"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      :disabled="disabled"
      :title="modelValue"
    />
    <label
      v-if="!modelValue && attrs.placeholder"
      class="input-placeholder text-ellipsis"
      :for="safeId"
      :title="attrs.placeholder"
    >
      {{ attrs.placeholder }}<span v-if="required" class="required-star">*</span>
    </label>
  </div>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';
import { decodeHtmlEntities } from '@/utils/decodeHtmlEntities';
import { isFullWidth, toHalfWidth } from '@/utils/textWidthConverter';

export default {
  name: 'ReCruiseInput',
  emits: ['update:modelValue', 'onBlur', 'onFocus', 'onInput'],
  mixins: [sharedComputedProps],
  props: {
    modelValue: [String, Number],
    required: Boolean,
    isError: Boolean,
    regexFilter: [Object, String],
    regexReplace: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator: val => ['md', 'lg'].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputType() {
      return this.$attrs.type || 'text';
    },
    inputClass() {
      return {
        error: this.isError,
      };
    },
  },
  methods: {
    handleBlur(e) {
      this.$emit('onBlur', e);
    },
    handleFocus(e) {
      this.$emit('onFocus', e);
    },
    handleInput(e) {
      const filteredValue = e.target.value.replace(this.regexFilter, this.regexReplace);
      let sanitizedValue = this.$sanitize(filteredValue);

      if (isFullWidth(sanitizedValue)) {
        sanitizedValue = toHalfWidth(sanitizedValue);
      }

      if (
        this.inputType === 'userId' ||
        this.inputType === 'password' ||
        this.inputType === 'emailAddress' ||
        this.inputType === 'emailDomain'
      ) {
        e.target.value = decodeHtmlEntities(sanitizedValue).replaceAll(' ', '');
      } else {
        e.target.value = decodeHtmlEntities(sanitizedValue);
      }

      this.$emit('update:modelValue', e.target.value);
      this.$emit('onInput', e);
    },
  },
};
</script>

<style scoped>
.input-wrapper {
  position: relative;
  width: 100%;
  border-radius: 5px;
  background: var(--White);
  color: var(--Grey-grey-900, #333);
}

.md {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
}

.lg {
  height: 49px;
  font-size: 16px;
  font-weight: 500;
}

.cruise-input {
  font-size: 14px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  border-radius: inherit;
  text-align: inherit;
  padding: 0 16px;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
}

.cruise-input:focus {
  border: 1px solid var(--Blue-blue-500, #446ceb);
}

.cruise-input.error {
  border: 1px solid var(--Orange-orange-500, #f64852);
}

.input-placeholder {
  display: inline-block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 1px;
  padding: 0 16px;
  transform: translateY(-50%);
  color: var(--Grey-grey-300, #b6bbbf);
  user-select: none;
}

.required-star {
  color: var(--Orange-orange-500);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
