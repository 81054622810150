<template>
  <div class="list-wrapper">
    <div class="list-header" @click="handleClick">
      <div class="list-category" :class="noCategoryClass">
        <div class="category" :class="noCategoryClass">
          {{ category || emphasis }}
        </div>
      </div>

      <div class="list-title" :class="noCategoryClass">{{ title }}</div>
    </div>

    <div class="list-desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'CruiseListItem',
  emits: ['onClick'],
  components: {},
  props: {
    category: String,
    emphasis: String,
    title: String,
    desc: String,
  },
  data() {
    return {};
  },
  computed: {
    noCategoryClass() {
      return { 'no-category': !this.category && !this.emphasis };
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 1px solid var(--Grey-grey-200);
}

.list-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.list-category {
  width: 40px;
  height: 20px;
  margin-bottom: 15px;
}

.list-category.no-category {
  display: none;
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: var(--Blue-blue-500);
  color: var(--White);
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.list-title {
  font-size: 16px;
  font-weight: 600;
}

.category.no-category {
  display: none;
}

.list-title.no-category {
  font-weight: 400;
  line-height: 150%;
}

.list-desc {
  display: flex;
  align-items: center;
  height: 30px;
  color: var(--Grey-grey-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (min-width: 768px) {
  .list-wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 28px 0;
  }

  .list-header {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 80%;
  }

  .list-category {
    width: 48px;
    height: 22px;
    margin-bottom: 0;
  }

  .list-category.no-category {
    display: block;
  }

  .category {
    font-size: 12px;
  }

  .list-title {
    font-size: 20px;
  }

  .list-desc {
    font-size: 18px;
  }
}
</style>
