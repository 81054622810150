<template>
  <div class="confirm-background flex-center">
    <div class="confirm-wrap">
      <div class="confirm-content">
        <div class="confirm-title" v-if="message?.title">
          <span>{{ message.title }}</span>
        </div>

        <div class="content-info" v-if="message?.content">
          <span>{{ message.content }}</span>
        </div>
      </div>

      <div class="button-wrap">
        <ReCruiseButton class="cancel-btn" theme="outline" @click="cancel">{{ $t('actions.cancel') }}</ReCruiseButton>
        <ReCruiseButton class="confirm-btn" @click="confirm">
          {{ message?.confirmBtnText ? message.confirmBtnText : $t('actions.confirm') }}
        </ReCruiseButton>
      </div>
    </div>
  </div>
</template>

<script>
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'CruiseConfirm',
  components: { ReCruiseButton },
  emits: ['confirm', 'cancel'],
  props: { message: Object },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
    applyOverflowHidden(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
  created() {
    this.applyOverflowHidden(true);
  },
  beforeUnmount() {
    this.applyOverflowHidden(false);
  },
};
</script>

<style scoped>
.confirm-background {
  position: fixed;
  z-index: var(--zLayoutLoading);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(238, 244, 248, 0.6);
  backdrop-filter: blur(4px);
  text-align: center;
  word-break: keep-all;
  padding: 0 20px;
}

.confirm-wrap {
  display: flex;
  flex-direction: column;
  background: var(--White);
  border-radius: 15px;
  width: 357px;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.07), 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.confirm-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 20px;
  overflow: auto;
}

.confirm-title {
  width: 100%;
  color: var(--Grey-grey-900, #333);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -1.5px;
  white-space: pre-wrap;
}
.content-info {
  white-space: pre-wrap;
  color: var(--Grey-grey-700, #686d72);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.button-wrap {
  display: flex;
}

.cancel-btn {
  border: none;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
  background: #fff;
  border-radius: 0;
  width: 100%;
  height: 55px;
}

.cancel-btn:hover {
  border: none;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
}

.confirm-btn {
  width: 100%;
  height: 55px;
  border-radius: 0;
}
</style>
