<template>
  <div class="panel-wrapper">
    <div class="panel-left">
      <!-- Route Type Select -->
      <div v-show="isShow" ref="routeWrap" class="way-select-wrapper">
        <SelectModalOnlyMobile
          v-model="searchState.isRoundTrip"
          v-model:isOpen="isOpenRouteTypeOptions"
          valueProperty="isRoundTrip"
          :options="routeTypeList"
          :title="$t('labels.selectRouteType')"
          @onChange="initDateRangeByRouteType"
        >
          <template #option="{ item }">
            <span :title="$t(item.label)">{{ $t(item.label) }}</span>
          </template>
        </SelectModalOnlyMobile>
      </div>

      <!-- Departure/Arrival Select  -->
      <div class="port-select-wrapper flex-between" ref="portWrap">
        <SelectGroupModalOnlyMobile
          v-model="searchState.departure"
          :isOpen="isOpenDepartureOptions"
          valueProperty="portCode"
          :options="departureList"
          :title="$t('labels.selectDeparture')"
          :placeholder="$t('labels.selectDeparture')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleDeparture"
          @onChange="selectDeparture"
        >
          <template #option="{ item }">
            <span :title="item.portName">{{ item.portName }}</span>
          </template>
        </SelectGroupModalOnlyMobile>

        <div v-show="isShow" class="port-icon flex-center">
          <img :src="arrowIcon" alt="arrow port icon" />
        </div>

        <SelectGroupModalOnlyMobile
          v-show="isShow"
          v-model="searchState.arrival"
          :isOpen="isOpenArrivalOptions"
          valueProperty="portCode"
          :options="arrivalList"
          :title="$t('labels.selectArrival')"
          :placeholder="$t('labels.selectArrival')"
          :isArrowIcon="false"
          :isBorder="false"
          @onClick="toggleArrival"
          @onChange="selectArrival"
        >
          <template #option="{ item }">
            <span :title="item.portName">{{ item.portName }}</span>
          </template>
        </SelectGroupModalOnlyMobile>
      </div>

      <!-- Seat/Passenger Select -->
      <div class="passenger-count-select-wrapper" ref="passengerCountWrap" @click="isClickPort">
        <ReCabinPassengerCountInput
          :cabinLength="passengerTypeCountList.length"
          :totalPassenger="totalPassengerCount.total"
          @onClick="togglePassengerCountLayer"
        />
      </div>

      <!-- Departure/Arrival Date Select -->
      <div class="date-select-wrapper" ref="calendarWrap" @click="isClickPort">
        <ReCruiseDatePicker
          v-model:dateRange="dateRange"
          :range="searchState.isRoundTrip"
          :label="dateLabel"
          :disabled="disableSelectDateDropbox"
          @update:dateRange="selectDate"
          @onClick="toggleCalendar"
        />
      </div>
    </div>

    <!-- Search Button -->
    <div class="panel-right">
      <ReCruiseButton class="search-btn" :disabled="isSearchDisabled" @click="goBookingPage">
        {{ $i18n.locale !== 'ja' ? $t('actions.reserve') : $t('actions.search') }}
      </ReCruiseButton>
    </div>
  </div>

  <!-- Cabin/Passenger Layer -->
  <RePassengerCountModal
    v-model:showModal="isOpenPassengerCountLayer"
    :maxCount="maxCount"
    :modalTitle="$t('labels.selectCabinPassengers')"
    :typeText="$t('labels.cabin')"
    :noticeList="convertedNoticeList"
    :passengerTypeCountList="passengerTypeCountList"
    :departure="searchState.departure"
    @update:addCabin="addCabin"
    @update:removeCabin="removeCabin"
    @selectedConfirm="togglePassengerCountLayer"
  />

  <!-- Calendar Layer -->
  <ReCalendarModal
    v-model:showModal="isOpenCalendar"
    v-model:dateRange="dateRange"
    :range="searchState.isRoundTrip"
    :dateRange="dateRange"
    :allowedDate="filteredScheduleList"
    @update:dateRange="selectDate"
    :label="dateLabel"
    :disabled="disableSelectDateDropbox"
    :doubleCalendar="true"
    @selectedConfirm="toggleCalendar"
  />
</template>

<script>
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';
import { YYYYMMDD } from '@/const/const';

import ReservationService from '@/services/reservation';
import GradeService from '@/services/grade';
import ReCruiseDatePicker from '@/components/renewal/common/ReCruiseDatePicker.vue';
import ReCabinPassengerCountInput from '@/components/renewal/common/ReCabinPassengerCountInput.vue';
import ReCalendarModal from '@/components/renewal/home/ReCalendarModal.vue';
import RePassengerCountModal from '@/components/renewal/home/RePassengerCountModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import SelectModalOnlyMobile from '@/components/renewal/modal/SelectModalOnlyMobile.vue';
import SelectGroupModalOnlyMobile from '@/components/renewal/modal/SelectGroupModalOnlyMobile.vue';

export default {
  name: 'ReCruiseSearchPanel',
  emits: ['update:isPositionUp'],
  props: {
    isPositionUp: Boolean,
  },
  components: {
    ReCruiseDatePicker,
    ReCabinPassengerCountInput,
    ReCalendarModal,
    RePassengerCountModal,
    ReCruiseButton,
    SelectModalOnlyMobile,
    SelectGroupModalOnlyMobile,
  },
  data() {
    return {
      defaultPassenger: { adult: 1, child: 0, infant: 0 },
      routeTypeList: [
        {
          isRoundTrip: true,
          label: 'labels.roundTrip',
        },
        {
          isRoundTrip: false,
          label: 'labels.oneWay',
        },
      ],
      currentDate: dayjs().format(YYYYMMDD),
      departureList: [],
      arrivalList: [],

      searchState: { departure: null, arrival: null, isRoundTrip: true },

      dateRange: { from: null, to: null },
      dateLabel: { from: 'labels.goingDay', to: 'labels.comingDay' },

      isOpenRouteTypeOptions: false, // Route Type Layer
      isOpenDepartureOptions: false, // Departure Select Layer
      isOpenArrivalOptions: false, // Arrival Select Layer
      isOpenCalendar: false, // Departure/Arrival Date Select Calendar
      isOpenPassengerCountLayer: false, // Cabel/Passenger Select Layer

      scheduleList: [],
      departureSchedule: [],

      passengerTypeCountList: [],
      maxCount: 3,
      isShow: true,

      voyageNo: { leaveVoyageNo: null, returnVoyageNo: null },
      schedule: { from: null, to: null },

      availableGradeList: null,

      passengerInfo: {
        type: '',
        name: { value: '', required: true, error: false, message: 'passenger.name' },
        country: { value: '', required: true, error: false, message: 'profile.nationality' },
        year: { value: '', required: true, error: false, message: 'time.year' },
        month: { value: '', required: true, error: false, message: 'time.month' },
        day: { value: '', required: true, error: false, message: 'time.day' },
        sex: { value: '', required: true, error: false, message: 'profile.gender' },
        firstNameEn: { value: '', required: true, error: false, message: 'profile.firstName' },
        lastNameEn: { value: '', required: true, error: false, message: 'profile.lastName' },
        passportNumber: { value: '', required: true, error: false, message: 'profile.passportNumber' },
        // countryNumber: { value: '', required: true, error: false, message: 'profile.countryCode' },
        mobile: { value: '', required: true, error: false, message: 'labels.phoneNumber' },
        address: '',
        email: '',
        emailDomain: '',
        emailTemplate: '',

        leaveGradeCode: '',
        leavePriceAmount: 0,
        leaveDiscountCode: '',
        leaveDiscountAppliedAmount: 0,
        leaveSingleCharge: 0,
        leaveMealCode: '',
        leaveMealPrice: 0,

        leaveMileageAmount: 0,
        leaveShuttleAmount: 0,

        returnGradeCode: '',
        returnPriceAmount: 0,
        returnDiscountCode: '',
        returnDiscountAppliedAmount: 0,
        returnSingleCharge: 0,
        returnMealCode: '',
        returnMealPrice: 0,
      },

      needsPortSelection: false,
      isInitDate: false,
    };
  },
  watch: {
    passengerTypeCountList: {
      handler() {
        this.dateRange = { from: null, to: null };
      },
      deep: true,
    },
  },
  computed: {
    totalPassengerCount() {
      return this.passengerTypeCountList.reduce((acc, cur) => {
        Object.keys(cur).forEach(key => {
          acc[key] = (acc[key] || 0) + cur[key];
          acc.total = (acc.total || 0) + cur[key];
        });
        return acc;
      }, {});
    },
    canSearch() {
      if (this.searchState.isRoundTrip) {
        const searchStateValues = Object.values(this.searchState);
        const dateRangeValues = Object.values(this.dateRange);
        const combinedValuesForRoundTrip = [...searchStateValues, ...dateRangeValues];

        return combinedValuesForRoundTrip.every(value => !!value);
      } else {
        const searchStateValuesWithoutIsRoundTrip = Object.entries(this.searchState)
          .filter(([key, _]) => key !== 'isRoundTrip')
          .map(([_, value]) => value);
        const dateValues = [this.dateRange.from];
        const combinedValuesForOneWay = [...searchStateValuesWithoutIsRoundTrip, ...dateValues];

        return combinedValuesForOneWay.every(value => !!value);
      }
    },
    isSearchDisabled() {
      return !this.canSearch;
    },
    isPortSelectionComplete() {
      if (this.searchState.departure?.highlight) {
        return false;
      }
      return !this.searchState.departure || !this.searchState.arrival;
    },
    disableSelectDateDropbox() {
      if (this.dateRange.from && this.dateRange.to) {
        return false;
      }
      return !this.dateRange.from || !this.dateRange.to;
    },
    filteredScheduleList() {
      return this.scheduleList?.map(schedule => schedule.departureExpectDt);
    },
    convertedNoticeList() {
      const noticeList = ['reservation.guide.max3Reservations'];

      if (!this.searchState.departure?.highlight) {
        noticeList.push('reservation.guide.oneNight.passengersAge');
        noticeList.push('reservation.guide.oneNight.vehicle');
      } else {
        noticeList.push('reservation.guide.cruise.passengersAge');
      }
      return noticeList;
    },
    checkOneNightPassenger() {
      return (
        this.searchState.departure?.highlight && this.passengerTypeCountList.some(item => item.adult + item.infant < 2)
      );
    },
    arrowIcon() {
      const getFileName = baseName => (this.needsPortSelection ? `${baseName}_red` : baseName);

      const isRoundTrip = this.searchState.isRoundTrip;
      const baseFileName = isRoundTrip ? 'swap_icon' : 'arrow_right_icon';

      return require(`@/assets/images/icon/${getFileName(baseFileName)}.svg`);
    },
  },
  methods: {
    ...mapMutations('booking', ['updateSearchBookingInfo']),

    isClickPort() {
      this.isLayoutUp = !this.isLayoutUp;
      if (!this.searchState.departure || !this.searchState.arrival) {
        let element = this.$refs.portWrap;
        element.classList.add('shake-animation');
        this.needsPortSelection = true;

        setTimeout(() => {
          element.classList.remove('shake-animation');
          this.needsPortSelection = false;
        }, 500);
      }
    },
    initDateRangeByRouteType() {
      this.dateRange = { from: null, to: null };
      this.schedule = { from: null, to: null };
    },
    toggleDeparture() {
      this.isOpenDepartureOptions = !this.isOpenDepartureOptions;
      this.isOpenArrivalOptions = false;
    },
    async selectDeparture(departure) {
      this.dateRange = { from: null, to: null };
      this.searchState.departure = departure;
      this.isOpenDepartureOptions = false;
      this.defaultPassenger.adult = 1;

      if (departure.highlight) {
        this.isShow = false;
        this.isOpenArrivalOptions = false;
        this.searchState.isRoundTrip = false;
        this.defaultPassenger.adult = 2;

        this.passengerTypeCountList.forEach(item => {
          item.adult = Math.max(2, item.adult);
          item.child = 0;
        });
        this.searchState.arrival = this.departureList.find(el => el.portName === departure.portName);
        this.departureSchedule = await this.getScheduleList(this.searchState.departure, this.searchState.arrival);
        this.scheduleList = this.departureSchedule;
      } else {
        this.isShow = true;
        this.setArrivalList();
        this.isOpenArrivalOptions = true;
        this.searchState.arrival = null;
      }
    },
    toggleArrival() {
      if (!this.searchState.departure) {
        this.toggleDeparture();
        return;
      }
      this.isOpenDepartureOptions = false;

      if (this.arrivalList.length && this.searchState.isRoundTrip) {
        this.isOpenArrivalOptions = !this.isOpenArrivalOptions;
      }
    },
    setArrivalList() {
      const departureInfo = this.searchState.departure;
      this.arrivalList = !departureInfo ? [] : departureInfo.portInfo;
    },
    togglePassengerCountLayer() {
      if (this.isPortSelectionComplete) {
        this.isOpenPassengerCountLayer = false;
        return;
      }
      this.isOpenPassengerCountLayer = !this.isOpenPassengerCountLayer;
      this.$emit('update:isPositionUp', this.isOpenPassengerCountLayer);
    },
    handleClickOutside(e) {
      if (!this.$refs.routeWrap.contains(e.target)) {
        this.isOpenRouteTypeOptions = false;
      }

      if (!this.$refs.portWrap.contains(e.target)) {
        this.isOpenDepartureOptions = false;
        this.isOpenArrivalOptions = false;
      }

      if (!this.$refs.calendarWrap.contains(e.target)) {
        if (!this.isInitDate) this.isOpenCalendar = false;
        this.isInitDate = false;
      }

      if (!this.$refs.passengerCountWrap.contains(e.target)) {
        this.isOpenPassengerCountLayer = false;
      }

      if (!this.isOpenCalendar && !this.isOpenPassengerCountLayer) {
        this.$emit('update:isPositionUp', false);
      }
    },
    addCabin() {
      if (this.maxCount <= this.passengerTypeCountList.length) {
        return;
      }

      const cabin = { ...this.defaultPassenger };
      this.passengerTypeCountList.push(cabin);
    },
    removeCabin(index) {
      this.passengerTypeCountList.splice(index, 1);
    },
    async selectArrival(arrival) {
      this.searchState.arrival = arrival;
      this.isOpenArrivalOptions = false;
      this.departureSchedule = await this.getScheduleList(this.searchState.departure, this.searchState.arrival);
      this.scheduleList = this.departureSchedule;
    },
    async getDepartureList() {
      try {
        const { data } = await ReservationService.getDeparturePortList({ voyageType: 'cruise' });
        this.departureList = data.map(item => ({
          ...item,
          highlight: item.portInfo.some(port => item.portCode === port.portCode),
        }));
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    findSchedule(date) {
      return this.scheduleList.find(schedule => date.isSame(schedule.departureExpectDt, 'date'));
    },
    async handleCabinListRequest(from, to, fromSchedule, toSchedule) {
      const { arrival, departure, isRoundTrip } = this.searchState;
      const body = {
        leaveVoyageNumber: fromSchedule?.voyageNo || null,
        returnVoyageNumber: toSchedule?.voyageNo || null,
        count: this.passengerTypeCountList,
      };

      try {
        await GradeService.getAvailableCabinList(body);
      } catch (error) {
        if (!this.isInitDate) this.isInitDate = true;

        if (from && !to) {
          this.dateRange = { from: null, to: null };

          this.voyageNo.leaveVoyageNo = null;
          if (this.voyageNo.returnVoyageNo) this.voyageNo.returnVoyageNo = null;

          this.schedule.from = null;
          if (this.schedule.to) this.schedule.to = null;

          if (isRoundTrip) {
            this.scheduleList = this.departureSchedule;
          }
        }

        if (from && to) {
          this.dateRange.to = null;

          this.voyageNo.returnVoyageNo = null;

          this.schedule.to = null;
          this.scheduleList = await this.getScheduleList(arrival, departure, from.format(YYYYMMDD));
        }

        this.$alert({ content: error });
      }
    },
    async selectDate(dateRange) {
      const { from, to } = dateRange;
      const { arrival, departure, isRoundTrip } = this.searchState;

      // one night
      const isOneNightCruise = departure.highlight && departure.portCode === 'PUS';
      if (isOneNightCruise) {
        const fromSchedule = this.findSchedule(from);
        this.schedule = { from: fromSchedule, to: null };
        this.voyageNo = { leaveVoyageNo: fromSchedule.voyageNo, returnVoyageNo: null };

        await this.handleCabinListRequest(from, to, fromSchedule);
        return;
      }

      // cruise
      if (from && !to) {
        const fromSchedule = this.findSchedule(from);
        this.schedule.from = fromSchedule;
        this.voyageNo.leaveVoyageNo = fromSchedule.voyageNo;

        if (isRoundTrip) {
          this.scheduleList = await this.getScheduleList(arrival, departure, from.format(YYYYMMDD));
        }

        await this.handleCabinListRequest(from, to, fromSchedule);
        return;
      }

      if (from && to) {
        const toSchedule = this.findSchedule(to);
        this.schedule.to = toSchedule;
        this.voyageNo.returnVoyageNo = toSchedule.voyageNo;
        this.scheduleList = this.departureSchedule;

        await this.handleCabinListRequest(from, to, this.schedule.from, toSchedule);
      }
    },
    async getScheduleList(departure, arrival, date = this.currentDate) {
      try {
        const query = { date, departurePort: departure.portCode, arrivalPort: arrival.portCode };

        // cruise
        if (departure.portCode === 'OSA' || arrival.portCode === 'OSA') {
          const { data } = await ReservationService.getOsakaVoyageSchedule(query);
          return data;
        }

        // one night
        if (departure.highlight && departure.portCode === 'PUS') {
          const { data } = await ReservationService.getOneNightVoyageSchedule(date);
          return data;
        }
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
    async getAvailableCabinList(leaveVoyageNumber, returnVoyageNumber) {
      try {
        const body = {
          leaveVoyageNumber,
          returnVoyageNumber,
          count: this.passengerTypeCountList,
        };
        const { data } = await GradeService.getAvailableCabinList(body);
        this.availableGradeList = data;
      } catch (error) {
        throw error;
      }
    },
    async goBookingPage() {
      try {
        const isBusanPort =
          this.schedule.from.seasonType === 'ONC0' ||
          this.schedule.from.seasonType === 'ONC1' ||
          this.schedule.from.seasonType === 'ON24';

        if (!this.canSearch) {
          alert(this.$t('messages.setSearchConditions'));
          return;
        }

        if (this.checkOneNightPassenger) {
          return await this.$alert({ content: this.$t('messages.min2PeopleReservation') });
        }

        await this.getAvailableCabinList(this.voyageNo.leaveVoyageNo, this.voyageNo.returnVoyageNo);

        this.updateSearchBookingInfo({
          ...this.voyageNo,
          bookingType: 'cruise',
          routeCode: this.searchState.departure.highlight ? 'ONC' : 'OSC',
          category: this.searchState.departure.highlight ? 'oneNight' : 'osaka',
          schedule: this.schedule,
          bookingName: this.searchState.departure.voyageName,
          departure: this.searchState.departure,
          arrival: this.searchState.arrival,
          isRoundTrip: this.searchState.isRoundTrip,
          dateRange: this.dateRange,
          passengerTypeCountList: this.passengerTypeCountList.map(item => {
            const passenger = [];

            for (const key in item) {
              for (let i = 0; i < item[key]; i++) {
                const info = JSON.parse(JSON.stringify(this.passengerInfo));
                info.type = key;

                if (isBusanPort) {
                  delete info.firstNameEn;
                  delete info.lastNameEn;
                  delete info.passportNumber;
                }
                passenger.push(info);
              }
            }
            return { ...item, passenger };
          }),
          availableGradeList: this.availableGradeList,
        });

        const redirect = '/booking/cruise/grade';
        this.$router.push({ path: '/agreement/osaka', query: { redirect } });
      } catch (error) {
        await this.$alert({ content: error });
      }
    },
    toggleCalendar() {
      if (this.isPortSelectionComplete) {
        this.isOpenCalendar = false;
        return;
      }

      this.isOpenCalendar = !this.isOpenCalendar;
      this.$emit('update:isPositionUp', this.isOpenCalendar);
    },
  },
  mounted() {
    this.passengerTypeCountList.push({ ...this.defaultPassenger });
    this.getDepartureList();
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.panel-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 24px;
  font-size: 16px;
}

.panel-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
}

/* Departure/Arrival Port */
.port-select-wrapper {
  width: 100%;
  box-sizing: border-box;
  background: var(--White);
  height: 50px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100);
}

.departure-select,
.arrival-select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
  padding-left: 16px;
}

.departure-select > label,
.arrival-select > label {
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 52px;
  left: 0;
  width: 125px;
  border-radius: 5px;
  border-top: 0.5px solid #f0f0f0;
  border-bottom: 0.5px solid #f0f0f0;
  background: var(--White);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: var(--zContentsPopover);
  overflow: hidden;
}

.dropdown-content.right {
  left: auto;
  right: 0;
}

.dropdown-item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  color: var(--Blue-blue-500);
  background: var(--Blue-blue-50);
}

/* Departure/Arrival Port Icon */
.port-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
}

/* Route Type Select */
.way-select-wrapper {
  width: 100%;
  font-size: 16px;
}

/* Departure/Arrival Date Select */
.date-select-wrapper {
  width: 100%;
  cursor: pointer;
}

.date-picker {
  max-width: inherit;
}

/* Seat/Passenger Select */
.passenger-count-select-wrapper {
  position: relative;
  width: 100%;
}

/* /* Search Button */
.search-btn {
  width: 100%;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .panel-wrapper {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    gap: 16px;
  }

  .panel-left {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 0;
    width: calc(100% - 140px);
  }

  .way-select-wrapper {
    max-width: 116px;
  }

  .port-select-wrapper {
    min-width: 458px;
  }

  .date-select-wrapper {
    min-width: 258px;
  }

  :deep(.current-calendar),
  :deep(.next-calendar) {
    width: 521px;
    margin: 32px 0;
  }

  .passenger-count-select-wrapper {
    position: inherit;
    max-width: 250px;
  }

  .panel-right {
    width: 140px;
  }

  .search-btn {
    width: 100%;
    max-width: 140px;
  }
}
</style>
