<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('feeAndDiscount.title') }}</div>
      <div class="txt_det">{{ $t('feeAndDiscount.tsushimaIntro') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <!-- Tabs -->
      <ul class="component_tabs">
        <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }">
          <a href="javascript:" @click="currentTab = index">{{ $t(tab) }}</a>
        </li>
      </ul>
      <!-- //Tabs -->
      <div class="wrap_tabs">
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 0">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">
                  {{ $t('feeAndDiscount.tableHeader.oneWayFee') }}
                  <span class="txt_unit">{{ $t('feeAndDiscount.tsushima.unitKorea') }}</span>
                </div>

                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="166px" />
                      <col width="23%" />
                      <col width="23%" />
                      <col width="23%" />
                      <col width="23%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th rowspan="2">{{ $t('feeAndDiscount.tableHeader.division') }}</th>
                        <th colspan="2">{{ $t('sailingInfo.courses.hitakatsu') }}</th>
                        <th colspan="2">{{ $t('sailingInfo.courses.izuhara') }}</th>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekday') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekendPeakSeasons') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekday') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekendPeakSeasons') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.adult') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.adultDetail') }}</span>
                        </th>
                        <td>90,000</td>
                        <td>110,000</td>
                        <td>110,000</td>
                        <td>130,000</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.child') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.childDetail') }}</span>
                        </th>
                        <td>45,000</td>
                        <td>55,000</td>
                        <td>55,000</td>
                        <td>65,000</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.baby') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.babyDetail') }}</span>
                        </th>
                        <td>9,000</td>
                        <td>11,000</td>
                        <td>11,000</td>
                        <td>13,000</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.infant') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.infantDetail') }}</span>
                        </th>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.premiumSeat') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.honorSeat') }}</span>
                        </th>
                        <td colspan="2">
                          {{
                            $t('feeAndDiscount.oneWayAddFee', {
                              price: '10,000',
                              currency: $t('currency.krw'),
                            })
                          }}
                        </td>
                        <td colspan="2">
                          {{
                            $t('feeAndDiscount.oneWayAddFee', {
                              price: '20,000',
                              currency: $t('currency.krw'),
                            })
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul class="check_info">
                  <li>{{ $t('feeAndDiscount.tsushima.annotation') }}</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 1">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">
                  {{ $t('feeAndDiscount.tableHeader.oneWayFee') }}
                  <span class="txt_unit">{{ $t('feeAndDiscount.tsushima.unitJapan') }}</span>
                </div>

                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="166px" />
                      <col width="23%" />
                      <col width="23%" />
                      <col width="23%" />
                      <col width="23%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th rowspan="2">{{ $t('feeAndDiscount.tableHeader.division') }}</th>
                        <th colspan="2">{{ $t('sailingInfo.courses.hitakatsu') }}</th>
                        <th colspan="2">{{ $t('sailingInfo.courses.izuhara') }}</th>
                      </tr>
                      <tr>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekday') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekendPeakSeasons') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekday') }}</th>
                        <th>{{ $t('feeAndDiscount.tableHeader.weekendPeakSeasons') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.adult') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.adultDetail') }}</span>
                        </th>
                        <td>9,500</td>
                        <td>11,500</td>
                        <td>11,500</td>
                        <td>13,500</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.child') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.childDetail') }}</span>
                        </th>
                        <td>4,750</td>
                        <td>5,750</td>
                        <td>5,750</td>
                        <td>6,750</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.baby') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.babyDetail') }}</span>
                        </th>
                        <td>950</td>
                        <td>1,150</td>
                        <td>1,150</td>
                        <td>1,350</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.infant') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.infantDetail') }}</span>
                        </th>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                        <td>{{ $t('feeAndDiscount.free') }}</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('feeAndDiscount.tableHeader.premiumSeat') }}<br />
                          <span class="txt_sub">{{ $t('feeAndDiscount.tableHeader.honorSeat') }}</span>
                        </th>
                        <td colspan="2">
                          {{
                            $t('feeAndDiscount.oneWayAddFee', {
                              price: '1,000',
                              currency: $t('currency.krw'),
                            })
                          }}
                        </td>
                        <td colspan="2">
                          {{
                            $t('feeAndDiscount.oneWayAddFee', {
                              price: '2,000',
                              currency: $t('currency.krw'),
                            })
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul class="check_info">
                  <li>{{ $t('feeAndDiscount.tsushima.annotation') }}</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
      </div>

      <ul class="component_tabs">
        <li v-for="(tab, index) in tabs2" :key="index" :class="{ active: currentTab2 === index }">
          <a href="javascript:" @click="currentTab2 = index">{{ $t(tab) }}</a>
        </li>
      </ul>
      <div class="wrap_tabs">
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab2 === 0">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.label') }}</div>
                <div class="txt_detail">
                  <ul class="list_dot">
                    <li>
                      <b>{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.infant.label') }}</b
                      >{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.infant.detail') }}
                    </li>
                    <li>
                      <b>{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.baby.label') }}</b
                      >{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.baby.detail') }}
                    </li>
                    <li>
                      <b>{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.child.label') }}</b
                      >{{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.child.detail') }}
                      <div class="list_sub">
                        {{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.child.roomNotice') }}
                      </div>
                      <div class="list_sub">
                        {{ $t('feeAndDiscount.tsushimaDiscountPolicy.ageDiscount.child.discountNotice') }}
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.osakaDiscountPolicy.seafarer.label') }}</div>
                <div class="txt_detail">
                  <ul class="list_dot">
                    <li>
                      <b>{{ $t('feeAndDiscount.osakaDiscountPolicy.seafarer.seafarer') }}</b
                      >{{ $t('feeAndDiscount.osakaDiscountPolicy.seafarer.detail') }}
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.tsushimaDiscountPolicy.disability.label') }}</div>
                <div class="txt_detail">
                  <ul class="list_dot">
                    <li>
                      <b>{{ $t('feeAndDiscount.tsushimaDiscountPolicy.disability.grade1To3.label') }}</b
                      >{{ $t('feeAndDiscount.tsushimaDiscountPolicy.disability.grade1To3.detail') }}
                    </li>
                    <li>
                      <b>{{ $t('feeAndDiscount.tsushimaDiscountPolicy.disability.grade4To6.label') }}</b
                      >{{ $t('feeAndDiscount.tsushimaDiscountPolicy.disability.grade4To6.detail') }}
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.tsushimaDiscountPolicy.military.label') }}</div>
                <div class="txt_detail">
                  <ul class="list_dot">
                    <li>
                      <b>{{ $t('feeAndDiscount.osakaDiscountPolicy.military.family.label') }}</b
                      >{{ $t('feeAndDiscount.osakaDiscountPolicy.military.family.detail') }}
                    </li>
                    <li>
                      <b>{{ $t('feeAndDiscount.osakaDiscountPolicy.military.soldier.completer') }}</b
                      >{{ $t('feeAndDiscount.osakaDiscountPolicy.military.soldier.and')
                      }}<b>{{ $t('feeAndDiscount.osakaDiscountPolicy.military.soldier.reservist') }}</b
                      >{{ $t('feeAndDiscount.osakaDiscountPolicy.military.soldier.detail') }}
                    </li>
                  </ul>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab2 === 1">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('feeAndDiscount.refundPolicy.boardingPass') }}</div>

                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="50%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('feeAndDiscount.refundPolicy.tableHeader.refundDate') }}</th>
                        <th>{{ $t('feeAndDiscount.refundPolicy.tableHeader.refund') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="txt_center">{{ $t('feeAndDiscount.refundPolicy.before3Days') }}</th>
                        <td>100%</td>
                      </tr>
                      <tr>
                        <th class="txt_center">{{ $t('feeAndDiscount.refundPolicy.before2Days') }}</th>
                        <td>90%</td>
                      </tr>
                      <tr>
                        <th class="txt_center">{{ $t('feeAndDiscount.refundPolicy.before1Days') }}</th>
                        <td>70%</td>
                      </tr>
                      <tr>
                        <th class="txt_center">{{ $t('feeAndDiscount.refundPolicy.before2Hours') }}</th>
                        <td>50%</td>
                      </tr>
                      <tr>
                        <th class="txt_center">{{ $t('feeAndDiscount.refundPolicy.after') }}</th>
                        <td>0%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul class="check_info">
                  <li>{{ $t('feeAndDiscount.refundPolicy.annotation.refundNotice') }}</li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DaemadoProcedure',
  data() {
    return {
      currentTab: 0,
      tabs: ['feeAndDiscount.tabs.korea', 'feeAndDiscount.tabs.japan'],
      currentTab2: 0,
      tabs2: ['feeAndDiscount.tabs.discountPolicy', 'feeAndDiscount.tabs.refundPolicy'],
    };
  },
};
</script>
<style scoped>
/********************************************************
   component tabs
********************************************************/
.wrap_tabs + .component_tabs {
  margin-top: 60px;
}
.component_tabs {
  margin-bottom: 60px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #446ceb;
}
.component_tabs li {
  flex: auto;
  width: 100%;
}
.component_tabs li + li {
  border-left: 1px solid #446ceb;
}
.component_tabs li a {
  display: block;
  line-height: 68px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #446ceb;
  font-weight: 600;
}
.component_tabs li.active a {
  background-color: #446ceb;
  color: #fff;
}
@media (max-width: 767px) {
  .wrap_tabs + .component_tabs {
    margin-top: 48px;
  }
  .component_tabs {
    margin-bottom: 24px;
  }
  .component_tabs li a {
    line-height: 48px;
    font-size: 16px;
  }
}

/********************************************************
   component table
********************************************************/
.wrap_table {
  overflow: auto;
}
.component_table {
  width: 100%;
}

.component_table tr th {
  padding: 26px 0;
  height: 100px;
  text-align: center;
  border: 1px solid #dddfe2;
  border-left: none;
  background-color: #f5f7f9;
  font-size: 20px;
  color: #686d72;
  font-weight: 600;
}
.component_table tr td:last-of-type,
.component_table tr th:last-of-type {
  border-right: none;
}
.component_table tr td {
  padding: 26px 0;
  height: 100px;
  text-align: center;
  font-size: 20px;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.component_table tr th .det_view {
  color: #f5a13e;
  font-size: 14px;
  font-weight: normal;
}
.component_table tr td .list_dot li {
  font-size: 20px;
  color: #686d72;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component_table tr td .list_dot li:before {
  display: inline-block;
  margin-right: 13px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #b6bbbf;
  content: '';
}

@media (max-width: 767px) {
  .component_table {
    white-space: nowrap;
  }
  .component_table tr td,
  .component_table tr th {
    font-size: 16px;
    padding: 16px 13px;
    height: 70px;
  }
  .component_table tr th .det_view {
    font-size: 12px;
  }
  .component_table tr td .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .component_table tr td .list_dot li:before {
    display: none;
  }
}

/********************************************************
  Detail Table
********************************************************/
.detail_table {
  width: 100%;
}
.wrap_table + .check_info {
  margin-top: 16px;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  white-space: nowrap;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tbody tr th.bg_th .txt_sub {
  font-size: 18px;
  font-weight: normal;
}
.detail_table tr td {
  white-space: nowrap;
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
    white-space: nowrap;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr th.bg_th .txt_sub {
    font-size: 14px;
  }
  .detail_table tr th.txt_center {
    text-align: center;
  }
}

/********************************************************
   box Info
********************************************************/
.box_info {
  margin-top: 60px;
  padding: 54px 60px;
  display: flex;
  background-color: #fffae6;
}
.box_info:before {
  display: inline-block;
  margin-right: 30px;
  width: 51px;
  height: 45px;
  background: url('@/assets/images/information/icon/icon_info.svg') no-repeat;
  content: '';
  flex: none;
}
.check_info {
}
.check_info li {
  padding-left: 33px;
  line-height: 32px;
  position: relative;
  color: #686d72;
  font-size: 20px;
  font-weight: 500;
}
.check_info li:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
  margin-right: 12px;
  width: 21px;
  height: 29px;
  background: url('@/assets/images/information/icon/icon_check.svg') no-repeat;
  content: '';
}

@media (max-width: 767px) {
  .check_info li {
    padding-left: 29px;
    font-size: 16px;
    line-height: 26px;
  }
  .check_info li + li {
    margin-top: 16px;
  }
  .check_info li:before {
    top: 5px;
    width: 17px;
    height: 24px;
    background-size: 100%;
  }
}

.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  position: relative;
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
.view_detail .area_detail .label_title .txt_unit {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 20px;
  font-weight: 500;
  color: #686d72;
}
.view_detail .area_detail .label_title .txt_sub {
  font-size: 20px;
  font-weight: 500;
  color: #304da7;
}
.view_detail .area_detail .txt_detail {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  color: #686d72;
  white-space: pre-wrap;
  word-break: auto-phrase;
}
.view_detail .area_detail .txt_detail .list_dot li b {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }

  .view_detail .area_detail .label_title .txt_unit {
    font-size: 16px;
  }
  .view_detail .area_detail .txt_detail {
    font-size: 16px;
    line-height: 20px;
  }
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_dot li .list_sub {
    font-size: 16px;
  }
  .view_detail .area_detail + .area_detail {
    padding: 60px 0 0 0;
  }
}
</style>
