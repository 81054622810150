<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('menu.operationInfo') }}</div>
      <div class="txt_det">{{ $t('sailingInfo.oneNightTitle') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('sailingInfo.routeSchedule') }}</div>
            <!-- Tabs -->
            <ul class="component_tabs">
              <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }">
                <a href="javascript:" @click="currentTab = index">{{ $t(tab) }}</a>
              </li>
            </ul>
            <!-- //Tabs -->
            <div class="wrap_tabs">
              <div class="img_map" v-if="currentTab === 0">
                <img :src="imgSrc('cruise')" alt="Route" />
              </div>
              <div class="img_map daehan" v-else>
                <img :src="imgSrc('daehan')" alt="Route" />
              </div>
              <!-- Tab contents  -->
              <div class="tab_content" v-show="currentTab === 0">
                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="2">{{ $t('sailingInfo.courses.label') }}</th>
                        <th>{{ $t('sailingInfo.departure') }}</th>
                        <th>{{ $t('sailingInfo.boardingProcedure') }}</th>
                        <th>{{ $t('sailingInfo.buffet') }}</th>
                        <th>{{ $t('sailingInfo.fireworks') }}</th>
                        <th>{{ $t('sailingInfo.arrival') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center" rowspan="2">{{ $t('sailingInfo.oneNightCruise') }}</th>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.standard') }}</th>
                        <td>
                          {{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sat'), time: '17:00' }) }}
                        </td>
                        <td>15:30 ~ 16:00</td>
                        <td>18:00 ~ 20:00</td>
                        <td>20:50</td>
                        <td>{{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sun'), time: '09:00' }) }}</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.winter') }}</th>
                        <td>{{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sat'), time: '16:30' }) }}</td>
                        <td>15:00 ~ 15:30</td>
                        <td>18:00 ~ 20:00</td>
                        <td>20:50</td>
                        <td>{{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sun'), time: '09:00' }) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="view_detail">
                  <ol class="area_detail">
                    <li>
                      <div class="label_title">{{ $t('sailingInfo.productGuide') }}</div>
                      <div class="txt_detail">
                        <div class="label_detail">
                          {{ $t('sailingInfo.busanCruise.title') }}
                          <span class="sub_detail">{{ $t('sailingInfo.busanCruise.duration') }}</span>
                        </div>
                        <div class="det_strong">
                          {{ $t('sailingInfo.busanCruise.key') }}
                        </div>

                        <ul class="list_dot">
                          <li>
                            {{ $t('sailingInfo.busanCruise.description') }}
                          </li>
                        </ul>
                      </div>
                      <div class="txt_detail">
                        <div class="label_detail">
                          {{ $t('sailingInfo.charterCruise.title') }}
                          <span class="sub_detail">{{ $t('sailingInfo.charterCruise.duration') }}</span>
                        </div>
                        <div class="det_strong">{{ $t('sailingInfo.charterCruise.key') }}</div>

                        <ul class="list_dot">
                          <li>
                            {{ $t('sailingInfo.charterCruise.description') }}
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <hr class="hr_line" />
                    </li>
                    <li>
                      <div class="label_title">{{ $t('sailingInfo.reservationRequirements') }}</div>
                      <!-- Note -->
                      <div class="view_note">
                        <!-- ui Note -->
                        <div class="ui_note">
                          <em class="img_note"></em>
                          <div class="txt_note">
                            <div class="label_note">{{ $t('sailingInfo.requiredTitle') }}</div>
                            {{ $t('sailingInfo.oneNightRequiredInfo1') }}
                            <span class="sub_note">{{ $t('sailingInfo.oneNightRequiredInfo2') }}</span
                            >{{ $t('sailingInfo.oneNightRequiredInfo3') }}
                          </div>
                        </div>
                      </div>
                      <!-- //Note -->
                    </li>
                  </ol>
                </div>
              </div>
              <!-- //Tab contents  -->
              <!-- Tab contents  -->
              <div class="tab_content" v-show="currentTab === 1">
                <div class="wrap_table">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.courses.label') }}</th>
                        <th>{{ $t('sailingInfo.departure') }}</th>
                        <th>{{ $t('sailingInfo.ticketing') }}</th>
                        <th>{{ $t('sailingInfo.DepartureProcedure') }}</th>
                        <th>{{ $t('sailingInfo.boardingProcedure') }}</th>
                        <th>{{ $t('sailingInfo.buffet') }}</th>
                        <th>{{ $t('sailingInfo.fireworks') }}</th>
                        <th>{{ $t('sailingInfo.arrival') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.koreaStraitCruise') }}</th>
                        <td>
                          {{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sat'), time: '16:30' }) }}
                        </td>
                        <td>14:00 ~ 15:00</td>
                        <td>15:30 ~ 16:00</td>
                        <td>15:50 ~ 16:10</td>
                        <td>18:30 ~ 20:30</td>
                        <td>21:20</td>
                        <td>
                          {{ $t('sailingInfo.aroundTimeOfDay', { day: getDay('Sun'), time: '09:30' }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- box Info -->
                <div class="box_info">
                  <ul class="list_check">
                    <li>{{ $t('sailingInfo.departureKoreaStraitCruise') }}</li>
                    <li>
                      {{ $t('sailingInfo.portClearance') }}
                    </li>
                  </ul>
                </div>
                <!-- //box Info -->

                 <div class="view_detail">
                  <ol class="area_detail">
                    <li>
                      <div class="label_title">{{ $t('sailingInfo.productGuide') }}</div>
                      <div class="txt_detail">
                        <div class="label_detail">
                          {{ $t('sailingInfo.koreaStraitCruiseInfo.title') }}
                          <span class="sub_detail">{{ $t('sailingInfo.koreaStraitCruiseInfo.duration') }}</span>
                        </div>
                        <div class="det_strong">
                          {{ $t('sailingInfo.koreaStraitCruiseInfo.key') }}
                        </div>

                        <ul class="list_dot">
                          <li>
                            {{ $t('sailingInfo.koreaStraitCruiseInfo.description') }}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <!-- //Tab contents  -->
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OneNightProduct',
  data() {
    return {
      currentTab: 0,
      tabs: ['oneNightCruise', 'koreaStraitCruise'],
    };
  },
  methods: {
    imgSrc(route) {
      const localeKey = this.$i18n.locale !== 'ko' ? `_${route}_${this.$i18n.locale}` : `_${route}`;
      return require(`@/assets/images/information/product/map_onenight${localeKey}.jpg`);
    },
    getDay(type) {
      if (this.$i18n.locale === 'ko') {
        return type === 'Sat' ? '토요일' : '일요일';
      } else if (this.$i18n.locale === 'en') {
        return type === 'Sat' ? 'Saturday' : 'Sunday';
      } else {
        return type === 'Sat' ? '土曜日' : '日曜日';
      }
    },
  },
};
</script>

<style scoped>
/********************************************************
   component tabs
********************************************************/
.component_tabs {
  margin-bottom: 60px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #446ceb;
}
.component_tabs li {
  flex: auto;
  width: 100%;
}
.component_tabs li + li {
  border-left: 1px solid #446ceb;
}
.component_tabs li a {
  display: block;
  line-height: 68px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #446ceb;
  font-weight: 600;
}
.component_tabs li.active a {
  background-color: #446ceb;
  color: #fff;
}
@media (max-width: 767px) {
  .component_tabs {
    margin-bottom: 24px;
  }
  .component_tabs li a {
    line-height: 48px;
    font-size: 16px;
  }
}

.layout_mobile {
  display: none;
}
.layout_web {
  display: block;
}
@media (max-width: 767px) {
  .layout_mobile {
    display: block;
  }
  .layout_web {
    display: none;
  }
}

/********************************************************
   box Info
********************************************************/
.box_info {
  margin-top: 60px;
  padding: 54px 60px;
  display: flex;
  background-color: #fffae6;
}
.view_detail {
  margin-top: 60px;
}
.box_info:before {
  display: inline-block;
  margin-right: 30px;
  width: 51px;
  height: 45px;
  background: url('@/assets/images/information/icon/icon_info.svg') no-repeat;
  content: '';
  flex: none;
}
.box_info .list_check {
  white-space: pre-wrap;
}
.box_info .list_check li {
  padding-left: 33px;
  line-height: 32px;
  position: relative;
  color: #686d72;
  font-size: 20px;
  font-weight: 500;
}
.box_info .list_check li strong {
  color: #f56a3e;
  font-weight: 600;
}
.box_info .list_check li:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
  margin-right: 12px;
  width: 21px;
  height: 29px;
  background: url('@/assets/images/information/icon/icon_check.svg') no-repeat;
  content: '';
}

@media (max-width: 767px) {
  .box_info {
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }
  .box_info + .view_detail {
    margin-top: 48px;
  }
  .box_info:before {
    margin: 0 0 24px 0;
    width: 41px;
    height: 36px;
    background-size: 100% 100%;
  }
  .box_info .list_check li {
    padding-left: 29px;
    font-size: 16px;
    line-height: 26px;
  }
  .box_info .list_check li + li {
    margin-top: 16px;
  }
  .box_info .list_check li:before {
    top: 5px;
    width: 17px;
    height: 24px;
    background-size: 100%;
  }
}
/********************************************************
  Detail Table
********************************************************/
.wrap_table {
  overflow: auto;
}
.wrap_table + .label_title {
  margin-top: 60px;
}
.detail_table {
  white-space: nowrap;
  width: 100%;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  white-space: pre;
}
.detail_table tbody tr td .txt_sub {
  font-size: 18px;
  color: #686d72;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tr td {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
  white-space: pre;
}
.detail_table tr td .txt_round {
  color: #304da7;
}
.detail_table tr th .arrow_go {
  display: inline-block;
  margin: 0 12px;
  width: 14px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_go.svg') no-repeat;
}
.detail_table tr th .arrow_round {
  display: inline-block;
  margin: 0 12px;
  width: 27px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_round.svg') no-repeat;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .wrap_table + .label_title {
    margin-top: 48px;
  }
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr td .txt_sub {
    font-size: 14px;
  }
}

.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail li .img_map {
  margin-bottom: 60px;
  font-size: 0;
}
.view_detail .area_detail li .img_map img {
  width: 100%;
  border-radius: 10px;
}
.view_detail .area_detail > li .hr_line {
  border: none;
  border-top: 1px solid #dddfe2;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
@media (max-width: 767px) {
  .view_detail .area_detail li .img_map {
    margin-bottom: 24px;
  }
  .view_detail .area_detail li .img_map img {
    height: 160px;
    object-fit: cover;
    object-position: center;
  }
  .view_detail .area_detail li .img_map.daehan img {
    height: 160px;
    object-fit: cover;
    object-position: 38% 50%;
  }
  .view_detail .area_detail .hr_line {
    margin: -36px 0;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link {
    line-height: 40px;
    font-size: 18px;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link + .txt_link {
    margin-left: 20px;
  }
}
.view_detail .area_detail .txt_detail .txt_strong {
  color: #505458;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 32px;
}
.view_detail .area_detail .txt_detail .det_strong {
  color: #9299a0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .det_strong {
    font-size: 16px;
  }
  .view_detail .area_detail .txt_detail .txt_strong {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 24px;
  }
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 16px;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 24px;
  color: #505458;
}
.view_detail .area_detail .txt_detail .label_detail .sub_detail {
  color: #b6bbbf;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  color: #686d72;
  line-height: 28px;
}
.view_detail .area_detail .txt_detail .list_dot li strong {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .label_detail {
    font-size: 18px;
    line-height: 24px;
  }
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }
}

.view_note {
  display: flex;
  margin-bottom: 32px;
}
.view_note .ui_note {
  flex: auto;
  display: flex;
  align-items: center;
}
.view_note .img_note,
.view_note .img_ticket {
  display: inline-block;
  flex: none;
  width: 200px;
  height: 200px;
  margin-right: 50px;
  background: url('@/assets/images/information/icon/img_monitor.svg') no-repeat;
}
.view_note .img_ticket {
  background: url('@/assets/images/information/icon/img_ticket.svg') no-repeat;
}
.view_note .txt_note {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
}
.view_note .txt_note .list_note li {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
  display: flex;
}
.view_note .txt_note .list_note li:before {
  margin: 13px;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #686d72;
  content: '';
}
.view_note .txt_note .sub_note {
  color: #a8adb3;
}
.view_note .txt_note .label_note {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #505458;
  line-height: 31px;
}
@media (max-width: 767px) {
  .view_note {
    flex-direction: column;
  }
  .view_note .ui_note + .ui_note {
    margin-top: 16px;
  }
  .view_note .ui_note {
    flex-direction: column;
  }
  .view_note .txt_note {
    text-align: center;
  }
  .view_note .txt_note .label_note {
    font-size: 20px;
  }
  .view_note .txt_note {
    font-size: 16px;
    line-height: 24px;
  }
  .view_note .img_note,
  .view_note .img_ticket {
    margin: 0 0 16px 0;
  }

  .view_note .txt_note .list_note li {
    font-size: 16px;
    line-height: 24px;
  }
  .view_note .txt_note .list_note li:before {
    margin-top: 8px;
  }
}
</style>
