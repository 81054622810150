<template>
  <div v-if="showModal" class="modal-background flex-center" :class="modalWrapperClass" @click="closeModal">
    <div class="modal-contents" :class="autoHeightClass" v-bind="$attrs" @click.stop>
      <div class="modal-title">
        {{ title }}

        <button v-if="showCloseIcon" class="close-button" @click="closeModal">
          <img class="close-icon" src="@/assets/images/icon/close_icon.svg" alt="icon" />
        </button>
      </div>

      <div class="content-wrapper" :class="[autoHeightClass, hasButtonSlotClass]">
        <slot name="content"></slot>
      </div>

      <div v-if="$slots.button" class="modal-btns" :class="autoHeightClass">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReCruiseModal',
  inheritAttrs: false,
  emits: ['update:showModal', 'onClose'],
  props: {
    title: String,
    showModal: {
      type: Boolean,
      required: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    hideModal: Boolean,
  },
  watch: {
    showModal: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      },
    },
  },
  computed: {
    modalWrapperClass() {
      return { 'hide-modal': this.hideModal };
    },
    autoHeightClass() {
      return this.autoHeight ? 'auto' : '';
    },
    hasButtonSlotClass() {
      return this.$slots.button ? 'has-btn' : '';
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
      this.$emit('onClose');
    },
  },
  beforeUnmount() {
    document.body.style.overflow = '';
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  z-index: var(--zLayoutModal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Backdrop-renewal);
  backdrop-filter: blur(4px);
}

.hide-modal {
  display: none;
}

.modal-contents {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--White);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-title {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 60px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--Grey-grey-100);
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.54px;
}

.content-wrapper {
  padding: 24px 16px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.content-wrapper.has-btn {
  margin-bottom: 54px;
}

.modal-btns {
  width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal-contents {
    border-radius: 10px;
    width: 30%;
    min-width: 600px;
    height: 74%;
  }

  .modal-contents.auto {
    height: auto;
    max-height: 74%;
  }

  .content-wrapper {
    padding: 24px 16px;
  }

  .content-wrapper.has-btn {
    margin-bottom: 82px;
  }

  .content-wrapper.auto {
    margin: 0;
  }

  .modal-title {
    height: 72px;
    max-height: 72px;
    font-size: 20px;
    font-weight: 600;
  }

  .modal-btns {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    gap: 16px;
    position: fixed;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    width: 30%;
    min-width: 600px;
    border-radius: 0 0 10px 10px;
  }

  .modal-btns.auto {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
