<template>
  <label class="custom-checkbox">
    <input type="checkbox" hidden v-bind="attrs" :id="safeId" :checked="modelValue" @change="handleChange" />
    <span class="checkmark flex-center">
      <CheckRoundIcon class="check-icon" :size="14" v-if="!!modelValue" />
    </span>
    <span v-if="label" class="checkbox-label">{{ label }}</span>
  </label>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';
import CheckRoundIcon from 'vue-material-design-icons/CheckBold';

export default {
  name: 'CruiseCheckbox',
  mixins: [sharedComputedProps],
  emits: ['update:modelValue', 'onChange'],
  components: { CheckRoundIcon },
  props: {
    label: String,
    modelValue: Boolean,
    shape: {
      type: String,
      default: 'square',
      validator: value => ['round', 'square'].includes(value),
    },
  },
  computed: {
    borderRadius() {
      return this.shape === 'round' ? '50%' : '4px';
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.checked);
      this.$emit('onChange', e);
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.checkmark {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background-color: var(--White);
  border-radius: v-bind(borderRadius);
  border: 1px solid var(--Black);
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: var(--Main);
  border: 1px solid var(--Main);
}

.check-icon {
  color: var(--White);
}

.checkbox-label {
  display: inline-flex;
  align-items: center;
  height: 12px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .custom-checkbox {
    font-size: 14px;
  }

  .checkbox-label {
    font-size: 14px;
    height: 14px;
  }
}
</style>
