<template>
  <div class="tour-wrapper container-default-renewal">
    <BreadcrumbNavigation />

    <div class="main-contents">
      <div class="page-title-wrapper">
        <h1 class="page-title">{{ $t('menu.tourProduct') }}</h1>
        <p class="page-desc">{{ $t('pageDescription.tourProduct') }}</p>
      </div>

      <div class="tour-select-wrapper">
        <!-- Select Departure Date -->
        <div class="date-select-wrapper" ref="calendar">
          <ReCruiseDatePicker v-model:dateRange="dateRange" :label="dateLabel" @onClick="toggleCalendar" disabled />

          <!-- Calendar Layer -->
          <ReCalendarModal
            class="calendar"
            v-model:showModal="isOpenCalendar"
            v-model:dateRange="dateRange"
            :dateRange="dateRange"
            :label="dateLabel"
          />
        </div>

        <!-- Travel/Departure Port Select  -->
        <div class="port-select-wrapper">
          <div class="port-select" ref="travelPort">
            <SelectModalOnlyMobile
              v-model="travelPort"
              v-model:isOpen="isOpenTravelPortOptions"
              valueProperty="code"
              :options="portList"
              :title="$t('tourProduct.selectTravelDestination')"
              :placeholder="$t('tourProduct.selectTravelDestination')"
            >
              <template #option="{ item }">
                {{ getLocalText(item, 'name') }}
              </template>
            </SelectModalOnlyMobile>
          </div>

          <div class="port-select" ref="departurePort">
            <SelectModalOnlyMobile
              v-model="departurePort"
              v-model:isOpen="isOpenDeparturePortOptions"
              valueProperty="code"
              :options="portList"
              :title="$t('labels.selectDeparture')"
              :placeholder="$t('labels.selectDeparture')"
            >
              <template #option="{ item }">
                {{ getLocalText(item, 'name') }}
              </template>
            </SelectModalOnlyMobile>
          </div>
        </div>

        <!-- Select Travel Period -->
        <div ref="period" class="tour-period-select-wrapper">
          <SelectModalOnlyMobile
            v-model="period"
            v-model:isOpen="isOpenPeriodOptions"
            :options="termList"
            valueProperty="value"
            :title="$t('tourProduct.selectTravelPeriod')"
            :placeholder="$t('tourProduct.selectTravelPeriod')"
          >
            <template #option="{ item }">
              {{ $t(item.label) }}
            </template>
          </SelectModalOnlyMobile>
        </div>

        <ReCruiseButton class="search-btn" theme="primary" @click="searchTour">
          {{ $t('actions.search') }}
        </ReCruiseButton>
      </div>
    </div>

    <div class="tour-products-wrapper">
      <div class="tour-product-header">
        <b v-if="$i18n.locale === 'en'" class="tour-header-title">
          All <span> {{ packageTourList.length }} </span> Tour {{ packageTourList.length > 1 ? 'Products' : 'Product' }}
        </b>
        <b v-else class="tour-header-title">
          {{ $t('tourProduct.allTourProducts') }}
          <span> {{ packageTourList.length }} </span>
          {{ $t('tourProduct.count') }}
        </b>

        <div ref="priceSort">
          <ReCruiseSelect
            class="tour-price-sort"
            v-model:isOpen="isOpeneSortOptions"
            v-model="sort"
            valueProperty="value"
            :placeholder="$t('filters.lowestPrice')"
            :options="sortList"
          >
            <template #option="{ item }">
              {{ $t(item.label) }}
            </template>
          </ReCruiseSelect>
        </div>
      </div>
      <div class="tour-products-inner">
        <ul class="tour-list">
          <TourProductItem
            v-for="tour in packageTourList"
            :key="tour.packageTour.id"
            :tour="tour"
            @onClick="toggleSchedule"
          />
        </ul>
      </div>
    </div>
  </div>

  <TourScheduleModal
    v-if="selectedTour"
    :showCloseIcon="false"
    v-model:showModal="isOpenScheduleModal"
    :title="$t('tourProduct.tourProductReservation')"
    :tour="selectedTour"
  />
</template>

<script>
import { getLocalText, formatDate } from '@/utils/convert';
import { YYYYMMDD } from '@/const/const';
import PackageTourService from '@/services/packageTour';
import PortService from '@/services/port';

import BreadcrumbNavigation from '@/components/common/BreadcrumbNavigation';
import ReCruiseDatePicker from '@/components/renewal/common/ReCruiseDatePicker.vue';
import ReCalendarModal from '@/components/renewal/home/ReCalendarModal.vue';
import ReCruiseSelect from '@/components/renewal/common/ReCruiseSelect.vue';
import TourProductItem from '@/components/tour/TourProductItem.vue';
import SelectModalOnlyMobile from '@/components/renewal/modal/SelectModalOnlyMobile.vue';
import TourScheduleModal from '@/components/tour/TourScheduleModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'TourView',
  components: {
    BreadcrumbNavigation,
    ReCruiseDatePicker,
    ReCalendarModal,
    ReCruiseSelect,
    TourProductItem,
    SelectModalOnlyMobile,
    TourScheduleModal,
    ReCruiseButton,
  },
  data() {
    return {
      sort: 1,
      sortList: [
        { label: 'filters.lowestPrice', value: 1 },
        { label: 'filters.highestPrice', value: -1 },
      ],
      dateRange: { from: null, to: null },
      dateLabel: { from: 'tourProduct.departureDate' },
      isOpenCalendar: false,

      travelPort: null,
      isOpenTravelPortOptions: false,
      departurePort: null,
      isOpenDeparturePortOptions: false,
      isOpeneSortOptions: false,

      period: null,
      isOpenPeriodOptions: false,

      isCancelModal: false,
      isOpenScheduleModal: false,

      portList: [],
      packageList: [],
      selectedTour: null,
    };
  },
  computed: {
    termList() {
      return [
        { label: 'tourProduct.within4Days', value: 4 },
        { label: 'tourProduct.within5Days', value: 5 },
        { label: 'tourProduct.within7Days', value: 7 },
        { label: 'tourProduct.within10Days', value: 10 },
        { label: 'tourProduct.within14Days', value: 14 },
      ];
    },
    locale() {
      return this.$i18n.locale;
    },
    packageTourList() {
      const localeMap = { ko: 'isOpenedKo', en: 'isOpenedEn', ja: 'isOpenedJa' };
      return this.packageList
        .filter(item => item.packageTour[localeMap[this.locale]])
        .sort((a, b) => {
          if (this.sort === 1) {
            return a.packageTour.packagePrice - b.packageTour.packagePrice;
          }
          return b.packageTour.packagePrice - a.packageTour.packagePrice;
        });
    },
  },
  methods: {
    getLocalText,

    async init() {
      await this.getTourList();
      await this.getPortList();
    },
    toggleCalendar() {
      this.isOpenCalendar = !this.isOpenCalendar;
    },
    toggleSchedule(tour) {
      this.selectedTour = tour;
      this.isOpenScheduleModal = !this.isOpenScheduleModal;
    },
    searchTour() {
      const query = {
        startDate: this.dateRange.from ? formatDate(this.dateRange.from, YYYYMMDD) : null,
        departurePort: this.departurePort,
        arrivalPort: this.travelPort,
        term: this.period,
      };
      this.getTourList(query);
    },
    async getPortList() {
      try {
        const { data } = await PortService.getPortList();
        this.portList = data;
      } catch (error) {
        alert(error);
      }
    },
    async getTourList(query) {
      try {
        const { data } = await PackageTourService.getPackageTourList(query);
        this.packageList = data || [];
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
    handleClickOutside(e) {
      if (!this.$refs.calendar.contains(e.target)) {
        this.isOpenCalendar = false;
      }

      if (!this.$refs.travelPort.contains(e.target)) {
        this.isOpenTravelPortOptions = false;
      }

      if (!this.$refs.departurePort.contains(e.target)) {
        this.isOpenDeparturePortOptions = false;
      }

      if (!this.$refs.period.contains(e.target)) {
        this.isOpenPeriodOptions = false;
      }

      if (!this.$refs.priceSort.contains(e.target)) {
        this.isOpeneSortOptions = false;
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.tour-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 800px;
}

.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* white-space: pre; */
  word-break: keep-all;
  margin-bottom: 24px;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.page-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.tour-select-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Grey-grey-50);
}

.date-select-wrapper {
  width: 100%;
}

.port-select-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.port-select {
  width: 100%;
}

.tour-period-select-wrapper {
  width: 100%;
}

.search-btn {
  width: 100%;
}

.tour-product-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.tour-header-title {
  font-size: 16px;
  font-weight: 600;
}

.tour-header-title span {
  color: var(--Blue-blue-500);
}

.tour-price-sort {
  width: 137px;
  height: 36px;
  font-size: 14px;
}

:deep(.tour-price-sort .options-wrapper) {
  top: 38px;
}

@media screen and (min-width: 768px) {
  .tour-wrapper {
    gap: 60px;
    padding-bottom: 60px;
  }

  .page-title {
    font-size: 34px;
  }

  .page-desc {
    font-size: 20px;
  }

  .page-desc br {
    display: none;
  }

  .tour-select-wrapper {
    padding: 0;
    flex-direction: row;
    gap: 24px;
    border-radius: 0;
    background: var(--white);
    height: 100%;
    font-size: 20px;
  }

  .tour-period-select-wrapper {
    flex: 1 0;
  }

  .search-btn {
    flex: 0.5 0;
  }

  .select-wrapper {
    font-size: 17px;
  }

  :deep(.date-item span) {
    font-size: 17px;
  }

  .date-select-wrapper {
    flex: 1 0;
    position: relative;
  }

  :deep(.calendar) {
    position: absolute;
    top: 52px;
    left: 0;
    border-radius: 5px;
    border-top: 0.5px solid #f0f0f0;
    border-bottom: 0.5px solid #f0f0f0;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }

  :deep(.month-navigator) {
    padding: 0;
    position: absolute;
    top: 13px;
    left: 16px;
  }

  :deep(.month-navigator:last-child) {
    left: auto;
    right: 16px;
  }

  :deep(.calendar-footer) {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .port-select-wrapper {
    flex: 2 0;
    gap: 24px;
  }

  .tour-product-header {
    margin-bottom: 40px;
  }

  .tour-header-title {
    font-size: 20px;
  }

  .tour-price-sort {
    width: 200px;
    height: 50px;
    font-size: 16px;
  }

  :deep(.tour-price-sort .options-wrapper) {
    top: 52px;
  }
}
</style>
