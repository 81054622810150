<template>
  <div class="search-wrapper">
    <ReCruiseInput
      class="search-input"
      :placeholder="placeholder || $t('messages.enterSearchKeyword')"
      v-model="keyword"
      @keyup.enter="search"
    />
    <ReCruiseButton class="search-button" @click="search"> {{ btnText || $t('actions.search') }} </ReCruiseButton>
  </div>
</template>

<script>
import ReCruiseInput from '@/components/renewal/common/ReCruiseInput';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton';

export default {
  name: 'CruiseSearch',
  emits: ['search'],
  components: { ReCruiseInput, ReCruiseButton },
  props: {
    placeholder: String,
    btnText: String,
  },
  data() {
    return {
      keyword: '',
    };
  },
  methods: {
    search() {
      this.$emit('search', this.keyword);
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--Grey-grey-200);
}

.search-input {
  height: 32px;
}

:deep(.cruise-input) {
  height: 32px;
  border: 1px solid var(--Blue-blue-500);
}

:deep(.cruise-input),
:deep(.input-placeholder) {
  padding: 0 10px;
  font-size: 14px;
}

.search-button {
  width: 62px;
  height: 32px;
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .search-wrapper {
    background: var(--Grey-grey-50);
    gap: 16px;
    border: none;
    height: 140px;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }

  .search-input {
    width: 554px;
    height: 40px;
  }

  :deep(.cruise-input) {
    height: 40px;
    border: 1px solid var(--Grey-grey-100);
  }

  :deep(.cruise-input),
  :deep(.input-placeholder) {
    padding: 0 16px;
    font-size: 16px;
  }

  .search-button {
    width: 100px;
    height: 40px;
    font-size: 16px;
  }
}
</style>
