<template>
  <ModalOnlyMobile
    :showModal="showModal"
    @update:showModal="updateShowModal"
    :title="$t('labels.selectDate')"
    class="modal-wrapper"
  >
    <div class="week">
      <div v-for="(day, idx) in dayOfWeek" class="unit" :key="idx">
        <span class="empty">{{ day }}</span>
      </div>
    </div>
    <div class="content-wrapper">
      <ReCruiseCalendar
        :range="range"
        :dateRange="dateRange"
        :allowedDate="allowedDate"
        @click.stop
        @update:dateRange="updateDateRange"
        :disabled="disabled"
        :doubleCalendar="doubleCalendar"
      >
        <div class="date-input-wrapper">
          <ReDateItem :range="range" :label="label.from" :date="dateRange.from" :disabled="disabled" />
          <template v-if="range">
            <span class="date-separator" :class="{ disabled: disabled }">~</span>
            <ReDateItem :label="label.to" :date="dateRange.to" :disabled="disabled" />
          </template>
        </div>

        <div class="button-wrapper">
          <ReCruiseButton class="confirm-button" @click="closeModal">{{ $t('actions.confirm') }}</ReCruiseButton>
        </div>
      </ReCruiseCalendar>
    </div>
  </ModalOnlyMobile>
</template>

<script>
import ModalOnlyMobile from '@/components/renewal/modal/ModalOnlyMobile.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import ReCruiseCalendar from '@/components/renewal/calendar/ReCruiseCalendar.vue';
import ReDateItem from '@/components/renewal/common/ReDateItem.vue';

const dayOfWeek = {
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  ko: ['일', '월', '화', '수', '목', '금', '토'],
  ja: ['日', '月', '火', '水', '木', '金', '土'],
};

export default {
  name: 'ReCalendarModal',
  components: { ModalOnlyMobile, ReCruiseButton, ReCruiseCalendar, ReDateItem },
  emits: ['update:showModal', 'update:dateRange', 'selectedConfirm'],
  props: {
    showModal: Boolean,
    range: Boolean,
    allowedDate: Array,
    dateRange: {
      type: Object,
      required: true,
    },
    label: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    doubleCalendar: Boolean,
  },
  computed: {
    dayOfWeek() {
      return dayOfWeek[this.$i18n.locale];
    },
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    updateDateRange(dateRange) {
      this.$emit('update:dateRange', dateRange);
    },
    closeModal() {
      this.$emit('selectedConfirm');
      this.updateShowModal(!this.showModal);
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: relative;
  padding-top: 60px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  top: 100px;
}

.date-input-wrapper {
  position: fixed;
  left: 0;
  bottom: 54px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 46px;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  background: #f4f5f6;
  box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1);
}

.date-separator {
  height: 20px;
}

.button-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.confirm-button {
  width: 100%;
  height: 54px;
  border-radius: 0 !important;
}

.date-item {
  width: 100%;
}

.week {
  position: fixed;
  top: 60px;
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 16px;
  border-bottom: 1px solid var(--Grey-grey-100);
  background: var(--White);
  z-index: var(--zContentsHeader);
}

.unit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
  text-align: center;
  color: var(--Grey-grey-600);
}

.unit:first-child,
.unit:first-child .date {
  color: var(--Orange-orange-500) !important;
}

.empty {
  width: 35px;
  padding-right: 13.83px;
  box-sizing: content-box;
}

@media screen and (min-width: 768px) {
  :deep(.modal-wrapper) {
    height: auto;
  }

  .content-wrapper {
    position: static;
  }

  .date-input-wrapper {
    display: none;
  }

  .week {
    display: none;
  }

  .button-wrapper {
    position: static;
    width: 80px;
    height: 36px;
    justify-content: center;
    align-items: center;
  }

  .confirm-button {
    width: 100%;
    height: 100%;
    border-radius: 5px !important;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
