<template>
  <div class="support-container container-default-renewal">
    <BreadcrumbNavigation />

    <router-view></router-view>
  </div>
</template>

<script>
import BreadcrumbNavigation from '@/components/common/BreadcrumbNavigation';

export default {
  name: 'SupportView',
  components: { BreadcrumbNavigation },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.support-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  padding-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .support-container {
    gap: 60px;
    padding-bottom: 200px;
  }
}
</style>
