<template>
  <div class="page-title-wrapper">
    <h1 class="page-title">{{ $t('menu.notice') }}</h1>
  </div>

  <div class="notice-inner">
    <CruiseSearch :placeholder="$t('labels.searchKeyword')" @search="search" />

    <div v-if="noticeList && noticeList.length > 0" v-for="notice in noticeList" :key="notice.id">
      <CruiseListItem
        :emphasis="isNewText(notice.isNew)"
        :title="getLocalText(notice, 'title')"
        :desc="notice.createdAt"
        @onClick="goNoticeDetail(notice.id)"
      />
    </div>

    <div v-else-if="noResultsMessage" class="no-result">
      {{ noResultsMessage }}
    </div>
  </div>

  <CruisePagination :page="activePage" :pages="totalPage" @update:page="handlePage" />
</template>

<script>
import dayjs from 'dayjs';
import { getLocalText, getLocalKey } from '@/utils/convert';

import NoticeService from '@/services/notice';

import CruiseSearch from '@/components/common/CruiseSearch';
import CruiseListItem from '@/components/common/CruiseListItem';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'Notice',
  components: { CruiseSearch, CruiseListItem, CruisePagination },
  data() {
    return {
      keyword: '',
      noticeList: [],
      activePage: 1,
      totalPage: 0,
      noResultsMessage: '',
    };
  },
  methods: {
    getLocalText,

    async getNoticeList(page) {
      try {
        const isOpenedLocaleKey = getLocalKey('isOpened');

        const params = {
          keyword: this.keyword || '',
          page: page || 1,
          limit: 10,
          [isOpenedLocaleKey]: true,
        };
        const { data } = await NoticeService.getNoticeList(params);
        this.totalPage = data.pages;
        this.noticeList = data.notices.map(notice => ({
          ...notice,
          isNew: this.isWithinThreeDays(notice.createdAt),
        }));

        this.noResultsMessage = this.noticeList.length === 0 ? this.$t('messages.noSearchResults') : '';
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    isWithinThreeDays(createdAt) {
      return dayjs().diff(dayjs(createdAt), 'day') < 3;
    },
    isNewText(isNew) {
      return isNew ? 'NEW' : '';
    },
    handlePage(page) {
      this.activePage = page;
      this.getNoticeList(page);
    },
    search(keyword) {
      this.keyword = keyword;
      this.getNoticeList();
    },
    goNoticeDetail(id) {
      this.$router.push(`/support/notice/${id}`);
    },
  },
  created() {
    this.getNoticeList();
  },
};
</script>

<style scoped>
.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  word-break: keep-all;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}
.notice-inner {
  margin-bottom: 50px;
}

.no-result {
  display: flex;
  padding: 150px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--Grey-grey-700);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.1px;
}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 34px;
  }

  .no-result {
    padding: 250px 0 0;
    font-size: 22px;
  }
}
</style>
