<template>
  <div ref="select">
    <SelectModalOnlyMobile
      v-model="locale"
      v-model:isOpen="isOpenLanguageModal"
      valueProperty="locale"
      :options="languageList"
      :title="$t('actions.selectLanguage')"
      wrapperClass="wrapper-class"
      selectorClass="selector-class"
      optionsClass="options-class"
      @onChange="changeLocale"
    >
      <template #iconImg>
        <span class="triangle-icon" :class="{ active }">
          <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Polygon 1" d="M3.5 4.5L0.468911 0.75L6.53109 0.75L3.5 4.5Z" fill="currentColor" />
          </svg>
        </span>
      </template>

      <template #option="{ item }">
        <div class="country-option">
          <div class="country-icon" :class="`${item.locale}`">
            <img :src="countryIconSrc(item.locale)" alt="flag" />
          </div>
          {{ item.label }}
        </div>
      </template>
    </SelectModalOnlyMobile>
  </div>
</template>

<script>
import { setItem } from '@/utils/localStorage';

import SelectModalOnlyMobile from '@/components/renewal/modal/SelectModalOnlyMobile.vue';

export default {
  name: 'LocaleSelect',
  components: { SelectModalOnlyMobile },
  props: {
    active: Boolean,
  },
  data() {
    return {
      isOpenLanguageModal: false,
      languageList: [
        {
          locale: 'ko',
          label: '한국어',
        },
        // {
        //   locale: 'ja',
        //   label: '日本語',
        // },
        {
          locale: 'en',
          label: 'English',
        },
      ],
    };
  },
  computed: {
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(value) {
        this.$emit('changeLocale', value);
      },
    },
  },
  methods: {
    countryIconSrc(locale) {
      return require(`@/assets/images/icon/country_icon_${locale}.svg`);
    },
    changeLocale(locale) {
      setItem('userLanguage', locale);
      window.location.reload();
    },
    handleClickOutside(event) {
      if (this.$refs.select && !this.$refs.select.contains(event.target)) {
        this.isOpenLanguageModal = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.country-option {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
}

.country-icon {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 22.5px;
  border: 1px solid var(--Grey-grey-100);
  overflow: hidden;
  background-color: var(--White);
}

.country-icon img {
  width: 22.5px;
  height: 14.25px;
}

.country-icon.ja img {
  height: 14.25px;
}

.country-icon.en img {
  width: 27px;
  height: 18px;
}

.triangle-icon.active {
  color: #333333;
}

.wrapper-class {
  width: 85px;
  height: 32px;
}

:deep(.selector-class) {
  border: none;
  border-radius: 0;
  gap: 10px;
  padding: 0;
  background: none;
}

@media screen and (min-width: 768px) {
  :deep(.options-class) {
    position: relative;
    top: 0;
    left: -12px;
    width: 109px;
  }

  .triangle-icon {
    color: #fff;
  }

  .triangle-icon.active {
    color: #333333 !important;
  }

  .header-container:hover .triangle-icon {
    color: #333333;
  }
}
</style>
