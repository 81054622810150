import { createApp, h } from 'vue';
import CruiseAlert from '@/components/common/dialog/CruiseAlert.vue';
import CruiseConfirm from '@/components/common/dialog/CruiseConfirm.vue';

export default {
  install: (app, { i18n }) => {
    app.config.globalProperties.$alert = message => {
      return new Promise(resolve => {
        const alertMountNode = document.createElement('div');
        document.body.appendChild(alertMountNode);

        const alertApp = createApp({
          render() {
            return h(CruiseAlert, {
              message,
              onClose: () => {
                alertApp.unmount();
                document.body.removeChild(alertMountNode);
                resolve();
              },
            });
          },
        });

        alertApp.use(i18n);
        alertApp.mount(alertMountNode);
      });
    };

    app.config.globalProperties.$confirm = message => {
      return new Promise(resolve => {
        const confirmMountNode = document.createElement('div');
        document.body.appendChild(confirmMountNode);

        const confirmApp = createApp({
          render() {
            return h(CruiseConfirm, {
              message,
              onConfirm: () => {
                confirmApp.unmount();
                document.body.removeChild(confirmMountNode);
                resolve(true);
              },
              onCancel: () => {
                confirmApp.unmount();
                document.body.removeChild(confirmMountNode);
                resolve(false);
              },
            });
          },
        });

        confirmApp.use(i18n);
        confirmApp.mount(confirmMountNode);
      });
    };
  },
};
