import api from '@/services/api';

const API_URL = '/api/mileage';

class MileageService {
  async getUserMileage(params) {
    try {
      const response = await api.get(`${API_URL}/read`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getUserMileageList(params) {
    try {
      const response = await api.get(`${API_URL}/readList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new MileageService();
