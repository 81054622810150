<template>
  <li v-if="distributed.header" class="tab" :class="tabClass" @click="navClick">
    <slot name="title">
      <span>{{ title }}</span>
    </slot>
    <div v-show="isActive && !noBorder" class="active-border"></div>
  </li>

  <template v-else>
    <div v-show="isActive" v-bind="$attrs">
      <slot></slot>
    </div>
  </template>
</template>

<script>
export default {
  name: 'CruiseTab',
  inheritAttrs: false,
  inject: ['tabs', 'distributed'],
  props: {
    title: String,
    tabType: {
      type: String,
      default: 'default',
      validator: value => ['default', 'add'].includes(value),
    },
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    noBorder() {
      const theme = this.distributed.theme;
      return theme === 'group' || theme === 'group center' || theme === 'primary-button';
    },
    isActive() {
      return this.tabs.activeTab === this.index;
    },
    tabClass() {
      return [{ active: this.isActive }, this.distributed.theme, this.distributed.size];
    },
  },
  methods: {
    navClick() {
      if (!this.isActive && this.tabType !== 'add') {
        this.distributed.changeTabTo(this.index);
      }
    },
  },
  mounted() {
    this.index = Array.from(this.$parent.$el.children).indexOf(this.$el);
  },
};
</script>

<style scoped>
/* Tab Theme : Default */
.tab {
  height: 40px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 13px 8px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #969696;
  min-width: 70px;
}

.tab.active {
  color: var(--Black);
  font-weight: 700;
}

div {
  width: 100%;
  height: 100%;
}

span {
  display: block;
}

.active-border {
  position: absolute;
  bottom: 0;
  left: 8px;
  width: calc(100% - 16px);
  height: 2px;
  background: var(--Black);
  border-radius: 5px 5px 0 0;
}

/* Tab Theme : Group */
.tab.group {
  width: 100%;
  height: 100%;
  color: var(--Grey-grey-500);
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.1px;
  background: var(--White);
  padding: 10px 30px 16px;
  border-bottom: 1px solid var(--Grey-grey-100);
}

.tab.group.small {
  font-size: 14px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab.group.active {
  color: var(--Blue-blue-500);
  font-weight: 700;
  border-bottom: 2px solid var(--Blue-blue-500, #446ceb);
}

.tab.group.center {
  width: auto;
}

/* Tab Theme : Blue */
.tab.blue {
  width: 120px;
  min-width: 120px;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
}

.tab.blue.active {
  font-weight: 700;
  color: var(--Blue-blue-500, #446ceb);
}

.tab.blue .active-border {
  left: 0;
  width: 100%;
  border-radius: 0;
  background: var(--Blue-blue-500, #446ceb);
}

.tab.primary-button {
  color: var(--Blue-blue-500, #446ceb);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  border-radius: 100px;
  border: 1px solid var(--Blue-blue-500, #446ceb);
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.tab.primary-button.active {
  color: #fff;
  background: var(--Blue-blue-500, #446ceb);
}

@media screen and (min-width: 768px) {
  .tab {
    height: 60px;
    font-size: 16px;
    padding: 0;
    min-width: 120px;
  }

  /* Tab Theme : Blue */
  .tab.blue {
    font-size: 16px;
  }

  .active-border {
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 2px;
    background: var(--Black);
  }

  /* Tab Theme : Group */
  .tab.group {
    height: 100%;
    font-size: 20px;
  }

  .tab.group.small {
    font-size: 20px;
    padding: 10px 30px 16px;
  }

  .tab.group.active {
    color: var(--Blue-blue-500);
    font-weight: 700;
    border-bottom: 2px solid var(--Blue-blue-500, #446ceb);
  }

  .tab.primary-button {
    font-size: 20px;
    padding: 16px 30px;
  }
}
</style>
