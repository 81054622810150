<template>
  <div v-if="showModal" class="modal-background">
    <div class="modal-contents" v-bind="$attrs" @click.stop>
      <div class="modal-title">
        {{ title }}
        <button v-if="showCloseIcon" class="close-button flex-center" @click="closeModal">
          <img class="close-icon" src="@/assets/images/icon/close_icon.svg" alt="icon" />
        </button>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalOnlyMobile',
  inheritAttrs: false,
  emits: ['update:showModal', 'onClose'],
  props: {
    title: String,
    showModal: {
      type: Boolean,
      required: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(value) {
        this.applyOverflowHidden(value);
      },
    },
  },
  beforeUnmount() {
    this.applyOverflowHidden(false);
  },
  methods: {
    closeModal() {
      this.$emit('update:showModal', false);
      this.$emit('onClose');
    },
    handleScroll() {
      this.applyOverflowHidden();
    },
    applyOverflowHidden(value) {
      if (window.innerWidth < 768) {
        if (value) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-background {
  position: fixed;
  z-index: var(--zLayoutModal);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-contents {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--White);
  overflow: auto;
}

.modal-title {
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--Grey-grey-100);
  color: var(--Grey-grey-900);
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.54px;
  background: var(--White);
  position: fixed;
  z-index: var(--zContentsHeader);
}

.close-button {
  position: absolute;
  top: 22px;
  right: 16px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal-background {
    position: static;
  }

  .modal-contents {
    position: static;
    overflow: visible;
  }

  .modal-title {
    display: none;
  }
}
</style>
