<template>
  <div class="input-field-wrapper">
    <CruiseLabel :label="label" :helperText="helperText" :required="required" />
    <slot></slot>
  </div>
</template>

<script>
import CruiseLabel from '@/components/common/CruiseLabel.vue';

export default {
  name: 'CruiseInputField',
  components: { CruiseLabel },
  props: {
    label: String,
    helperText: String,
    required: Boolean,
  },
};
</script>

<style scoped>
.input-field-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
