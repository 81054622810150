<template>
  <div class="my-page-wrapper">
    <div class="banner flex-center">
      <div class="welcome-message">{{ $t('user.welcome', { userName: user?.name }) }}</div>
    </div>

    <div class="contents-wrapper">
      <ReCruiseTabs theme="group" size="small" v-model:activeTab="globalTab">
        <ReCruiseTab :title="$t('labels.upcomingJourney')">
          <div class="my-trip-wrapper">
            <ReCruiseTabs theme="primary-button" v-model:activeTab="categoryTab" v-if="!isLoading">
              <template #common>
                <div v-if="$i18n.locale !== 'en'" class="past-wrapper">
                  <span>
                    <u>{{ $t('checkReservationDetails.date') }}</u
                    >{{ $i18n.locale === 'ko' ? ' ' : '' }}{{ $t('checkReservationDetails.prevReservationList') }}
                  </span>
                  {{ $i18n.locale === 'ko' ? '&nbsp;' : '' }}
                  <span>
                    <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
                      {{ $t('checkReservationDetails.here') }} </a
                    >{{ $t('checkReservationDetails.pleaseCheck') }}
                  </span>
                </div>

                <div v-else class="past-wrapper">
                  <span>
                    {{ $t('checkReservationDetails.pleaseCheck') }}
                    <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
                      {{ $t('checkReservationDetails.here') }}
                    </a>
                  </span>
                  &nbsp;
                  <span>
                    {{ $t('checkReservationDetails.prevReservationList') }}
                    <u>{{ $t('checkReservationDetails.date') }} </u>
                  </span>
                </div>
              </template>

              <ReCruiseTab
                v-for="(trips, category) in tripList"
                :title="`${categoryLabelMap[category]}${trips.length > 0 ? `(${trips.length})` : ''}`"
              >
                <template v-for="trip in trips">
                  <component
                    :is="tripComponent[trip.reservationMaster.route.code]"
                    :trip="trip"
                    :tripState="tripState"
                  ></component>
                </template>
              </ReCruiseTab>
            </ReCruiseTabs>
          </div>
        </ReCruiseTab>

        <ReCruiseTab :title="$t('labels.pastJourney')">
          <div class="my-trip-wrapper">
            <ReCruiseTabs theme="primary-button" v-model:activeTab="categoryTab" v-if="!isLoading">
              <template #common>
                <div v-if="$i18n.locale !== 'en'" class="past-wrapper">
                  <span>
                    <u>{{ $t('checkReservationDetails.date') }}</u
                    >{{ $i18n.locale === 'ko' ? ' ' : '' }}{{ $t('checkReservationDetails.prevReservationList') }}
                  </span>
                  {{ $i18n.locale === 'ko' ? '&nbsp;' : '' }}
                  <span>
                    <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
                      {{ $t('checkReservationDetails.here') }} </a
                    >{{ $t('checkReservationDetails.pleaseCheck') }}
                  </span>
                </div>

                <div v-else class="past-wrapper">
                  <span>
                    {{ $t('checkReservationDetails.pleaseCheck') }}
                    <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
                      {{ $t('checkReservationDetails.here') }}
                    </a>
                  </span>
                  &nbsp;
                  <span>
                    {{ $t('checkReservationDetails.prevReservationList') }}
                    <u>{{ $t('checkReservationDetails.date') }} </u>
                  </span>
                </div>
              </template>

              <ReCruiseTab
                v-for="(trips, category) in tripList"
                :title="`${categoryLabelMap[category]}${trips.length > 0 ? `(${trips.length})` : ''}`"
              >
                <template v-for="trip in trips">
                  <component
                    :is="tripComponent[trip.reservationMaster.route.code]"
                    :trip="trip"
                    :tripState="tripState"
                  ></component>
                </template>
              </ReCruiseTab>
            </ReCruiseTabs>
          </div>
        </ReCruiseTab>

        <ReCruiseTab :title="$t('labels.myCoupon')">
          <div class="coupon-tab-wrapper">
            <MyCoupons v-if="globalTab === 2" />
          </div>
        </ReCruiseTab>

        <ReCruiseTab :title="$t('labels.mileage')">
          <div class="mileage-tab-wrapper">
            <MyMileage v-if="globalTab === 3" />
          </div>
        </ReCruiseTab>
      </ReCruiseTabs>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ReservationService from '@/services/reservation';

import MyCoupons from '@/components/myPage/MyCoupons.vue';
import MyMileage from '@/components/myPage/MyMileage.vue';
import ReCruiseTabs from '@/components/renewal/tab/ReCruiseTabs.vue';
import ReCruiseTab from '@/components/renewal/tab/ReCruiseTab.vue';
import CruiseTripItem from '@/components/myPage/CruiseTripItem.vue';
import FastShipTripItem from '@/components/myPage/FastShipTripItem.vue';

export default {
  name: 'MyPageView',
  components: { MyCoupons, MyMileage, ReCruiseTabs, ReCruiseTab, CruiseTripItem },
  data() {
    return {
      globalTab: 0,
      timeMap: ['future', 'past'],
      categoryTab: 0,
      categoryMap: ['cruise', 'fastShip', 'package'],
      categoryLabelMap: {
        cruise: this.$t('cruise'),
        fastShip: this.$t('express'),
        package: this.$t('tourProduct.label'),
      },
      tripList: { cruise: [], fastShip: [], package: [] },
      tripState: '',
    };
  },
  computed: {
    ...mapState('common', ['isLoading']),
    ...mapState('auth', ['user']),

    tripComponent() {
      return {
        OSC: CruiseTripItem,
        ONC: CruiseTripItem,
        TSE: FastShipTripItem,
      };
    },
  },
  watch: {
    globalTab: {
      immediate: true,
      handler(globalTab) {
        if (globalTab > 1) return;
        this.tripState = this.timeMap[globalTab];

        this.categoryTab = 0;
        this.getMyTrip();
      },
    },
  },
  methods: {
    async getMyTrip() {
      try {
        const promises = this.categoryMap.map(async category => {
          const query = {
            userId: this.user.userId,
            time: this.timeMap[this.globalTab],
            category,
          };

          const { data } = await ReservationService.getMyTrip(query);
          return { category, data: data || [] };
        });

        const results = await Promise.all(promises);

        results.forEach(({ category, data }) => {
          this.tripList[category] = data;
        });
      } catch (error) {
        await this.$alert({ content: error });
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.my-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 97px;
  background: #ecf0fd;
}

.contents-wrapper {
  width: 100%;
  height: 100%;
}

.welcome-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  font-size: 20px;
  font-weight: 700;
  color: var(--Blue-blue-700, #304da7);
}

.my-trip-wrapper {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}

.past-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 150%;
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
}

.past-link {
  color: #446ceb;
  font-weight: 700;
  text-decoration-line: underline;
}

.coupon-tab-wrapper {
  display: flex;
  justify-content: center;
  padding: 20px 16px;
}

@media screen and (min-width: 768px) {
  .banner {
    height: 160px;
  }

  .contents-wrapper {
    width: 100%;
    height: 100%;
    max-width: 1384px;
    padding: 24px 16px 100px;
  }

  .welcome-message {
    justify-content: flex-start;
    font-size: 28px;
    max-width: 1384px;
  }

  .my-tab-wrapper {
    padding: 0;
    gap: 20px;
  }

  .my-trip-wrapper {
    padding-top: 60px;
  }

  .past-wrapper {
    justify-content: flex-start;
    font-size: 20px;
  }

  .mileage-tab-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
