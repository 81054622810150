<template>
  <label class="custom-radio">
    <span v-if="label" class="radio-label" :class="labelClass">{{ label }}</span>
    <input type="radio" hidden :id="safeId" v-bind="attrs" :value="value" :checked="isChecked" @change="handleChange" />
    <span class="checkmark flex-center">
      <CheckRoundIcon class="check-icon" :size="14" v-if="isChecked" />
    </span>
  </label>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';
import CheckRoundIcon from 'vue-material-design-icons/CheckBold';

export default {
  name: 'CruiseRadio',
  mixins: [sharedComputedProps],
  emits: ['update:modelValue', 'onChange'],
  components: { CheckRoundIcon },
  props: {
    label: String,
    value: [String, Number, Boolean, Object],
    modelValue: [String, Number, Boolean],
    position: {
      type: String,
      default: 'right',
      validator: value => ['right', 'top'].includes(value),
    },
  },
  computed: {
    isChecked() {
      return this.$attrs.checked ?? this.value === this.modelValue;
    },
    isPositionRight() {
      return this.position === 'right';
    },
    labelClass() {
      return {
        'label-right': this.isPositionRight,
      };
    },
    flexDirection() {
      return this.isPositionRight ? 'row' : 'column';
    },
  },
  methods: {
    handleChange(e) {
      let value = e.target.value;
      let type = typeof this.value;

      if (type !== 'string') {
        value = JSON.parse(value);
      }

      this.$emit('update:modelValue', value);
      this.$emit('onChange', e);
    },
  },
};
</script>

<style scoped>
.custom-radio {
  display: inline-flex;
  flex-direction: v-bind(flexDirection);
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.label-right {
  order: 1;
}

.checkmark {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--White);
  border: 1px solid var(--Black);
}

.custom-radio input:checked ~ .checkmark {
  background-color: var(--Main);
  border: 1px solid transparent;
}

.check-icon {
  color: var(--White);
}

.radio-label {
  display: inline-block;
  height: 12px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .custom-radio {
    font-size: 14px;
  }

  .radio-label {
    font-size: 14px;
    height: 14px;
  }
}
</style>
