<template>
  <div class="select-wrapper" :class="disabledClass" @click="handleClick">
    <div class="selector" :class="labelClass">
      <span class="text-ellipsis">
        <template v-if="selectedItem">
          <template v-if="$slots.option">
            <slot name="option" :item="selectedItem"></slot>
          </template>
          <span v-else :title="selectedItem"> {{ selectedItem }} </span>
        </template>
        <span v-else :title="placeholder">{{ placeholder || $t('actions.select') }}</span>
      </span>

      <template v-if="isArrowIcon">
        <template v-if="$slots.iconImg">
          <div :class="activeClass">
            <slot name="iconImg"></slot>
          </div>
        </template>

        <template v-else>
          <div class="arrow-icon-wrapper" :class="activeClass">
            <img class="arrow-icon" src="@/assets/images/icon/arrow_down_icon.svg" alt="arrow icon" />
          </div>
        </template>
      </template>
    </div>

    <div class="options-wrapper" v-if="showOptions">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="option-item text-ellipsis"
        :class="highlightClass(item?.highlight)"
        @click="selectItem(item)"
      >
        <template v-if="$slots.option">
          <slot name="option" :item="item" :index="index"></slot>
        </template>
        <span v-else>{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CruiseSelect',
  emits: ['update:modelValue', 'update:isOpen', 'onClick', 'onChange'],
  props: {
    modelValue: [String, Number, Object, Boolean],
    valueProperty: String,
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    isOpen: Boolean,
    isArrowIcon: {
      type: Boolean,
      default: true,
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showOptions: false,
      selectedItem: null,
    };
  },
  watch: {
    isOpen(value) {
      this.showOptions = value;
    },
    options(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.selectedItem = '';
      }
    },
  },
  computed: {
    labelClass() {
      return {
        'gray-color': this.modelValue === '' || this.modelValue === null || this.modelValue === undefined,
      };
    },
    disabledClass() {
      return {
        'gray-color': this.disabled,
      };
    },
    activeClass() {
      return {
        active: this.showOptions,
      };
    },
    highlightClass() {
      return highlight => ({ highlight: highlight });
    },
    borderNoneClass() {
      return {
        'border-none': !this.isBorder,
      };
    },
  },
  methods: {
    init() {
      const isEmptyValue = this.modelValue === undefined;
      const isModelObject = typeof this.modelValue === 'object';

      if (this.options.length && !isEmptyValue) {
        const selectedItem = this.options.find(el => el[this.valueProperty] === this.modelValue);
        this.selectedItem = isModelObject ? this.modelValue : selectedItem;
      }
    },
    handleClick() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
        this.$emit('update:isOpen', this.showOptions);
        this.$emit('onClick');
      }
    },
    selectItem(item) {
      this.selectedItem = item;

      const isModelObject = typeof this.modelValue === 'object';
      const value = isModelObject || !this.valueProperty ? item : item[this.valueProperty];

      this.$emit('update:modelValue', value);
      this.$emit('onChange', value);
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.select-wrapper {
  width: 100%;
  height: 40px;
  position: relative;
  align-items: center;
  font-size: 14px;
}

.gray-color {
  color: var(--Grey-grey-200);
}

.select-wrapper.gray-color {
  pointer-events: none;
}

.selector {
  width: 100%;
  height: 100%;
  padding: 0 8px 0 16px;
  border-radius: 5px;
  border: 1px solid var(--Grey-grey-100);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--White);
}

.border-none {
  border: none;
  background: none;
}

.options-wrapper {
  position: absolute;
  width: 100%;
  top: 42px;
  border-radius: 5px;
  border-top: 0.5px solid #f0f0f0;
  border-bottom: 0.5px solid #f0f0f0;
  background: var(--White);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.07), 2px 2px 6px 0px rgba(0, 0, 0, 0.1);
  z-index: var(--zContentsPopover);
  overflow: hidden;
}

.option-item {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  cursor: pointer;
}

.option-item:hover {
  color: var(--Blue-blue-500);
  background: var(--Blue-blue-50);
}

.option-item.highlight {
  color: var(--Blue-blue-500);
}

.arrow-icon {
  width: 24px;
  height: 24px;
  color: var(--Grey-grey-600);
  cursor: pointer;
  transition: transform 0.5s ease, color 0.5s ease;
  pointer-events: none;
}

.arrow-icon.active {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .select-wrapper {
    height: 50px;
  }

  .options-wrapper {
    top: 52px;
  }
}
</style>
