<template>
  <div class="login-inner">
    <div class="login-logo">
      <img class="logo-img" src="@/assets/images/logo/panstar_full_logo.svg" alt="panstar cruise logo" />
    </div>
    <form class="login-form-wrapper" @submit.prevent="handleLogin">
      <div class="login-form">
        <CruiseInput
          type="userId"
          class="input-id"
          name="id"
          v-model="userId"
          :placeholder="$t('login.inputId')"
          required
        />
        <CruiseInput
          class="input-pw"
          type="password"
          name="pw"
          v-model="userPassword"
          :placeholder="$t('login.inputPassword')"
          required
        />
      </div>
      <div class="save-id">
        <CruiseCheckbox v-model="isSaveId" :label="$t('login.saveId')" />
      </div>
      <CruiseButton type="submit" class="login-btn">{{ $t('user.login') }}</CruiseButton>
    </form>
    <div class="search-account flex-between">
      <span class="search-id" @click="goSearchPage('id')">{{ $t('login.findId') }}</span>
      <span class="search-pw" @click="goSearchPage('pw')">{{ $t('login.findPassword') }}</span>
    </div>
    <!-- <div class="separator"><p>{{ $t('login.loginWithAnotherAccount') }}</p></div>
    <div class="sns-login">
      <CruiseButton class="kakao" theme="t">
        <img src="@/assets/images/icon/kakao-icon.svg" alt="kakao logo" />
        {{ $t('login.loginWithKakao') }}
      </CruiseButton>
      <CruiseButton class="google" theme="t">
        <img src="@/assets/images/icon/google-icon.svg" alt="google logo" />
        {{ $t('login.loginWithGoogle') }}
      </CruiseButton>
    </div> -->
    <CruiseButton class="signup" theme="t" @click="goRegister">{{ $t('user.signUp') }}</CruiseButton>
  </div>

  <div class="flex-center">
    <div v-if="$i18n.locale !== 'en'" class="past-wrapper">
      <span>
        <u>{{ $t('checkReservationDetails.date') }}</u
        >{{ $i18n.locale === 'ko' ? ' ' : '' }}{{ $t('checkReservationDetails.prevReservationList') }}
      </span>
      {{ $i18n.locale === 'ko' ? '&nbsp;' : '' }}
      <span>
        <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
          {{ $t('checkReservationDetails.here') }} </a
        >{{ $t('checkReservationDetails.pleaseCheck') }}
      </span>
    </div>

    <div v-else class="past-wrapper">
      <span>
        {{ $t('checkReservationDetails.pleaseCheck') }}
        <a class="past-link" href="https://ticket.panstarcruise.com/member/booking.html" target="_blank">
          {{ $t('checkReservationDetails.here') }}
        </a>
      </span>
      &nbsp;
      <span>
        {{ $t('checkReservationDetails.prevReservationList') }}
        <u>{{ $t('checkReservationDetails.date') }} </u>
      </span>
    </div>
  </div>

  <UserInfoModal v-if="isOpenUserInfoModal" v-model:showModal="isOpenUserInfoModal" :userId="userId" />
</template>

<script>
import { mapState } from 'vuex';
import { getItem, setItem, removeItem } from '@/utils/localStorage';

import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseCheckbox from '@/components/common/CruiseCheckbox.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';
import UserInfoModal from './UserInfoModal.vue';

export default {
  name: 'LoginForm',
  components: { CruiseInput, CruiseCheckbox, CruiseButton, UserInfoModal },
  data() {
    return {
      userId: getItem('userId') || '',
      userPassword: '',
      isSaveId: false,
      isLogin: false,
      isOpenUserInfoModal: false,
    };
  },
  watch: {
    isSaveId(newValue) {
      if (newValue) {
        setItem('userId', this.userId);
      } else {
        removeItem('userId');
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'status']),
  },
  created() {
    this.init();
  },
  mounted() {
    this.loadUserId();
  },
  methods: {
    init() {
      if (this.user) this.$router.push('/');
    },
    loadUserId() {
      const userIdInStorage = getItem('userId');
      if (userIdInStorage) {
        this.userId = userIdInStorage;
        this.isSaveId = true;
      }
    },
    async handleLogin() {
      if (this.isLogin) return;
      this.isLogin = true;

      try {
        if (!this.userId || !this.userPassword) {
          await this.$alert({ content: this.$t('login.enterUserIdAndPassword') });
          return;
        }

        const params = { userId: this.userId, password: this.userPassword };
        await this.$store.dispatch('auth/login', params);

        if (this.isSaveId) {
          setItem('userId', this.userId);
        } else {
          removeItem('userId');
        }

        if (this.status.isInfoMissed) {
          this.isOpenUserInfoModal = true;
          return;
        }

        const redirect = this.$route.query.redirect || '/';
        this.$router.push(redirect);
      } catch (error) {
        this.$alert({ content: error });
      } finally {
        this.isLogin = false;
      }
    },
    goSearchPage(type) {
      const path = `/login/search/${type}`;
      this.$router.push(path);
    },
    goRegister() {
      this.$store.dispatch('booking/setRedirectTo', this.$route.query.redirect || '/');
      this.$router.push('/register');
    },
  },
};
</script>

<style scoped>
.login-inner {
  width: 100%;
  padding: 0 16px 20px;
  font-size: 14px;
}

.login-logo {
  width: 100%;
  height: 24px;
  margin: 40px 0;
}
.logo-img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.input-id,
.input-pw {
  margin-bottom: 10px;
}

.save-id {
  height: 20px;
  margin-bottom: 20px;
}

.login-btn {
  width: 100%;
}

.search-account {
  margin: 10px 0 20px;
}

.search-id,
.search-pw {
  display: block;
  cursor: pointer;
}

.separator {
  position: relative;
  width: 100%;
  min-width: 328px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.separator::before {
  content: '';
  position: absolute;
  top: 20px;
  left: 0;
  width: calc(32% - 10px);
  height: 1px;
  background: #dcdcdc;
}

.separator::after {
  content: '';
  position: absolute;
  top: 20px;
  right: 0;
  width: calc(32% - 10px);
  height: 1px;
  background: #dcdcdc;
}

.sns-login {
  display: flex;
  justify-content: space-between;
  height: 40px;
  gap: 16px;
  margin-bottom: 20px;
}

.kakao,
.google {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
}

.kakao > img,
.google > img {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.signup {
  width: 100%;
}

.past-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 150%;
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
}

.past-link {
  color: #446ceb;
  font-weight: 700;
  text-decoration-line: underline;
}

@media screen and (min-width: 768px) {
  .login-inner {
    font-size: 14px;
    width: 396px;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    background: var(--White);
    padding: 0 20px 20px;
    margin: 60px auto 0;
  }

  .separator {
    max-width: 356px;
  }

  .separator::before,
  .separator::after {
    width: calc(33% - 10px);
  }

  .sns-login {
    gap: 10px;
  }
}
</style>
