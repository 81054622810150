import i18n from '@/languages/i18n';
import dayjs from 'dayjs';

export const convertCommaNumber = num => {
  return `${new Intl.NumberFormat().format(num)}`;
};

export const capitalizeFirstLetter = string => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getLocalKey = keyName => {
  const locale = capitalizeFirstLetter(i18n.global.locale);
  return `${keyName}${locale}`;
};

export const getLocalText = (obj, keyName) => {
  const locale = capitalizeFirstLetter(i18n.global.locale);
  return obj[`${keyName}${locale}`] || '';
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format);
};

export const formatDateRange = (startDate, endDate, format = 'YYYY-MM-DD') => {
  const formattedStartDate = dayjs(startDate).format(format);
  const formattedEndDate = dayjs(endDate).format(format);
  return `${formattedStartDate} ~ ${formattedEndDate}`;
};

export const formatPhoneNumber = phoneNumber => {
  if (!phoneNumber) return '-';

  return phoneNumber.length === 11
    ? `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7)}`
    : phoneNumber;
};
