import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/languages/i18n';
import dialogPlugin from '@/plugins/dialogPlugin';
import dompurifyPlugin from '@/plugins/dompurifyPlugin';
import '@/styles/global.css';

createApp(App).use(store).use(i18n).use(router).use(dialogPlugin, { i18n }).use(dompurifyPlugin).mount('#app');
