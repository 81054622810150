<template>
  <div class="event-wrapper">
    <div class="event-header container-default-renewal">
      <div class="event-header-inner">
        <div class="header-title">{{ $t('menu.event') }}</div>
        <div class="header-desc">{{ $t('pageDescription.event') }}</div>
        <TextButton class="event-more" @click="goEventPage">{{ $t('actions.viewAll') }}</TextButton>
      </div>
    </div>
    <div class="event-list container-default-renewal">
      <div class="event-item" v-for="event in eventList" :key="event.id" @click="goEventDetailPage(event.id)">
        <div class="event-thumbnail">
          <img class="event-image" :src="getLocalText(event, 'coverImageUrl')" alt="event thumbnail" />
          <div class="image-dimmed" v-if="!event.statusEn"></div>
        </div>
        <div class="event-content">
          <div class="category event" :class="eventCategoryClass(event.statusEn)">
            <span class="category-text event" :class="eventCategoryClass(event.statusEn)">
              {{ getLocalText(event, 'status') }}
            </span>
          </div>
          <div class="event-title">{{ getLocalText(event, 'name') }}</div>
          <div class="event-date">
            {{ event.isUnlimited ? $t('labels.alwaysOn') : formatDateRange(event.startDate, event.endDate) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getLocalText, getLocalKey, formatDateRange } from '@/utils/convert';

import EventService from '@/services/event';

import TextButton from '@/components/button/TextButton.vue';

export default {
  name: 'EventContent',
  components: { TextButton },
  data() {
    return {
      eventList: [],
    };
  },
  methods: {
    getLocalText,
    formatDateRange,

    init() {
      this.getEventList();
    },
    async getEventList() {
      try {
        const isOpenedLocaleKey = getLocalKey('isOpened');

        const params = {
          statusEn: 'On',
          page: 1,
          limit: 10,
          [isOpenedLocaleKey]: true,
        };

        const { data } = await EventService.getEventList(params);

        const tempData = data.events.sort((a, b) => {
          return dayjs(b.startDate) - dayjs(a.startDate);
        });

        this.eventList = tempData.slice(0, 4);
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    goEventPage() {
      this.$router.push('/event');
    },
    goEventDetailPage(id) {
      if (id !== 143) {
        this.$router.push(`/event/${id}`);
      } else {
        // 신한카드이벤트 예외처리를 위한 코드
        const link = 'https://www.shinhancard.com/pconts/html/benefit/event/1228910_2239.html';
        window.open(link);
      }
    },
    eventCategoryClass(status) {
      if (status === 'On') {
        return 'ongoing';
      } else {
        return 'closed';
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {},
};
</script>

<style scoped>
/* Event Contents */
.event-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.event-header {
  display: flex;
  width: 100%;
}

.event-header-inner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.5px;
}

.header-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.event-more {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  line-height: 24px;
}

.event-list {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-left: 16px;
  padding-bottom: 10px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
}

.event-list::-webkit-scrollbar {
  display: none;
}

.event-item {
  width: 100%;
  min-width: 237px;
  height: 100%;
  border-radius: 20px;
  border: 1px solid var(--Grey-grey-200);
  background: var(--White);
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.event-item:hover {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.event-item:hover .event-image {
  scale: 1.2;
}

.event-thumbnail {
  position: relative;
  width: 100%;
  height: 168px;
  overflow: hidden;
  border-bottom: 1px solid var(--Grey-grey-200);
}

.event-image {
  width: 100%;
  object-fit: cover;
  transition: scale 0.3s ease;
}

.image-dimmed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: var(--White);
}

.event-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.event-title {
  color: var(--Grey-grey-900);
  font-size: 18px;
  font-weight: 800;
  height: 43px;
  line-height: 130%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.event-date {
  color: var(--Grey-grey-600);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

.category {
  width: fit-content;
  height: 19px;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.category.event {
  height: 22px;
  padding: 4px 10px;
}

.category.event.ongoing {
  border: 1px solid #446ceb;
}

.category.event.closed {
  border: 1px solid var(--Grey-grey-700);
}

.category-text {
  width: fit-content;
  height: 13px;
  font-size: 11px;
  font-weight: 500;
}

.category-text.event {
  height: 14px;
  font-size: 12px;
}

.category-text.ongoing {
  color: #446ceb;
}

.category-text.closed {
  color: var(--Grey-grey-700);
}

@media screen and (min-width: 768px) {
  /* Event Contents */
  .event-wrapper {
    gap: 56px;
  }

  .header-title {
    font-size: 34px;
    line-height: 36px;
  }

  .header-desc {
    font-size: 20px;
    line-height: 24px;
  }

  .event-more {
    bottom: 0;
    top: auto;
    font-size: 20px;
  }

  .event-list {
    gap: 23px;
    overflow: visible;
  }

  .event-item {
    min-width: 321px;
  }

  .event-thumbnail {
    height: 325px;
  }

  .event-image {
    object-fit: cover;
  }

  .event-content {
    padding: 24px;
  }

  .event-title {
    font-size: 23.678px;
    margin-bottom: 8px;
    height: 57px;
  }

  .event-date {
    color: var(--Grey-grey-600);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  .category {
    height: 28px;
    padding: 5px 15px;
  }

  .category.event {
    height: 28px;
    padding: 5px 15px;
  }

  .category-text.event {
    height: 18px;
    font-size: 14.799px;
  }
}
</style>
