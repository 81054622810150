import store from '@/store';
import { INCREASE_LOADING, DECREASE_LOADING } from '@/const/const';

export const startLoading = () => {
  store.commit(INCREASE_LOADING);
};

export const stopLoading = () => {
  store.commit(DECREASE_LOADING);
};
