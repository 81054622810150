export const decodeJWT = token => {
  const base64Url = token.split('.')[1]; // Get Payload of JWT
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Convert Base64Url to Base64
  const decodedValue = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  ); // Base64 decoding and URI component decoding
  return JSON.parse(decodedValue); // Parse decoded string into JSON object
};
