<template>
  <div v-show="isShow && popUpList.length > 0" class="popup-wrapper">
    <swiper
      ref="mySwiper"
      class="popup-swiper"
      :modules="modules"
      :loop="true"
      :pagination="paginationOptions"
      @swiper="onSwiper"
    >
      <swiper-slide class="popup-slide" v-for="item in popUpList">
        <img :src="getLocalText(item, 'imageUrl')" alt="popup image" draggable="false" />
      </swiper-slide>
    </swiper>

    <div class="popup-btns">
      <span class="today-btn" @click="hidePopupForToday">{{ $t('actions.doNotShowToday') }}</span>
      <span class="close-btn" @click="closePopup">
        <span>{{ $t('actions.close') }}</span>
        <CloseIcon class="close-icon" :size="20" />
      </span>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import CookieManager from '@/utils/cookie';
import PopUpService from '@/services/popUp';
import { getLocalText } from '@/utils/convert';

import CloseIcon from 'vue-material-design-icons/Close';

export default {
  name: 'CruisePopup',
  components: { Swiper, SwiperSlide, CloseIcon },
  props: {
    showPopup: Boolean,
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
  data() {
    return {
      swiperInstance: null,
      isShow: false,
      popupData: [],
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    paginationOptions() {
      if (this.popUpList.length) {
        return {
          clickable: true,
        };
      } else {
        return false;
      }
    },
    popUpList() {
      const localeMap = { ko: 'isOpenedKo', en: 'isOpenedEn', ja: 'isOpenedJa' };
      return this.popupData.filter(item => item[localeMap[this.locale]]);
    },
  },
  methods: {
    getLocalText,
    getTomorrowMidnight() {
      let now = new Date();
      let tomorrowMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      return tomorrowMidnight;
    },
    hidePopupForToday() {
      const cookieManager = new CookieManager();
      cookieManager.setCookieFromExpireDate('isHidePopupForToday', 'true', this.getTomorrowMidnight());

      this.closePopup();
    },
    closePopup() {
      this.isShow = false;
    },
    isShowPopupForToday() {
      const cookieManager = new CookieManager();
      const isHidePopupForToday = cookieManager.getCookie('isHidePopupForToday');

      this.isShow = !isHidePopupForToday;
    },
    async getPopUpList() {
      try {
        const { data } = await PopUpService.getPopUpList();
        const filteredData = data.filter(item => item.id !== 14); // id = 14: 일본 테스트 페이지 사용 금지 공지 게시
        this.popupData = filteredData;
        this.swiperInstance.slideTo(1, 0);
      } catch (error) {
        console.error(error);
      }
    },
    onSwiper(swiper) {
      this.swiperInstance = swiper;
    },
  },
  created() {
    this.getPopUpList();
    this.isShowPopupForToday();
  },
};
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 86%;

  border-radius: 11px;
  background: var(--White);
  transform: translate(-50%, -50%);
  z-index: var(--zLayoutLoading);

  box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popup-swiper {
  flex: 1 0;
  width: 100%;
  background: gray;
}

.popup-slide,
.swiper-slide-duplicate {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

:deep(.swiper-slide-duplicate) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
}

:deep(.swiper-pagination) {
  bottom: 20px;
}

:deep(.swiper-pagination-bullet) {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: var(--Grey-grey-50, #f5f7f9);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
}

:deep(.swiper-pagination-bullet-active) {
  width: 30px;
  background: var(--Blue-blue-500, #446ceb);
}

.popup-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 50px;
  color: var(--Grey-grey-700, #686d72);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
}

.today-btn,
.close-btn {
  cursor: pointer;
}

.close-btn {
  display: flex;
  align-items: center;
  gap: 4px;
}

.close-icon {
  padding-bottom: 3px;
}

@media screen and (min-width: 768px) {
  .popup-wrapper {
    width: 460px;
  }

  .popup-btns {
    height: 60px;
    font-size: 18px;
  }
}
</style>
