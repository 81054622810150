<template>
  <div class="mileage-wrapper">
    <div class="my-mileage">
      {{ $t('mileage.myMileage') }} : {{ convertCommaNumber(myMileage) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
      }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}
    </div>

    <div>
      <div class="sub-title">{{ $t('mileage.earningHistory') }}</div>
      <CruiseTable :columns="earningHead" :rows="earningList">
        <template #amount="{ item }">
          {{ convertCommaNumber(item.amount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
          }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}
        </template>
      </CruiseTable>
    </div>

    <div>
      <div class="sub-title">{{ $t('mileage.usageHistory') }}</div>
      <CruiseTable :columns="spendingHead" :rows="spendingList">
        <template #amount="{ item }">
          {{ convertCommaNumber(item.amount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
          }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}
        </template>
      </CruiseTable>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { convertCommaNumber } from '@/utils/convert';

import MileageService from '@/services/mileage';
import CruiseTable from '@/components/common/CruiseTable.vue';

export default {
  name: 'MyMileage',
  components: { CruiseTable },
  data() {
    return {
      myMileage: 100000,
      historyList: [],
      earningHead: [
        { key: 'createdAt', label: 'labels.date', style: { width: '40%', 'text-align': 'start' } },
        { key: 'ticketNumber', label: 'reservation.number', style: { width: '30%', 'text-align': 'center' } },
        { key: 'amount', label: 'mileage.amountEarned', style: { width: '30%', 'text-align': 'end' } },
      ],
      spendingHead: [
        { key: 'createdAt', label: 'labels.date', style: { width: '40%', 'text-align': 'start' } },
        { key: 'ticketNumber', label: 'reservation.number', style: { width: '30%', 'text-align': 'center' } },
        { key: 'amount', label: 'mileage.amountUsed', style: { width: '30%', 'text-align': 'end' } },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    earningList() {
      return this.historyList.filter(history => !history.isUsed);
    },
    spendingList() {
      return this.historyList.filter(history => history.isUsed);
    },
  },
  methods: {
    convertCommaNumber,

    init() {
      this.getMyMileage();
      this.getMyMileageHistory();
    },
    async getMyMileage() {
      if (!this.user) return;

      try {
        const query = {
          userId: this.user.userId,
        };
        const { data } = await MileageService.getUserMileage(query);
        this.myMileage = data.mileage;
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
    async getMyMileageHistory() {
      if (!this.user) return;

      try {
        const query = {
          userId: this.user.userId,
          name: this.user?.name,
          mobile: this.user.mobile,
        };
        const { data } = await MileageService.getUserMileageList(query);
        this.historyList = data;
      } catch (error) {
        this.$alert({ content: error });
        console.error(error);
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.mileage-wrapper {
  width: 100%;
  max-width: 812px;
  padding: 20px 16px;
  color: var(--Black);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.my-mileage {
  border-radius: 5px;
  background: #f7f7f7;
  height: 60px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.sub-title {
  font-size: 14px;
  font-weight: 700;
  padding: 12px 20px;
}

.normal-text {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .mileage-wrapper {
    max-width: 812px;
    padding: 20px 0;
  }
}
</style>
