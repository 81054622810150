<template>
  <BreadcrumbNavigation />

  <div class="page-title-wrapper">
    <h1 class="page-title">{{ $t('menu.event') }}</h1>
    <p class="page-desc">{{ $t('pageDescription.event') }}</p>
  </div>

  <div class="event-inner">
    <ReCruiseTabs theme="group" v-model:activeTab="activeCategoryTab">
      <ReCruiseTab v-for="label in categoryTabLabel" :title="$t(label)"> </ReCruiseTab>
    </ReCruiseTabs>

    <div v-if="!activeCategoryTab" class="sub-tab-wrapper">
      <ReCruiseButton
        class="tab-menu"
        v-for="(status, i) in statusTabLabel"
        :theme="activeStatusTab === i ? 'primary' : 'outline'"
        @click="clickStatusTab(i)"
      >
        {{ $t(status) }}
      </ReCruiseButton>
    </div>
    <div v-else class="news-white-space"></div>

    <ul class="event-list">
      <li class="event-item" v-for="event in eventData?.events" key="event.id" @click="goEventDetail(event.id)">
        <div class="event-thumbnail">
          <img class="event-image" :src="event[getLocalKey('coverImageUrl')]" alt="event thumbnail" />
          <div class="image-dimmed" v-if="activeStatusTab === 1"></div>
        </div>
        <div class="event-content">
          <div v-if="!activeCategoryTab" class="category event" :class="eventCategoryClass(activeStatusTab)">
            <span class="category-text event" :class="eventCategoryClass(activeStatusTab)">
              {{ eventStatusText(activeStatusTab) }}
            </span>
          </div>
          <div class="event-title">{{ event[getLocalKey('name')] }}</div>
          <div class="event-date">
            {{ periodText(event) }}
          </div>
        </div>
      </li>
    </ul>

    <CruisePagination :page="categoryTabList[activeCategoryTab].page" :pages="totalPage" @update:page="handlePage" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getLocalKey, formatDateRange } from '@/utils/convert';

import EventService from '@/services/event';

import BreadcrumbNavigation from '@/components/common/BreadcrumbNavigation';
import ReCruiseTabs from '@/components/renewal/tab/ReCruiseTabs.vue';
import ReCruiseTab from '@/components/renewal/tab/ReCruiseTab.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import CruisePagination from '@/components/common/CruisePagination.vue';

export default {
  name: 'EventList',
  components: { BreadcrumbNavigation, ReCruiseTabs, ReCruiseTab, ReCruiseButton, CruisePagination },
  data() {
    return {
      activeCategoryTab: 0,
      activeStatusTab: 0,

      eventList: [],

      categoryTabList: [
        { categoryKo: '혜택', categoryJa: '特典', categoryEn: 'benefit', page: 1 },
        { categoryKo: '소식', categoryJa: 'ニュース', categoryEn: 'news', page: 1 },
      ],

      statusTabList: [
        { isOn: true, statusKo: '진행중', statusJa: '進行中', statusEn: 'on', page: 1 },
        { isOn: false, statusKo: '종료', statusJa: '終了', statusEn: 'off', page: 1 },
      ],
    };
  },
  watch: {
    activeCategoryTab() {
      this.activeStatusTab = 0;
    },
  },
  computed: {
    categoryTabLabel() {
      return ['event.benefit', 'event.news'];
    },
    statusTabLabel() {
      return ['event.inProgress', 'event.close'];
    },
    eventData() {
      if (this.eventList[this.activeCategoryTab]) {
        // const eventTotalList = this.eventList[this.activeCategoryTab][this.activeStatusTab] || {};

        // // TODO: id가 143인 신한카드이벤트 노출을 막기위함: 2024.10.31 이후 삭제 처리
        // if (eventTotalList && Array.isArray(eventTotalList.events)) {
        //   eventTotalList.events = eventTotalList.events.filter(event => event.id !== 143);
        // }

        // return eventTotalList;
        return this.eventList[this.activeCategoryTab][this.activeStatusTab] || {};
      }
    },
    totalPage() {
      return this.eventData?.pages || 1;
    },
  },
  methods: {
    getLocalKey,

    async getEventList() {
      try {
        const categoryTab = this.categoryTabList[this.activeCategoryTab];
        const statusTab = this.statusTabList[this.activeStatusTab];
        const isOpenedLocaleKey = getLocalKey('isOpened');

        const params = {
          categoryEn: categoryTab.categoryEn,
          statusEn: this.activeCategoryTab === 0 ? statusTab.statusEn : null,

          // category: { code: categoryTab.categoryEn },
          // status: this.activeCategoryTab === 0 ? { code: statusTab.statusEn } : null,

          page: this.activeCategoryTab === 0 ? statusTab.page : categoryTab.page || 1,
          limit: 10,
          [isOpenedLocaleKey]: true,
        };
        const { data } = await EventService.getEventList(params);

        this.eventList[this.activeCategoryTab][this.activeStatusTab] = data;
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    async getTotalEventList() {
      try {
        const isOpenedLocaleKey = getLocalKey('isOpened');

        const promises = this.categoryTabList
          .map((categoryTab, index) => {
            return this.statusTabList.map(statusTab => {
              const params = {
                categoryEn: categoryTab.categoryEn,
                statusEn: index === 0 ? statusTab.statusEn : null,

                // category: { code: categoryTab.categoryEn },
                // status: index === 0 ? { code: statusTab.statusEn } : null,

                page: this.activeCategoryTab === 0 ? categoryTab.page : statusTab.page || 1,
                limit: 10,
                [isOpenedLocaleKey]: true,
              };
              return EventService.getEventList(params);
            });
          })
          .flat();
        const results = await Promise.all(promises);
        const tempArr = results.map(res => res.data);

        tempArr.splice(-1, 1);

        for (let i = 0; i < tempArr.length; i += 2) {
          [tempArr[i], tempArr[i + 1]].forEach(item => {
            if (item && item.events) {
              item.events.sort((a, b) => {
                return dayjs(b.startDate) - dayjs(a.startDate);
              });
            }
          });

          this.eventList.push([tempArr[i], tempArr[i + 1]]);
        }
      } catch (error) {
        alert(error);
        console.error(error);
      }
    },
    eventCategoryClass(tabIndex) {
      if (tabIndex === 0) {
        return 'on';
      } else {
        return 'off';
      }
    },
    eventStatusText(tabIndex) {
      return tabIndex === 0 ? this.$t('event.inProgress') : this.$t('event.close');
    },
    clickStatusTab(index) {
      this.activeStatusTab = index;
    },
    periodText(event) {
      return event.isUnlimited ? this.$t('labels.alwaysOn') : formatDateRange(event.startDate, event.endDate);
    },
    handlePage(page) {
      if (this.activeCategoryTab === 0) {
        this.statusTabList[this.activeStatusTab].page = page;
      } else {
        this.categoryTabList[this.activeCategoryTab].page = page;
      }
      this.getEventList();
    },
    goEventDetail(id) {
      if (id !== 143) {
        this.$router.push(`/event/${id}`);
      } else {
        // 신한카드이벤트 예외처리를 위한 코드
        const link = 'https://www.shinhancard.com/pconts/html/benefit/event/1228910_2239.html';
        window.open(link);
      }
    },
  },
  created() {
    this.getTotalEventList();
  },
  mounted() {},
};
</script>

<style scoped>
.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  word-break: keep-all;
}

.page-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -1.5px;
}

.page-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

:deep(.tabs-wrapper .tab-nav .tab) {
  padding-top: 4px;
}

.sub-tab-wrapper {
  display: flex;
  padding: 24px 0;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.news-white-space {
  height: 30px;
}

.tab-menu {
  width: 100px;
  height: 41px;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  border-radius: 100px;
}
.event-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  gap: 40px 16px;
}

.event-item {
  width: calc(50% - 8px);
  min-width: 156px;
  height: 100%;
  background: var(--White);
  cursor: pointer;
  overflow: hidden;
}

.event-item:hover .event-image {
  scale: 1.2;
}

.event-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 153px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 16px;
  border: 1px solid var(--Grey-grey-200);
}

.event-image {
  transition: scale 0.3s ease;
  object-fit: cover;
  min-height: 153px;
}

.image-dimmed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: var(--White);
}

.event-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event-title {
  color: var(--Grey-grey-900);
  font-size: 16px;
  font-weight: 800;
  height: 39px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.event-date {
  color: var(--Grey-grey-600);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
}

.category {
  width: fit-content;
  height: 19px;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.category.event {
  height: 22px;
  padding: 4px 10px;
}

.category.event.on {
  border: 1px solid var(--Blue-blue-500);
}

.category.event.off {
  border: 1px solid var(--Grey-grey-700);
}

.category-text {
  width: fit-content;
  height: 13px;
  font-size: 11px;
  font-weight: 500;
}

.category-text.event {
  height: 14px;
  font-size: 12px;
}

.category-text.on {
  color: var(--Blue-blue-500);
}

.category-text.off {
  color: var(--Grey-grey-700);
}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 34px;
  }

  .page-desc {
    font-size: 20px;
  }

  .page-desc br {
    display: none;
  }

  .sub-tab-wrapper {
    padding: 60px 0;
  }

  .news-white-space {
    height: 54px;
  }

  .tab-menu {
    width: auto;
    min-width: 112px;
    padding: 0 20px;
    height: 56px;
    font-size: 20px;
  }

  .event-list {
    gap: 60px 16px;
    overflow: visible;
    margin-bottom: 150px;
  }

  .event-item {
    width: calc(25% - 12px);
    min-width: 326px;
    height: 100%;
    border-radius: 20px;
    border: 1px solid var(--Grey-grey-200);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }

  .event-item:hover {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }

  .event-thumbnail {
    width: 100%;
    height: 100%;
    min-height: 319px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--Grey-grey-200);
    margin-bottom: 0;
  }

  .event-image {
    min-height: 319px;
  }

  .event-content {
    padding: 24px;
  }

  .event-title {
    font-size: 23.678px;
    margin-bottom: 8px;
    height: 57px;
  }

  .event-date {
    color: var(--Grey-grey-600);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  .category {
    height: 28px;
    padding: 5px 15px;
  }

  .category.event {
    height: 28px;
    padding: 5px 15px;
  }

  .category-text.event {
    height: 18px;
    font-size: 14.799px;
  }
}
</style>
