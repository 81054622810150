<template>
  <CruiseInputField :label="$t('profile.email')" required>
    <div class="email-address-wrapper">
      <div class="email-wrapper input-width">
        <div class="email-field">
          <CruiseInput
            type="emailAddress"
            class="email-left"
            :placeholder="$t('profile.inputEmail')"
            v-model="emailId"
            :disabled="isVerified"
          />
          <span>@</span>
          <CruiseInput
            type="emailDomain"
            class="email-right"
            :placeholder="$t('profile.inputSelf')"
            v-model="emailDomain"
            :readonly="!!emailTemplate"
            :disabled="isVerified"
          />
        </div>

        <CruiseSelect
          class="email-selector"
          v-model="emailTemplate"
          :options="emailList"
          @onChange="setEmailDomain"
          :disabled="isVerified"
        >
          <template #option="{ item }">
            {{ item === '' ? $t('profile.inputSelf') : item }}
          </template>
        </CruiseSelect>
      </div>
    </div>

    <div v-show="!emailId || isChangedEmail" class="auth-number-wrap">
      <div class="auth-number-inner">
        <div class="auth-number-box input-width">
          <CruiseInput
            :placeholder="inputAuthPlaceholder"
            v-model.number="authCodeInput"
            :disabled="!authCode || isVerified"
          />
          <span v-if="timer" class="timer">{{ formattedTime }}</span>
        </div>

        <CruiseButton
          v-show="!emailId || isChangedEmail"
          class="auth-request-btn"
          :theme="authButtonTheme"
          @keydown.enter.prevent
          @click="requestAuth"
        >
          {{ authButtonText }}
        </CruiseButton>

        <CruiseButton
          class="auth-request-btn"
          :theme="authConfirmButtonTheme"
          @keydown.enter.prevent
          @click="authConfirm"
          :disabled="isVerified"
        >
          {{ $t('auth.authConfirm') }}
        </CruiseButton>
      </div>

      <div class="result-wrapper">
        <p v-if="authResultMessage" class="auth-result-msg" :class="authSuccessClass">{{ authResultMessage }}</p>
      </div>
    </div>
  </CruiseInputField>
</template>

<script>
import { validators } from '@/utils/validator';
import UserService from '@/services/user';

import CruiseButton from '@/components/button/CruiseButton.vue';
import CruiseInputField from '@/components/common/CruiseInputField.vue';
import CruiseInput from '@/components/common/CruiseInput.vue';
import CruiseSelect from '@/components/common/CruiseSelect.vue';

export default {
  name: 'EmailAuth',
  emits: ['update:isVerified', 'update:userEmail', 'update:userEmailDomain'],
  components: { CruiseButton, CruiseInputField, CruiseInput, CruiseSelect },
  props: {
    isVerified: Boolean,
    userEmail: String,
    userEmailDomain: String,
    userName: String,
    userId: String,
  },
  data() {
    return {
      originEmailId: this.userEmail,
      originEmailDomain: this.userEmailDomain,
      emailId: this.userEmail || '',
      emailDomain: this.userEmailDomain || '',
      emailTemplate: '',
      emailList: ['', 'naver.com', 'gmail.com', 'daum.net', 'hotmail.com'],

      authCodeInput: null,

      authCode: null,
      authButtonText: this.$t('auth.authRequest'),
      authResultMessage: '',
      timer: null,
      timeLeft: 300,
      message: '',

      isChangedEmail: false,
    };
  },
  watch: {
    emailId(value) {
      this.isChangedEmail = value !== this.originEmailId;
      this.$emit('update:userEmail', value);
    },
    emailDomain(value) {
      this.isChangedEmail = value !== this.originEmailDomain;
      this.$emit('update:userEmailDomain', value);
    },
  },
  computed: {
    searchType() {
      return this.$route.params.type || '';
    },

    isSuccessAuth() {
      return !!this.authCode || this.isVerified;
    },
    authButtonTheme() {
      return this.authCode ? 'q' : 'p';
    },
    inputAuthPlaceholder() {
      return this.authCode ? this.message : this.$t('auth.inputAuthCode');
    },
    formattedTime() {
      let minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    authConfirmButtonTheme() {
      return this.timer ? 'p' : 's';
    },
    authSuccessClass() {
      return this.isVerified ? 'success' : '';
    },
  },
  methods: {
    setEmailDomain(domain) {
      this.emailDomain = domain;
    },
    async getAuthCode() {
      try {
        let response;

        if (this.searchType) {
          let params = {
            target: this.searchType,
            email: this.emailId + '@' + this.emailDomain,
            ...(this.userName && { name: this.userName }),
            ...(this.userId && { userId: this.userId }),
          };

          response = await UserService.checkUserExists(params);
        } else {
          let params = {
            email: this.emailId + '@' + this.emailDomain,
          };

          response = await UserService.checkExistEmail(params);
        }

        return response;
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    async requestAuth() {
      if (!this.emailId || !this.emailDomain) {
        this.$alert({ content: this.$t('messages.enterEmail') });
        return;
      }
      const convertedEmail = this.emailId + '@' + this.emailDomain;
      if (!validators.isValidEmail(convertedEmail)) {
        this.$alert({ content: this.$t('messages.checkEmail') });
        return;
      }

      if (this.authCode) {
        this.resetTimer();
      } else {
        const response = await this.getAuthCode();

        this.authCode = response?.data;
        this.message = response?.message;

        if (this.timer) {
          clearInterval(this.timer);
          this.timeLeft = 300;
        } else {
          this.authButtonText = this.$t('auth.reRequest');
        }

        this.timer = setInterval(() => {
          if (this.timeLeft > 0) {
            this.timeLeft -= 1;
          } else {
            this.resetTimer();
          }
        }, 1000);
      }
    },
    async authConfirm() {
      if (!this.authCode) {
        return;
      }

      if (!this.authCodeInput) {
        this.$alert({ content: this.$t('messages.enterAuthCode') });
        return;
      }

      try {
        const params = {
          encryptedNumber: this.authCode,
          number: this.authCodeInput,
        };

        const { data } = await UserService.validate(params);

        if (data) {
          this.authResultMessage = this.$t('messages.authCodeConfirmed');
          this.$emit('update:isVerified', true);
          this.$emit('update:userEmail', this.emailId);
          this.$emit('update:userEmailDomain', this.emailDomain);
          this.timer = null;
        } else {
          this.authResultMessage = this.$t('messages.authCodeMismatch');
          this.$emit('update:isVerified', false);
        }
      } catch (error) {
        this.$alert({ content: error });
      }
    },
    resetTimer() {
      clearInterval(this.timer);
      this.timer = null;
      this.timeLeft = 300;
      this.authButtonText = this.$t('auth.authRequest');
      this.authCode = null;
      this.authCodeInput = null;
      this.authResultMessage = '';
      this.$emit('update:isVerified', false);
    },
  },
};
</script>

<style scoped>
.email-address-wrapper {
  display: flex;
  gap: 10px;
  flex: 1 0;
  width: 100%;
}

.email-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0;
}

.email-field {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  width: 100%;
}

.auth-number-wrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.auth-number-inner {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.auth-number-box {
  position: relative;
  width: 100%;
}

.timer {
  position: absolute;
  top: 13px;
  right: 16px;
}

.auth-request-btn {
  width: 120px;
  min-width: 72.5px;
}

.auth-btn-wrapper {
  width: 100%;
}

.result-wrapper {
  display: flex;
  justify-content: space-between;
}

.auth-result-msg {
  font-size: 12px;
  color: var(--Red);
}

.auth-result-msg.success {
  color: var(--Main);
}

.email-left,
.email-right {
  flex: 1 0;
}

.email-selector {
  width: 100%;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .input-width {
    max-width: 366px;
  }

  .email-address-wrapper,
  .auth-number-wrap {
    width: 100%;
  }

  .email-address-wrapper,
  .auth-number-inner {
    flex-direction: row;
    gap: 10px;
  }

  .email-wrapper {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .email-field {
    width: calc(75% - 8px);
  }

  .email-selector {
    width: 25%;
    max-width: 118.5px;
    margin: 0;
  }

  :deep(.input-width) {
    max-width: 100% !important;
  }

  .auth-request-btn {
    width: 118.5px;
    min-width: 118.5px;
  }
}
</style>
