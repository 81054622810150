<template>
  <div class="login-wrapper container-default">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.login-wrapper {
  width: 100%;
}
</style>
