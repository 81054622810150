<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('facilityGuide.facilityLocation') }}</div>
      <div class="txt_det">{{ $t('facilityGuide.dreamFacilityInfo.intro') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <!-- Swiper -->
      <swiper
        class="tabs_slider"
        :modules="modules"
        :slidesPerView="'auto'"
        :space-between="12"
        navigation
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(tab, index) in tabs" :key="index">
          <a href="javascript:" :class="{ active: currentTab === index }" @click="currentTab = index">{{ $t(tab) }}</a>
        </swiper-slide>
      </swiper>
      <!-- //Swiper -->
      <div v-show="currentTab === 0">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">CAFE YUME</div>
              <div class="room_space">
                {{ $t('facilityGuide.dreamFacilityInfo.cafeYume.desc') }}
              </div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.cafeYume.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 3F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility1" :key="index" :class="{ active: dreamFacility1 === index }">
                <a href="javascript:" @click="dreamFacility1 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility1 === 0">
              <img src="@/assets/images/information/facility/dream_cafe_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility1 === 1">
              <img src="@/assets/images/information/facility/dream_cafe_facility02.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility1 === 2">
              <img src="@/assets/images/information/facility/dream_cafe_facility03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_cafe_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
      <div v-show="currentTab === 1">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">RESTAURANT</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.restaurant.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.restaurant.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility2" :key="index" :class="{ active: dreamFacility2 === index }">
                <a href="javascript:" @click="dreamFacility2 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility2 === 0">
              <img src="@/assets/images/information/facility/dream_restaurant_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility2 === 1">
              <img src="@/assets/images/information/facility/dream_restaurant_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_restaurant_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 2">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">THERAPY HOUSE</div>
              <div class="room_space">
                {{ $t('facilityGuide.dreamFacilityInfo.therapyHouse.desc') }}
              </div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility3" :key="index" :class="{ active: dreamFacility3 === index }">
                <a href="javascript:" @click="dreamFacility3 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility3 === 0">
              <img src="@/assets/images/information/facility/dream_house_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility3 === 1">
              <img src="@/assets/images/information/facility/dream_house_facility02.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility3 === 2">
              <img src="@/assets/images/information/facility/dream_house_facility03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_house_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 3">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">SAUNA</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.sauna.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.sauna.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility4" :key="index" :class="{ active: dreamFacility4 === index }">
                <a href="javascript:" @click="dreamFacility4 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility4 === 0">
              <img src="@/assets/images/information/facility/dream_sauna_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility4 === 1">
              <img src="@/assets/images/information/facility/dream_sauna_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_sauna_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 4">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">CONVENIENCE STORE</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.convenienceStore.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.convenienceStore.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility5" :key="index" :class="{ active: dreamFacility5 === index }">
                <a href="javascript:" @click="dreamFacility5 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility5 === 0">
              <img src="@/assets/images/information/facility/dream_store_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility5 === 1">
              <img src="@/assets/images/information/facility/dream_store_facility02.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility5 === 2">
              <img src="@/assets/images/information/facility/dream_store_facility03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_store_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 5">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">GIFT SHOP</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.giftShop.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.giftShop.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility6" :key="index" :class="{ active: dreamFacility6 === index }">
                <a href="javascript:" @click="dreamFacility6 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility6 === 0">
              <img src="@/assets/images/information/facility/dream_gift_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility6 === 1">
              <img src="@/assets/images/information/facility/dream_gift_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_gift_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 6">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">KARAOKE</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.karaoke.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.karaoke.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility7" :key="index" :class="{ active: dreamFacility7 === index }">
                <a href="javascript:" @click="dreamFacility7 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility7 === 0">
              <img src="@/assets/images/information/facility/dream_karaoke_facility01.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_karaoke_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 7">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">SNACK BAR</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.snackBar.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.snackBar.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility8" :key="index" :class="{ active: dreamFacility8 === index }">
                <a href="javascript:" @click="dreamFacility8 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility8 === 0">
              <img src="@/assets/images/information/facility/dream_bar_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility8 === 1">
              <img src="@/assets/images/information/facility/dream_bar_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_bar_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 8">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">THE PARADISE</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.paradise.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.paradise.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 2F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility9" :key="index" :class="{ active: dreamFacility9 === index }">
                <a href="javascript:" @click="dreamFacility9 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility9 === 0">
              <img src="@/assets/images/information/facility/dream_paradise_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility9 === 1">
              <img src="@/assets/images/information/facility/dream_paradise_facility02.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility9 === 2">
              <img src="@/assets/images/information/facility/dream_paradise_facility03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_paradise_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 9">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">DUTY FREE SHOP</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.dutyFreeShop.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.dutyFreeShop.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility10" :key="index" :class="{ active: dreamFacility10 === index }">
                <a href="javascript:" @click="dreamFacility10 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility10 === 0">
              <img src="@/assets/images/information/facility/dream_shop_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility10 === 1">
              <img src="@/assets/images/information/facility/dream_shop_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_shop_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 10">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">SUSHI BAR</div>
              <div class="room_space">
                {{ $t('facilityGuide.dreamFacilityInfo.sushiBar.desc') }}
              </div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.sushiBar.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility11" :key="index" :class="{ active: dreamFacility11 === index }">
                <a href="javascript:" @click="dreamFacility11 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility11 === 0">
              <img src="@/assets/images/information/facility/dream_sushi_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility11 === 1">
              <img src="@/assets/images/information/facility/dream_sushi_facility02.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility11 === 2">
              <img src="@/assets/images/information/facility/dream_sushi_facility03.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_sushi_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>

      <div v-show="currentTab === 11">
        <!-- View Room -->
        <div class="view_room">
          <!-- info Room -->
          <div class="info_room">
            <div class="info_room_content">
              <div class="name_room">CONFERENCE ROOM</div>
              <div class="room_space">{{ $t('facilityGuide.dreamFacilityInfo.conferenceRoom.desc') }}</div>
              <ul class="list_info">
                <li>{{ $t('facilityGuide.dreamFacilityInfo.conferenceRoom.detail') }}</li>
                <li>{{ $t('facilityGuide.location') }} : 1F A-DECK</li>
              </ul>
            </div>

            <!-- Tab menu -->
            <ul class="tab_menu">
              <li v-for="(tab, index) in facility12" :key="index" :class="{ active: dreamFacility12 === index }">
                <a href="javascript:" @click="dreamFacility12 = index">
                  <img :src="tab.imageSrc" />
                </a>
              </li>
            </ul>
            <!-- //Tab menu -->
          </div>
          <!-- //info Room -->

          <div class="tab_content">
            <div class="tab_content" v-show="dreamFacility12 === 0">
              <img src="@/assets/images/information/facility/dream_room_facility01.png" />
            </div>
            <div class="tab_content" v-show="dreamFacility12 === 1">
              <img src="@/assets/images/information/facility/dream_room_facility02.png" />
            </div>
          </div>
        </div>
        <!-- //View Room -->
        <!-- Location -->
        <div class="view_location">
          <div class="label_location">{{ $t('facilityGuide.location') }}</div>
          <div class="local_image">
            <img src="@/assets/images/information/facility/dream_room_location.png" />
          </div>
        </div>
        <!-- //Location -->
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/navigation';
import 'swiper/css';

export default {
  name: 'TabContainer',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      currentTab: 0,
      tabs: [
        'facilityGuide.dreamFacilityTabs.cafeYume',
        'facilityGuide.dreamFacilityTabs.restaurant',
        'facilityGuide.dreamFacilityTabs.therapyHouse',
        'facilityGuide.dreamFacilityTabs.sauna',
        'facilityGuide.dreamFacilityTabs.convenienceStore',
        'facilityGuide.dreamFacilityTabs.giftShop',
        'facilityGuide.dreamFacilityTabs.karaoke',
        'facilityGuide.dreamFacilityTabs.snackBar',
        'facilityGuide.dreamFacilityTabs.paradise',
        'facilityGuide.dreamFacilityTabs.dutyFreeShop',
        'facilityGuide.dreamFacilityTabs.sushiBar',
        'facilityGuide.dreamFacilityTabs.conferenceRoom',
      ],
      dreamFacility1: 0,
      facility1: [
        { imageSrc: require('@/assets/images/information/facility/dream_cafe_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_cafe_facility02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_cafe_facility03.png') },
        // Add more tab data as needed
      ],
      dreamFacility2: 0,
      facility2: [
        { imageSrc: require('@/assets/images/information/facility/dream_restaurant_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_restaurant_facility02.png') },
        // Add more tab data as needed
      ],
      dreamFacility3: 0,
      facility3: [
        { imageSrc: require('@/assets/images/information/facility/dream_house_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_house_facility02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_house_facility03.png') },
        // Add more tab data as needed
      ],
      dreamFacility4: 0,
      facility4: [
        { imageSrc: require('@/assets/images/information/facility/dream_sauna_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_sauna_facility02.png') },
        // Add more tab data as needed
      ],
      dreamFacility5: 0,
      facility5: [
        { imageSrc: require('@/assets/images/information/facility/dream_store_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_store_facility02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_store_facility03.png') },
        // Add more tab data as needed
      ],
      dreamFacility6: 0,
      facility6: [
        { imageSrc: require('@/assets/images/information/facility/dream_gift_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_gift_facility02.png') },
        // Add more tab data as needed
      ],
      dreamFacility7: 0,
      facility7: [
        { imageSrc: require('@/assets/images/information/facility/dream_karaoke_facility01.png') },
        // Add more tab data as needed
      ],
      dreamFacility8: 0,
      facility8: [
        { imageSrc: require('@/assets/images/information/facility/dream_bar_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_bar_facility02.png') },
        // Add more tab data as needed
      ],
      dreamFacility9: 0,
      facility9: [
        { imageSrc: require('@/assets/images/information/facility/dream_paradise_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_paradise_facility02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_paradise_facility03.png') },
        // Add more tab data as needed
      ],
      dreamFacility10: 0,
      facility10: [
        { imageSrc: require('@/assets/images/information/facility/dream_shop_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_shop_facility02.png') },
        // Add more tab data as needed
      ],
      dreamFacility11: 0,
      facility11: [
        { imageSrc: require('@/assets/images/information/facility/dream_sushi_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_sushi_facility02.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_sushi_facility03.png') },
        // Add more tab data as needed
      ],
      dreamFacility12: 0,
      facility12: [
        { imageSrc: require('@/assets/images/information/facility/dream_room_facility01.png') },
        { imageSrc: require('@/assets/images/information/facility/dream_room_facility02.png') },
        // Add more tab data as needed
      ],
    };
  },
  watch: {
    currentTab() {
      for (let i = 1; i <= this.tabs.length; i++) {
        const currentIndex = `dreamFacility${i}`;
        this[currentIndex] = 0;
      }
    },
  },
  methods: {
    showTabContent(imageSrc) {
      this.selectedTabImageSrc = imageSrc;
    },
  },
  setup() {
    const onSwiper = () => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.tabs_slider {
  padding: 6px 2px;
  margin: 0 -2px;
}
.tabs_slider .swiper-slide {
  width: auto !important;
  margin-right: 12px;
}
.tabs_slider .swiper-slide a {
  display: inline-block;
  padding: 0 28px;
  border-radius: 100px;
  border: 1px solid #446ceb;
  font-weight: 600;
  color: #446ceb;
  font-size: 20px;
  line-height: 54px;
  background-color: #fff;
}
.tabs_slider .swiper-slide a.active {
  background-color: #446ceb;
  color: #fff;
}
.tabs_slider :deep(.swiper-button-prev),
.tabs_slider :deep(.swiper-button-next) {
  width: 62px;
  height: 62px;
  top: 4px;
  margin: 0;
  background-color: #fff;
}
.tabs_slider :deep(.swiper-button-prev) {
  left: 0;
}
.tabs_slider :deep(.swiper-button-next) {
  right: 0;
}

.tabs_slider :deep(.swiper-button-prev:before),
.tabs_slider :deep(.swiper-button-next:before) {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  content: '';
  flex: none;
}
.tabs_slider :deep(.swiper-button-prev:before) {
  left: 2px;
}
.tabs_slider :deep(.swiper-button-next:before) {
  right: 2px;
}
.tabs_slider :deep(.swiper-button-prev.swiper-button-disabled),
.tabs_slider :deep(.swiper-button-next.swiper-button-disabled) {
  display: none;
}
.tabs_slider :deep(.swiper-wrapper) {
  height: 72px;
}
.tabs_slider :deep(.swiper-button-prev:after),
.tabs_slider :deep(.swiper-button-next:after) {
  color: #b6bbbf;
  font-weight: bold;
  font-size: 20px;
  z-index: 1;
}
.tabs_slider :deep(.swiper-wrapper) {
  height: auto;
  padding: 2px 2px;
  margin: 0 -2px;
}

.view_room {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 0 0;
}
.view_room .info_room {
  width: 420px;
  margin-right: 77px;
  flex: none;
}
.view_room .info_room .info_room_content {
  height: 273px;
}
.view_room .info_room .data_view {
  color: #9299a0;
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 6px;
}
.view_room .info_room .name_room {
  color: #304da7;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  letter-spacing: -1px;
  margin-bottom: 24px;
}
.view_room .info_room .room_space {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #505458;
}
.view_room .info_room .list_info {
  margin: 27px 0 0 0;
}
.view_room .info_room .list_info li {
  color: #686d72;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: flex-start;
}
.view_room .info_room .list_info li:before {
  position: relative;
  top: 2px;
  margin: 13px;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #b6bbbf;
  content: '';
}

.view_room .tab_menu {
  margin: 27px 0 0 0;
  display: inline-flex;
  border-radius: 5px;
  max-width: 100%;
  overflow: auto;
  scrollbar-width: none;
}
.view_room .tab_menu li {
  flex: auto;
  height: 90px;
  opacity: 0.6;
  width: 100%;
  max-width: 140px;
  min-width: 140px;
}
.view_room .tab_menu li.active {
  opacity: 1;
}
.view_room .tab_menu li a img {
  width: 100%;
  height: 100%;
}
.view_room .tab_menu li a {
  display: block;
  width: 100%;
  height: 100%;
}
.view_room .tab_content {
  flex: auto;
  width: 100%;
  height: 390px;
  overflow: hidden;
  border-radius: 16px;
}

.view_room .tab_content img {
  object-fit: cover;
  object-position: 50% 28%;
}

.view_location {
  margin: 60px 0 0 0;
}

.view_location .local_image {
  overflow: auto;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;
}
/* .view_location img {
  width: 1352px;
} */
.view_location .label_location {
  margin-bottom: 24px;
  color: #505458;
  font-weight: 600;
  line-height: 24px;
  font-size: 22px;
}

@media (max-width: 767px) {
  .view_room .tab_content {
    height: 234px;
    border-radius: 5px;
  }
  .view_room .info_room img {
    width: auto;
  }
  .view_room {
    flex-direction: column;
    margin-top: 24px;
  }
  .view_room .info_room {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .view_room .info_room .info_room_content {
    height: auto;
  }
  .view_room .info_room .name_room {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .view_room .info_room .room_space {
    font-size: 18px;
    line-height: 24px;
  }
  .view_room .info_room .list_info {
    margin: 16px 0 0 0;
  }
  .view_room .info_room .list_info li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_room .info_room .list_info li:before {
    margin-top: 6px;
  }
  .view_room .info_room .list_info li + li {
    margin-top: 8px;
  }
  .view_room .tab_menu {
    margin-top: 24px;
  }
  .view_room .tab_menu li {
    height: 63px;
    max-width: 109px;
    min-width: 109px;
  }
  .view_location {
    margin: 20px 0 0 0;
  }
  .view_location .local_image img {
    width: auto;
    height: 190px;
  }
  .view_location .label_location {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
  }
  .tabs_slider :deep(.swiper-button-next),
  .tabs_slider :deep(.swiper-button-prev) {
    display: none !important;
  }

  .tabs_slider .swiper-slide {
    margin-right: 8px;
  }
  .tabs_slider .swiper-slide a {
    font-size: 14px;
    padding: 0 12px;
    line-height: 41px;
  }
  .view_room .info_room .list_info .txt_info {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
