import api from '@/services/api';

const API_URL = '/api/url';

class UrlService {
  async getLongUrl(shortUrl) {
    try {
      const response = await api.get(`${API_URL}/getLongURLFromShortURL`, { params: { shortUrl } });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new UrlService();
