<template>
  <div v-if="show" class="sidebar-wrapper" @click="closeSidebar">
    <aside class="main-sidebar" @click.stop>
      <header class="sidebar-header">
        <div class="header-left">
          <LocaleSelect />

          <button class="close-button" @click="closeSidebar">
            <img class="close-icon" src="@/assets/images/icon/close_icon.svg" alt="icon" />
          </button>
        </div>

        <router-link to="/" class="logo" @click="closeSidebar">
          <img class="logo-img" src="@/assets/images/logo/panstar_lite_logo.svg" alt="panstar cruise logo" />
        </router-link>
      </header>

      <UserNav @update:show="closeSidebar" />

      <div class="menu-container">
        <SidebarMenuItem :menuList="globalNav" @clickMenuItem="closeSidebar" />
      </div>
    </aside>
  </div>
</template>

<script>
import SidebarMenuItem from '@/layouts/SidebarMenuItem.vue';
import UserNav from '@/components/user/UserNav.vue';
import LocaleSelect from '@/layouts/LocaleSelect.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'MainSidebar',
  components: { SidebarMenuItem, UserNav, LocaleSelect },
  emits: ['update:show'],
  props: { show: Boolean },
  computed: {
    ...mapGetters('common', ['globalNav']),
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.applyOverflowHidden(value);
      },
    },
  },
  beforeUnmount() {
    document.body.style.overflow = '';
  },
  methods: {
    closeSidebar() {
      this.$emit('update:show', false);
    },
    applyOverflowHidden(value) {
      if (value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
};
</script>

<style scoped>
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--Backdrop-renewal);
  border-bottom: var(--default--border);
  z-index: var(--zNavMenuPopover);
}

.main-sidebar {
  float: right;
  width: 100%;
  height: 100%;
  background: var(--White);
  border-right: var(--default--border);
  box-shadow: 0 1px 3px rgba(31, 35, 40, 0.12), 0 8px 24px rgba(66, 74, 83, 0.12);
  animation: slideIn 0.25s cubic-bezier(0.33, 1, 0.68, 1) 0s 1 normal none running;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  display: flex;
  height: 60px;
  padding: 0px 16px;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--White);
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
}

.logo {
  display: block;
}

.logo-img {
  width: 38px;
  height: 19px;
}

.header-left {
  display: flex;
  gap: 16px;
}

.close-button {
  padding: 8px;
}

.menu-container {
  flex: 1 0;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menu-container::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 768px) {
  .main-sidebar {
    width: 502px;
  }

  .logo {
    display: none;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
