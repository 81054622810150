<template>
  <div class="nav-menu">
    <div class="prev-menu">
      <div class="menu">
        <div class="menu-text">HOME</div>
      </div>
      <template v-for="(item, idx) in pathInfo.newPath" :key="idx">
        <div class="arrow-icon">
          <img src="@/assets/images/icon/arrow_right-navigation_icon_gray.svg" alt="arrow icon" />
        </div>
        <div v-if="idx + 1 === pathInfo.newPath.length" class="current-menu">
          <template v-if="pathInfo.children.length > 0">
            <CruiseDropDown
              :placeholder="item"
              :options="pathInfo.children"
              valueProperty="name"
              @onChange="changePath"
            >
              <template #option="{ item }">
                {{ item?.name }}
              </template>
            </CruiseDropDown>
          </template>
          <template v-else>
            {{ item }}
          </template>
        </div>
        <div v-else class="menu">
          <div class="menu-text">{{ item }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CruiseDropDown from '@/components/common/CruiseDropDown.vue';

export default {
  name: 'BreadcrumbNavigation',
  components: { CruiseDropDown },
  data() {
    return {
      selectedPath: null,
    };
  },
  computed: {
    ...mapGetters('common', ['globalNav']),

    path() {
      return this.$route.path;
    },
    pathInfo() {
      return this.findPathAndNames(this.globalNav, this.path);
    },
  },
  methods: {
    findPathAndNames(data, targetPath, currentPath = []) {
      for (const item of data) {
        // Add name at current step to path
        const newPath = [...currentPath, item.name];
        // Check if the current item's path matches the targetPath
        if (item.path === targetPath || item.stopped) {
          return {
            newPath,
            children: data || [],
          }; // return the array of names up to current step
        }

        // If the current item has children, recursively search
        if (item.children) {
          const result = this.findPathAndNames(item.children, targetPath, newPath);
          if (result) return result; // return if a match was found
        }
      }
      return null;
    },
    changePath(_, { path }) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.nav-menu {
  display: none;
}

.prev-menu {
  width: auto;
  display: flex;
  align-items: center;
  gap: 16px;
}

.menu {
  color: var(--Grey-grey-300);
  font-size: 16px;
  font-weight: 500;
}

.menu-text {
  padding-top: 3px;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.current-menu {
  color: var(--Blue-blue-900);
  font-size: 16px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .nav-menu {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
</style>
