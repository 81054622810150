<template>
  <ModalOnlyMobile :showModal="showModal" @update:showModal="updateShowModal" :title="modalTitle" class="modal-wrapper">
    <TourProductDetail :tour="tour" />
  </ModalOnlyMobile>
</template>

<script>
import ModalOnlyMobile from '@/components/renewal/modal/ModalOnlyMobile.vue';
import TourProductDetail from '@/components/tour/TourProductDetail.vue';

export default {
  name: 'TourMoreInfoModal',
  components: { ModalOnlyMobile, TourProductDetail },
  emits: ['update:showModal'],
  props: {
    showModal: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    modalTitle: String,
    tour: Object,
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: relative;
  padding-top: 60px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  top: 60px;
}

@media screen and (min-width: 768px) {
  :deep(.modal-wrapper) {
    height: auto;
  }

  .content-wrapper {
    top: 0;
  }
}
</style>
