<template>
  <button class="text-btn" :class="theme">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'TextButton',
  props: {
    theme: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style scoped>
.text-btn {
  color: var(--Blue-blue-500);
  font-size: 14px;
  font-weight: 400;
}

.text-btn:hover,
.text-btn:active {
  color: var(--Blue-blue-700);
}

.text-btn.danger {
  color: var(--Orange-orange-500);
}

.text-btn.danger:hover,
.text-btn.danger:active {
  font-weight: 600;
  text-decoration: underline;
}

.text-btn.grey {
  color: var(--Grey-grey-400);
}
</style>
