<template>
  <div class="main-wrapper">
    <swiper class="main-swiper-wrapper" @swiper="initSwiper" v-bind="swiperOptions">
      <swiper-slide class="main-slide-wrapper" v-for="(banner, idx) in bannerList" @click="goToPage(banner)">
        <div class="main-slide-inner">
          <div class="main-slide-img-wrapper">
            <img class="main-slide-img" :src="mainSlideImageSrc(banner)" alt="main banner" />
          </div>

          <div class="main-slider-text-wrapper container-default-renewal">
            <div class="main-slider-text">
              <div class="main-slider-title">{{ banner.title }}</div>
              <div class="main-slider-desc">{{ banner.desc }}</div>
            </div>
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-controls-wrapper container-default-renewal">
        <div class="swiper-controls">
          <!-- Progress Bar -->
          <div class="progress-container">
            <div class="progress-bar" :style="progressBarStyle"></div>
          </div>

          <!-- Pagination -->
          <div class="main-swiper-pagination"></div>

          <!-- Navigation -->
          <div class="navigation-container">
            <div class="main-swiper-button-prev"></div>
            <div class="main-swiper-button-next"></div>
          </div>
        </div>
      </div>
    </swiper>

    <slot></slot>
  </div>
</template>

<script>
import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { getLocalText } from '@/utils/convert';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
  name: 'MainSlider',
  props: {
    bannerList: {
      type: Array,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        modules: [Pagination, Navigation, Autoplay],
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        pagination: {
          type: 'fraction',
          clickable: true,
          el: '.main-swiper-pagination',
        },
        navigation: {
          nextEl: '.main-swiper-button-next',
          prevEl: '.main-swiper-button-prev',
        },
        onSlideChange: this.resetProgressBar,
      },

      progressBarWidth: 0,
      interval: null,
    };
  },
  computed: {
    progressBarStyle() {
      return {
        width: `${this.progressBarWidth}%`,
      };
    },
  },
  methods: {
    getLocalText,

    initSwiper(swiper) {
      this.resetProgressBar();
      swiper.on('slideChange', () => {
        this.resetProgressBar();
      });
    },
    resetProgressBar() {
      clearInterval(this.interval);
      this.progressBarWidth = 0;
      this.interval = setInterval(() => {
        if (this.progressBarWidth < 100) {
          this.progressBarWidth += 100 / 70;
        } else {
          clearInterval(this.interval);
          this.progressBarWidth = 100;
        }
      }, 100);
    },
    goToPage(banner) {
      if (banner.path) {
        this.$router.push(banner.path);
      } else if (banner.link) { // TODO: 신한카드이벤트 예외처리를 위한 코드로서 24.10.31 이후 삭제 처리
        window.open(banner.link);
      }
    },
    mainSlideImageSrc(banner) {
      const imgUrl = getLocalText(banner, 'imgUrl');
      return require(`@/assets/images/home/mainSlide/${imgUrl}`);
    },
  },
  created() {
    this.swiperOptions.onSlideChange = this.resetProgressBar.bind(this);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.main-wrapper {
  position: relative;
  height: 100%;
}

.main-swiper-wrapper {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.main-slide-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.main-slide-img-wrapper {
  width: 100%;
  height: 100%;
}

.main-slider-text-wrapper {
  width: 100%;
  color: var(--White);
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 23.21%;
}

.main-slider-text {
  white-space: pre;
}

.main-slider-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -2px;
  margin-bottom: 16px;
}

.main-slider-desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  width: 233px;
  word-break: keep-all;
}

@media screen and (min-width: 768px) {
  .main-wrapper {
    position: relative;
    height: calc(100vh - 76px);
    z-index: 2;
  }

  .main-swiper-wrapper {
    display: block;
    height: 100%;
  }

  .main-slider-text-wrapper {
    width: 100%;
    top: 29.26%;
  }

  .main-slider-text.slide01 {
    width: 513px;
  }

  .main-slider-text.slide02 {
    width: 710px;
  }

  .main-slider-title {
    font-size: 60px;
    margin-bottom: 32px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -2px;
  }

  .main-slider-desc {
    font-size: 24px;
    width: 100%;
  }

  /* Main Slider Pagination/Navigation */
  .swiper-controls-wrapper {
    position: absolute;
    top: 56.91%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    transition: top 0.3s ease;
    z-index: 2;
  }

  .swiper-controls {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 8px;
  }

  /* Main Slider Progress Bar */
  .progress-container {
    width: 144px;
    height: 5px;
    background: rgba(255, 255, 255, 0.3);
  }

  .progress-bar {
    height: 100%;
    width: 144px;
    height: 5px;
    background-color: white;
    transition: width 0.1s linear;
  }

  /* Main Slider Pagination */
  .main-swiper-pagination {
    color: var(--White);
    width: auto;
    height: 100%;
    line-height: 24px;
    left: auto;
    right: 80px;
    top: 0;
  }

  /* Main Slider Navigation */
  .navigation-container {
    display: flex;
    gap: 8px;
    width: 56px;
    height: 24px;
  }

  .main-swiper-button-prev::after,
  .main-swiper-button-next::after {
    content: none !important;
  }

  .main-swiper-button-prev,
  .main-swiper-button-next {
    width: 24px;
    height: 100%;
    border-radius: 6px;
    border: 0.6px solid rgba(255, 255, 255, 0.63);
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(3px);
    background-repeat: no-repeat;
    background-position: 40% center;
    top: auto;
    bottom: 0;
    cursor: pointer;
  }

  .main-swiper-button-prev:hover,
  .main-swiper-button-next:hover {
    border: 1px solid #fff;
  }

  .main-swiper-button-prev {
    left: auto;
    right: 48px;
    background-image: url('@/assets/images/icon/arrow_left_navigation_icon_w.svg');
  }

  .main-swiper-button-next {
    right: 16px;
    transform: rotate(180deg);
    background-image: url('@/assets/images/icon/arrow_left_navigation_icon_w.svg');
  }
}
</style>
