<template>
  <div class="agreement-container container-default-renewal">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AgreementView',
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.agreement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
}

@media screen and (min-width: 768px) {
  .agreement-container {
    gap: 60px;
    padding-top: 60px;
    padding-bottom: 100px;
  }
}
</style>
