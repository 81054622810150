<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('menu.operationInfo') }}</div>
      <div class="txt_det">{{ $t('sailingInfo.osakaTitle') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('sailingInfo.sailingRoute') }}</div>
            <div class="img_map">
              <img :src="imgSrc" alt="Route" />
            </div>

            <div class="wrap_table layout_web">
              <table class="detail_table">
                <colgroup>
                  <col width="140px" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('sailingInfo.courses.label') }}</th>
                    <th>{{ $t('sailingInfo.courses.tsushima') }}</th>
                    <th>{{ $t('sailingInfo.courses.kanmonBridge') }}</th>
                    <th>{{ $t('sailingInfo.courses.setoBridge') }}</th>
                    <th>{{ $t('sailingInfo.courses.akashiBridge') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="bg_th">
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em>{{ $t('sailingInfo.routes.osaka') }}
                    </th>
                    <td>
                      {{ $t('sailingInfo.time', { n: 16, m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 1, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: 21, m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 6, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: '05', m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 14, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: '08', m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 17, m: 30 }) }})
                    </td>
                  </tr>
                  <tr>
                    <th class="bg_th">
                      {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em>{{ $t('sailingInfo.routes.busan') }}
                    </th>
                    <td>
                      {{ $t('sailingInfo.time', { n: '08', m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 17, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: '03', m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 12, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: 19, m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 4, m: 30 }) }})
                    </td>
                    <td>
                      {{ $t('sailingInfo.time', { n: 16, m: 30 }) }}<br />
                      ({{ $t('sailingInfo.sailingDuration', { n: 1, m: 30 }) }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="wrap_table layout_mobile">
              <table class="detail_table">
                <colgroup>
                  <col width="" />
                  <col width="" />
                  <col width="" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('sailingInfo.courses.label') }}</th>
                    <th class="">
                      {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em>{{ $t('sailingInfo.routes.osaka') }}
                    </th>
                    <th class="">
                      {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em>{{ $t('sailingInfo.routes.busan') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th class="bg_th txt_center">{{ $t('sailingInfo.courses.tsushima') }}</th>
                    <td>{{ $t('sailingInfo.time', { n: 16, m: 30 }) }}</td>
                    <td>{{ $t('sailingInfo.time', { n: '08', m: 30 }) }}</td>
                  </tr>

                  <tr>
                    <th class="bg_th txt_center">{{ $t('sailingInfo.courses.kanmonBridge') }}</th>
                    <td>{{ $t('sailingInfo.time', { n: 21, m: 30 }) }}</td>
                    <td>{{ $t('sailingInfo.time', { n: '03', m: 30 }) }}</td>
                  </tr>
                  <tr>
                    <th class="bg_th txt_center">{{ $t('sailingInfo.courses.setoBridge') }}</th>
                    <td>{{ $t('sailingInfo.time', { n: '05', m: 30 }) }}</td>
                    <td>{{ $t('sailingInfo.time', { n: 19, m: 30 }) }}</td>
                  </tr>
                  <tr>
                    <th class="bg_th txt_center">
                      {{ $t('sailingInfo.courses.akashiBridgeMobile') }}
                    </th>
                    <td>{{ $t('sailingInfo.time', { n: '08', m: 30 }) }}</td>
                    <td>{{ $t('sailingInfo.time', { n: 16, m: 30 }) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>

          <li>
            <div class="label_title">{{ $t('sailingInfo.reservationRequirements') }}</div>
            <!-- Note -->
            <div class="view_note">
              <!-- ui Note -->
              <div class="ui_note">
                <em class="img_note"></em>
                <div class="txt_note">
                  <div class="label_note">{{ $t('sailingInfo.requiredTitle') }}</div>
                  {{ $t('sailingInfo.requiredInfo') }}
                </div>
              </div>
              <!-- //ui Note -->
              <!-- ui Note -->
              <div class="ui_note">
                <em class="img_note2"></em>

                <div class="txt_note">
                  <div class="label_note">{{ $t('sailingInfo.optionalTitle') }}</div>
                  {{ $t('sailingInfo.osakaOptionalInfo') }}
                </div>
              </div>
              <!-- //ui Note -->
            </div>
            <!-- //Note -->
            <div class="txt_detail">
              <ul class="list_dot">
                <li>
                  {{ $t('sailingInfo.osakaDisclaimer') }}
                </li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OsakaProduct',
  data() {
    return {};
  },
  computed: {
    imgSrc() {
      const localeKey = this.$i18n.locale !== 'ko' ? `_${this.$i18n.locale}` : '';
      return require(`@/assets/images/information/product/map_osaka_cruise${localeKey}.jpg`);
    },
  },
};
</script>

<style scoped>
.layout_mobile {
  display: none;
}
.layout_web {
  display: block;
}
@media (max-width: 767px) {
  .layout_mobile {
    display: block;
  }
  .layout_web {
    display: none;
  }
}
/********************************************************
  Detail Table
********************************************************/
.wrap_table {
  overflow: auto;
}
.detail_table {
  white-space: nowrap;
  width: 100%;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  white-space: pre;
  background-color: #f5f7f9;
}
.detail_table tbody tr td .txt_sub {
  font-size: 18px;
  color: #686d72;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tr td {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table tr th .arrow_go {
  display: inline-block;
  margin: 0 12px;
  width: 14px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_go.svg') no-repeat;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr td .txt_sub {
    font-size: 14px;
  }
}

.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail li .img_map {
  margin-bottom: 60px;
  font-size: 0;
}
.view_detail .area_detail li .img_map img {
  width: 100%;
  border-radius: 10px;
}
.view_detail .area_detail > li .hr_line {
  border: none;
  border-top: 1px solid #dddfe2;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
@media (max-width: 767px) {
  .view_detail .area_detail li .img_map {
    margin-bottom: 24px;
  }
  .view_detail .area_detail li .img_map img {
    height: 160px;
    object-fit: cover;
    object-position: center;
  }
  .view_detail .area_detail .hr_line {
    margin: -36px 0;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link {
    line-height: 40px;
    font-size: 18px;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link + .txt_link {
    margin-left: 20px;
  }
  .view_detail .area_detail > li + li {
    margin-top: 48px;
  }
}
.view_detail .area_detail .txt_detail .txt_strong {
  color: #505458;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .txt_strong {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 24px;
  }
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 16px;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 24px;
  color: #505458;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  color: #686d72;
  line-height: 28px;
  white-space: pre-wrap;
}
.view_detail .area_detail .txt_detail .list_dot li strong {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }
}

.view_note {
  display: flex;
  margin-bottom: 32px;
}
.view_note .ui_note {
  flex: auto;
  display: flex;
  align-items: center;
}
.view_note .img_note2,
.view_note .img_note {
  display: inline-block;
  flex: none;
  width: 200px;
  height: 200px;
  margin-right: 50px;
  background: url('@/assets/images/information/icon/img_monitor.svg') no-repeat;
}
.view_note .img_note2 {
  background: url('@/assets/images/information/icon/img_monitor2.svg') no-repeat;
}
.view_note .txt_note {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
  white-space: pre-line;
}
.view_note .txt_note .label_note {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #505458;
  line-height: 31px;
}
@media (max-width: 767px) {
  .view_note {
    flex-direction: column;
  }
  .view_note .ui_note + .ui_note {
    margin-top: 16px;
  }
  .view_note .ui_note {
    flex-direction: column;
  }
  .view_note .txt_note {
    text-align: center;
  }
  .view_note .txt_note .label_note {
    font-size: 20px;
  }
  .view_note .txt_note {
    font-size: 16px;
    line-height: 24px;
  }
  .view_note .img_note2,
  .view_note .img_note {
    margin: 0 0 16px 0;
  }
}
</style>
