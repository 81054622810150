const state = {};

const mutations = {};

const actions = {};

const getters = {};

const tour = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default tour;
