<template>
  <label class="custom-radio">
    <span v-if="label" class="radio-label" :class="labelClass">{{ label }}</span>
    <input type="radio" hidden :id="safeId" v-bind="attrs" :value="value" :checked="isChecked" @change="handleChange" />
    <span
      class="checkmark flex-center"
      :class="markClass"
      tabindex="0"
      @keydown.space.prevent="handleSpacePress"
    ></span>
  </label>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';

export default {
  name: 'ReCruiseRadio',
  mixins: [sharedComputedProps],
  emits: ['update:modelValue', 'onChange'],
  props: {
    label: String,
    value: [String, Number, Boolean, Object],
    modelValue: [String, Number],
    position: {
      type: String,
      default: 'right',
      validator: value => ['right', 'top'].includes(value),
    },
  },
  computed: {
    isChecked() {
      return this.$attrs.checked ?? this.value === this.modelValue;
    },
    isPositionRight() {
      return this.position === 'right';
    },
    labelClass() {
      return {
        'label-right': this.isPositionRight,
      };
    },
    markClass() {
      return [{ checked: this.isChecked, disabled: this.$attrs.disabled }];
    },
    flexDirection() {
      return this.isPositionRight ? 'row' : 'column';
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('update:modelValue', e.target.value);
      this.$emit('onChange', e);
    },
    handleSpacePress() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style scoped>
.custom-radio {
  display: inline-flex;
  flex-direction: v-bind(flexDirection);
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.label-right {
  order: 1;
}

.radio-label {
  display: inline-block;
}

.checkmark {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--Disabled, #9299a0);
  background: transparent;
}

.checkmark.checked {
  border: 6px solid var(--Blue-blue-500, #446ceb);
}

.checkmark.disabled {
  cursor: default;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
}

.checkmark.checked.disabled {
  border: 6px solid var(--Blue-blue-100, #c5d1f9);
}
</style>
