<template>
  <ul class="root-menu-wrapper" v-for="(rootMenu, rootMenuIdx) in menuList" :key="rootMenuIdx">
    <li
      role="button"
      class="root-menu-item"
      :class="activeClass(rootMenu)"
      @click="rootMenu.blocked ? toggleMenu(rootMenu) : clickMenuItem(rootMenu)"
    >
      {{ rootMenu.name }}
    </li>

    <div v-if="rootMenu.children" class="arrow-icon" :class="activeClass(rootMenu)">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_1799_15185)">
          <path
            d="M7.41 8.59009L12 13.1701L16.59 8.59009L18 10.0001L12 16.0001L6 10.0001L7.41 8.59009Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1799_15185">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>

    <ul v-if="rootMenu.children" class="parent-menu-wrapper" :class="activeClass(rootMenu)">
      <template v-for="parentMenu in rootMenu.children">
        <li
          class="parent-menu"
          :class="[activeClass(parentMenu), blockedClass(parentMenu)]"
          @click="parentMenu.blocked ? null : clickMenuItem(parentMenu)"
        >
          {{ parentMenu.name }}
        </li>

        <ul v-if="parentMenu.children" class="child-menu-wrapper">
          <li
            v-for="childMenu in parentMenu.children"
            class="child-menu-item"
            :class="activeClass(childMenu)"
            @click="clickMenuItem(childMenu)"
          >
            {{ childMenu.name }}
          </li>
        </ul>
      </template>
    </ul>
  </ul>
</template>

<script>
export default {
  name: 'SidebarMenuItem',
  emits: ['clickMenuItem'],
  props: {
    item: Object,
    menuList: Array,
  },
  data() {
    return {
      activeIndex: -1,
    };
  },
  mounted() {
    this.updateActiveMenu();
  },
  watch: {
    '$route.path': 'updateActiveMenu',
  },
  methods: {
    toggleMenu(menu) {
      menu.isActive = !menu.isActive;
    },
    clickMenuItem(menu) {
      if (menu.blocked) return;

      this.$router.push(menu.path);
      this.$emit('clickMenuItem');
      this.updateActiveMenu();
    },
    updateActiveMenu() {
      const path = this.$route.path;
      this.clearActiveMenus();
      this.activateMenuByPath(this.menuList, path);
    },
    clearActiveMenus(menus = this.menuList) {
      menus.forEach(menu => {
        menu.isActive = false;
        if (menu.children) {
          this.clearActiveMenus(menu.children);
        }
      });
    },
    activateMenuByPath(menus, path, isGroupBookingPath = path === '/support/qna/group-booking') {
      menus.forEach(menu => {
        const isGroupBooking = menu.path === '/support/qna/group-booking';

        if (path.includes(menu.path) && (!isGroupBookingPath || isGroupBooking)) {
          menu.isActive = true;
        }

        if (menu.children) {
          this.activateMenuByPath(menu.children, path, isGroupBookingPath);
          if (!isGroupBookingPath && this.isAnyChildActive(menu.children)) {
            menu.isActive = true;
          }
        }
      });
    },
    isAnyChildActive(children) {
      return children.some(child => child.isActive);
    },

    activeClass(menu) {
      return menu.isActive ? 'active' : '';
    },
    blockedClass(menu) {
      return menu.blocked ? 'blocked' : '';
    },
  },
};
</script>

<style scoped>
.root-menu-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.root-menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0px 16px;
  align-items: center;
  border-bottom: 1px solid var(--Grey-grey-50);
  background: var(--White);
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.root-menu-item.active {
  color: var(--Blue-blue-500);
}

.arrow-icon {
  position: absolute;
  top: 13px;
  right: 16px;
  width: 24px;
  height: 24px;
  color: var(--Grey-grey-300);
  cursor: pointer;
  transition: transform 0.5s ease, color 0.5s ease;
}

.arrow-icon.active {
  color: var(--Blue-blue-500);
  transform: rotate(180deg);
}

.parent-menu-wrapper {
  background: var(--Grey-grey-50);
  max-height: 0;
  opacity: 0;
  padding: 0 32px;

  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.parent-menu-wrapper.active {
  max-height: 800px;
  opacity: 1;
  padding: 16px 32px;
}

.parent-menu {
  display: flex;
  flex-direction: column;
  color: var(--Grey-grey-900, #333);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.8px;
}

.parent-menu:hover,
.parent-menu.active {
  cursor: pointer;
  color: var(--Blue-blue-500);
}

.parent-menu.blocked {
  color: var(--Grey-grey-500);
  cursor: default;
}

.child-menu-wrapper {
  padding: 0 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.child-menu-wrapper:last-child {
  padding-bottom: 0;
}

.child-menu-item {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.child-menu-item:hover,
.child-menu-item.active {
  color: var(--Blue-blue-500);
}

@media screen and (min-width: 768px) {
  .root-menu-item {
    height: 70px;
    padding: 0 24px;
    font-size: 20px;
  }

  .arrow-icon {
    top: 23px;
    right: 24px;
  }
}
</style>
