<template>
  <ModalOnlyMobile :showModal="showModal" @update:showModal="updateShowModal" :title="modalTitle" class="modal-wrapper">
    <RePassengerCount
      class="content-wrapper"
      :maxCount="maxCount"
      :passengerTypeCountList="passengerTypeCountList"
      :departure="departure"
      :header="typeText"
      :noticeList="noticeList"
      @update:addCabin="addCabin"
      @update:removeCabin="removeCabin"
    >
      <div class="button-wrapper">
        <ReCruiseButton class="confirm-button" @click="closeModal">{{ $t('actions.confirm') }}</ReCruiseButton>
      </div>
    </RePassengerCount>
  </ModalOnlyMobile>
</template>

<script>
import ModalOnlyMobile from '@/components/renewal/modal/ModalOnlyMobile.vue';
import RePassengerCount from '@/components/renewal/home/RePassengerCount.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';

export default {
  name: 'RePassengerCountModal',
  components: { ModalOnlyMobile, RePassengerCount, ReCruiseButton },
  emits: ['update:showModal', 'update:addCabin', 'update:removeCabin', 'selectedConfirm'],
  props: {
    showModal: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    maxCount: Number,
    modalTitle: String,
    passengerTypeCountList: Array,
    departure: Object,
    typeText: String,
    noticeList: Array,
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.$emit('selectedConfirm');
      this.updateShowModal(!this.showModal);
    },
    addCabin() {
      this.$emit('update:addCabin');
    },
    removeCabin(index) {
      this.$emit('update:removeCabin', index);
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  position: relative;
  padding-top: 60px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  top: 60px;
}

.button-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.confirm-button {
  width: 100%;
  height: 54px;
  border-radius: 0 !important;
}

@media screen and (min-width: 768px) {
  :deep(.modal-wrapper) {
    height: auto;
  }

  .content-wrapper {
    top: 0;
  }

  .button-wrapper {
    position: absolute;
    bottom: -10px;
    left: auto;
    right: 16px;
    width: 80px;
    height: 36px;
    justify-content: center;
    align-items: center;
  }

  .confirm-button {
    width: 100%;
    height: 100%;
    border-radius: 5px !important;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
