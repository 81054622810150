<template>
  <div class="calendar-header">
    {{ monthYear }}
  </div>
</template>

<script>
export default {
  name: 'CalendarHeader',
  props: {
    date: Object,
  },
  computed: {
    monthYear() {
      return this.date.format('YYYY.MM');
    },
  },
};
</script>

<style scoped>
.calendar-header {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--LG-Black);
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.36px;
}

@media screen and (min-width: 768px) {
  .calendar-header {
    justify-content: center;
  }
}
</style>
