import { createI18n } from 'vue-i18n';
import { getItem, setItem } from '@/utils/localStorage';
import ko from '@/languages/ko.json';
import en from '@/languages/en.json';
import ja from '@/languages/ja.json';

const messages = { ko, en, ja };

const getBrowserLanguage = () => {
  const lang = navigator.language || navigator.languages[0];
  if (lang.startsWith('ko')) {
    return 'ko';
  } else if (lang.startsWith('ja')) {
    return 'en'; // 일본어 오픈전까지 영어로 표시
  } else {
    return 'en';
  }
};
const getUserLanguage = getItem('userLanguage') && (getItem('userLanguage') === 'ko' ? 'ko' : 'en');
const userLanguage = getUserLanguage || getBrowserLanguage();
setItem('userLanguage', userLanguage);

// I18n 인스턴스 생성
const i18n = createI18n({
  locale: userLanguage,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
