import api from '@/services/api';

const API_URL = '/api/qna';

class QnaService {
  /** 1:1 Qna Registration
   * @description Automatically sent to contact person's email
   */
  async submitQna(params) {
    try {
      const response = await api.post(`${API_URL}/post`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new QnaService();
