<template>
  <div v-show="isShow" class="popup-wrapper">
    <div class="image-wrapper">
      <img class="tip-image" :src="imgSrc" alt="tip image" draggable="false" />
    </div>

    <div class="popup-btns">
      <span class="close-btn" @click="closePopup">
        <span>{{ $t('actions.close') }}</span>
        <CloseIcon class="close-icon" :size="20" />
      </span>
    </div>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  name: 'BookingTip',
  components: { CloseIcon },
  props: { routeCode: String, img: String },
  data() {
    return {
      isShow: true,
    };
  },
  computed: {
    imgSrc() {
      const routeCodeMap = {
        OSC: 'osaka',
        ONC: 'onenight',
        TSE: 'daemado',
      };

      const localeKey = this.$i18n.locale !== 'ko' ? `_${this.$i18n.locale}` : '';
      return this.img || require(`@/assets/tip/${routeCodeMap[this.routeCode]}_tip${localeKey}.svg`);
    },
  },
  methods: {
    closePopup() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 86%;

  border-radius: 11px;
  background: var(--White);
  transform: translate(-50%, -50%);
  z-index: var(--zLayoutLoading);

  box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.image-wrapper {
  width: 100%;
}

.tip-image {
  width: 100%;
  object-fit: contain;
}

.popup-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 50px;
  color: var(--Grey-grey-700, #686d72);
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
}

.close-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.close-icon {
  padding-bottom: 3px;
}

@media screen and (min-width: 768px) {
  .popup-wrapper {
    max-width: 460px;
    max-height: 740px;
  }

  .popup-btns {
    height: 60px;
    font-size: 18px;
  }
}
</style>
