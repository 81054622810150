import api from '@/services/api';

const API_URL = '/api/termAgreement';

class TermService {
  async getTermList(params) {
    try {
      const response = await api.get(`${API_URL}/getList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new TermService();
