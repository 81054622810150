import api from '@/services/api';

const API_URL = '/api/popUp';

class PopUpService {
  async getPopUpList() {
    try {
      const response = await api.get(`${API_URL}/getList`);

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new PopUpService();
