<template>
  <div class="coupon-item" :class="stateClass" @click="handleClick">
    <div class="title-discount-wrapper">
      <div class="discount-rate" :class="stateClass">{{ coupon?.discountRate }}%</div>
      <div class="coupon-title" :class="stateClass">
        {{ getLocalText(coupon, 'couponName') }}
      </div>
    </div>

    <div class="coupon-detail">
      <div class="detail-item">
        <span class="detail-title" :class="stateClass">{{ $t('labels.usagePeriod') }}</span>
        <span class="detail-content" :class="stateClass">{{ avaliableDate }}</span>
      </div>
      <div class="detail-item">
        <span class="detail-title term-text" :class="stateClass">{{ $t('labels.usageConditions') }}</span>
        <span class="detail-content term-text" :class="stateClass">
          {{ getLocalText(coupon, 'conditionDescription') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalText } from '@/utils/convert';

export default {
  name: 'CouponItem',
  props: {
    coupon: Object,
    isSelected: Boolean,
    disabled: Boolean,
  },
  emits: ['onClick'],
  computed: {
    stateClass() {
      return {
        selected: this.isSelected,
        disabled: this.disabled,
      };
    },
    avaliableDate() {
      const { availableDateFrom, availableDateTo } = this.coupon;
      return availableDateFrom ? `${availableDateFrom} ~ ${availableDateTo}` : this.$t('labels.noLimit');
    },
  },
  methods: {
    getLocalText,
    handleClick() {
      if (this.disabled) {
        return;
      }

      this.$emit('onClick');
    },
  },
};
</script>

<style scoped>
.coupon-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100, #dddfe2);
  background: #fff;
  overflow: hidden;
  width: 100%;
  min-height: 179px;
  cursor: pointer;
}

.coupon-item:hover:not(.disabled) {
  border: 1px solid var(--Blue-blue-500, #446ceb);
}

.coupon-item.selected {
  background: var(--Blue-blue-500, #446ceb);
}

.title-discount-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 16px;
}

.discount-rate {
  color: var(--Blue-blue-500, #446ceb);
  font-size: 32px;
  font-weight: 700;
}

.coupon-title {
  color: var(--Grey-grey-900, #333);
  font-size: 16px;
  font-weight: 600;
}

.coupon-detail {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  border-top: 1px solid var(--Grey-grey-100, #dddfe2);
}

.detail-item {
  display: flex;
  gap: 8px;
}

.detail-title {
  color: var(--Grey-grey-700, #686d72);
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
}

.detail-content {
  color: var(--Grey-grey-700, #686d72);
  font-size: 12px;
  font-weight: 400;
  word-break: break-all;
  white-space: pre;
}

.term-text.disabled {
  color: var(--Orange-orange-500, #f56a3e);
}

.selected {
  color: #fff !important;
}

.disabled {
  color: var(--Grey-grey-200, #cdd0d3);
  cursor: default;
}
</style>
