const state = {
  virtualAccount: null,
  completeState: null,
};

const mutations = {
  setVirtualAccount(state, payload) {
    state.virtualAccount = payload;
  },
  setCompleteState(state, payload) {
    state.completeState = payload;
  },
};

const actions = {};

const getters = {};

const payment = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default payment;
