<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('baggageGuide.osaka.title') }}</div>
      <div class="txt_det">{{ $t('baggageGuide.intro') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <div class="view_detail">
        <ol class="area_detail">
          <li>
            <div class="label_title">{{ $t('baggageGuide.carryOnBaggageRules') }}</div>
            <div class="txt_detail">
              <div class="label_detail">{{ $t('baggageGuide.generalBaggage') }}</div>
              <ul class="list_dot">
                <li>
                  {{ $t('baggageGuide.osaka.carryOnBaggage') }}
                </li>
                <li>{{ $t('baggageGuide.osaka.freeCarryOnAllowed') }}</li>
              </ul>
              <ul class="list_star">
                <li>{{ $t('baggageGuide.osaka.noCartUseInImmigrationArea') }}</li>
              </ul>

              <div class="image_detail">
                <img :src="imgSrc('carry')" alt="Tsushima route baggage regulations image" />
              </div>
            </div>
          </li>

          <li>
            <div class="label_title">{{ $t('baggageGuide.osaka.checkedBaggageRules') }}</div>
            <div class="txt_detail">
              <div class="label_detail">{{ $t('baggageGuide.osaka.bicycle') }}</div>
              <ul class="list_dot">
                <li>{{ $t('baggageGuide.osaka.bicycleDescription') }}</li>
                <li>
                  {{ $t('baggageGuide.osaka.baggageSelfProcess') }}
                </li>
                <li>
                  <span class="txt_bold">{{ $t('baggageGuide.osaka.busanOsaka') }}</span
                  ><br />{{ $t('baggageGuide.osaka.busanOsakaPrice') }}
                </li>
                <li>
                  <span class="txt_bold">{{ $t('baggageGuide.osaka.osakaBusan') }}</span
                  ><br />{{ $t('baggageGuide.osaka.osakaBusanPrice') }}
                </li>
                <li>
                  {{ $t('baggageGuide.osaka.electricBicycle') }}
                </li>
              </ul>
            </div>
            <div class="txt_detail">
              <div class="label_detail">{{ $t('baggageGuide.osaka.otherBaggage') }}</div>
              <ul class="list_dot">
                <li>{{ $t('baggageGuide.osaka.otherBaggageDescription') }}</li>
                <li>
                  {{ $t('baggageGuide.osaka.checkedBaggageFeeDetails') }}
                </li>
                <li>
                  {{ $t('baggageGuide.osaka.checkedBaggageFeeConditions') }}
                </li>
              </ul>

              <div class="image_detail">
                <img :src="imgSrc('checked')" alt="Tsushima route luggage regulations image" />
              </div>
            </div>
          </li>

          <li>
            <div class="label_title">{{ $t('baggageGuide.prohibitedItems') }}</div>
            <div class="txt_detail">
              <ul class="list_number">
                <li v-for="item in prohibitedItemsList">{{ item }}</li>
              </ul>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/languages/i18n';

export default {
  name: 'OsakaBaggage',
  components: {},
  data() {
    return {
      prohibitedItemsList: [],
    };
  },
  methods: {
    imgSrc(type) {
      // const localeKey = this.$i18n.locale !== 'ko' ? `_${this.$i18n.locale}` : '';
      const localeKey = this.$i18n.locale !== 'ko' ? '_en' : '';
      return require(`@/assets/images/information/product/baggage_osaka_${type}${localeKey}.png`);
    },
    getProhibitedItemsList() {
      const locale = i18n.global.locale;
      const prohibitedItemsList = i18n.global.messages[locale].baggageGuide.prohibitedItemsList;

      this.prohibitedItemsList = prohibitedItemsList;
    },
  },
  created() {
    this.getProhibitedItemsList();
  },
};
</script>

<style scoped>
.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail > li {
  padding-bottom: 60px;
  border-bottom: 1px solid var(--Grey-grey-100);
  word-break: keep-all;
  letter-spacing: -0.32px;
}
.view_detail .area_detail > li:last-child {
  border-bottom: none;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 28px;
}
.view_detail .area_detail .txt_detail {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 48px;
}
@media (max-width: 767px) {
  .view_detail .area_detail > li {
    padding-bottom: 24px;
  }
  .view_detail .area_detail > li + li {
    margin-top: 24px;
  }
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 24px;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 150%;
  color: #505458;
}
.view_detail .area_detail .txt_detail .list_dot li,
.view_detail .area_detail .txt_detail .list_star li,
.view_detail .area_detail .txt_detail .list_number li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  color: #686d72;
  margin-top: 12px;
  font-weight: 400;
}
.view_detail .area_detail .txt_detail .list_star li {
  color: var(--Grey-grey-600);
}
.view_detail .area_detail .txt_detail .list_number li {
  padding-left: 0;
  margin-left: 30px;
  list-style-type: decimal;
}
.view_detail .area_detail .txt_detail ul li .list_number li {
  margin-left: 22px;
  padding-left: 0;
}
.view_detail .area_detail .txt_detail ul li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  flex: none;
  content: '';
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  background-color: #b6bbbf;
}
.view_detail .area_detail .txt_detail .list_star li:before {
  top: 3px;
  width: 30px;
  height: 30px;
  content: url('@/assets/images/icon/list_star_icon.svg');
}
.view_detail .area_detail .txt_detail .list_number li:before {
  background-color: transparent;
}
.view_detail .area_detail .txt_detail ul li ul li .list_dash p {
  font-weight: 400;
  margin-left: -20px;
  margin-top: 12px;
}
@media (max-width: 767px) {
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    margin-bottom: 24px;
    font-size: 22px;
  }
  .view_detail .area_detail .txt_detail {
    font-size: 16px;
    line-height: 20px;
  }
  .view_detail .area_detail .txt_detail .label_detail {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li,
  .view_detail .area_detail .txt_detail .list_star li,
  .view_detail .area_detail .txt_detail .list_number li,
  .view_detail .area_detail .txt_detail ul li ul li .list_dash p {
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_dot li,
  .view_detail .area_detail .txt_detail .list_star li,
  .view_detail .area_detail .txt_detail .list_number li {
    word-break: auto-phrase;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_star li:before {
    top: 0;
  }
  .view_detail .area_detail .txt_detail ul li .list_number li {
    margin-left: 17px;
  }
  .view_detail .area_detail .txt_detail ul li ul li .list_dash p {
    margin-left: -15px;
    margin-top: 8px;
  }
}
.image_detail {
  margin-top: 32px;
}
.line_break br,
.line_break br {
  display: none;
}
@media (max-width: 767px) {
  .line_break br,
  .line_break br {
    display: block;
  }
  .image_detail {
    margin-top: 24px;
  }
}
.txt_bold {
  font-weight: 600;
}
</style>
