<template>
  <ReCruiseModal
    :showModal="showModal"
    @update:showModal="updateShowModal"
    :title="$t('labels.reservationCancel')"
    autoHeight
    :hideModal="isShowAccountModal"
  >
    <template #content>
      <div class="booking-list-wrapper">
        <div class="booking-list" v-for="reservation in reservations">
          <div class="booking-number">
            <div>{{ $t('reservation.number') }}{{ colonByLocale }}{{ reservation.reservationNumber }}</div>
            <ReCruiseCheckbox
              class="label-title mobile"
              v-model="reservation.isAllSelected"
              :disabled="isAllTicketsCanceled(reservation.passengers)"
              @onChange="toggleSelectAll($event, reservation)"
            />
          </div>

          <div class="booking-info mobile">
            <div class="booking-description">
              <div class="booking-name-customer">
                <div class="passenger-wrapper">
                  <div
                    class="passenger-info"
                    v-for="{
                      name,
                      englishName,
                      passportNumber,
                      mobile,
                      tickets,
                      sex,
                      passengerKind,
                    } in reservation.passengers"
                  >
                    <div class="info-item">
                      <div class="user-info">
                        <div class="user-name">
                          <span class="bold">{{ $t('labels.name') }}{{ colonByLocale }}</span
                          >{{ name }}
                        </div>
                        <div class="user-detail">
                          <img class="gender-icon" :src="getGenderIcon(sex)" alt="gender icon" />
                          <!-- 승객 종류 -->
                          <!-- <p>{{ passengerKind }}(장애인1급)</p> -->
                        </div>
                      </div>
                      <div>
                        <span class="bold">{{ $t('labels.phoneNumber') }}{{ colonByLocale }}</span
                        >{{ formatPhoneNumber(mobile) }}
                      </div>

                      <div>
                        <span class="bold">{{ $t('profile.englishName') }}{{ colonByLocale }}</span
                        >{{ englishName }}
                      </div>
                      <div>
                        <span class="bold">{{ $t('profile.passportNumber') }}{{ colonByLocale }}</span
                        >{{ passportNumber }}
                      </div>
                    </div>

                    <div class="flex-between">
                      <div class="info-item">
                        <div>
                          <span class="bold">
                            {{ getLocalText(ports.leave.departure, 'name') }} ->
                            {{ getLocalText(ports.leave.arrival, 'name') }}
                          </span>
                        </div>
                        <div>
                          <span class="bold">{{ $t('labels.departureDateTime') }}{{ colonByLocale }}</span>
                          {{ leaveDepartureDateTimeText }}
                        </div>
                        <div>
                          <span class="bold">{{ $t('labels.grade') }}{{ colonByLocale }}</span>
                          {{ getLocalText(tickets.leaveRooms.grade, 'gradeName') }}
                        </div>
                        <div v-if="routeCode === 'OSC'">
                          <span class="bold">{{ $t('fareDetails.extraService') }}{{ colonByLocale }}</span>
                          {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                        </div>
                        <div class="payment-status">
                          <span class="bold">{{ $t('labels.state') }}{{ colonByLocale }}</span>
                          <span :class="paymentStatusClass(tickets.leaveRooms.status)">{{
                            paymentStatus(tickets.leaveRooms.status)
                          }}</span>
                        </div>
                        <div>
                          <span class="bold">{{ $t('payment.amount') }}{{ colonByLocale }}</span>
                          {{ convertCommaNumber(tickets.priceDetail.leave.total || 0)
                          }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                          }}{{ getReservationCurrency(reservation.reservationNumber) }}
                        </div>
                      </div>

                      <ReCruiseCheckbox
                        class="label-title"
                        v-model="tickets.leaveRooms.isSelected"
                        :disabled="tickets.leaveRooms.status === 'CAN'"
                      />
                    </div>

                    <div class="flex-between" v-if="isRoundTrip">
                      <div class="info-item">
                        <div>
                          <span class="bold">
                            {{ getLocalText(ports.return.departure, 'name') }} ->
                            {{ getLocalText(ports.return.arrival, 'name') }}
                          </span>
                        </div>
                        <div>
                          <span class="bold">{{ $t('labels.returnDateTime') }}{{ colonByLocale }}</span>
                          {{ returnDepartureDateTimeText }}
                        </div>
                        <div>
                          <span class="bold">{{ $t('labels.grade') }}{{ colonByLocale }}</span>
                          {{ getLocalText(tickets.returnRoom.grade, 'gradeName') }}
                        </div>
                        <div v-if="routeCode === 'OSC'">
                          <span class="bold">{{ $t('fareDetails.extraService') }}{{ colonByLocale }}</span>
                          {{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}
                        </div>
                        <div class="payment-status">
                          <span class="bold">{{ $t('labels.state') }}{{ colonByLocale }}</span>
                          <span :class="paymentStatusClass(tickets.returnRoom.status)">{{
                            paymentStatus(tickets.returnRoom.status)
                          }}</span>
                        </div>
                        <div>
                          <span class="bold">{{ $t('payment.amount') }}{{ colonByLocale }}</span>
                          {{ convertCommaNumber(tickets.priceDetail.return.total || 0)
                          }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                          }}{{ getReservationCurrency(reservation.reservationNumber) }}
                        </div>
                      </div>

                      <ReCruiseCheckbox
                        class="label-title"
                        v-model="tickets.returnRoom.isSelected"
                        :disabled="tickets.returnRoom.status === 'CAN'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap_table pc">
            <table class="detail_table">
              <colgroup>
                <col width="110px" />
                <col width="60px" />
                <col width="152.5px" />

                <col width="152.5px" />
                <col width="152.5px" />

                <col width="50px" />

                <col width="120px" />
                <col width="120px" />

                <col width="152.5px" />
                <col width="152.5px" />
                <col width="152.5px" />

                <!-- <col v-if="routeCode === 'OSC'" width="120px" /> -->

                <!-- <template v-if="isRoundTrip">
                  <col width="130px" />
                  <col width="150px" />
                  <col v-if="routeCode === 'OSC'" width="120px" />
                  <col width="165px" />
                </template> -->
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('labels.name') }}</th>
                  <th class="txt_no_pw">{{ $t('profile.gender') }}</th>
                  <th class="txt_no_pw">{{ $t('labels.phoneNumber') }}</th>

                  <th class="txt_no_pw">{{ $t('profile.englishName') }}</th>
                  <th class="txt_no_pw">{{ $t('profile.passportNumber') }}</th>

                  <th>
                    <ReCruiseCheckbox
                      class="label-title"
                      v-model="reservation.isAllSelected"
                      :disabled="isAllTicketsCanceled(reservation.passengers)"
                      @onChange="toggleSelectAll($event, reservation)"
                    />
                  </th>

                  <th class="txt_no_pw">{{ $t('labels.departurePort') }}</th>
                  <th class="txt_no_pw">{{ $t('labels.arrivalPort') }}</th>
                  <th class="txt_no_pw">
                    {{ isRoundTrip ? $t('labels.departureReturnDateTime') : $t('labels.departureDate') }}
                  </th>

                  <th>{{ $t('labels.grade') }}</th>
                  <th class="txt_no_pw">{{ $t('fareDetails.extraService') }}</th>
                  <th class="txt_no_pw">{{ $t('labels.state') }}</th>

                  <th class="txt_no_pw">{{ $t('payment.amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="{
                    name,
                    englishName,
                    passportNumber,
                    mobile,
                    tickets,
                    sex,
                    passengerKind,
                  } in reservation.passengers"
                >
                  <tr>
                    <th :rowspan="roundRowSpan" class="txt_center txt_no_pw">{{ name }}</th>
                    <th :rowspan="roundRowSpan" class="txt_no_pw">{{ getGenderText(sex) }}</th>
                    <th :rowspan="roundRowSpan" class="txt_no_pw">{{ formatPhoneNumber(mobile) }}</th>

                    <th class="txt_no_pw" :rowspan="roundRowSpan">{{ englishName }}</th>
                    <th class="txt_no_pw" :rowspan="roundRowSpan">{{ passportNumber }}</th>

                    <td>
                      <ReCruiseCheckbox
                        class="label-title"
                        v-model="tickets.leaveRooms.isSelected"
                        :disabled="tickets.leaveRooms.status === 'CAN'"
                      />
                    </td>

                    <td>{{ getLocalText(ports.leave.departure, 'name') }}</td>
                    <td>{{ getLocalText(ports.leave.arrival, 'name') }}</td>
                    <td>{{ leaveDepartureDateTimeText }}</td>

                    <td>{{ getLocalText(tickets.leaveRooms.grade, 'gradeName') }}</td>
                    <td>{{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}</td>
                    <td :class="paymentStatusClass(tickets.leaveRooms.status)">
                      {{ paymentStatus(tickets.leaveRooms.status) }}
                    </td>
                    <td>
                      {{ convertCommaNumber(tickets.priceDetail.leave.total || 0)
                      }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                      }}{{ getReservationCurrency(reservation.reservationNumber) }}
                    </td>
                  </tr>

                  <tr v-if="isRoundTrip">
                    <td>
                      <ReCruiseCheckbox
                        class="label-title"
                        v-model="tickets.returnRoom.isSelected"
                        :disabled="tickets.returnRoom.status === 'CAN'"
                      />
                    </td>
                    <td>{{ getLocalText(ports.return.departure, 'name') }}</td>
                    <td>{{ getLocalText(ports.return.arrival, 'name') }}</td>
                    <td>{{ returnDepartureDateTimeText }}</td>

                    <td>{{ getLocalText(tickets.returnRoom.grade, 'gradeName') }}</td>
                    <td>{{ tickets.meal ? $t('fareDetails.onboardMeal') : $t('fareDetails.notIncluded') }}</td>
                    <td :class="paymentStatusClass(tickets.returnRoom.status)">
                      {{ paymentStatus(tickets.returnRoom.status) }}
                    </td>
                    <td>
                      {{ convertCommaNumber(tickets.priceDetail.return.total || 0)
                      }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                      }}{{ getReservationCurrency(reservation.reservationNumber) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="flex-end">
          <div class="refund_wrapper">
            <div class="cancel_info">
              <div class="flex-between">
                <div class="cancel_title">{{ $t('labels.cancelPrice') }}</div>
                <div class="cancel_price">
                  {{ convertCommaNumber(refundDetail.cancelAmount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                  }}{{ getReservationCurrency(reservations[0].reservationNumber) }}
                </div>
              </div>
              <div class="flex-between">
                <div class="cancel_title">{{ $t('labels.cancelFee') }}</div>
                <div class="cancel_fee">
                  {{ (refundDetail.cancelFee > 0 && '-' + convertCommaNumber(refundDetail.cancelFee)) || '0'
                  }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                  }}{{ getReservationCurrency(reservations[0].reservationNumber) }}
                </div>
              </div>
            </div>

            <div class="refund_info flex-between">
              <div class="refund_title">{{ $t('labels.refundPrice') }}</div>
              <div class="refund_amount">
                {{ convertCommaNumber(refundDetail.refundAmount) }}{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                }}{{ getReservationCurrency(reservations[0].reservationNumber) }}
              </div>
            </div>
          </div>
        </div>

        <div class="agreement_wrapper">
          <ReCruiseCheckbox class="label-title" v-model="isAgreeCancel" />
          <div class="agree_text" @click="checkCancelAgree">
            <template v-if="isEnglish">
              {{ $t('messages.cancelFee.checked') }}
              <TextButton class="cancel_btn" @click.stop="showCancelFeePopup">
                {{ $t('messages.cancelFee.policy') }}.
              </TextButton>
            </template>
            <template v-else>
              <TextButton class="cancel_btn" @click.stop="showCancelFeePopup">
                {{ $t('messages.cancelFee.policy') }}
              </TextButton>
              {{ $t('messages.cancelFee.checked') }}
            </template>
          </div>
        </div>
      </div>
    </template>

    <template #button>
      <ReCruiseButton class="close-btn" :class="mobileClass" theme="outline" @click="closeModal">
        {{ $t('actions.close') }}
      </ReCruiseButton>
      <ReCruiseButton
        class="confirm-btn"
        :theme="confirmButtonTheme"
        :disabled="!isAnyChecked"
        @click="cancelReservation"
      >
        {{ $t('actions.proceed') }}
      </ReCruiseButton>
    </template>
  </ReCruiseModal>

  <CancelNoticePopup v-if="isShowCancelFeePopup" @close="isShowCancelFeePopup = false" :dimmed="isMobile" />

  <CancelFailedPopup
    v-if="isShowCancelFailedPopup"
    :status="cancelStatus"
    @close="isShowCancelFailedPopup = false"
    :dimmed="isMobile"
    :nameList="checkedPassengerNames"
  />

  <AccountRefundModal
    v-if="isShowAccountModal"
    :refundDetail="refundDetail"
    :reservationNumber="reservations[0].reservationNumber"
    v-model:showModal="isShowAccountModal"
    @cancelVirtualAccountReservation="cancelVirtualAccountReservation"
  />
</template>

<script>
import dayjs from 'dayjs';
import PaymentService from '@/services/payment';
import { YYYYMMDD, YYYY_MM_DD, YYYYMMDDHHMM } from '@/const/const';
import { convertCommaNumber, getLocalText, formatPhoneNumber, formatDate } from '@/utils/convert';

import ReCruiseModal from '@/components/renewal/modal/ReCruiseModal.vue';
import ReCruiseButton from '@/components/renewal/button/ReCruiseButton.vue';
import TextButton from '@/components/button/TextButton.vue';
import ReCruiseCheckbox from '@/components/renewal/common/ReCruiseCheckbox.vue';
import CancelNoticePopup from '@/components/myPage/CancelNoticePopup.vue';
import CancelFailedPopup from '@/components/myPage/CancelFailedPopup.vue';
import AccountRefundModal from '@/components/myPage/AccountRefundModal.vue';

export default {
  name: 'CancelReservationModal',
  components: {
    ReCruiseModal,
    ReCruiseButton,
    TextButton,
    ReCruiseCheckbox,
    CancelNoticePopup,
    CancelFailedPopup,
    AccountRefundModal,
  },
  emits: ['update:showModal'],
  props: {
    reservationDetail: Object,
    showModal: Boolean,
    isRound: Boolean,
    isMulti: Boolean,
    routeCode: String,
  },
  data() {
    return {
      isAgreeCancel: false,
      isShowCancelFeePopup: false,
      isShowCancelFailedPopup: false,
      cancelStatus: '',
      checkedPassengerNames: [],
      paymentCancellationData: [],

      refundDetail: {
        cancelAmount: 0,
        cancelFee: 0,
        refundAmount: 0,
      },

      isShowAccountModal: false,
      refundAccountInfo: {},

      osakaGeneralTicketDiscountCodes: [
        { code: '019', name: '온라인 할인' },
        { code: '005', name: '장애인(1~3급)' },
        { code: '006', name: '장애인(4~6급)' },
        { code: '009', name: '국가 유공자' },
        { code: '010', name: '어린이' },
        { code: '003', name: '유아' },
      ],
    };
  },
  watch: {
    reservationDetail: {
      handler() {
        this.updateAllCheckedStatus();
        this.setPaymentCancellationData();
        this.setRefundDetail();
      },
      deep: true,
    },
    showModal(value) {
      if (!value) {
        this.reservations.forEach(reservation => {
          reservation.passengers.forEach(passenger => {
            const { leaveRooms, returnRoom } = passenger.tickets;
            leaveRooms.isSelected = false;
            returnRoom.isSelected = false;
          });
        });

        this.isAgreeCancel = false;
      }
    },
  },
  computed: {
    now() {
      // DEBUG: 테스트용 시간
      const dateTime = '2025-01-09 13:00';
      return dayjs();
    },
    reservationMaster() {
      return this.reservationDetail.reservationMaster;
    },
    ports() {
      return this.reservationDetail.ports;
    },
    reservations() {
      this.reservationMaster.reservation.forEach(reservation => {
        reservation.passengers.forEach(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;

          leaveRooms.isSelected = leaveRooms.status === 'CAN' ? false : leaveRooms.isSelected;
          returnRoom.isSelected = returnRoom?.status === 'CAN' ? false : returnRoom?.isSelected;
        });
      });
      return this.reservationMaster.reservation;
    },
    leaveDepartureDateTime() {
      return dayjs(this.reservationMaster.departureSchedule.eta);
    },
    returnDepartureDateTime() {
      return dayjs(this.reservationMaster?.returnSchedule?.eta);
    },
    leaveDepartureDateTimeText() {
      return this.formatDate(this.reservationMaster.departureSchedule.eta, YYYYMMDDHHMM);
    },
    returnDepartureDateTimeText() {
      return this.formatDate(this.reservationMaster?.returnSchedule?.eta, YYYYMMDDHHMM);
    },
    isRoundTrip() {
      return this.isRound || this.isMulti;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    mobileClass() {
      return this.isMobile ? 'mobile' : '';
    },
    confirmButtonTheme() {
      return this.isAnyChecked && this.isAgreeCancel ? 'primary' : 'disabled';
    },
    isAnyChecked() {
      return this.reservations.some(reservation =>
        reservation.passengers.some(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;
          const leaveChecked = leaveRooms.isSelected;
          const returnChecked = returnRoom?.isSelected || false;

          if (this.isRoundTrip) {
            return leaveChecked || returnChecked;
          }
          return leaveChecked;
        }),
      );
    },
    roundRowSpan() {
      return this.isRoundTrip ? 2 : 1;
    },
    cancelFeePolicy() {
      return { title: this.$t('labels.cancelFeeGuide'), content: this.$t('withdrawal.completeMessage2') };
    },
    isEnglish() {
      return this.$i18n.locale === 'en';
    },
    colonByLocale() {
      const localeColonMap = {
        ko: ': ',
        en: ' : ',
        ja: '：',
      };

      return localeColonMap[this.$i18n.locale] || ' : ';
    },
    hasAnyCanceledStatus() {
      return this.reservations.some(reservation =>
        reservation.passengers.some(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;
          return leaveRooms.status === 'CAN' || returnRoom?.status === 'CAN';
        }),
      );
    },
  },
  methods: {
    convertCommaNumber,
    getLocalText,
    formatPhoneNumber,
    formatDate,

    setRefundDetail() {
      const initialValue = { cancelAmount: 0, cancelFee: 0, refundAmount: 0 };

      const result = this.paymentCancellationData.reduce((acc, item) => {
        acc.cancelAmount += item.cancelAmount || 0;
        acc.cancelFee += item.cancelFee || 0;
        acc.refundAmount += item.refundAmount || 0;
        return acc;
      }, initialValue);

      this.refundDetail = result;
    },
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    closeModal() {
      this.updateShowModal(!this.showModal);
    },
    cancelValidation() {
      if (this.hasAnyCanceledStatus) {
        this.cancelStatus = 'ALREADY_CANCELED';
        return false;
      }

      const isPastDeparture = departureDateTime => {
        return dayjs(departureDateTime).isBefore(this.now);
      };

      const isVirtualAccountPending =
        this.reservationMaster.paymentResponse.pay_method === 'PAVC' && this.reservationMaster.status === 'PND';

      let alreadyLeft = false;
      let hasLeaveOnly = false;
      let isPending = false;

      // 공통 검증 로직
      const checkReservation = reservation => {
        reservation.passengers.forEach(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;
          const leaveChecked = leaveRooms.isSelected;
          const returnChecked = returnRoom?.isSelected || false;

          if (isPastDeparture(this.leaveDepartureDateTime)) {
            if (returnChecked) {
              alreadyLeft = true;
            }
          } else {
            if (leaveChecked && !returnChecked) {
              hasLeaveOnly = true;
            }
          }
        });
      };

      const isAllChecked = reservation => {
        reservation.passengers.forEach(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;
          const leaveChecked = leaveRooms.isSelected;
          const returnChecked = returnRoom?.isSelected;

          if (this.isRoundTrip) {
            if (!leaveChecked || !returnChecked) isPending = true;
          } else {
            if (!leaveChecked) isPending = true;
          }
        });
      };

      if (isVirtualAccountPending) {
        this.reservations.forEach(reservation => isAllChecked(reservation));

        if (isPending) {
          this.cancelStatus = 'PENDING_PAYMENT';
          return false;
        }
      }

      if (this.isRoundTrip) {
        this.reservations.forEach(reservation => checkReservation(reservation));

        if (alreadyLeft) {
          this.cancelStatus = 'ROUND_ALREADY_LEFT';
          return false;
        }

        if (hasLeaveOnly) {
          this.cancelStatus = 'ROUND_NOT_LEFT';
          return false;
        }
      }

      this.cancelStatus = '';
      return true;
    },
    calcurateCancelRate(departureDateTime) {
      const daysDifference = departureDateTime.diff(this.now, 'day');
      const hoursDifference = departureDateTime.diff(this.now, 'hour');

      if (daysDifference >= 3) return 0;
      if (daysDifference === 2) return 10;
      if (daysDifference === 1) return 30;
      if (daysDifference === 0 && hoursDifference >= 2) return 50;
      return 100;
    },
    calcurateDiscountTicketCancelRate(paymentDate, departureDateTime) {
      const paymentAfter24h = dayjs(paymentDate).add(24, 'hour');
      const departureMinus7Days = dayjs(departureDateTime).subtract(7, 'day');
      const departureMinus6Days = dayjs(departureDateTime).subtract(6, 'day');
      const departureMinus1Day = dayjs(departureDateTime).subtract(1, 'day');

      if (now.isAfter(paymentAfter24h) && now.isBefore(departureMinus7Days)) return 30;
      if (now.isSameOrAfter(departureMinus6Days, 'day') && now.isBefore(departureMinus1Day, 'day')) return 50;
      if (now.isSameOrAfter(departureDateTime, 'day')) return 100;
      return 0;
    },
    calculateMealRate(departureDateTime) {
      if (this.now.isSameOrAfter(departureDateTime, 'day')) {
        return 100;
      }
      return 0;
    },
    calculateRefundFee(cancelRate, leavePriceDetail, returnPriceDetail) {
      if (cancelRate === 0) {
        return 0;
      }

      const leaveFee = leavePriceDetail ? leavePriceDetail.grade * (cancelRate / 100) : 0;
      const returnFee = this.isRoundTrip && returnPriceDetail ? returnPriceDetail.grade * (cancelRate / 100) : 0;

      const refundFee = leaveFee + returnFee;
      return refundFee;
    },
    calculateRefundMealFee(leavePriceDetail, returnPriceDetail) {
      const leaveMealFee = leavePriceDetail ? leavePriceDetail.meal : 0;
      const returnMealFee = this.isRoundTrip && returnPriceDetail ? returnPriceDetail.meal : 0;

      return leaveMealFee + returnMealFee;
    },
    setPaymentCancellationData() {
      const paymentCancellationData = this.reservations.reduce((acc, reservation) => {
        const resultData = reservation.passengers.map(passenger => {
          const { ticketNumber, saleCode, leaveRooms, returnRoom, paymentDetail, priceDetail, meal } =
            passenger.tickets;

          const leaveChecked = leaveRooms.isSelected;
          const returnChecked = returnRoom?.isSelected || false;
          const bothChecked = leaveChecked && returnChecked;
          const onlyReturnChecked = !leaveChecked && returnChecked;

          const isVirtualAccount = this.reservationMaster.paymentResponse.pay_method === 'PAVC';
          const isVirtualAccountPending = isVirtualAccount && this.reservationMaster.status === 'PND';

          const routeCode = this.reservationMaster.route.code;
          const paymentDate = dayjs(this.reservationMaster.insertDate);
          const reservationStatus = this.reservationMaster.status;

          const isDiscountTicket =
            !this.osakaGeneralTicketDiscountCodes.some(item => item.code === saleCode) &&
            routeCode === 'OSC' &&
            paymentDate &&
            reservationStatus === 'CMP';

          if (this.isRoundTrip && !returnChecked) return null;
          if (!this.isRoundTrip && !leaveChecked) return null;

          const leavePriceDetail = priceDetail.leave;
          const returnPriceDetail = priceDetail?.return || false;

          const cancelType = this.isRoundTrip
            ? (leaveChecked && returnChecked) || isVirtualAccountPending
              ? 'A'
              : 'R'
            : 'A';
          const cancelRate = isVirtualAccountPending
            ? 0
            : isDiscountTicket
            ? this.calcurateDiscountTicketCancelRate(
                paymentDate,
                onlyReturnChecked ? this.returnDepartureDateTime : this.leaveDepartureDateTime,
              )
            : this.calcurateCancelRate(onlyReturnChecked ? this.returnDepartureDateTime : this.leaveDepartureDateTime);
          const refundFee = isVirtualAccountPending
            ? 0
            : this.calculateRefundFee(cancelRate, onlyReturnChecked ? null : leavePriceDetail, returnPriceDetail);

          const isMeal = meal;
          const mealRate =
            isMeal && !isVirtualAccountPending
              ? this.calculateMealRate(onlyReturnChecked ? this.returnDepartureDateTime : this.leaveDepartureDateTime)
              : 0;
          const refundMealFee =
            isMeal && mealRate > 0 && !isVirtualAccountPending
              ? this.calculateRefundMealFee(onlyReturnChecked ? null : leavePriceDetail, returnPriceDetail)
              : 0;

          const leavePriceAmount = priceDetail.leave.total;
          const returnPriceAmount = this.isRoundTrip && returnChecked ? priceDetail.return.total : 0;
          const refundFeeSum = refundFee + refundMealFee;

          const refundAmount = () => {
            if (bothChecked) {
              return paymentDetail.total - refundFeeSum;
            } else {
              if (leaveChecked && !returnChecked) return leavePriceAmount - refundFeeSum;
              if (!leaveChecked && returnChecked) return returnPriceAmount - refundFeeSum;
            }

            return 0;
          };
          const cancelAmount = onlyReturnChecked ? returnPriceAmount : leavePriceAmount + returnPriceAmount;
          const cancelFee = isVirtualAccountPending ? 0 : refundFeeSum;

          const isMileage = leavePriceDetail.mileage || returnPriceDetail?.mileage;
          const mileageAmount = isMileage ? leavePriceDetail.mileage + (returnPriceDetail?.mileage || 0) : null;

          return {
            birthDay: passenger.birthDay || null,
            englishName: passenger?.englishName || null,
            passport: passenger?.passportNumber || null,
            sex: passenger.sex || null,
            userId: passenger.name === this.reservationMaster?.reserverName ? this.reservationMaster?.reserverId : null,

            cancelDate: this.formatDate(this.now, YYYYMMDD),
            cancelType,

            cancelRate,
            refundFee,

            mealRate,
            refundMealFee,

            mileageAmount,

            cancelAmount,
            cancelFee,
            refundAmount: refundAmount(),

            reservationNumber: reservation.reservationNumber,
            ticketNumber: ticketNumber,
          };
        });

        return acc.concat(resultData.filter(item => item !== null));
      }, []);

      this.paymentCancellationData = paymentCancellationData;
    },

    async cancelReservation() {
      if (!this.isAnyChecked || !this.isAgreeCancel) {
        return;
      }

      if (!this.cancelValidation()) {
        this.getCheckedPassengerNames();
        this.isShowCancelFailedPopup = true;
      } else {
        try {
          this.setPaymentCancellationData();

          this.paymentCancellationData.forEach(item => {
            delete item.cancelAmount;
            delete item.cancelFee;
          });
          const reservationStatus = this.reservationMaster.status;
          const isVirtualAccount = this.reservationMaster.paymentResponse.pay_method === 'PAVC';
          const isVirtualAccountPaid = isVirtualAccount && reservationStatus === 'CMP';

          if (isVirtualAccountPaid & !this.isShowAccountModal) {
            this.isShowAccountModal = true;
            return;
          }

          const isConfirmed = await this.$confirm({ content: this.$t('messages.confirmReservationCancel') });

          if (isConfirmed) {
            const isAllChecked = this.reservations.every(reservation =>
              reservation.passengers.every(passenger => {
                const { leaveRooms, returnRoom } = passenger.tickets;
                const leaveChecked = leaveRooms.status === 'CAN' ? true : leaveRooms.isSelected;
                const returnChecked = returnRoom?.status === 'CAN' ? true : returnRoom?.isSelected || true;

                return leaveChecked && returnChecked;
              }),
            );

            const body = {
              isEntireCancel: isAllChecked ? true : false,
              payMethod: this.reservationMaster.paymentResponse.pay_method,
              paymentCancellationData: this.paymentCancellationData,
              reservationMasterId: this.reservationMaster.id,
              status: reservationStatus,

              ...(isVirtualAccountPaid &&
                this.refundAccountInfo.bankCode && {
                  refundBankCode: this.refundAccountInfo.bankCode,
                }),
              ...(isVirtualAccountPaid &&
                this.refundAccountInfo.depositor && {
                  refundDepositor: this.refundAccountInfo.depositor,
                }),
              ...(isVirtualAccountPaid &&
                this.refundAccountInfo.account && {
                  refundAccount: this.refundAccountInfo.account,
                }),
            };

            await PaymentService.cancelReservation(body);

            this.$alert({ content: this.$t('messages.successCancellation') }).then(() => {
              this.$router.push('/my-page');
            });
          }
        } catch (error) {
          await this.$alert({ title: this.$t('reservation.cancel.title.failed'), content: error });
        }
      }
    },
    cancelVirtualAccountReservation(refundAccountInfo) {
      this.refundAccountInfo = refundAccountInfo;
      this.cancelReservation();
    },
    getGenderText(gender) {
      const genderText = gender === 'M' ? this.$t('profile.male') : this.$t('profile.female');
      return genderText;
    },
    getGenderIcon(gender) {
      return require(`@/assets/images/icon/gender_icon_${gender}.svg`);
    },
    toggleSelectAll(e, reservation) {
      const checked = e.target.checked;

      reservation.passengers.forEach(passenger => {
        const { leaveRooms, returnRoom } = passenger.tickets;
        const leaveStatus = leaveRooms.status;
        const returnStatus = returnRoom?.status;

        // if (this.isRoundTrip) {
        //   leaveRooms.isSelected = leaveStatus === 'CAN' ? false : checked;
        //   returnRoom.isSelected = returnStatus === 'CAN' ? false : checked;
        // } else {
        //   leaveRooms.isSelected = leaveStatus === 'CAN' ? false : checked;
        // }

        if (this.isRoundTrip) {
          leaveRooms.isSelected = checked;
          returnRoom.isSelected = checked;
        } else {
          leaveRooms.isSelected = checked;
        }
      });

      reservation.isAllSelected = checked;
    },
    getReservationCurrency(reservationNumber) {
      const reservationType = reservationNumber.substring(0, 2);
      return reservationType === 'WR' ? this.$t('currency.krw') : this.$t('currency.jpy');
    },
    showCancelFeePopup() {
      this.isShowCancelFeePopup = !this.isShowCancelFeePopup;
    },
    checkCancelAgree() {
      this.isAgreeCancel = !this.isAgreeCancel;
    },
    updateAllCheckedStatus() {
      this.reservations.forEach(reservation => {
        reservation.isAllSelected = reservation.passengers.some(passenger => {
          const { leaveRooms, returnRoom } = passenger.tickets;

          const hasAnyCanceledStatus = leaveRooms.status === 'CAN' || returnRoom?.status === 'CAN';
          const hasRemainCMP = leaveRooms.status === 'CMP';

          // TODO: 부분취소가 된 경우 전체 체크박스 상태를 어떻게 처리할지 결정 필요
          // 왕복: 오는편만 취소 / 둘다 취소
          // 편도: 가는편 취소
          const isLeaveRoomCanceled = leaveRooms.status === 'CAN';
          const isReturnRoomCanceled = returnRoom?.status === 'CAN';
          const isAllCanceled = this.isRoundTrip ? isLeaveRoomCanceled && isReturnRoomCanceled : isLeaveRoomCanceled;

          const leaveChecked = leaveRooms?.isSelected || false;
          const returnChecked = returnRoom?.isSelected || false;
          if (hasAnyCanceledStatus || hasRemainCMP) {
            if (hasRemainCMP) {
              return leaveChecked ? true : false;
            } else return false;
          } else {
            return this.isRoundTrip ? leaveChecked && returnChecked : leaveChecked;
          }

          // [왕복]
          // 1. 취소전
          // 1-1. 가는편 / 오는편 모두 클릭 가능
          // => 가는편&&오는편

          // 2. 취소 후
          // 2-1. 부분 취소, 오는편만 취소인 경우 - 가는편 클릭 가능 / 오는편 무시
          // 2-2. 전체 취소, 모두 클릭 불가 - 가는편 오는편 무시
          // => 가는편 && !오는편, !가는편 && !오는편

          // [편도]
          // 1. 취소전
          // 1-1. 가는편 클릭 가능
          // => 가는편

          // 2. 취소 후
          // => !가는편

          // if (hasAnyCanceledStatus) {
          //   console.log(passenger.name, '1hasAnyCanceledStatus', leaveChecked, returnChecked);

          //   // if (this.isRoundTrip) {
          //   //   if (isReturnRoomCanceled) return leaveChecked && !returnChecked;
          //   //   if (isAllCanceled) return !leaveChecked && !returnChecked;
          //   // } else {
          //   //   return !leaveChecked;
          //   // }
          // } else {
          //   console.log(passenger.name, '2hasAnyCanceledStatus', leaveChecked, returnChecked);
          //   return this.isRoundTrip ? leaveChecked && returnChecked : leaveChecked;
          // }
        });
      });
    },
    getCheckedPassengerNames() {
      if (this.cancelStatus === 'ROUND_ALREADY_LEFT') {
        this.checkedPassengerNames = this.reservations
          .map(reservation => {
            return reservation.passengers
              .map(passenger => passenger.name)
              .filter(name => {
                const ticket = reservation.passengers.find(passenger => passenger.name === name).tickets;
                return ticket.leaveRooms.isSelected || ticket.returnRoom?.isSelected;
              });
          })
          .flat();
      } else if (this.cancelStatus === 'ROUND_NOT_LEFT') {
        this.checkedPassengerNames = this.reservations
          .map(reservation => {
            return reservation.passengers
              .map(passenger => passenger.name)
              .filter(name => {
                const ticket = reservation.passengers.find(passenger => passenger.name === name).tickets;
                return ticket.leaveRooms.isSelected && !ticket.returnRoom?.isSelected;
              });
          })
          .flat();
      } else {
        this.checkedPassengerNames = [];
      }
    },
    paymentStatusClass(status) {
      const statusMapping = {
        PND: 'payment-waiting',
        CMP: 'payment-completed',
        EXP: 'payment-expiration',
        CAN: 'payment-cancellation',
      };

      return status ? statusMapping[status] : '';
    },
    paymentStatus(status) {
      const statusMapping = {
        PND: 'payment.waiting',
        CMP: 'payment.completed',
        EXP: 'payment.expiration',
        CAN: 'payment.cancellation',
      };

      return status ? this.$t(statusMapping[status]) : '';
    },
    isAllTicketsCanceled(passengers) {
      return passengers.every(passenger => {
        const { leaveRooms, returnRoom } = passenger.tickets;
        return leaveRooms.status === 'CAN' && (!returnRoom || returnRoom.status === 'CAN');
      });
    },
  },
};
</script>

<style scoped>
.pc {
  display: none !important;
}

.booking-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.booking-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.booking-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding-right: 16px;
}

.booking-number > div {
  font-weight: 600;
}

.booking-info {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--Grey-grey-100);
  margin-bottom: 10px;
  font-size: 14px;
}

.booking-info:last-child {
  margin-bottom: 0;
}

.booking-description {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.booking-name-customer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.account-number {
  color: #f00;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

.passenger-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.passenger-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 34px;
}

.passenger-info:not(:last-child) {
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
  padding-bottom: 16px;
}

.passenger-info > span {
  flex: 1 0;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 4px;
}

.user-detail {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--Grey-grey-700);
  background: var(--White);
  color: var(--Grey-grey-700);
  font-size: 12px;
  font-weight: 400;
}

.gender-icon {
  width: 14px;
  height: 14px;
}

.location {
  display: flex;
  gap: 3px;
  font-weight: 500;
}

.location > * {
  font-weight: 500;
}

.close-btn,
.confirm-btn {
  width: 100%;
  height: 54px;
  font-size: 18px;
  border-radius: 0;
}

.close-btn {
  background: var(--Grey-grey-50);
  color: var(--Grey-grey-500, var(--Disabled));
  font-size: 18px;
  font-weight: 600;
  border: none;
}

.close-btn.mobile:hover {
  background: var(--Grey-grey-100);
  color: var(--Grey-grey-700);
  border: none;
}

.bold {
  font-weight: 600;
}

.refund_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cancel_info {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #dddfe2;
}

.cancel_title,
.cancel_price,
.cancel_fee {
  color: var(--Grey-grey-500, var(--Disabled, #9299a0));
  font-size: 14px;
  font-weight: 500;
}

.refund_title {
  color: var(--Grey-grey-900);
  font-size: 16px;
  font-weight: 500;
}

.refund_amount {
  color: var(--Grey-grey-900);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.agreement_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.agree_text {
  font-size: 14px;
  cursor: pointer;
}

.cancel_btn {
  text-decoration: underline;
}

.payment-waiting {
  color: var(--Orange-orange-400, #f78865) !important;
}

.payment-completed {
  color: var(--Blue-blue-500, #446ceb) !important;
}

.payment-expiration {
  color: var(--Orange-orange-500, #f64852) !important;
}

.payment-cancellation {
  color: red !important;
}

@media screen and (min-width: 768px) {
  :deep(.modal-contents) {
    width: 100%;
    max-width: 1070px;
  }

  :deep(.content-wrapper) {
    padding: 16px 16px 0;
  }

  .booking-list-wrapper {
    gap: 16px;
  }

  .mobile {
    display: none !important;
  }

  .pc {
    display: block !important;
  }

  .booking-number {
    font-size: 16px;
  }

  .booking-list {
    max-height: 449px;
  }

  .booking-info {
    flex-direction: row;
  }

  .booking-info:last-child {
    margin-bottom: 0;
  }

  .booking-description {
    padding: 20px;
    flex-direction: row;
    justify-content: space-between;
  }

  .booking-name-customer {
    justify-content: center;
    gap: 24px;
  }

  .passenger-info {
    flex-direction: row;
    padding: 0;
  }

  .passenger-info:not(:last-child) {
    border-bottom: none;
  }

  .agreement_wrapper {
    margin-bottom: 79px;
  }

  :deep(.modal-btns) {
    width: 100%;
    max-width: 1070px;
  }

  .close-btn,
  .confirm-btn {
    width: 160px;
    height: 50px;
    border-radius: 5.102px;
  }

  .close-btn {
    color: var(--Blue-blue-500);
    border: 1px solid var(--Blue-blue-500);
    background: var(--White);
  }

  .refund_wrapper {
    width: 50%;
  }

  .cancel_title,
  .cancel_price,
  .cancel_fee {
    font-size: 14px;
  }

  .refund_title {
    font-size: 16px;
    font-weight: 500;
  }

  .refund_amount {
    font-size: 22px;
    font-weight: 600;
  }

  :deep(.text-btn) {
    font-size: 14px;
  }

  .agree_text {
    font-size: 14px;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
}

/********************************************************
   component table
********************************************************/
.wrap_table {
  overflow: auto;
}

/********************************************************
  Detail Table
********************************************************/

.detail_table {
  width: 100%;
}
.wrap_table + .check_info {
  margin-top: 16px;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  border-left: none;
  line-height: 60%;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  background-color: #f5f7f9;
  white-space: nowrap;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tbody tr th.bg_th .txt_sub {
  font-size: 18px;
  font-weight: normal;
}
.detail_table tr td {
  white-space: nowrap;
  padding: 16px;
  font-size: 18px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
  line-height: 60%;
}
.detail_table .txt_no_pw {
  white-space: nowrap;
}
.detail_table .txt_pw {
  white-space: pre-wrap;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
</style>
