<template>
  <CruiseModal :showModal="showModal" :showCloseIcon="isShowCloseIcon" :title="title" class="cancel-modal">
    <div class="content-wrapper">
      <div>
        <div class="search-desc">{{ $t('searchAccount.yourInformation') }}</div>
        <div class="search-info">
          <b>{{ $t('labels.name') }}: </b>{{ searchResult.name }}
        </div>
        <div class="search-info">
          <b>{{ $t('labels.userId') }}: </b>{{ searchResult.userId }}
        </div>
        <div class="search-info" v-if="!isSearchTypeId">
          <b>{{ $t('labels.tempPassword') }}: </b>{{ searchResult.password }}
        </div>
        <p v-if="!isSearchTypeId" class="notice">
          {{ $t('searchAccount.tempPasswordLGuide') }}
        </p>
      </div>

      <div class="btn-wrapper">
        <CruiseButton class="bottom-btn" @click="goPage('')">{{ $t('searchAccount.goToLogin') }}</CruiseButton>
        <CruiseButton v-if="isSearchTypeId" class="bottom-btn" theme="s" @click="goPage('/search/pw')">
          {{ $t('login.findPassword') }}
        </CruiseButton>
      </div>
    </div>
  </CruiseModal>
</template>

<script>
import CruiseModal from '@/components/modal/CruiseModal.vue';
import CruiseButton from '@/components/button/CruiseButton.vue';

export default {
  name: 'LoginSearchModal',
  components: { CruiseModal, CruiseButton },
  emits: ['update:showModal'],
  props: {
    showModal: Boolean,
    title: String,
    searchType: {
      type: String,
      validator: value => ['id', 'pw'].includes(value),
    },
    searchResult: Object,
  },
  data() {
    return {
      isShowCloseIcon: false,
    };
  },
  computed: {
    isSearchTypeId() {
      return this.searchType === 'id';
    },
  },
  methods: {
    updateShowModal(value) {
      this.$emit('update:showModal', value);
    },
    goPage(type) {
      this.updateShowModal(!this.showModal);

      const path = `/login${type}`;
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 20px 16px 70px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.search-desc {
  font-size: 20px;
  margin-bottom: 60px;
  text-align: center;
}

.search-info {
  font-size: 23px;
  margin-bottom: 25px;
}

.notice {
  font-size: 12px;
  color: var(--Red);
}

.btn-wrapper {
  padding: 10px 16px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  gap: 16px;
  background-color: var(--White);
}

.bottom-btn {
  flex: 1 0;
}

@media screen and (min-width: 768px) {
  :deep(.cancel-modal) {
    width: 852px;
    height: auto;
  }

  .content-wrapper {
    padding: 10px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .search-desc {
    text-align: left;
  }

  .btn-wrapper {
    padding: 0;
    width: auto;
    position: static;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
  }

  .bottom-btn {
    flex: none;
  }
}
</style>
