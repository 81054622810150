<template>
  <div class="date-item" :class="disabledClass" :disabled="disabled">
    <span v-if="!date" class="date-label" :class="disabledClass">{{ $t(label) }}</span>
    <span v-else class="date-label">{{ dateLabel }}</span>
  </div>
</template>
<script>
import { YYYY_MM_DD } from '@/const/const';

export default {
  name: 'DateItem',
  props: {
    label: String,
    date: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dateLabel() {
      return this.date?.format(YYYY_MM_DD);
    },
    disabledClass() {
      return {
        'gray-color': this.disabled,
      };
    },
  },
};
</script>

<style scoped>
.date-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 20px;
}

.date-item span {
  font-weight: 500;
  line-height: 20px;
}

.date-label {
  cursor: default;
  width: 100%;
  color: var(--Grey-grey-900);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
}

.gray-color {
  color: var(--Grey-grey-200);
}
</style>
