<template>
  <div class="tabs-wrapper">
    <CruiseDistributor tag="ul" :class="tabClass" :theme="theme" :header="true" :size="size" :changeTabTo="changeTabTo">
      <slot></slot>
    </CruiseDistributor>
    <slot name="common"></slot>

    <CruiseDistributor class="tab-contents">
      <slot></slot>
    </CruiseDistributor>
  </div>
</template>

<script>
import CruiseDistributor from '@/components/tab/CruiseDistributor.vue';

export default {
  name: 'CruiseTabs',
  emits: ['update:activeTab'],
  components: { CruiseDistributor },
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      validator: value => ['group', 'group center', 'blue', 'primary-button'].includes(value),
    },
    size: String,
  },
  watch: {
    activeTab(value) {
      this.activeTabIndex = value || 0;
    },
  },
  data() {
    return {
      activeTabIndex: this.activeTab || 0,
    };
  },
  provide() {
    const tabs = {};

    Object.defineProperty(tabs, 'activeTab', {
      get: () => this.activeTabIndex,
    });
    return { tabs };
  },
  computed: {
    tabClass() {
      return ['tab-nav', this.theme];
    },
  },
  methods: {
    changeTabTo(tab) {
      this.activeTabIndex = tab;
      this.$emit('update:activeTab', tab);
    },
  },
};
</script>

<style scoped>
.tabs-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.tab-nav {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid var(--Grey-grey-100, #dddfe2);
  overflow: auto hidden;
  padding: 0 8px;
}

/* Tab Theme : Group */
.tab-nav.group {
  padding: 0;
  height: 40px;
  border-bottom: none;
}

/* Tab Theme : Group */
.tab-nav.group.center {
  justify-content: center;
}

/* Tab Theme : Blue */
.tab-nav.blue {
  padding: 0;
  height: 42px;
}

.tab-nav.primary-button {
  display: flex;
  gap: 12px;
  border-bottom: none;
}

.tab-contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .tab-nav {
    padding: 0;
    height: 60px;
  }
}
</style>
