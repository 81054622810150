export const regExp = {
  number: /^[0-9]+$/,
  notNumber: /[^0-9]/g,
  notEnglish: /[^a-zA-Z]/g,
  upperCase: /^[A-Z]+$/,
  notUpperCase: /[^A-Z]/g,
  id: /^[A-Za-z0-9]{6,12}$/, // 6~12 characters, English, Number
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{8,20}$/, // 8~20 characters, Includes English+Number+Special Character ,
  birth: /^(\d{4})\.(\d{2})\.(\d{2})$/, // YYYY.MM.DD
  email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  dot: /\./g,
};

export const validators = {
  isUpperCase: text => regExp.upperCase.test(text.trim()),
  isNumber: text => regExp.number.test(text.trim()),
  isValidId: text => regExp.id.test(text.trim()),
  isValidPassword: text => regExp.password.test(text.trim()),
  isValidBirth: text => regExp.birth.test(text.trim()),
  isValidEmail: text => regExp.email.test(text.trim()),
};
