import api from '@/services/api';

const API_URL = '/api/softbankPayment';

class SbPaymentService {
  async getDateAndHash(params) {
    try {
      const response = await api.post(`${API_URL}/getDateAndHash`, params);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSoftbankPayment(params) {
    try {
      const response = await api.get(`${API_URL}/getSoftbankPayment`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new SbPaymentService();
