<template>
  <div class="cruise-wrapper">
    <div class="cruise-header container-default-renewal">
      <div class="header-title">{{ $t('home.newPanstarCruise2024') }}</div>
      <div class="header-desc">{{ $t('home.variousOperationProducts') }}</div>
    </div>
    <swiper
      class="cruise-swiper-wrapper"
      :class="$i18n.locale === 'ko' ? '' : 'pd-20'"
      v-bind="swiperOptions"
      @init="initCruiseSwiper"
      @slideChangeTransitionStart="addCustomClassAtStart"
      @slideChangeTransitionEnd="addCustomClassAtEnd"
      @loopFix="addCustomClassAtEndpoint"
    >
      <swiper-slide class="cruise-slide-wrapper" v-for="item in cruiseList" @click="goToPage(item.path)">
        <div class="cruise-slide-inner" :class="cruiseTypeClass(item.key)">
          <div class="cruise-slide-img"></div>
          <div class="cruise-bg"></div>
          <div class="cruise-slide-text-wrapper" :class="cruiseTypeClass(item.key)">
            <div class="cruise-slide-sub">{{ $t(item.subTitle) }}</div>
            <div class="cruise-slide-title">{{ $t(item.title) }}</div>
            <div class="cruise-slide-text">{{ $t(item.tags) }}</div>
            <div class="cruise-slide-desc">{{ $t(item.operation) }}</div>
            <div class="cruise-slide-price">
              {{ convertCommaNumber(item.price)
              }}<span
                >{{ $i18n.locale !== 'en' ? '' : '&nbsp;'
                }}{{ $i18n.locale !== 'ja' ? $t('currency.krw') : $t('currency.jpy') }}~</span
              >
            </div>
          </div>
        </div>
      </swiper-slide>

      <!-- Navigation -->
      <div class="navigation-container position-center">
        <div class="cruise-swiper-button-prev"></div>
        <div class="cruise-swiper-button-next"></div>
      </div>
    </swiper>
  </div>
</template>

<script>
import i18n from '@/languages/i18n';
import { convertCommaNumber } from '@/utils/convert';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
  name: 'CruiseSlider',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiperOptions: {
        modules: [Navigation],
        loop: true,
        speed: 300,
        slidesPerView: 3,
        spaceBetween: 18,
        centeredSlides: true,
        preventClicks: true,
        speed: 300,
        loopedSlides: 3, // this.cruiseList.length
        navigation: {
          nextEl: '.cruise-swiper-button-next',
          prevEl: '.cruise-swiper-button-prev',
        },
        breakpoints: {
          768: {
            spaceBetween: 18,
            slidesPerView: 3,
          },
          1352: {
            spaceBetween: 56,
            slidesPerView: 'auto',
          },
        },
      },

      originalCruiseList: [
        {
          key: 'osaka',
          title: 'home.osaka.title',
          subTitle: 'home.osaka.subTitle',
          tags: 'home.osaka.tags',
          operation: 'home.osaka.operation',
          price: `${i18n.global.locale !== 'ja' ? '100000' : '12000'}`,
          imgUrl: '',
          path: '/product/osaka/info',
        },
        {
          key: 'oneNight',
          title: 'home.oneNight.title',
          subTitle: 'home.oneNight.subTitle',
          tags: 'home.oneNight.tags',
          operation: 'home.oneNight.operation',
          price: '130000',
          imgUrl: '',
          path: '/product/one-night/info',
        },
        {
          key: 'express',
          title: 'home.tsushima.title',
          subTitle: 'home.tsushima.subTitle',
          tags: 'home.tsushima.tags',
          operation: 'home.tsushima.operation',
          price: `${i18n.global.locale !== 'ja' ? '90000' : '9500'}`,
          imgUrl: '',
          path: '/product/daemado/info',
        },
      ],
    };
  },
  computed: {
    cruiseList() {
      if (this.$i18n.locale === 'ja') {
        return this.originalCruiseList.filter(item => item.key !== 'oneNight');
      }

      return this.originalCruiseList;
    },
  },
  methods: {
    convertCommaNumber,

    initCruiseSwiper(swiper) {
      this.modifyCustomClass(swiper, 'changed');
    },
    addCustomClassAtStart(swiper) {
      this.modifyCustomClass(swiper, 'changing', 'changed');
    },
    addCustomClassAtEnd(swiper) {
      this.modifyCustomClass(swiper, 'changed', 'changing');
    },
    addCustomClassAtEndpoint(swiper) {
      const isEndpoint = swiper.realIndex === 0 || swiper.realIndex === this.cruiseList.length - 1;
      if (isEndpoint) this.modifyCustomClass(swiper, 'changed', 'changing');
    },
    modifyCustomClass(swiper, addClass, removeClass) {
      this.$nextTick(() => {
        swiper.slides.forEach(slide => {
          if (addClass) slide.classList.add(addClass);
          if (removeClass) slide.classList.remove(removeClass);
        });
      });
    },
    goToPage(path) {
      this.$router.push(path);
    },
    cruiseTypeClass(key) {
      if (key === 'osaka') {
        return 'osaka';
      } else if (key === 'oneNight') {
        return 'busan';
      } else {
        return 'daemado';
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
/* Operation Product Contents */
.cruise-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 445px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
}

.cruise-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.header-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.5px;
}

.header-desc {
  color: var(--Grey-grey-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

/* Operation Product Slide */
.cruise-swiper-wrapper {
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 762px;
}
.cruise-swiper-wrapper.pd-20 {
  padding-top: 20px;
}

:deep(.swiper-slide) {
  cursor: pointer;
}

.cruise-slide-wrapper {
  width: 100%;
}

.cruise-slide-inner {
  width: 242px;
  height: 340px;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--White);
  white-space: pre-wrap;
}

.cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner {
  border: 2px solid var(--Blue-blue-500);
}

.main-slide-img-wrapper {
  width: 100%;
  height: 100%;
}

.cruise-slide-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--above);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.cruise-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 184px;
  z-index: var(--above);
}

.cruise-slide-text-wrapper {
  width: 100%;
  color: var(--White);
  z-index: var(--above);
  padding: 34px 0 0 20px;
}

.cruise-slide-sub {
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 6px;
}

.cruise-slide-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.257px;
  margin-bottom: 16px;
}

.cruise-slide-text {
  display: none;
}

.cruise-slide-desc {
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
  border: 1px solid var(--White);
  margin-bottom: 8px;
  padding: 3px 5px;
}

.cruise-slide-price {
  color: var(--Grey-grey-900);
  color: var(--White);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
}

.cruise-slide-price span {
  font-size: 14px;
  font-weight: 400;
}

/* Operation Product Slide : OsakaC ruise */
.cruise-slide-inner.osaka .cruise-slide-img {
  background-position-y: 70px;
  background-image: url('@/assets/images/home/cruiseSlide/cruise_mobile_01.png');
}

.cruise-slide-inner.osaka .cruise-bg {
  background: linear-gradient(
    180deg,
    #fff 40%,
    rgba(255, 255, 255, 0.71) 70.39%,
    rgba(255, 255, 255, 0.39) 89.26%,
    rgba(255, 255, 255, 0) 100%
  );
}

.cruise-slide-inner.osaka .cruise-slide-text-wrapper {
  color: var(--Blue-blue-500);
}

.cruise-slide-inner.osaka .cruise-slide-desc {
  border: 1px solid var(--Blue-blue-500, #446ceb);
}

.cruise-slide-inner.osaka .cruise-slide-price {
  color: var(--Grey-grey-900);
}

/* Operation Product Slide : Busan One Night Cruise */
.cruise-slide-inner.busan .cruise-slide-img {
  background-image: url('@/assets/images/home/cruiseSlide/cruise_mobile_02.png');
  background-color: #252443;
  background-position-y: bottom;
}

/* Operation Product Slide : Tsushima Express */
.cruise-slide-inner.daemado .cruise-bg {
  height: 260px;
  background: linear-gradient(180deg, #a9b8d5 47.07%, rgba(147, 173, 212, 0) 85.58%);
}

.cruise-slide-inner.daemado .cruise-slide-img {
  background-color: #a9b8d5;
  background-position-y: 120px;
  background-position-x: center;
  background-image: url('@/assets/images/home/cruiseSlide/cruise_mobile_03.png');
}

.navigation-container {
  display: none;
}

@media screen and (min-width: 768px) {
  /* Operation Product Contents */
  .cruise-wrapper {
    gap: 60px;
    height: 100%;
  }

  .header-title {
    font-size: 34px;
    line-height: 36px;
  }

  .header-desc {
    font-size: 20px;
    line-height: 24px;
  }

  /* Operation Product Slide */
  .cruise-swiper-wrapper {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    max-width: 1678px;
    padding: 0;
  }

  :deep(.cruise-swiper-wrapper .swiper-slide) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 361px;
    height: 377px;
    transition: none;
    overflow: visible;
  }

  .cruise-slide-inner {
    width: 100%;
    height: 100%;
  }

  .cruise-swiper-wrapper .swiper-slide .cruise-slide-inner {
    display: block;
    background: var(--White);
    position: absolute;
    border-radius: 42px;
    overflow: hidden;
  }

  .cruise-swiper-wrapper .swiper-slide .cruise-slide-inner .cruise-slide-img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-position: bottom right;
    z-index: 1;
  }

  .cruise-bg {
    width: 100%;
    height: 208px;
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-bg {
    width: 432px;
    height: 100%;
  }

  .cruise-slide-sub {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .cruise-slide-title {
    font-size: 36.286px;
    font-weight: 600;
    line-height: 34.286px;
    letter-spacing: 0.257px;
    margin-bottom: 24px;
  }

  .cruise-slide-price {
    display: none;
  }

  .cruise-swiper-wrapper .swiper-slide .cruise-slide-inner .cruise-slide-text-wrapper {
    display: flex;
    overflow: hidden;
    position: absolute;
    font-size: 14px;
    flex-direction: column;
    justify-content: space-between;
    padding: 45px 30px 0 30px;
  }

  .cruise-swiper-wrapper .swiper-slide.swiper-slide-active .cruise-slide-inner .cruise-slide-text-wrapper,
  .cruise-swiper-wrapper .swiper-slide.swiper-slide-active .cruise-slide-inner .cruise-slide-text-wrapper {
    padding: 55px 0 0 30px;
  }

  .cruise-slide-text {
    color: var(--White);
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 64px;
  }

  .cruise-slide-desc,
  .cruise-slide-price {
    display: none;
  }

  .cruise-slide-desc {
    font-size: 15px;
    font-weight: 500;
    padding: 5px;
    margin-bottom: 8px;
  }

  .cruise-slide-price {
    display: none;
    font-size: 38.357px;
  }

  .cruise-slide-price span {
    font-size: 25.571px;
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-text,
  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-desc,
  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-price {
    display: block;
  }

  /* Operation Product Slide : Osaka Cruise */
  .cruise-slide-inner.osaka .cruise-slide-text {
    color: #6d6d6d;
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.osaka .cruise-slide-img {
    background-image: url('@/assets/images/home/cruiseSlide/cruise_01.png');
  }

  .cruise-slide-inner.osaka .cruise-slide-img {
    background-position-y: 0;
    background-image: url('@/assets/images/home/cruiseSlide/cruise_01_s.png');
  }

  .cruise-slide-inner.osaka .cruise-bg {
    height: 241px;
    background: linear-gradient(180deg, #fff 28.76%, rgba(255, 255, 255, 0) 82.16%);
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.osaka .cruise-bg {
    height: 100%;
    background: linear-gradient(
      90deg,
      #fff 74.11%,
      rgba(255, 255, 255, 0.94) 82.77%,
      rgba(255, 255, 255, 0.83) 87.9%,
      rgba(255, 255, 255, 0.47) 94.32%,
      rgba(255, 255, 255, 0) 100.42%
    );
  }

  /* Operation Product Slide : Busan One Night Cruise */
  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.busan .cruise-slide-img {
    background-image: url('@/assets/images/home/cruiseSlide/cruise_02.png');
  }

  .cruise-slide-inner.busan .cruise-slide-img {
    background-image: url('@/assets/images/home/cruiseSlide/cruise_02_s.png');
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.busan .cruise-bg {
    background: linear-gradient(
      90deg,
      #2a2b48 74.11%,
      rgba(43, 44, 73, 0.94) 82.77%,
      rgba(43, 44, 73, 0.83) 87.9%,
      rgba(43, 44, 73, 0.47) 94.32%,
      rgba(43, 44, 73, 0) 100.42%
    );
  }

  /* Operation Product Slide : Tsushima Express */
  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.daemado .cruise-slide-img {
    background-image: url('@/assets/images/home/cruiseSlide/cruise_03.png');
  }

  .cruise-slide-inner.daemado .cruise-slide-img {
    background-position-y: bottom;
    background-position-x: right;
    background-image: url('@/assets/images/home/cruiseSlide/cruise_03_s.png');
  }

  .cruise-slide-inner.daemado .cruise-bg {
    height: 208px;
    background: linear-gradient(180deg, #a9b8d5 47.07%, rgba(147, 173, 212, 0) 85.58%);
  }

  .cruise-slide-wrapper.swiper-slide-active .cruise-slide-inner.daemado .cruise-bg {
    height: 100%;
    background: linear-gradient(
      90deg,
      #aab9d6 74.11%,
      rgba(170, 185, 214, 0.94) 82.77%,
      rgba(170, 185, 214, 0.83) 87.9%,
      rgba(170, 185, 214, 0.47) 94.32%,
      rgba(170, 185, 214, 0) 100.42%
    );
  }

  /* Operation Product Slide : Change Element */
  :deep(.cruise-swiper-wrapper .changing),
  :deep(.cruise-swiper-wrapper .changed) {
    transform: translateX(-241px);
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  /* .cruise-swiper-wrapper .changing {
    transition: transform 0.3s;
    pointer-events: none;
  }
  .cruise-swiper-wrapper .changed {
    transition: none;
  } */

  :deep(.cruise-swiper-wrapper .changing.swiper-slide-active),
  :deep(.cruise-swiper-wrapper .changed.swiper-slide-active) {
    transform: translateX(0);
  }

  :deep(.cruise-swiper-wrapper .swiper-slide-active ~ .changing),
  :deep(.cruise-swiper-wrapper .swiper-slide-active ~ .changed) {
    transform: translateX(241px);
  }

  .cruise-swiper-wrapper .changing.swiper-slide-active .cruise-slide-inner,
  .cruise-swiper-wrapper .changed.swiper-slide-active .cruise-slide-inner {
    width: 844px;
    left: -241px;
  }

  /* .cruise-swiper-wrapper .changing .cruise-slide-inner {
    transition: all 0.3s;
  }
  .cruise-swiper-wrapper .changed .cruise-slide-inner {
    transition: none;
  } */

  /* .cruise-swiper-wrapper .changing .cruise-slide-inner .cruise-slide-img {
    transition: all 0.3s;
  }
  .cruise-swiper-wrapper .changed .cruise-slide-inner .cruise-slide-img {
    transition: none;
  } */

  /* Operation Product Slider Navigation */
  .navigation-container {
    width: 100%;
    max-width: 902px;
    transition: top 0.3s ease;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }

  .cruise-swiper-button-prev::after,
  .cruise-swiper-button-next::after {
    content: none !important;
  }

  .cruise-swiper-button-prev,
  .cruise-swiper-button-next {
    pointer-events: auto;
    cursor: pointer;
    width: 58px;
    height: 58px;
    padding: 12px;
    border-radius: 118px;
    background-color: var(--White);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('@/assets/images/icon/arrow_left_navigation_icon.svg_grey.svg');
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25), -2px 0px 3px 0px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .cruise-swiper-button-next {
    transform: scaleX(-1);
  }

  .cruise-swiper-wrapper:hover .cruise-swiper-button-prev,
  .cruise-swiper-wrapper:hover .cruise-swiper-button-next {
    opacity: 1;
  }
}
</style>
