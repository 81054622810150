<template>
  <div class="detail-wrapper container-default-renewal">
    <div class="detail-info">
      <div class="detail-title">{{ $t('tourProduct.productIntroduction') }}</div>
      <!-- <div class="detail-desc">
        {{ getLocalText(tour?.packageTour, 'contentText') }}
      </div> -->
    </div>
    <div class="detail-img">
      <img :src="getLocalText(tour?.packageTour, 'routeImageUrl')" alt="tour map" />
    </div>
  </div>
</template>

<script>
import { getLocalText } from '@/utils/convert';

export default {
  name: 'TourProductDetail',
  props: {
    tour: Object,
  },
  methods: {
    getLocalText,
  },
};
</script>

<style scoped>
.detail-wrapper {
  padding-top: 84px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.detail-title {
  font-size: 20px;
  font-weight: 700;
}

.detail-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: -0.28px;
  white-space: pre;
  word-break: keep-all;
}

.detail-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .detail-wrapper {
    margin-top: 40px;
    padding: 24px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid var(--Grey-grey-100);
    box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  }

  .detail-desc {
    width: 343px;
  }

  .detail-img {
    width: 100%;
    border-radius: 10px;
  }
}
</style>
