<template>
  <div class="count-wrap">
    <div class="count-btn flex-center" :class="disabledClass" @click="decrement">
      {{ $t('actions.delete') }}
      <div class="icon" :class="disabledMinusClass">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M13.1663 9.16536H3.83301V7.83203H13.1663V9.16536Z" fill="currentColor" />
        </svg>
      </div>
    </div>
    <span class="num">{{ count }}</span>
    <div class="count-btn flex-center" :class="disabledClass" @click="increment">
      {{ $t('actions.add') }}
      <div class="icon" :class="disabledPlusClass">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path
            d="M13.1666 9.16488H9.16658V13.1649H7.83325V9.16488H3.83325V7.83154H7.83325V3.83154H9.16658V7.83154H13.1666V9.16488Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CruiseCount',
  components: {},
  emits: ['update:count'],
  props: {
    count: {
      default: 0,
      type: Number,
    },
    minCount: {
      default: 0,
      type: Number,
    },
    maxCount: {
      type: Number,
      default: Number.MAX_VALUE,
    },
  },
  data() {
    return {};
  },
  computed: {
    disabledClass() {
      return this.minCount === this.count || this.maxCount === this.count ? 'disabled' : '';
    },
    disabledMinusClass() {
      return this.minCount === this.count ? 'disabled' : '';
    },
    disabledPlusClass() {
      return this.maxCount === this.count ? 'disabled' : '';
    },
  },
  methods: {
    decrement() {
      if (this.count > this.minCount) {
        this.updateCount(this.count - 1);
      }
    },
    increment() {
      if (this.count < this.maxCount) {
        this.updateCount(this.count + 1);
      }
    },
    updateCount(count) {
      this.$emit('update:count', count);
    },
  },
};
</script>

<style scoped>
.count-wrap {
  display: flex;
  align-items: center;
}

.count-btn {
  width: 35px;
  height: 35px;
  text-indent: -9999px;
  border-radius: 50px;
  border: 1px solid var(--Grey-grey-100);
  background: var(--White);
  cursor: pointer;
}

.count-btn.disabled {
  border: 1px solid var(--Grey-grey-100) !important;
}

.count-btn:focus,
.count-btn:active {
  border: 1px solid var(--Blue-blue-500);
}

.icon {
  color: var(--Grey-grey-600);
}

.icon.disabled {
  color: var(--Grey-grey-100);
}

.icon:focus,
.icon:active {
  color: var(--Blue-blue-500);
}

.num {
  width: 43px;
  display: flex;
  justify-content: center;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
}
</style>
