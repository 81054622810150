<template>
  <div class="label-wrapper">
    <label class="input-label" v-bind="attrs">{{ label }}<span v-if="required" class="required-star">*</span></label>
    <span class="helper-text">{{ helperText }}</span>
  </div>
</template>

<script>
import { sharedComputedProps } from '@/mixins/formMixins';

export default {
  name: 'CruiseLabel',
  mixins: [sharedComputedProps],
  props: {
    label: String,
    helperText: String,
    required: Boolean,
  },
};
</script>

<style scoped>
.label-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  font-size: 12px;
  font-weight: 500;
  color: var(--Grey-grey-900, #333);
}

.helper-text {
  color: #969696;
}

.required-star {
  color: var(--Orange-orange-500);
}

@media screen and (min-width: 768px) {
  .label-wrapper {
    font-size: 14px;
  }
}
</style>
