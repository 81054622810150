import api from '@/services/api';

const API_URL = '/api/event';

class EventService {
  async getEventList(params) {
    try {
      const response = await api.get(`${API_URL}/getList`, { params });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getEventDetail(id) {
    try {
      const response = await api.get(`${API_URL}/get`, { params: { id } });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new EventService();
