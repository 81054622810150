<template>
  <div class="layout_contents">
    <!-- Title -->
    <div class="area_title">
      <div class="txt_title">{{ $t('menu.checkInGuide') }}</div>
      <div class="txt_det">{{ $t('checkInGuide.subTitle') }}</div>
    </div>
    <!-- //Title -->

    <div class="area_contents">
      <!-- Tabs -->
      <ul class="component_tabs">
        <li v-for="(tab, index) in tabs" :key="index" :class="{ active: currentTab === index }">
          <a href="javascript:" @click="currentTab = index">{{ $t(tab) }}</a>
        </li>
      </ul>
      <!-- //Tabs -->
      <div class="wrap_tabs">
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 0">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('checkInGuide.processingTime') }}</div>
                <div class="wrap_table layout_web">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="" />
                      <col width="210px" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.courses.label') }}</th>
                        <th>{{ $t('sailingInfo.departureTime') }}</th>
                        <th>{{ $t('sailingInfo.arrivalTime') }}</th>
                        <th>{{ $t('sailingInfo.baggageCheckin') }}</th>
                        <th>{{ $t('sailingInfo.immigrationTime') }}</th>
                        <th>{{ $t('sailingInfo.boardingDeadline') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th">
                          {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.osaka') }}
                        </th>
                        <td>
                          {{ $t('sailingInfo.ports.busan')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.busanDays') }}</span> <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                      </tr>
                      <tr>
                        <th class="bg_th" rowspan="2">
                          {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.busan') }}
                        </th>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaDepartureDays') }}</span> <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>14:00 ~ 14:30</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                      </tr>
                      <tr>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaArrivalDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '17:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 12:00</td>
                        <td>15:00 ~ 16:00</td>
                        <td>15:00 ~ 16:00</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '16:40' }) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="wrap_table layout_mobile">
                  <table class="detail_table">
                    <colgroup>
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.courses.label') }}</th>
                        <th class="bg_th">
                          {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.osaka') }}
                        </th>
                        <th class="bg_th" colspan="2">
                          {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.busan') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.departureTime') }}</th>
                        <td>
                          {{ $t('labels.busanPort')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.busanDays') }}</span>
                          <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaDepartureDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaArrivalDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '17:00' }) }}
                        </td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.arrivalTime') }}</th>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>{{ $t('sailingInfo.nextDay') }} 12:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.baggageCheckinMobile') }}
                        </th>
                        <td>13:00 ~ 14:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>15:00 ~ 16:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.immigrationTimeMobile') }}
                        </th>
                        <td>13:00 ~ 14:00</td>
                        <td>14:00 ~ 14:30</td>
                        <td>15:00 ~ 16:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.boardingDeadlineMobile') }}
                        </th>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '16:40' }) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <hr class="hr_line" />
              </li>
              <li>
                <div class="label_title">{{ $t('checkInGuide.atLeaving') }}</div>

                <div class="txt_detail" v-for="item in busanDepartureSteps">
                  <div class="txt_strong">{{ item.instruction }}</div>
                  <ul class="list_dot">
                    <li>{{ item.details }}</li>
                  </ul>
                </div>
              </li>
              <li>
                <hr class="hr_line" />
              </li>
              <li>
                <div class="label_title">{{ $t('checkInGuide.atReturn') }}</div>

                <div class="txt_detail" v-for="item in busanArrivalSteps">
                  <div class="txt_strong">{{ item.instruction }}</div>
                  <ul class="list_dot">
                    <li v-if="typeof item.details === 'string'">{{ item.details }}</li>
                    <li v-if="typeof item.details === 'object'">{{ item.details[0] }}</li>
                    <li v-if="typeof item.details === 'object'">{{ item.details[1] }}</li>
                  </ul>
                </div>
                <div class="txt_detail">
                  <!-- Link -->
                  <div class="ui_link">
                    <a href="javascript:" class="txt_link">{{ $t('checkInGuide.koEntryCard') }}</a>
                    <a href="javascript:" class="txt_link">{{ $t('checkInGuide.koCustomsForm') }}</a>
                  </div>
                  <!-- //Link -->
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
        <!-- Tab contents  -->
        <div class="tab_content" v-show="currentTab === 1">
          <div class="view_detail">
            <ol class="area_detail">
              <li>
                <div class="label_title">{{ $t('checkInGuide.processingTime') }}</div>
                <div class="wrap_table layout_web">
                  <table class="detail_table">
                    <colgroup>
                      <col width="140px" />
                      <col width="" />
                      <col width="210px" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.courses.label') }}</th>
                        <th>{{ $t('sailingInfo.departureTime') }}</th>
                        <th>{{ $t('sailingInfo.arrivalTime') }}</th>
                        <th>{{ $t('sailingInfo.baggageCheckin') }}</th>
                        <th>{{ $t('sailingInfo.immigrationTime') }}</th>
                        <th>{{ $t('sailingInfo.boardingDeadline') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th">
                          {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.osaka') }}
                        </th>
                        <td>
                          {{ $t('sailingInfo.ports.busan')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.busanDays') }}</span> <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                      </tr>
                      <tr>
                        <th class="bg_th" rowspan="2">
                          {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.busan') }}
                        </th>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaDepartureDays') }}</span> <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>14:00 ~ 14:30</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                      </tr>
                      <tr>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaArrivalDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '17:00' }) }}
                        </td>
                        <td>{{ $t('sailingInfo.nextDay') }} 12:00</td>
                        <td>15:00 ~ 16:00</td>
                        <td>15:00 ~ 16:00</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '16:40' }) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="wrap_table layout_mobile">
                  <table class="detail_table">
                    <colgroup>
                      <col width="" />
                      <col width="" />
                      <col width="" />
                      <col width="" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>{{ $t('sailingInfo.courses.label') }}</th>
                        <th class="bg_th">
                          {{ $t('sailingInfo.routes.busan') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.osaka') }}
                        </th>
                        <th class="bg_th" colspan="2">
                          {{ $t('sailingInfo.routes.osaka') }}<em class="arrow_go"></em
                          >{{ $t('sailingInfo.routes.busan') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.departureTime') }}</th>
                        <td>
                          {{ $t('labels.busanPort')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.busanDays') }}</span>
                          <br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaDepartureDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '15:00' }) }}
                        </td>
                        <td>
                          {{ $t('sailingInfo.ports.osaka')
                          }}<span class="txt_sub">{{ $t('sailingInfo.ports.osakaArrivalDays') }}</span
                          ><br />
                          {{ $t('sailingInfo.ports.aroundTime', { time: '17:00' }) }}
                        </td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">{{ $t('sailingInfo.arrivalTime') }}</th>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>{{ $t('sailingInfo.nextDay') }} 10:00</td>
                        <td>{{ $t('sailingInfo.nextDay') }} 12:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.baggageCheckinMobile') }}
                        </th>
                        <td>13:00 ~ 14:00</td>
                        <td>13:00 ~ 14:00</td>
                        <td>15:00 ~ 16:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.immigrationTimeMobile') }}
                        </th>
                        <td>13:00 ~ 14:00</td>
                        <td>14:00 ~ 14:30</td>
                        <td>15:00 ~ 16:00</td>
                      </tr>
                      <tr>
                        <th class="bg_th txt_center">
                          {{ $t('sailingInfo.boardingDeadlineMobile') }}
                        </th>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '14:40' }) }}</td>
                        <td>{{ $t('sailingInfo.ports.untilTime', { time: '16:40' }) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <hr class="hr_line" />
              </li>
              <li>
                <div class="label_title">{{ $t('checkInGuide.atLeaving') }}</div>

                <div class="txt_detail" v-for="item in osakaDepartureSteps">
                  <div class="txt_strong">{{ item.instruction }}</div>
                  <ul class="list_dot">
                    <li>{{ item.details }}</li>
                  </ul>
                </div>
              </li>
              <li>
                <hr class="hr_line" />
              </li>
              <li>
                <div class="label_title">{{ $t('checkInGuide.atReturn') }}</div>

                <div class="txt_detail" v-for="item in osakaArrivalSteps">
                  <div class="txt_strong">{{ item.instruction }}</div>
                  <ul class="list_dot">
                    <li v-if="typeof item.details === 'string'">{{ item.details }}</li>
                    <li v-if="typeof item.details === 'object'">{{ item.details[0] }}</li>
                    <li v-if="typeof item.details === 'object'">{{ item.details[1] }}</li>
                  </ul>
                </div>
                <div class="txt_detail">
                  <!-- Link -->
                  <div class="ui_link">
                    <a href="javascript:" class="txt_link">{{ $t('checkInGuide.jaEntryCard') }}</a>
                    <a href="javascript:" class="txt_link">{{ $t('checkInGuide.jaCustomsForm') }}</a>
                  </div>
                  <!-- //Link -->
                </div>
              </li>
            </ol>
          </div>
        </div>
        <!-- //Tab contents  -->
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/languages/i18n';

export default {
  name: 'OsakaProcedure',
  data() {
    return {
      currentTab: 0,
      tabs: ['sailingInfo.routes.busan', 'sailingInfo.routes.osaka'],

      busanDepartureSteps: [],
      busanArrivalSteps: [],
      osakaDepartureSteps: [],
      osakaArrivalSteps: [],
    };
  },
  methods: {
    getOsakaList() {
      const locale = i18n.global.locale;
      const osakaCruise = i18n.global.messages[locale].checkInGuide.osakaCruise;

      this.busanDepartureSteps = osakaCruise.busan.departureSteps;
      this.busanArrivalSteps = osakaCruise.busan.arrivalSteps;

      this.osakaDepartureSteps = osakaCruise.osaka.departureSteps;
      this.osakaArrivalSteps = osakaCruise.osaka.arrivalSteps;
    },
  },
  created() {
    this.getOsakaList();
  },
};
</script>

<style scoped>
/********************************************************
   component tabs
********************************************************/
.component_tabs {
  margin-bottom: 60px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #446ceb;
}
.component_tabs li {
  flex: auto;
  width: 100%;
}
.component_tabs li + li {
  border-left: 1px solid #446ceb;
}
.component_tabs li a {
  display: block;
  line-height: 68px;
  font-size: 24px;
  text-align: center;
  background-color: #fff;
  color: #446ceb;
  font-weight: 600;
}
.component_tabs li.active a {
  background-color: #446ceb;
  color: #fff;
}
@media (max-width: 767px) {
  .component_tabs {
    margin-bottom: 24px;
  }
  .component_tabs li a {
    line-height: 48px;
    font-size: 16px;
  }
}
.view_detail {
  display: flex;
}
.view_detail .area_detail {
  flex: auto;
  width: 100%;
}
.view_detail .area_detail > li .hr_line {
  border: none;
  border-top: 1px solid #dddfe2;
}
.view_detail .area_detail > li + li {
  margin-top: 60px;
}
.view_detail .area_detail .label_title {
  color: #304da7;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 28px;
}
.view_detail .area_detail .label_title .txt_sub {
  font-size: 20px;
  font-weight: 500;
  color: #304da7;
}
.view_detail .area_detail .txt_detail {
  color: #686d72;
  font-size: 20px;
  line-height: 30px;
}
.view_detail .area_detail .txt_detail .ui_link {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.view_detail .area_detail .txt_detail .ui_link .txt_link {
  display: inline-block;
  border: 1px solid #829df2;
  border-radius: 5px;
  line-height: 44px;
  padding: 0 15px;
  color: #304da7;
  background-color: #ecf0fd;
  font-size: 22px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .view_detail .area_detail .hr_line {
    margin: -36px 0;
  }
  .view_detail .area_detail .txt_detail .ui_link {
    gap: 10px 15px;
  }
  .view_detail .area_detail .txt_detail .ui_link .txt_link {
    line-height: 40px;
    font-size: 18px;
  }
}
.view_detail .area_detail .txt_detail .txt_strong {
  color: #505458;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.view_detail .area_detail .txt_detail + .txt_detail {
  margin-top: 32px;
}
@media (max-width: 767px) {
  .view_detail .area_detail .txt_detail .txt_strong {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 24px;
  }
  .view_detail .area_detail .txt_detail + .txt_detail {
    margin-top: 16px;
  }
}
.view_detail .area_detail .txt_detail .label_detail {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 24px;
  color: #505458;
}
.view_detail .area_detail .txt_detail .list_dot li {
  padding-left: 30px;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li strong {
  font-weight: 600;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li .list_sub {
  margin-top: 4px;
  font-size: 20px;
  color: #686d72;
}
.view_detail .area_detail .txt_detail .list_dot li + li {
  margin-top: 8px;
}
.view_detail .area_detail .txt_detail .list_dot li:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  margin: 13px;
  border-radius: 50%;
  content: '';
  background-color: #b6bbbf;
  flex: none;
  content: '';
}
.view_detail .area_detail + .area_detail {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .view_detail {
    flex-direction: column;
  }
  .view_detail .area_detail .label_title {
    font-size: 22px;
  }
  .view_detail .area_detail .txt_detail {
    font-size: 16px;
    line-height: 20px;
  }
  .view_detail .area_detail .txt_detail .label_detail {
    font-size: 18px;
    margin-bottom: 12px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li {
    font-size: 16px;
    line-height: 22px;
  }
  .view_detail .area_detail .txt_detail .list_dot li:before {
    margin-top: 8px;
  }
  .view_detail .area_detail .txt_detail .list_dot li .list_sub {
    font-size: 16px;
  }
  .view_detail .area_detail + .area_detail {
    padding: 60px 0 0 0;
  }
}
.layout_mobile {
  display: none;
}
.layout_web {
  display: block;
}
@media (max-width: 767px) {
  .layout_mobile {
    display: block;
  }
  .layout_web {
    display: none;
  }
}
/********************************************************
  Detail Table
********************************************************/
.wrap_table {
  overflow: auto;
}
.detail_table {
  white-space: nowrap;
  width: 100%;
}
.detail_table thead tr th {
  padding: 16px;
  border: 1px solid #dddfe2;
  background-color: #f5f7f9;
  color: #686d72;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  border-left: none;
}
.detail_table thead tr th:last-of-type {
  border-right: none;
}
.detail_table tbody tr th.bg_th {
  white-space: pre;
  background-color: #f5f7f9;
}
.detail_table tbody tr td .txt_sub {
  font-size: 18px;
  color: #686d72;
}
.detail_table tbody tr th.bg_th.txt_center {
  text-align: center;
}
.detail_table tr td {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #686d72;
  border: 1px solid #dddfe2;
}
.detail_table tr th .arrow_go {
  display: inline-block;
  margin: 0 12px;
  width: 14px;
  height: 12px;
  background: url('@/assets/images/information/icon/arrow_go.svg') no-repeat;
}
.detail_table tr td:last-of-type {
  border-right: none;
}
.detail_table tbody tr th {
  padding: 16px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  background-color: none;
  color: #686d72;
  border: 1px solid #dddfe2;
  border-left: none;
}
.detail_table tbody tr th span {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #686d72;
}
@media (max-width: 767px) {
  .detail_table tbody tr th,
  .detail_table tbody tr td,
  .detail_table thead tr th {
    font-size: 16px;
  }
  .detail_table tbody tr th {
    text-align: left;
  }
  .detail_table tbody tr th span {
    display: block;
    font-size: 16px;
    line-height: 22px;
  }
  .detail_table tbody tr td .txt_sub {
    font-size: 14px;
  }
}
</style>
